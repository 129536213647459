import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonListHeader,
    IonNote,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar,
    useIonModal
} from "@ionic/react";
import {
    addOutline,
    addSharp,
    checkmarkOutline,
    checkmarkSharp,
    logoEuro, removeOutline, removeSharp,
    trashOutline,
    trashSharp, warningOutline, warningSharp
} from "ionicons/icons";
import SearchpanneauxPhotovoltaique from "./SearchPanneauPhotovoltaique";
import {FormProvider, Controller, useForm, useWatch, useController, useFormContext} from "react-hook-form";
import {PhotovoltaiqueWhiteIcon} from "../../Icons";
import styled from "@emotion/styled";
import {Product} from "../../../models/product.model";
import currency from "currency.js";
import React, {useEffect} from "react";
import {FormattedPlural} from "react-intl";
import {TVA_10, TVA_20, VatRate} from "../../../valueObjects/VatRate";
import { ErrorMessage } from '@hookform/error-message';
import {useProductPricingAlert, useProductPricingAlertFunc} from "../hooks/useProductPricingAlert";

export default function PanneauPhotovoltaiqueItem() {
    const title = 'Panneaux photovoltaïques';

    const product = useWatch({
        name: "panneauxPhotovoltaique.product",
    });

    return (
        <>
            {
                !product && (
                    <Controller
                        name={"panneauxPhotovoltaique.product"}
                        render={
                            ({ field, fieldState, formState, }) => {
                                return (
                                    <AddButton
                                        title={title}
                                        onProductSelected={field.onChange}
                                    />
                                )
                            }
                        }
                    />
                )
            }

            {
                product && (
                    <Card title={title} />
                )
            }
        </>
    )
}

type AddButtonProps = {
    title: string,
    onProductSelected?(product: any): void,
}

function AddButton({ title, onProductSelected }: AddButtonProps) {
    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (onProductSelected && role === 'confirm') && onProductSelected(data);
            dismiss(data, role);
        },
    });

    return (
        <IonRow>
            <IonCol>
                <IonButton color={"photovoltaique"} fill={"solid"} expand={"block"} onClick={() => present()}>
                    <IonIcon md={addSharp} ios={addOutline} slot="start"/>
                    <IonText>
                        <span>{title}</span>
                    </IonText>
                </IonButton>
            </IonCol>
        </IonRow>
    )
}

type CardProps = {
    title: string,
}

const MAX_PV = 16;
const NB_PV_POWER_3000 = 8; // nb de panneaux pour être à 3000 WATT

function Card({ title }: CardProps) {
    const {
        field
    } = useController({ name: 'panneauxPhotovoltaique.product' });

    const {setValue, formState: { errors }} = useFormContext();

    const [present, dismiss] = useIonModal(Modal, {
        onDismiss: (data: string, role: string) => {
            (field && role === 'confirm') && field.onChange(data);
            dismiss(data, role);
        },
    });

    const product = field.value as Product;

    const puissance = product.array_options?.options_puissance;

    const qty = useWatch({ name: 'panneauxPhotovoltaique.qty', defaultValue: 1 });
    const tvaTx = useWatch({ name: 'panneauxPhotovoltaique.tvaTx', defaultValue: TVA_10 });
    const totalHT = useWatch({ name: 'panneauxPhotovoltaique.totalHT', defaultValue: Number(product.price) * qty });
    const totalTTC = useWatch({ name: 'panneauxPhotovoltaique.totalTTC', defaultValue: (Number(product.price) *  qty) * (1 + (Number(tvaTx) / 100)) })

    const ht = currency(totalHT);
    const baseTva = Number(tvaTx);
    const coeffTva = baseTva / 100;
    const montantTva = ht.multiply(baseTva / 100);
    const ttc = ht.add(montantTva);

    useEffect(() => {
        // SI PUISSANCE SUPERIEUR A 3000 WATT OBLIGATION TVA 20%
        const isSup3000Watt = qty > NB_PV_POWER_3000;

        const myTvaTx = isSup3000Watt ? TVA_20 : tvaTx;

        const myQtyHt = currency(product.price as string).multiply(qty);
        const myCoeffTva = myTvaTx / 100;
        const myTtc = myQtyHt.multiply(1 + myCoeffTva);

        setValue('panneauxPhotovoltaique.totalHT', myQtyHt.value);
        setValue('panneauxPhotovoltaique.totalTTC', myTtc.value);

        if (isSup3000Watt) {
            setValue('panneauxPhotovoltaique.tvaTx', myTvaTx);
        }
    }, [qty]);

    useEffect(() => {
        const myHt = currency(totalHT);
        const myCoeffTva = tvaTx / 100;
        const myTtc = myHt.multiply(1 + myCoeffTva);
        setValue('panneauxPhotovoltaique.totalTTC', myTtc.value);
    }, [totalHT, tvaTx]);

    useEffect(() => {
        const myTtc = currency(totalTTC);
        const myHt = myTtc.divide(1 + coeffTva);
        setValue('panneauxPhotovoltaique.totalHT', myHt.value);
    }, [totalTTC]);

    const resetProduct = () => {
        field.onChange(undefined);
    }

    // Alert changement de tarif
    const alertProductPriceRange = useProductPricingAlertFunc();
    const onBlur = () => {
        alertProductPriceRange({ product, tvaTx, totalTTC, qty });
    }

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-photovoltaique)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-photovoltaique)' }} className={"ion-align-items-center"}>
                <PhotovoltaiqueWhiteIcon />

                <div style={{ marginLeft: 5 }}>
                    <IonCardSubtitle style={{ color: 'white' }}>{title}</IonCardSubtitle>
                    <IonCardTitle style={{ color: 'white' }}>
                        {product.label}
                    </IonCardTitle>
                </div>

                <IonButton fill="outline" style={{ marginLeft: 'auto' }} color={'light'} onClick={() => present()}>
                    Modifier
                </IonButton>
            </IonCardHeader>

            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <Controller
                                name={"panneauxPhotovoltaique.qty"}
                                render={
                                    ({ field }) => {
                                        const addOne = () => field.onChange(
                                            field.value < MAX_PV ? field.value + 1 : MAX_PV
                                        );
                                        const rmOne = () => field.onChange(field.value - 1);

                                        return (
                                            <Item>
                                                <IonButton type={"button"} fill={"outline"} color={"danger"} slot={"start"} size={"default"}
                                                           onClick={() => rmOne()}
                                                >
                                                    <IonIcon slot={"icon-only"} md={removeSharp} ios={removeOutline} />
                                                    1
                                                </IonButton>

                                                <IonLabel color={"photovoltaique"}><b>QTÉ TOTAL DE PANEAUX: </b></IonLabel>
                                                <IonInput type={'number'} value={field.value} onIonChange={
                                                    e => {
                                                        const nb = Number(e.detail.value);

                                                        field.onChange(nb < MAX_PV ? nb : MAX_PV)
                                                    }
                                                } />

                                                <IonButton type={"button"} fill={"outline"} color={"photovoltaique"} slot={"end"} size={"default"}
                                                           onClick={() => addOne()}
                                                >
                                                    <IonIcon slot={"icon-only"} md={addSharp} ios={addOutline} />
                                                    1
                                                </IonButton>
                                            </Item>
                                        )
                                    }
                                }
                                rules={{
                                    min: {
                                        value: 1,
                                        message: "Quantité paneaux invalide"
                                    },
                                    validate: (value: number) => {
                                        const isMonophase = product.array_options?.options_type_branchement === 'MONOPHASE';

                                        // si pas monophasé par de restrictions
                                        if (!isMonophase) {
                                            return;
                                        }

                                        const puissanceTotale = value * Number(puissance);

                                        return (puissanceTotale <= 6000 && isMonophase) || 'Erreur maximum de 6000Wc en monophasé dépassé'; // Interdire la puissance a partir de 6000Wc en monophasé
                                    }
                                }}
                                defaultValue={qty}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={"panneauxPhotovoltaique.qty"}
                                render={
                                ({ message }: any) => (
                                    <IonItem lines={"none"}>
                                        <IonIcon color="danger" md={warningSharp} ios={warningOutline} slot={'start'}/>
                                        <IonText color="danger">
                                            <small><b>{message}</b></small>
                                        </IonText>
                                    </IonItem>
                                )}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonItem>
                    <IonLabel>
                        <h2>
                            <IonText color={"photovoltaique"}><b>LE KIT COMPREND : </b></IonText>

                            <FormattedPlural value={qty} one="Panneau photovoltaïque" other="Panneaux photovoltaïques">
                                { (value) => <b>{`${qty} ${value} de ${puissance || 0} Watts`}</b> }
                            </FormattedPlural>
                        </h2>
                    </IonLabel>
                </IonItem>

                <IonItem>
                    <IonLabel>
                        <h2>
                            <IonText color={"photovoltaique"}><b>PUISSANCE TOTALE : </b></IonText>

                            {/* Calcul de la puissance totale */}
                            <b>{ (Number(qty) * Number(puissance || 0)).toFixed(2) } Watts</b>
                        </h2>
                    </IonLabel>
                </IonItem>

                <Controller
                    name={"panneauxPhotovoltaique.sourcePuissanceKit"}
                    render={
                        ({ field }) => (
                            <IonRadioGroup onIonChange={field.onChange}>
                                <IonListHeader>
                                    <IonLabel>
                                        Puissance du kit
                                    </IonLabel>
                                </IonListHeader>

                                <IonItem>
                                    <IonLabel>Ondulateur centralise</IonLabel>
                                    <IonRadio color={'photovoltaique'} value={"ONDULATEUR_CENTRALISE"} />
                                </IonItem>

                                <IonItem>
                                    <IonLabel>Micro-ondulateurs</IonLabel>
                                    <IonRadio color={'photovoltaique'} value={"MICRO_ONDULATEURS"} />
                                </IonItem>
                            </IonRadioGroup>
                        )
                } />

                <IonGrid>
                    <TotalRow>
                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>HT</IonNote>
                                <Controller
                                    name={"panneauxPhotovoltaique.totalHT"}
                                    render={
                                        ({ field }) => <IonInput placeholder="TOTAL HT" type={'number'} value={ht.toString()} onIonChange={field.onChange} onIonBlur={onBlur} />
                                    }
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>
                                    TVA
                                </IonNote>
                                <Controller
                                    name={"panneauxPhotovoltaique.tvaTx"}
                                    render={
                                        ({ field }) => (
                                            <IonSelect placeholder="TVA" value={field.value} onIonChange={field.onChange} cancelText={'Fermer'} onIonBlur={onBlur}>
                                                {
                                                    VatRate.PhotovoltaiqueRates().map(
                                                        r => (
                                                            <IonSelectOption key={r.label} value={r.value}>{r.label}</IonSelectOption>
                                                        )
                                                    )
                                                }
                                            </IonSelect>
                                        )
                                    }
                                    defaultValue={baseTva}
                                />
                                <IonNote slot={'end'}>
                                    % =
                                </IonNote>
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonInput placeholder="Montant TVA" readonly value={montantTva.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>TTC</IonNote>
                                <Controller
                                    name={"panneauxPhotovoltaique.totalTTC"}
                                    render={
                                        ({ field }) => {
                                            return <IonInput type={"number"} placeholder="TOTAL TTC" value={field.value} onIonChange={field.onChange} onIonBlur={onBlur} />
                                        }
                                    }
                                    defaultValue={ttc.toString()}
                                />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>
                    </TotalRow>

                    <IonRow className={'ion-margin-top'}>
                        <IonCol>
                            <IonButton type={"button"} expand="block" color={"danger"} onClick={() => resetProduct()}>
                                <IonIcon slot={"start"} md={trashSharp} ios={trashOutline} />

                                Supprimer ce produit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;

    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }

    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    ion-col:not(last-child) ion-item {
        --background: #e7efd0;
    }

    ion-col:last-child {
        --background: var(--ion-color-photovoltaique);
    }

    ion-col:last-child ion-note {
        color: white;
    }

    ion-col:last-child ion-item {
        --background: var(--ion-color-photovoltaique);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }

    ion-col ion-input, ion-col ion-select {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-photovoltaique);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-photovoltaique);
    }
`;

const Modal = ({ onDismiss, }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void; }) => {
    const methods = useForm();

    const handleDismiss = () => {
        const { selectedProduct } = methods.getValues();
        onDismiss(selectedProduct, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Annuler
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Recherche panneaux photovoltaïques</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <SearchpanneauxPhotovoltaique />
                </FormProvider>
            </IonContent>

            <IonButton onClick={handleDismiss} color={'photovoltaique'} expand={"block"}>
                <IonIcon md={checkmarkOutline} ios={checkmarkSharp} slot="start" />
                Valider le choix
            </IonButton>
        </IonPage>
    );
};