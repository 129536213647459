import StatelessConfirmationDialog from "./StatelessConfirmationDialog";
import {useConfirmationStore} from "./confirmation.store";

export default function ConfirmationDialog() {
    const baseProps = useConfirmationStore(s => s.props);

    const [accept, cancel] = useConfirmationStore(s => [s.accept, s.cancel]);

    return (
        <StatelessConfirmationDialog
            {...baseProps}
            onAccept={accept}
            onCancel={cancel}
        />
    )
}