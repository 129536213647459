export enum InvoiceStatus {
    DRAFT = '0', // Draft status.
    VALIDATED = '1', // Validated (need to be paid)

    /**
     * Classified paid.
     * @link https://jtraulle.github.io/dolibarr/d3/d75/class_facture.html#a2e85fa49b5aa0a754754f1aa9370e7c2
     */
    CLOSED = '2',

    /**
     * Classified abandoned and no payment done
     * @link https://jtraulle.github.io/dolibarr/d3/d75/class_facture.html#ad5f85117dc51c7ee04285e6471194f8b
     */
    ABANDONED = '3'
}

export default class Invoice {
    public brouillon?: any;
    public socid?: string;
    public fk_user_author?: string;
    public fk_user_valid?: string;
    public date?: number;
    public datem?: number;
    public date_livraison?: any;
    public delivery_date?: any;
    public ref_client?: any;
    public type?: string;
    public remise_absolue?: any;
    public remise_percent?: any;
    public total_ht?: string;
    public total_tva?: string;
    public total_localtax1?: string;
    public total_localtax2?: string;
    public total_ttc?: string;
    public revenuestamp?: string;
    public close_code?: any;
    public close_note?: any;
    public paye?: string;
    public module_source?: any;
    public pos_source?: any;
    public fk_fac_rec_source?: any;
    public fk_facture_source?: any;
    public date_lim_reglement?: number;
    public cond_reglement_code?: string;
    public mode_reglement_code?: any;
    public fk_bank?: any;

    public lines?: InvoiceLine[];
    public line?: any;
    public fac_rec?: any;
    public date_pointoftax?: string;
    public fk_multicurrency?: string;
    public multicurrency_code?: string;
    public multicurrency_tx?: string;
    public multicurrency_total_ht?: string;
    public multicurrency_total_tva?: string;
    public multicurrency_total_ttc?: string;
    public situation_cycle_ref?: any;
    public situation_counter?: any;
    public situation_final?: string;
    public retained_warranty?: string;
    public retained_warranty_date_limit?: number;
    public retained_warranty_fk_cond_reglement?: string;
    public id?: string;
    public entity?: string;
    public import_key?: any;
    public array_languages?: any;

    public linkedObjectsIds?: Record<'commande', Record<string, number>>;
    public canvas?: any;
    public fk_project?: string;
    public contact_id?: any;
    public user?: any;
    public origin?: any;
    public origin_id?: any;
    public ref?: string;
    public ref_ext?: any;
    public statut?: string;
    public status?: string;
    public country_id?: any;
    public country_code?: any;
    public state_id?: any;
    public region_id?: any;
    public mode_reglement_id?: string;
    public cond_reglement_id?: string;
    public demand_reason_id?: any;
    public transport_mode_id?: any;
    public shipping_method_id?: any;
    public model_pdf?: string;
    public last_main_doc?: string;
    public fk_account?: any;
    public note_public?: any;
    public note_private?: any;
    public name?: any;
    public lastname?: any;
    public firstname?: any;
    public civility_id?: any;
    public date_creation?: number;
    public date_validation?: number;
    public date_modification?: number;
    public specimen?: number;
    public fk_incoterms?: string;
    public label_incoterms?: any;
    public location_incoterms?: string;
    public cond_reglement_doc?: string;
    public user_author?: string;
    public user_valid?: string;
    public totalpaid?: string;
    public totalcreditnotes?: any;
    public totaldeposits?: any;
    public remaintopay?: string;
}

export class InvoiceLine {
    public fk_facture?: string;
    public fk_parent_line?: any;
    public desc?: string;
    public ref_ext?: string;
    public localtax1_type?: string;
    public localtax2_type?: string;
    public fk_remise_except?: any;
    public rang?: string;
    public fk_fournprice?: any;
    public pa_ht?: string;
    public marge_tx?: string;
    public marque_tx?: number;
    public remise_percent?: string;
    public special_code?: string;
    public origin?: any;
    public origin_id?: any;
    public fk_code_ventilation?: number;
    public date_start?: string;
    public date_end?: string;
    public situation_percent?: string;
    public fk_prev_id?: any;
    public multicurrency_subprice?: string;
    public multicurrency_total_ht?: string;
    public multicurrency_total_tva?: string;
    public multicurrency_total_ttc?: string;
    public label?: any;
    public ref?: string;
    public libelle?: string;
    public product_type?: string;
    public product_ref?: string;
    public product_label?: string;
    public product_desc?: string;
    public qty?: string;
    public subprice?: string;
    public price?: any;
    public fk_product?: string;
    public vat_src_code?: string;
    public tva_tx?: string;
    public localtax1_tx?: string;
    public localtax2_tx?: string;
    public remise?: any;
    public total_ht?: string;
    public total_tva?: string;
    public total_localtax1?: string;
    public total_localtax2?: string;
    public total_ttc?: string;
    public date_start_fill?: any;
    public date_end_fill?: any;
    public buy_price_ht?: any;
    public buyprice?: any;
    public info_bits?: string;
    public fk_user_author?: any;
    public fk_user_modif?: any;
    public id?: string;
    public rowid?: string;
    public fk_unit?: any;
    public date_debut_prevue?: any;
    public date_debut_reel?: any;
    public date_fin_prevue?: any;
    public date_fin_reel?: any;
    public entity?: any;
    public import_key?: any;
    public array_languages?: any;
    public linkedObjectsIds?: any;
    public canvas?: any;
    public statut?: any;
    public status?: any;
    public state_id?: any;
    public region_id?: any;
    public demand_reason_id?: any;
    public transport_mode_id?: any;
    public last_main_doc?: any;
    public fk_bank?: any;
    public fk_account?: any;
    public lines?: any;
    public date_creation?: any;
    public date_validation?: any;
    public date_modification?: any;
    public specimen?: number;
    public description?: string;
    public fk_product_type?: string;
    public code_ventilation?: string;
    public fk_accounting_account?: string;
}

export interface InvoiceSearchResult {
    brouillon: any
    socid: string
    fk_user_author: string
    fk_user_valid: string
    date: number
    datem: number
    date_livraison: any
    delivery_date: any
    ref_client: any
    type: string
    remise_absolue: any
    remise_percent: any
    total_ht: string
    total_tva: string
    total_localtax1: string
    total_localtax2: string
    total_ttc: string
    revenuestamp: string
    close_code: any
    close_note: any
    paye: string
    module_source: any
    pos_source: any
    fk_fac_rec_source: any
    fk_facture_source: any
    linked_objects: any[]
    date_lim_reglement: number
    cond_reglement_code: any
    mode_reglement_code: any
    fk_bank: any
    lines: Line[]
    line: any
    extraparams: any[]
    fac_rec: any
    date_pointoftax: string
    fk_multicurrency: string
    multicurrency_code: string
    multicurrency_tx: string
    multicurrency_total_ht: string
    multicurrency_total_tva: string
    multicurrency_total_ttc: string
    situation_cycle_ref: any
    situation_counter: any
    situation_final: string
    tab_previous_situation_invoice: any[]
    tab_next_situation_invoice: any[]
    retained_warranty: string
    retained_warranty_date_limit: string
    retained_warranty_fk_cond_reglement: string
    id: string
    entity: string
    validateFieldsErrors: any[]
    import_key: any
    array_options: any[]
    array_languages: any
    linkedObjectsIds: any
    canvas: any
    fk_project: string
    contact_id: any
    user: any
    origin: any
    origin_id: any
    ref: string
    ref_ext: any
    statut: string
    status: InvoiceStatus;
    country_id: any
    country_code: any
    state_id: any
    region_id: any
    barcode_type: any
    barcode_type_coder: any
    mode_reglement_id: string
    cond_reglement_id: string
    demand_reason_id: any
    transport_mode_id: any
    shipping_method_id: any
    model_pdf: any
    last_main_doc: any
    fk_account: any
    note_public: any
    note_private: any
    name: any
    lastname: any
    firstname: any
    civility_id: any
    date_creation: number
    date_validation: number
    date_modification: number
    specimen: number
    fk_incoterms: string
    label_incoterms: any
    location_incoterms: string
    cond_reglement_doc: any
    user_author: string
    user_valid: string
    totalpaid: any
    totalcreditnotes: any
    totaldeposits: any
    remaintopay: string
    has_delay: boolean;
    contacts_ids: any[];
    thirdPartyName: string;
    thirdPartyAddress: string;
    thirdPartyTown: string;
    thirdPartyZip: string;
}

export interface Line {
    fk_facture: string
    fk_parent_line: any
    desc: string
    ref_ext: string
    localtax1_type: string
    localtax2_type: string
    fk_remise_except: any
    rang: string
    fk_fournprice: any
    pa_ht: string
    marge_tx: string
    marque_tx: number
    remise_percent: string
    special_code: string
    origin: any
    origin_id: any
    fk_code_ventilation: number
    date_start: string
    date_end: string
    situation_percent: string
    fk_prev_id: any
    multicurrency_subprice: string
    multicurrency_total_ht: string
    multicurrency_total_tva: string
    multicurrency_total_ttc: string
    label: any
    ref: string
    libelle: string
    product_type: string
    product_ref: string
    product_label: string
    product_desc: string
    qty: string
    subprice: string
    price: any
    fk_product: string
    vat_src_code: string
    tva_tx: string
    localtax1_tx: string
    localtax2_tx: string
    remise: any
    total_ht: string
    total_tva: string
    total_localtax1: string
    total_localtax2: string
    total_ttc: string
    date_start_fill: any
    date_end_fill: any
    buy_price_ht: any
    buyprice: any
    info_bits: string
    fk_user_author: any
    fk_user_modif: any
    id: string
    rowid: string
    fk_unit: any
    date_debut_prevue: any
    date_debut_reel: any
    date_fin_prevue: any
    date_fin_reel: any
    entity: any
    validateFieldsErrors: any[]
    import_key: any
    array_options: any[]
    array_languages: any
    linkedObjectsIds: any
    canvas: any
    statut: any
    status: any
    state_id: any
    region_id: any
    barcode_type: any
    barcode_type_coder: any
    demand_reason_id: any
    transport_mode_id: any
    last_main_doc: any
    fk_bank: any
    fk_account: any
    lines: any
    date_creation: any
    date_validation: any
    date_modification: any
    specimen: number
    description: string
    fk_product_type: string
    code_ventilation: string
    fk_accounting_account: string
}
