import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText} from "@ionic/react";
import {useWatch} from "react-hook-form";
import {DateTime} from "luxon";
import styled from "@emotion/styled";
import {useUser} from "../../Auth/auth.store";
import {userToString} from "../../../models/user.model";

export default function EmargementItem() {
    const user = useUser();

    const city = useWatch({ name: 'customer.address.city' });
    const existingCity = useWatch({ name: 'existingCustomer.city', defaultValue: '' });
    const defaultValue = DateTime.now().toISO();
    const dateProposition = useWatch({ name: 'customer.dateProposition', defaultValue: defaultValue });
    const formatted = DateTime.fromISO(dateProposition).toLocaleString(DateTime.DATE_SHORT);

    const nomTechnicien = userToString(user);

    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <StyledItem style={{ border: '2px solid var(--ion-color-emargement)' }}>
                        <IonLabel className="ion-text-wrap">
                            <IonText color="emargement">
                                <h3>
                                   NOM DU TECHNICIEN
                                </h3>
                                <h2><b>{nomTechnicien}</b></h2>
                            </IonText>
                        </IonLabel>
                    </StyledItem>
                </IonCol>
                <IonCol size="6">
                    <StyledItem style={{ border: '2px solid var(--ion-color-emargement)' }}>
                        <IonLabel className="ion-text-wrap">
                            <IonText color="emargement">
                                <h3 className={"ion-text-uppercase"}>
                                    FAIT À <b>{city || existingCity || '.............'}</b> LE <b>{formatted || '.............'}</b>
                                </h3>
                            </IonText>
                        </IonLabel>
                    </StyledItem>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

const StyledItem = styled(IonItem)`
    --min-height: 72px;
    text-align: center;
`;