import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";
import AffaireTable from "../Affaire/AffaireTable";
import {AffaireListContext} from "../Affaire/useAffaireListContext";
import {useAffaireQuery} from "../Affaire/useAffaireQuery";
import React, {useEffect} from "react";
import {useQueryClient} from "@tanstack/react-query";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";


export default function AffaireCard({ id }: { id: string }) {
    const query = useAffaireQuery({ thirdparty_ids: id }, { page: 0, limit: 10, sortorder: 'DESC' }, true);

    // Get QueryClient from the context
    const qc = useQueryClient();

    const location = useLocation<{ proposalCreated: true }>();

    useEffect(() => {
        // Normalement le component CreateProposal set ça dans le state après une création
        // On va venir refresh la liste des devis si l'on vient de CreateProposal et que proposalCreated=true
        if (location.state?.proposalCreated) {
            query.refetch();
        }
    }, [location]);

    const refetchIntervention = () => {
        qc.invalidateQueries({ queryKey: ['interventions', { payload: { customerId: String(id), } }] });
    }

    return (
        <IonCard style={{ background: 'white' }}>
            <IonCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonCardTitle>Devis</IonCardTitle>

                <NewButton id={id} />
            </IonCardHeader>
            <IonCardContent>
                {
                    (query.isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (query.isError) && (
                        <>Pas de devis trouvés pour ce tiers</>
                    )
                }

                {
                    (!query.isLoading && !query.isError) && (
                        <AffaireListContext.Provider value={{ query }}>
                            <AffaireTable
                                prependInterventionCol={false}
                                minHeight={250}
                                showMode={'modal'}
                                onInterventionScheduled={() => refetchIntervention()}
                            />
                        </AffaireListContext.Provider>
                    )
                }
            </IonCardContent>
        </IonCard>
    )
}

function NewButton({ id }: { id: string }) {
    const returnUrl = `/page/ThirdParties/${id}`;

    const history = useHistory();

    const handleClick = () => {
        history.push(`/page/ThirdParties/${id}/NewProposal?returnUrl=${returnUrl}`);
    }

    return (
        <Button type={"button"} variant="outlined" color="primary" size={"small"} startIcon={<AddIcon />} onClick={handleClick}>
            Nouvelle
        </Button>
    )
}