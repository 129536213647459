import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonTextarea
} from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

export default function ObservationsItem() {
    return (
        <IonCard style={{ border: '2px solid var(--ion-color-primary)' }}>
            <IonCardHeader>
                <IonCardTitle color={"primary"} style={{textAlign: 'center'}}>Observations</IonCardTitle>
            </IonCardHeader>

            <IonCardContent style={{ paddingTop: 12 }}>
                <IonItem>
                    <Controller
                        name={"observations"}
                        render={
                            ({field}) => {
                                return (
                                    <IonTextarea
                                        placeholder={"Vos observations ici"}
                                        value={field.value}
                                        onIonChange={field.onChange}
                                    />
                                )
                            }
                        }
                        defaultValue={""}
                    />
                </IonItem>
            </IonCardContent>
        </IonCard>
    )
}