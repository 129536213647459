import {useController} from "react-hook-form";
import {IonButton, IonIcon, IonItem, IonLabel, useIonModal} from "@ionic/react";
import {addOutline, addSharp, searchSharp, searchOutline} from "ionicons/icons";
import SearchSalesForce from "./SearchSalesForce";
import {User} from "../../../models/user.model";

type AddSalesForceButtonProps = {
    name: string,
    label: string,
    placeholder: string,
    multipleSelectionOnSearch?: boolean,
}

export default function AddSalesForceButton(props: AddSalesForceButtonProps) {
    const { name, label, placeholder, multipleSelectionOnSearch } = props;

    const {
        field,
    } = useController({ name, defaultValue: [] });

    const [present, dismiss] = useIonModal(SearchSalesForceModal, {
        multipleSelectionOnSearch,
        title: placeholder,
        onDismiss: (data: string, role: string) => {
            (role === 'confirm') && field.onChange(data);
            dismiss(data, role);
        },
    });

    const users: User[] = Array.isArray(field.value) ? field.value : [field.value];

    if (users && users.length > 0) {
        const fullNames = users.map(
            u => (
                [
                    u.firstname,
                    u.lastname,
                ].filter(n => !!n).join(' ')
            )
        ).join(', ')

        return (
            <IonItem>
                <IonLabel>
                    <h2><b>{fullNames}</b></h2>
                    <h3>{label}</h3>
                </IonLabel>
                <IonButton slot={"end"} fill={"clear"} onClick={() => present()}>
                    <IonIcon slot="start" md={searchSharp} ios={searchOutline} />
                    Rechercher
                </IonButton>
            </IonItem>
        )
    }

    return (
        <IonItem button onClick={() => present()} detail color={"light"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            <IonLabel>
                {placeholder}
            </IonLabel>
        </IonItem>
    )
}

const SearchSalesForceModal = ({
    title, onDismiss, multipleSelectionOnSearch
}: { onDismiss: (data?: any | null | undefined | number, role?: string) => void, multipleSelectionOnSearch: boolean, title: string }
) => {
    return (
        <SearchSalesForce
            title={title}
            multiple={multipleSelectionOnSearch}
            onUserSelected={data => onDismiss(data, 'confirm')}
            onCancel={() => onDismiss()}
        />
    )
}