import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {PropsWithChildren} from "react";
import {useIsAuthenticated} from "./auth.store";

/**
 * https://dev.to/olumidesamuel_/implementing-protected-route-and-authentication-in-react-js-3cl4
 * https://www.robinwieruch.de/react-router-private-routes/
 *
 * @param children
 * @param Component
 * @param restOfProps
 * @constructor
 */
export default function ProtectedRoute({ children, component: Component, ...restOfProps }: PropsWithChildren<RouteProps>) {
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
        return <Redirect to="/Login" />;
    }

    return (
        <Route {...restOfProps}>
            {children}
        </Route>
    )
}