import {TextFieldProps} from "@mui/material";
import {Controller} from "react-hook-form";
import GenericTextFieldControl from "./GenericTextFieldControl";

interface GenericTextFieldControllerProps {
    name: string;
    label: string;
    defaultValue?: string;
    optional?: boolean;
    textFieldProps?: TextFieldProps;
}

export default function GenericTextFieldController({ name, label, defaultValue = "", optional, textFieldProps }: GenericTextFieldControllerProps) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={
                ({ field: { value, onChange }, fieldState: { error } }) => {
                    const hasError = !!error;

                    return (
                        <GenericTextFieldControl
                            label={label}
                            error={error}
                            optional={optional}
                            TextFieldProps={{
                                value,
                                onChange,
                                error: hasError,
                                ...textFieldProps,
                            }}
                        />
                    )
                }
            }
        />
    )
}