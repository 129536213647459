import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {CreateInterventionDto} from "../types";
import {AllInterventionStatus} from "../../../models/intervention.model";

export interface CreatePoseDto extends CreateInterventionDto {
    theoricalStartDate: string;
    theoricalEndDate: string;
}

export async function createPose(dto: CreatePoseDto) {
    const {data} = await interventionHttp.post(Endpoint.createPose, dto);

    if (data.status === AllInterventionStatus.SCHEDULED) {
        // await sendPoseScheduledMail(data.id, data.proposal.id!);
    }

    return data;
}