import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {ApiReport} from "./types";

type Payload = {
    foire?: string;
}

type Pagination = {
    sortfield: string,
    sortorder: string,
    limit: number
}

export async function fetchTableauMarges(payload: Payload = {}, pagination: Pagination = {sortfield: 't.rowid', sortorder: 'ASC', limit: 100}) {
    const { data } = await http.post<ApiReport<Row>>(Endpoint.reportsTableauMarge, {
        ...payload,
        ...pagination,
    });

    return data;
}

interface Row {
    reference: string;
    proposalDate: string;
    validityEnd: string;
    createdAt: string;
    origineAffaire: string;
    foireId: string;
    foire: string;
    status: string;
    customerName: string;
    customerAddress: string;
    customerTown: string;
    customerZip: string;
    totalHT: string;
    vatAmount: string;
    totalTTC: string;
    responsableIds: any;
    vendeurIds: any;
    rabatteurIds: string;
    prixPose: string;
    prixAchat: string;
    margeTheorique: string;
    responsables: string;
    vendeurs: string;
    rabatteurs: string;
}