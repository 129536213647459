import React, {useState} from "react";
import {IonButton, IonCol, IonInput, IonItem, IonLabel, IonNote, IonRow, IonText} from "@ionic/react";
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText} from "@mui/material";
import AddressAutocomplete from "./Common/AddressAutocomplete";
import {ErrorMessage} from "@hookform/error-message";

interface AddressFormGroupProps {
    label?: string;
    namePrefix?: string;
}

export default function AddressFormGroup({ label = 'Adresse', namePrefix = 'address' }: AddressFormGroupProps) {
    const [inputMode, setInputMode] = useState<'manual' | 'autocomplete'>('autocomplete');

    if (inputMode === 'manual') {
        return (
            <IonRow>
                <IonCol size="6">
                    <IonItem>
                        <IonLabel position="floating">{label}</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. 2 rue de la république" onIonChange={field.onChange} />)
                            }}
                            name={`${namePrefix}.street`}
                            rules={{ required: "Adresse client obligatoire" }}
                        />
                        <IonNote slot={'helper'}>
                            <IonButton fill="clear" onClick={() => setInputMode('autocomplete')}>
                                Saisie assistée
                            </IonButton>
                        </IonNote>
                    </IonItem>
                    <ErrorMessage
                        name={`${namePrefix}.street`}
                        as={<IonText color="danger" className="ion-padding-start" />}
                    />
                </IonCol>

                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Code postal</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. 42000" onIonChange={field.onChange} />)
                            }}
                            name={`${namePrefix}.postalCode`}
                            rules={{ required: "Code postal client obligatoire" }}
                        />
                    </IonItem>
                    <ErrorMessage
                        name={`${namePrefix}.postalCode`}
                        as={<IonText color="danger" className="ion-padding-start" />}
                    />
                </IonCol>

                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Ville</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. Saint-Étienne" onIonChange={field.onChange} />)
                            }}
                            name={`${namePrefix}.city`}
                            rules={{ required: "Ville client obligatoire" }}
                        />
                    </IonItem>
                    <ErrorMessage
                        name={`${namePrefix}.city`}
                        as={<IonText color="danger" className="ion-padding-start" />}
                    />
                </IonCol>
            </IonRow>
        )
    }

    return (
        <IonRow>
            <IonCol size={'12'}>
                <Controller
                    render={({ field: { onChange }, fieldState, formState, }) => {
                        return (
                            <IonItem lines={"none"}>
                                <FormControl variant="outlined" fullWidth>
                                    <IonLabel>{label}</IonLabel>
                                    <AddressAutocomplete
                                        onChange={
                                            p => {
                                                onChange(p);
                                            }
                                        }
                                    />
                                    <FormHelperText>
                                        <IonButton fill="clear" onClick={() => setInputMode('manual')}>
                                            Saisie manuelle
                                        </IonButton>
                                    </FormHelperText>
                                </FormControl>
                            </IonItem>
                        )
                    }}
                    name={`${namePrefix}`}
                    rules={{ required: "Adresse client obligatoire" }}
                />
                <ErrorMessage
                    name={namePrefix}
                    as={<IonText color="danger" className="ion-padding-start" />}
                />
            </IonCol>
        </IonRow>
    )
}