import {Controller} from "react-hook-form";
import React from "react";
import GenericTextFieldControl from "../../../Common/Form/GenericTextFieldControl";

export default function EmailControl({ name = 'email' }: { name?: string }) {
    const label = 'Email'

    return (
        <Controller
            name={name}
            defaultValue=""
            rules={{
                required: 'Email obligatoire',
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Email invalide'
                }
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <GenericTextFieldControl
                        label={label}
                        TextFieldProps={{
                            placeholder: label,
                            value,
                            onChange,
                        }}
                        error={error}
                    />
                )
            }}
        />
    )
}