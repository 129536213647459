import {QueryFunction, useQuery} from "@tanstack/react-query";
import {searchThirdParties, SearchThirdPartiesPayload} from "../../calls/ThirdParties/searchThirdParties";
import {Pagination} from "../../calls/types";
import {ThirdParty} from "../../models/third-party.model";
import {PaginatedResponse} from "../../models/common.model";

export function useThirdPartiesQuery(payload: SearchThirdPartiesPayload | null = {}, pagination: Pagination = { sortorder: 'DESC', limit: 500 }, enabled: boolean) {
    const queryKey = ['thirdParties', { payload, pagination }];

    const queryFn: QueryFunction<PaginatedResponse<ThirdParty>> = () => searchThirdParties(payload, pagination);

    return useQuery(queryKey, queryFn, {
        enabled,
    });
}