import {IonSpinner} from "@ionic/react";
import {
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Theme,
    Typography
} from "@mui/material";
import React from "react";
import {ColDef} from "./index";
import {SxProps} from "@mui/system";

interface PowerTableProps<T, R> {
    name: string; // Table name
    cols: Array<ColDef<T>>;
    rows: R[];
    getRowId?(row: R): string | number;
    renderCell?(cellId: T, value: any, row: R): React.ReactNode | void;
    loading: boolean;
    error: any;
    sx?: SxProps<Theme>;
}

/**
 * Inspiration https://mui.com/x/api/data-grid/data-grid/
 * @constructor
 */
export default function PowerTable<T, R>(props: PowerTableProps<T, R>) {
    const {
        name,
        cols,
        rows,
        getRowId: baseGetRowId,
        renderCell: baseRenderCell,
        loading,
        error,
        sx,
    } = props;

    const isError = !!error;

    const getRowId = baseGetRowId || ((r) => (r as any).id);
    const renderCell = baseRenderCell || ((cellId, value, row) => <TableCell>{value}</TableCell>);

    return (
        <TableContainer sx={{ background: 'white', minHeight: 500, ...sx }}>
            <StyledTable aria-label={name} size="small">
                <TableHead>
                    <TableRow>
                        {
                            cols.map(
                                col => (
                                    <TableCell key={`${name}-cell-${col.id as string}`}>
                                        {col.label}
                                    </TableCell>
                                )
                            )
                        }
                    </TableRow>
                </TableHead>

                {
                    // ErrorOverlay si jamais erreur
                    isError && (
                        <ErrorOverlay colSpan={cols.length} />
                    )
                }

                {
                    // LoadingOverlay on loading
                    loading && (
                        <LoadingOverlay colSpan={cols.length} />
                    )
                }

                {
                    // Sinon on affiche les données
                    !loading && (
                        <TableBody>
                            {
                                rows.map(
                                    (row) => {
                                        const rowId = getRowId(row);

                                        return (
                                            <TableRow key={`${name}-row-${rowId}`}>
                                                {
                                                    cols.map(
                                                        col => {
                                                            const value: any = (row as any)[col.id];

                                                            return (
                                                                <React.Fragment key={`${name}-row-${rowId}-cell-${col.id as string}`}>
                                                                    {
                                                                        renderCell(col.id, value, row) || <TableCell>{value}</TableCell>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    )
                                                }
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    )
                }
            </StyledTable>
        </TableContainer>
    )
}

export const StyledTable = styled(Table)`
    & th {
        font-weight: bold;
    }

    & th, tr {
        white-space: nowrap;
    }

    & td.ref {
        max-width: 100px;
        white-space: normal;
        word-wrap: break-word;
    }

    & td.label {
        max-width: 190px;
        white-space: normal;
        word-wrap: break-word;
    }

    & td.description {
        max-width: 210px;
        white-space: normal;
        word-wrap: break-word;
        text-align: justify;
    }
    
    & td.price {
        max-width: 100px;
    }
`;

export function LoadingOverlay({ colSpan }: ErrorOverlayProps) {
    return (
        <TableBody>
            <TableRow>
                <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ height: 450 }}
                    >
                        <IonSpinner name="crescent"></IonSpinner>
                    </Stack>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}

type ErrorOverlayProps = {
    message?: string;
    colSpan: number;
}

// const defaultMessage = "Une erreur s'est produite. Vérifiez les filtres.";

// Normalement on affiche le defaultMessage du dessus mais aujourd'hui les erreurs sont causée uniquement par les 404
const defaultMessage = "Pas de données disponibles. Vérifiez les filtres.";

export function ErrorOverlay({ colSpan, message = defaultMessage }: ErrorOverlayProps) {
    return (
        <TableBody>
            <TableRow>
                <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ height: 450 }}
                    >
                        <Typography>
                            {message}
                        </Typography>
                    </Stack>
                </TableCell>
            </TableRow>
        </TableBody>
    )
}