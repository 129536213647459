import {isNil, omitBy} from "lodash";
import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {PaginationResultModel} from "../../models/common.model";
import {AllInterventionStatus, Intervention, InterventionType} from "../../models/intervention.model";

export type SearchInterventionsPayload = {
    // Les filtres extistants
    status?: AllInterventionStatus;
    technicianId?: string;
    type?: InterventionType;
    types?: InterventionType[];
    customerId?: string;
    customerName?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    nearBy?: [number, number];
    maxDistance?: number; // lié à nearBy
    department?: string;
    productTypologies?: string[];
    dateStart?: string; // iso8601
    dateEnd?: string; // iso8601
    startCreateDate?: string; // iso8601
    endCreateDate?: string; // iso8601
}

export async function searchInterventions(payload: SearchInterventionsPayload = {}) {
    const dto = omitBy(payload, isNil);

    const {data} = await interventionHttp.post<PaginationResultModel<Intervention>>(Endpoint.interventionsSearch,{
        ...dto,
        sort: '-createdAt',
    });

    return data;
}