import {IonContent, IonPage} from "@ionic/react";
import Wordmark from "../components/Wordmark";
import styled from "@emotion/styled";
import LoginForm from "../components/Auth/LoginForm";
import {Redirect} from "react-router-dom";
import {useIsAuthenticated} from "../components/Auth/auth.store";

const LoginPage: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();

    // si authentifié pas la peine d'aller plus loin on redirect direct
    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <StyledPage>
            <IonContent>
                <div className="wrapper fadeInDown">
                    <div id="content">
                        <div className="title">
                            <Wordmark size={"lg"} />
                            <p>Bienvenue ! Veuillez renseigner vos identifiants de connexion pour accéder à votre compte</p>
                        </div>

                        <LoginForm />
                    </div>
                </div>
            </IonContent>
        </StyledPage>
    );
};

export default LoginPage;

const StyledPage = styled(IonPage)`
    width: 100%;
    
    #brand {
        font-family: 'Spantaran', serif;
        font-size: 3em;
        color: #32325d !important;
    }

    .error {
        color: tomato;
    }

    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 100%;
        padding: 20px;
    }

    #content {
        -webkit-border-radius: 10px 10px 10px 10px;
        border-radius: 10px 10px 10px 10px;
        background: #fff;
        padding: 30px;
        width: 90%;
        max-width: 450px;
        position: relative;
        -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
        text-align: center;
    }

    /* FORM TYPOGRAPHY*/

    input[type=button], input[type=submit], input[type=reset], .fadeIn.fourth {
        background-color: #32325d;
        border: none;
        color: white;
        padding: 15px 80px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        text-transform: uppercase;
        font-size: 13px;
        -webkit-box-shadow: 0 10px 30px 0 #F0F0F0;
        box-shadow: 0 10px 30px 0 #F0F0F0;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
        margin: 5px 20px 40px 20px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover {
        background-color: #172b4d;
    }

    input[type=button]:active, input[type=submit]:active, input[type=reset]:active {
        -moz-transform: scale(0.95);
        -webkit-transform: scale(0.95);
        -o-transform: scale(0.95);
        -ms-transform: scale(0.95);
        transform: scale(0.95);
    }

    input[type=text], input[type=password] {
        background-color: #f6f6f6;
        color: #0d0d0d;
        padding: 10px 80px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 5px;
        width: 85%;
        border: 2px solid #f6f6f6;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
    }

    input[type=text]:focus, input[type=password]:focus {
        background-color: #fff;
        border-bottom: 2px solid var(--ion-color-primary);
    }

    input[type=text]::placeholder, input[type=password]::placeholder {
        color: #5f5f5f;
    }

    *:focus {
        outline: none;
    }

`;