import React from 'react';
import {IonItem, IonLabel, IonToolbar} from "@ionic/react";
import PaymentForm from "./PaymentForm";
import Page from "../../pages/Page";
import Paper from "@mui/material/Paper";
import {FormProvider} from "react-hook-form";
import {usePaymentFilterForm} from "./usePaymentFilterForm";

const CreatePaymentPage = () => {

    const {methods} = usePaymentFilterForm();

    return (
        <FormProvider {...methods}>
            <Paper elevation={6}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Nouveau règlement</h1>
                            <h3>Saisie d'un règlement reçu du client</h3>
                        </IonLabel>
                    </IonItem>
                </IonToolbar>

                <PaymentForm/>
            </Paper>
    </FormProvider>


    );
};

export default CreatePaymentPage;