export enum MailingTemplates {
    CONFIRMATION_SIGNATURE = 'kamess/common/confirmation-signature',
    VALIDATION_BE_FOIRE = 'kamess/bureauEtude/validation-be-foire',
    VALIDATION_BE_TERRAIN = 'kamess/bureauEtude/validation-be-terrain',
    REFUS_BE = 'kamess/bureauEtude/refus',

    ANNULATION_DOSSIER = 'kamess/commercial/annulation-dossier',

    POSE_SCHEDULED = 'kamess/interventions/pose/scheduled',
    POSE_COMPLETED = 'kamess/interventions/pose/completed',

    SAV_CREATED = 'kamess/interventions/sav/created',
    SAV_SCHEDULED = 'kamess/interventions/sav/scheduled',
}