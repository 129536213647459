import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import {useInterventionsListContext} from "./useInterventionsListContext";
import {DateTime, Duration} from 'luxon';
import {ColDef} from "../Common/Table";
import {AllInterventionStatus, Intervention, InterventionType} from "../../models/intervention.model";
import PowerTable from "../Common/Table/PowerTable";
import InterventionTypeChip from "./InterventionTypeChip";
import InterventionStatusChip from "./InterventionStatusChip";
import {IonRouterLink} from "@ionic/react";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import InterventionShowModal from "../InterventionDetail/InterveventionShowModal";
import {useInterventionShowModalStore} from "./store";

type ColKey = keyof Intervention | 'duration' | 'customerName' | 'customerZip';

const cols: Array<ColDef<ColKey>> = [
    { id: 'reference', label: 'Référence', },
    { id: 'customerName', label: 'Tiers', },
    { id: 'type', label: 'Type', },
    { id: 'date', label: 'Date', },
    { id: 'customerZip', label: 'Ville / CP', },
    { id: 'duration', label: 'Durée', },
    { id: 'status', label: 'Statut', },
];

interface InterventionTableProps {
    sx?: SxProps<Theme>;
    showMode?: 'page' | 'modal'
}

export default function InterventionTable({ sx, showMode = 'page' }: InterventionTableProps) {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const rows = data?.docs || [];

    const { isOpen, selectInterventionId, hide } = useInterventionShowModalStore();

    return(
        <>
            <TableContainer sx={{ background: 'white' }}>
                <PowerTable
                    name={"intervention table"}
                    cols={cols}
                    rows={rows}
                    renderCell={makeRenderCell(showMode)}
                    loading={!!query?.isLoading}
                    error={query?.error}
                    sx={sx}
                />
            </TableContainer>

            <InterventionShowModal
                id={selectInterventionId}
                isOpen={isOpen}
                handleClose={
                    () => {
                        hide();
                        query?.refetch();
                    }
                }
            />
        </>
    )
}

function makeRenderCell(showMode: 'page' | 'modal') {
    return function renderCell(key: ColKey, value: any, row: Intervention) {
        if (key === "reference") {
            return (
                showMode === 'page'
                    ? <RefCellLink value={value} id={row.id} />
                    : <RefCellModal value={value} id={row.id} />
            )
        }

        if (key === "customerName") {
            return <ThirdpartyNameCell value={row.customer.name} />;
        }

        // Pour le moment array_options c'est que intervention type
        if (key === "type") {
            return <InterventionTypeCell value={value} />;
        }

        if (key === "date") {
            return <DateCell value={value}/>;
        }

        if (key === "customerZip") {
            return <TableCell>{row.place.town} {row.place.zipCode}</TableCell>
        }

        if (key === "duration") {
            return <DurationCell value={value} />;
        }

        if (key === "status") {
            return <InterventionStatutCell value={value} />;
        }
    }
}

function RefCellModal({ value, id }: { value: string, id: string }) {
    const { show } = useInterventionShowModalStore();

    return (
        <TableCell>
            <IonRouterLink style={{ cursor: 'pointer' }} onClick={() => show(id)}>{value}</IonRouterLink>
        </TableCell>
    )
}

function RefCellLink({ value, id }: { value: string, id: string }) {
    return (
        <TableCell>
            <IonRouterLink routerLink={`/page/Interventions/${id}`}>{value}</IonRouterLink>
        </TableCell>
    )
}

function ThirdpartyNameCell({ value } : { value: string }) {
    return (
        <TableCell>{value}</TableCell>
    )
}

function InterventionTypeCell({value} : {value: InterventionType}) {
    return (
        <TableCell>
            <InterventionTypeChip type={value} />
        </TableCell>
    );
}

function  DateCell({value }: { value: string }) {
    if (!value) {
        return <TableCell></TableCell>;
    }

    const d = DateTime.fromISO(value,{zone:"Europe/Paris"}).setLocale('fr');
    return <TableCell>{d.toLocaleString(DateTime.DATE_SHORT)}</TableCell>
}

function DurationCell({ value }: { value: string }) {
    const seconds = Number(value || 0);
    const txt = Duration.fromObject({ seconds }).toFormat('hh:mm:ss');

    return (
        <TableCell>
            {txt}
        </TableCell>
    )
}

function InterventionStatutCell({value} :{value: AllInterventionStatus}){
    return (
        <TableCell>
            <InterventionStatusChip status={value} />
        </TableCell>
    );
}
