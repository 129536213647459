import { AffaireListContext } from "../../Affaire/useAffaireListContext";
import {useAffaireQuery} from "../../Affaire/useAffaireQuery";
import AffaireTable from "../../Affaire/AffaireTable";
import {useThirdPartyBottomDrawerState} from "../useThirdPartyBottomDrawerStore";

export default function AffaireTab() {
    const { currentThirdPartyId: thirdPartyId } = useThirdPartyBottomDrawerState();

    const query = useAffaireQuery({ thirdparty_ids: thirdPartyId }, { page: 0, limit: 10, sortorder: 'DESC' }, true);

    if (query.isLoading) {
        return <>Chargement ...</>
    }

    if (query.isError) {
        return (<>Pas d'affaires trouvés pour ce tiers</>)
    }

    return (
        <AffaireListContext.Provider value={{ query }}>
            <AffaireTable prependInterventionCol={true} />
        </AffaireListContext.Provider>
    )
}