import {Controller, useWatch} from "react-hook-form"
import GenericTextFieldControl from "../../../Common/Form/GenericTextFieldControl";
import {Stack} from "@mui/material";
import React from "react";
import CivilityControl from "./CivilityControl";
import ManagerNameControl, {ManagerFirstNameControl} from "./ManagerNameControl";

export default function ThirdPartyNameControl({ name = 'name' }: { name?: string }) {
    const label = 'Nom';

    // Pour le moment on force le isBusiness à true car on ne gère pas encore dans cette version les clients particulier
    const isBusiness = useWatch({ name: 'isBusiness', defaultValue: true });

    if (isBusiness) {
        return (
            <Stack direction={"column"} spacing={2}>
                <CompanyNameControl />

                <Stack direction={"row"} spacing={2}>
                    <CivilityControl
                        name={"manager.civility"}
                        label={"Civilité gérant"}
                    />

                    <ManagerFirstNameControl
                        name={"manager.firstName"}
                    />

                    <ManagerNameControl
                        name={"manager.name"}
                    />
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction={"row"} spacing={2}>
            <CivilityControl />

            <FirstNameControl />

            <LastNameControl />
        </Stack>
    )
}

function CompanyNameControl({ name = 'name' }: { name?: string }) {
    const label = "Raison Sociale";

    return (
        <Controller
            name={name}
            defaultValue=""
            rules={{ required: 'Raison sociale obligatoire' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <GenericTextFieldControl
                        label={label}
                        TextFieldProps={{
                            placeholder: label,
                            value,
                            onChange,
                        }}
                        error={error}
                    />
                )
            }}
        />
    )
}

function LastNameControl({ name = 'lastName' }: { name?: string }) {
    const label = 'Nom';

    return (
        <Controller
            name={name}
            defaultValue={""}
            rules={{ required: 'Nom obligatoire' }}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    return (
                        <GenericTextFieldControl
                            label={label}
                            TextFieldProps={{
                                placeholder: label,
                                value,
                                onChange,
                            }}
                            error={error}
                        />
                    )
                }
            }
        />
    )
}

function FirstNameControl({ name = 'firstName' }: { name?: string }) {
    const label = 'Prénom';

    return (
        <Controller
            name={name}
            defaultValue={""}
            rules={{ required: 'Prénom obligatoire' }}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    return (
                        <GenericTextFieldControl
                            label={label}
                            TextFieldProps={{
                                placeholder: label,
                                value,
                                onChange,
                            }}
                            error={error}
                        />
                    )
                }
            }
        />
    )
}