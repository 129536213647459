import {CardStatus} from "../../models/kanban/card.model";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import {DateTime} from "luxon";

export default function DossierSendMairieAlert({ cardId, status, statusUpdatedAt }: { cardId: string, status: CardStatus, statusUpdatedAt: string }) {
    if (status !== CardStatus.CASE_SENT_MAIRIE) {
        return null;
    }

    if (!statusUpdatedAt) {
        return null;
    }

    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
    const toFormat= 'dd/MM/yyyy HH:mm:ss';
    const sentAt = DateTime.fromFormat(statusUpdatedAt, fromFormat);
    const raw = sentAt.toFormat(toFormat);
    const relative = sentAt.toRelativeCalendar();

    return (
        <Box sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center" gap={1}>
                <InfoIcon fontSize={"small"} />
                <Typography variant={"caption"}>
                    <b>Envoyé {relative}</b><br />
                    {raw}
                </Typography>
            </Stack>
        </Box>
    )
}