export enum PaymentMethodCode {
    CB = "CB",
    CHQ = "CHQ",
    LIQ = "LIQ",
    PRE = "PRE",
    VIR = "VIR",
}

export enum PaymentMethod {
    TIP = 1, // ?
    VIREMENT = 2,
    PRELEVEMENT = 3,
    ESPECE = 4,
    CB = 6,
    CHEQUE = 7
}

export class Payment {
    public amount: string;
    public type: PaymentMethodCode;
    public date: string;
    public num?: string;
    public ref?: string;
    public ref_ext?: string;
}

/**
 * Le dto retourné par ma route /payments/search
 */
export interface PaymentSearchResult {
    id: string;
    ref: string;
    externalRef: string;
    date: string;
    amount: string;
    status: string;
    externalPaymentId: string | null;
    externalPaymentSite: string | null;
    bankId: string;
    paymentMethod: PaymentMethod;
    paymentMethodCode: PaymentMethodCode;
    paymentMethodLabel: string;
    paymentNo: string;
    note: string;
    thirdPartyId: string;
    thirdPartyName: string;
    invoiceRef: string;
    invoiceDueDate: string; // Date limite de règlement au format YYYY-MM-DD (ex. 2022-09-28)
}