import {useQuery} from "@tanstack/react-query";
import {getIntervention} from "../../calls/Interventions/getIntervention";
import {Intervention} from "../../models/intervention.model";

export function useInterventionDetail<T extends Intervention>(id: string) {
    return useQuery(['intervention', id], ({queryKey}) => {
        return getIntervention<T>(queryKey[1] as string);
    }, {
        enabled: !!id,
    });
}