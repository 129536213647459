import {Box, Divider, Drawer} from "@mui/material";
import {IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow} from "@ionic/react";
import {searchOutline, searchSharp, closeOutline, closeSharp} from "ionicons/icons";
import {PropsWithChildren, useEffect} from "react";
import {useCloseFilterDrawer, useFilterDrawerIsOpen} from "./store";
import IonLoadingButton from "../Lab/IonLoadingButton";

interface FilterDrawerProps {
    loading?: boolean,
    onApply?(): void;
    onReset?(): void;
}

export default function FilterDrawer({ loading, onApply, onReset, children }: PropsWithChildren<FilterDrawerProps>) {
    const isOpen = useFilterDrawerIsOpen();
    const onClose = useCloseFilterDrawer();

    // Close le drawer on unmount
    useEffect(() => {
        return () => {
            onClose();
        }
    }, []);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const keyDownHandler = (event: DocumentEventMap['keydown']) => {
            if (event.key === 'Enter') {
                event.preventDefault();

                // 👇️ your logic here
                onApply && onApply();
                onClose && onClose();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isOpen]);

    return (
        <Drawer
            anchor={"right"}
            open={isOpen}
            onClose={() => onClose && onClose()}
            variant={"persistent"}
            hideBackdrop={false}
        >
            <Box
                sx={{ width: 400, display: 'flex', flexDirection: 'column', height: "100%" }}
                role="presentation"
            >
                <IonItem lines={"none"} style={{ minHeight: 50 }}>
                    <IonLabel>
                        <h1>Filtres</h1>
                    </IonLabel>

                    <IonButton slot={'end'} fill={'clear'} color={"medium"} size={"default"} shape={"round"} onClick={() => onClose && onClose()}>
                        <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
                    </IonButton>
                </IonItem>

                <Divider />

                <div style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 5
                }}>
                    {children}

                    <IonRow style={{ marginTop: 'auto'}}>
                        <IonCol>
                            <IonButton fill={'clear'} expand={'block'} onClick={() => onReset && onReset()} disabled={loading}>
                                Tout effacer
                            </IonButton>
                        </IonCol>

                        <IonCol>
                            <IonLoadingButton
                                loading={loading as boolean}
                                fill={'solid'}
                                expand={'block'}
                                icon={<IonIcon slot={"start"} md={searchOutline} ios={searchSharp} />}
                                onClick={() => {
                                    onApply && onApply();
                                    onClose && onClose();
                                }}
                            >
                                Rechercher
                            </IonLoadingButton>
                        </IonCol>
                    </IonRow>
                </div>
            </Box>

        </Drawer>
    )
}