import {Box, Drawer, Paper} from "@mui/material";
import React from "react";
import {
    briefcaseOutline,
    briefcaseSharp,
    closeOutline,
    closeSharp,
    constructOutline,
    constructSharp
} from "ionicons/icons";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useCloseThirdPartyBottomDrawer, useThirdPartyBottomDrawerState} from "../useThirdPartyBottomDrawerStore";
import styled from "@emotion/styled";
import {IonButton, IonIcon, IonItem, IonLabel} from "@ionic/react";
import AffaireTab from "./AffaireTab";
import PaymentTab from "./PaymentTab";
import DocumentTab from "./DocumentTab";
import VisiteTechniqueTab from "./VisiteTechniqueTab";
import PoseTab from "./PoseTab";
import TaskTab from "./TaskTab";
import SavTab from "./SavTab";

interface ThirdPartyBottomDrawerProps {
    onClose?(): void;
}

export default function ThirdPartyBottomDrawer({onClose}: ThirdPartyBottomDrawerProps) {
    const { isOpen, currentThirdPartyName } = useThirdPartyBottomDrawerState();

    const [value, setValue] = React.useState('0');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const closeDrawer = useCloseThirdPartyBottomDrawer();

    return (
        <StyledDrawer
            anchor={'bottom'}
            open={isOpen}
            onClose={() => onClose && onClose()}
            hideBackdrop={true}
            variant={"persistent"}
        >
            <IonItem lines={"none"}>
                <IonLabel>
                    <h2 style={{ fontWeight: 'bold' }}>Dossier {currentThirdPartyName}</h2>
                </IonLabel>

                <IonButton slot="end" fill={"clear"} shape={"round"} color={"medium"} onClick={() => closeDrawer()}>
                    <IonIcon slot="icon-only" md={closeSharp} ios={closeOutline} />
                </IonButton>
            </IonItem>

            <Box
                role="presentation"
                sx={{ maxHeight: '50vh', width: '100%', typography: 'body1', pl: 2, pb: 2, pr: 2  }}
            >
                <Paper elevation={3} sx={{ height: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabList onChange={handleChange} aria-label="thirdparty tabs">
                                {
                                    tabs.map(
                                        (tab, index) => (
                                            <StyledTab key={tab.id} label={tab.label} value={`${index}`} disableRipple />
                                        )
                                    )
                                }
                            </StyledTabList>
                        </Box>

                        {
                            tabs.map(
                                ({ component, ...tab }, index) => (
                                    <TabPanel key={`TabPanel-${tab.id}`} value={`${index}`}>
                                        {component}
                                    </TabPanel>
                                )
                            )
                        }
                    </TabContext>
                </Paper>
            </Box>
        </StyledDrawer>
    )
}

interface DrawerTab { id: string, label: string, mdIcon: string, iosIcon: string, component: React.ReactNode }

const tabs: DrawerTab[] = [
    { id: 'affaire', label: 'Affaires', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <AffaireTab /> },
    { id: 'reglement', label: 'Règlements', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <PaymentTab /> },
    { id: 'document', label: 'Documents', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <DocumentTab /> },
    { id: 'visiteTech', label: 'Visites Techniques', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <VisiteTechniqueTab /> },
    { id: 'pose', label: 'Poses', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <PoseTab /> },
    { id: 'sav', label: 'SAV', mdIcon: constructSharp, iosIcon: constructOutline, component: <SavTab /> },
    // { id: 'taches', label: 'Tâches', mdIcon: briefcaseSharp, iosIcon: briefcaseOutline, component: <TaskTab /> },
]

const StyledDrawer = styled(Drawer)`
    height: 45vh; 
    width: 100%;
    
    & .MuiDrawer-paper {
        border-top: 1px solid #dadcdf
    }
`;

const StyledTabList = styled(TabList)`
    background: #faf9fb;
    
    & .MuiTabs-indicator {
        background-color: var(--ion-color-primary);
    }
`;

const StyledTab = styled(Tab)`
    font-weight: bold;
    
    &.Mui-selected {
        color: var(--ion-color-primary);
    }
`;