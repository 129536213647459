import {FormControl, FormHelperText, TextField} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {InputBaseProps} from "@mui/material/InputBase";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";

interface CardCommentControlProps {
    name?: string;
    onBlur?: InputBaseProps['onBlur'];
    onFocus?: StandardInputProps['onFocus'];
    multiline?: boolean,
    rows?: number;
}

export default function CardCommentControl({ name = 'comment', onFocus, onBlur, multiline = false, rows = 2 }: CardCommentControlProps) {
    return (
        <Controller
            name={name}
            rules={{
                required: 'Commentaire obligatoire'
            }}
            defaultValue={""}
            render={
                ({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth error={!!error}>
                            <TextField
                                id="comment-multiline-static"
                                placeholder={"Ajouter une note ou un commentaire"}
                                multiline={multiline}
                                rows={rows}
                                size={"small"}
                                value={value}
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                error={!!error}
                            />

                            {
                                error && (
                                    <FormHelperText id="comment-error-message-text" sx={{ m: 0 }}>
                                        {error.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }
            }
        />
    )
}