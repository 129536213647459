import {useController} from "react-hook-form";
import React from "react";
import {Box, Stack} from "@mui/material";
import ContactsAutocomplete from "../Contact/ContactsAutocomplete";

export default function InterventionEditContactForm({ thirdPartyId }: { thirdPartyId: string }) {
    return (
        <Box sx={{ p: 2, maxWidth: 600 }}>
            <Stack direction={"column"} spacing={1}>
                <SelectContactController thirdPartyId={thirdPartyId} />
            </Stack>
        </Box>
    )
}

function SelectContactController({ thirdPartyId }: { thirdPartyId: string }) {
    const name = 'customer';

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController({
        name,
        defaultValue: null,
        rules: {
            required: "Contact obligatoire",
        }
    });

    return(
        <ContactsAutocomplete
            thirdPartyId={thirdPartyId}
            label={"Contact sur site"}
            placeholder={"Sélectionner un contact"}
            onChange={(c) => onChange({
                lastName: c.lastname,
                firstName: c.firstname,
                civility: c.civility_code,
                email: c.email,
                phone: c.phone_pro,
            })}
        />
    )
}