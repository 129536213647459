import {IonContent, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {useActiveCompany, useActiveCompanyId, useAvailableCompanies, useSetActiveCompanyId} from "./company.store";
import CompanyLogoThumbnail from "./CompanyLogoThumbnail";

export function CompaniesPopoverContent() {
    const activeCompanyId = useActiveCompanyId();
    const companies = useAvailableCompanies();
    const setActiveCompanyId = useSetActiveCompanyId();

    const handleSelectCompany = (id: string) => {
        setActiveCompanyId(id);

        // on ne se prend pas la tête on reload le tout à chaque fois q'une organisation est rendu active
        window.location.reload();
    }

    return (
        <IonContent className="ion-no-padding">
            <IonList className="ion-no-padding">
                {
                    companies.length > 0 && (
                        <ActiveCompanyItem />
                    )
                }

                <IonListHeader style={{ background: 'white' }}>
                    <IonLabel>Sélectionner une organisation</IonLabel>
                </IonListHeader>

                {
                    companies.length === 0 && (
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <p>Pas d'organisations trouvées</p>
                            </IonLabel>
                        </IonItem>
                    )
                }

                {
                    companies
                        .filter(c => c.id !== activeCompanyId) // on exclue l'orga active des propositions
                        .map(
                            (c, index) => {
                                const isActive = activeCompanyId === c.id;

                                return (
                                    <IonItem
                                        style={{ '--background': 'white' }}
                                        key={`company-${index}`}
                                        lines={"full"}
                                        className={`ion-text-wrap ${isActive ? 'active' : ''}`}
                                        onClick={() => handleSelectCompany(c.id)}
                                        button
                                        detail
                                    >
                                        <IonLabel>
                                            <h3>{c.label}</h3>
                                        </IonLabel>
                                    </IonItem>
                                )
                            }
                        )
                }
            </IonList>
        </IonContent>
    )
}

function ActiveCompanyItem() {
    const detail = useActiveCompany();

    const addr = [detail.zip, detail.town].filter(i => !!i).join(' - ');

    return (
        <IonItem lines={"none"}>
            <CompanyLogoThumbnail />
            <IonLabel>
                <h2>{detail.name}</h2>
                <p>{addr}</p>
            </IonLabel>
        </IonItem>
    )
}
