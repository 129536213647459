import create from 'zustand'

interface InterventionMapState {
    selectedId: string;
    setSelectedId(id: string): void;
}

export const useInterventionMapStore = create<InterventionMapState>(
    (set) => ({
        selectedId: '',
        setSelectedId: (id) => set(() => ({ selectedId: id })),
    })
)