import {FormProvider, useForm} from "react-hook-form";
import {InterventionFormModel} from "./InterventionForm";
import React from "react";
import {IonButton, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {DateTime} from "luxon";
import {InterventionType} from "../../models/intervention.model";
import InterventionScheduleForm from "./InterventionScheduleForm";
import {useUpdateIntervention} from "./useUpdateIntervention";
import {EditVisitetechniqueDto} from "../../calls/Interventions/VT/editVisiteTechnique";

interface InterventionFormModalProps {
    id: string;
    isOpen: boolean;
    onWillDismiss?(): void;
    defaultInterventionType?: InterventionType
}

export default function ControlledInterventionScheduleFormModal({ id, isOpen, defaultInterventionType = InterventionType.VISITE_TECHNIQUE, onWillDismiss }: InterventionFormModalProps) {
    const methods = useForm<InterventionFormModel>({
        defaultValues: {
            type: defaultInterventionType,
            date: DateTime.now().toISO(),
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().toISO(),
            duration: 1,
            desiredInterventionDate: DateTime.now().toISO(),
        }
    });

    const { mutateAsync, isLoading, isSuccess, isError, data } = useUpdateIntervention(InterventionType.VISITE_TECHNIQUE);

    const dismiss = () => {
        methods.reset();
        onWillDismiss && onWillDismiss();
    }

    const onSubmit = async ({ date, technician }: InterventionFormModel) => {
        const dto: EditVisitetechniqueDto = {
            technician,
            date,
        }

        await mutateAsync({ id, dto });

        methods.reset();

        onWillDismiss && onWillDismiss();
    }

    return (
        <IonModal isOpen={isOpen} backdropDismiss={false} keepContentsMounted={false} style={{ '--width': '30%', '--min-height': 400, '--backdrop-opacity': .4 }}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Programmer intervention</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <InterventionScheduleForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
