import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type GenerateDocumentPayload = {
    modulepart?: string;
    original_file?: string;
    doctemplate?: string;
    langcode?: string;
}

export function buildDocument(payload: GenerateDocumentPayload) {
    return http.put(Endpoint.documentBuildDoc, payload);
}