import create from 'zustand';
import {subscribeWithSelector} from "zustand/middleware";
import React from "react";

export interface ConfirmDialogProps {
    open: boolean;
    title: React.ReactNode;
    message: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
}

interface ConfirmationState {
    props: ConfirmDialogProps;
    confirmed: boolean | null;
    showConfirm(p: Omit<ConfirmDialogProps, 'open'>): void
    accept(): void;
    cancel(): void;
}

const initialProps: ConfirmDialogProps = {
    open: false,
    title: null,
    message: null,
}

export const useConfirmationStore = create<ConfirmationState>()(
    subscribeWithSelector(
        (set) => ({
            props: initialProps,
            confirmed: null,
            showConfirm: (props) => {
                set((state) => ({
                    confirmed: null,
                    props: {
                        open: true,
                        ...props,
                    },
                }))
            },
            accept() {
                return set((state) => ({
                    confirmed: true,
                    props: {
                        ...state.props,
                        open: false,
                    },
                }))
            },
            cancel() {
                return set((state) => ({
                    confirmed: false,
                    props: {
                        ...state.props,
                        open: false,
                    },
                }))
            }
        })
    )
);