import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import {useProductsListContext} from "./useProductsListContext";
import {FormattedNumber} from "react-intl";
import { ColDef } from "../Common/Table";
import PowerTable from "../Common/Table/PowerTable";
import {Product} from "../../models/product.model";

type ColId = 'id' | 'ref' | 'label' | 'description' | 'prix_achat' | 'price' | 'prix_pro' | 'prix_hors_cible' | 'prix_pose';

const cols: Array<ColDef<ColId>> = [
    { id: 'id', label: 'ID' },
    { id: 'ref', label: 'Référence' },
    { id: 'label', label: 'Libellé' },
    { id: 'description', label: 'Désignation' },
    { id: 'prix_achat', label: "Prix d'achat" },
    { id: 'price', label: 'Tarif public' },
    { id: 'prix_pro', label: 'Tarif professionel' },
    { id: 'prix_hors_cible', label: 'Tarif hors cible' },
    { id: 'prix_pose', label: 'Tarif de la pose' },
];

export default function ProductTable(){
    const {query} = useProductsListContext();

    const rows = query?.data?.data || [];

    return(
        <TableContainer sx={{ background: 'white' }}>
            <PowerTable
                name={"product table"}
                cols={cols}
                rows={rows}
                renderCell={renderCell}
                loading={!!query?.isLoading}
                error={query?.error}
            />
        </TableContainer>
    );
}

function renderCell(key: ColId, value: any, row: Product) {
    if (key === "ref") {
        return <TableCell className={"ref"}>{row.ref}</TableCell>;
    }

    if (key === "label") {
        return (
            <TableCell className={"label"}>
                {row.label}
            </TableCell>
        );
    }

    if (key === "description") {
        return (
            <TableCell className={"description"}>
                {row.description}
            </TableCell>
        );
    }

    if (key === "prix_achat") {
        return <PriceCell value={row.array_options.options_prix_achat} />;
    }

    if (key === 'price') {
        return <PriceCell value={row.price} priceBaseType={row.price_base_type} />;
    }

    if (key === 'prix_pro') {
        return <PriceCell value={row.array_options.options_prix_pro} />;
    }

    if (key === 'prix_hors_cible') {
        return <PriceCell value={row.array_options.options_prix_hors_cible} />;
    }

    if (key === 'prix_pose') {
        return <PriceCell value={row.array_options.options_prix_pose} />;
    }
}

function PriceCell({ value, priceBaseType }: { value: string, priceBaseType?: string }) {
    return (
        <TableCell className={"price"}>
            <FormattedNumber
                value={Number(value || 0)}
                style="currency"
                currency={"EUR"}
            />

            { priceBaseType && <>{priceBaseType}</> }
        </TableCell>
    )
}