import {Autocomplete, Popper, TextFieldProps, Typography} from "@mui/material";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {searchProducts} from "../../calls/Products/searchProducts";
import {StyledTextField} from "../Common/Form/Table/StyledTextField";

export interface  ProductAutocompleteOption {
    id: string;
    ref: string;
    label?: string;
    description?: string;
    priceWithoutTax?: string;
    tvaTx?: string;
    unitOfMeasureId?: string; // id de l'unité de mesure
    extraFields?: Partial<Record<'tpsPose' | 'coutFournitures', string>>;
    productType?: string;
}

type ProductAutocompleteProps = {
    value: ProductAutocompleteOption;
    onChange?(e: React.SyntheticEvent, product: ProductAutocompleteOption | null): void;
    placeholder?: string;
    noOptionsText?: string;
    multiple?: boolean;
    error?: boolean;
    textFieldProps?: TextFieldProps;
}

const PopperComponent = function (props: any) {
    return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
};

export default function ProductAutocomplete({ value, onChange, placeholder, noOptionsText, multiple, error, textFieldProps }: ProductAutocompleteProps) {
    const [inputValue, setInputValue] = useState("");

    const { data: options, isLoading } = useQuery(["products", "autocomplete", inputValue], () =>  searchProducts(
        inputValue ? ({
            reference: inputValue,
        }) : {}
    ), {
        // enabled: inputValue.length > 1,
        staleTime: 20 * 1000,
        select(data): ProductAutocompleteOption[] {
            return (
                (data?.items || [])
                    .map(
                        o => ({
                            id: o.id,
                            ref: o.ref,
                            label: o.label,
                            description: o.description,
                            priceWithoutTax: o.price,
                            tvaTx: o.tva_tx,
                            unitOfMeasureId: o.fk_unit,
                            productType: o.type,
                            extraFields: {
                                tpsPose: o.array_options?.options_tps_pose,
                                coutFournitures: o.array_options?.options_cout_fournitures,
                            }
                        })
                    )
            )
        }
    });

    return (
        <Autocomplete
            sx={{
                padding: 0,
            }}
            PopperComponent={PopperComponent}
            openOnFocus={false}
            value={value}
            onInputChange={(_, value) => setInputValue(value)}
            onChange={(event, value, reason) => {
                onChange && onChange(event, value as ProductAutocompleteOption);
            }}
            options={options || []}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            renderOption={(props, option) => (
                <li {...props} style={{ display: 'grid' }}>
                    <Typography variant="subtitle2" component="p">
                        {option.ref}
                    </Typography>

                    <Typography variant="caption" component="p">
                        {option.label}
                    </Typography>
                </li>
            )}
            noOptionsText={noOptionsText}
            multiple={multiple}
            loading={isLoading}
            renderInput={
                (params) => {
                    return(
                        <StyledTextField
                            error={error}
                            {...params}
                            {...textFieldProps}
                            onKeyDown={e => {
                                // A voir si vraie dans temps cette gestion spécifique du Enter
                                // Aujourd'hui dans l'utilisation dans le InlineTable si pas de valeur bloque l'ajout d'une nouvelle ligne
                                // On doit s'assurer qu'il y ait bien une valeur avant de propager le onKeyDown
                                if (e.key === "Enter") {
                                    (!!value && textFieldProps?.onKeyDown) && textFieldProps?.onKeyDown(e);
                                } else {
                                    textFieldProps?.onKeyDown && textFieldProps?.onKeyDown(e);
                                }
                            }}
                            placeholder={placeholder}
                            size="small"
                            variant="outlined"
                        />
                    )
                }
            }
        />
    )
}