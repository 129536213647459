import {AllInterventionStatus, interventionUI} from "../../models/intervention.model";
import {IonChip} from "@ionic/react";
import React from "react";

export default function InterventionStatusChip({ status }: { status?: AllInterventionStatus }) {
    if (!status) {
        return <></>;
    }

    const label = interventionUI.statusTranslation[status];
    const color = interventionUI.statusColorMapping[status];

    return <IonChip color={color || 'medium'}>{label || status}</IonChip>;
}
