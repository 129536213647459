import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Pagination} from "../types";
import {PaginatedResponse} from "../../models/common.model";
import {isNil, omitBy} from "lodash";
import {InvoiceSearchResult} from "../../models/invoice.model";

export type SearchInvoicesPayload = {
    thirdPartyIds?: string;
    thirdPartyName?: string;
    status?: 'draft' | 'unpaid' | 'paid' | 'cancelled';
    startDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    endDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    isLate?: boolean;
}

export type InvoiceSearchResponse = PaginatedResponse<InvoiceSearchResult> & {
    totalAmountHT: number;
    totalAmountTTC: number;
    totalPaid: number;
    totalRemainToPay: number;
}

export async function searchInvoices(payload: SearchInvoicesPayload = {}, pagination: Pagination  = {sortorder: 'DESC', limit: 500}) {
    const dto = omitBy(payload, isNil);

    const params = {
        ...pagination,
        sortfield: 't.ref',
    };

    const {data} = await http.post<InvoiceSearchResponse>(Endpoint.invoicesSearch, dto, { params });

    return data;
}