import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonDatetime,
    IonDatetimeButton,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonNote,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText
} from "@ionic/react";
import {Controller, useController, useFormContext, useWatch} from "react-hook-form";
import {DateTime} from "luxon";
import {Civility} from "../../valueObjects/Civility";
import AddFoireOrigineButton from "./OrigineAffaire/AddFoireOrigineButton";
import AddRabatteurButton from "./SalesForce/AddRabatteurButton";
import AddVendeurButton from "./SalesForce/AddVendeurButton";
import AddResponsableButton from "./SalesForce/AddResponsableButton";
import {useQuery} from "@tanstack/react-query";
import {listProposalDocumentTemplates} from "../../calls/Documents/listProposalDocumentTemplates";
import React, {useEffect, useState} from "react";
import {keyBy} from 'lodash';
import AddressAutocomplete from "../Common/AddressAutocomplete";
import {Alert, FormControl, FormHelperText} from "@mui/material";
import {personAddOutline, personAddSharp, personOutline, personSharp} from "ionicons/icons";
import SearchThirdPartyModal from "../ThirdParty/SearchThirdPartyModal";
import {ThirdParty} from "../../models/third-party.model";
import {ErrorMessage} from "@hookform/error-message";

export default function CustomerForm() {
    const defaultDate = DateTime.now().toISO();

    const origine = useWatch({ name: 'customer.origine', defaultValue: 1 });

    const [mode, setMode] = useState<string>('new'); // Mode nouveau client par défaut

    return (
        <>
            <IonCard style={{ overflow: 'unset' }}>
                <IonCardHeader>
                    <IonCardSubtitle>Veuillez choisir l'origine de l'affaire</IonCardSubtitle>
                    <IonCardTitle>Origine de l'affaire</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                    {
                        origine === 1 && (
                            <Alert severity="info">
                                Le consommateur ne beneficie pas d’un droit de retractation pour un achat effectue dans une foire ou dans un salon
                            </Alert>
                        )
                    }

                    <Controller
                        name={'customer.origine'}
                        render={
                            ({ field }) => {
                                return (
                                    <IonRadioGroup value={field.value} onIonChange={field.onChange}>
                                        <IonRow>
                                            <IonCol>
                                                <IonItem>
                                                    <IonLabel>Foire</IonLabel>
                                                    <IonRadio slot="start" value={1} />
                                                </IonItem>
                                            </IonCol>

                                            <IonCol>
                                                <IonItem>
                                                    <IonLabel>Terrain</IonLabel>
                                                    <IonRadio slot="start" value={2} />
                                                </IonItem>
                                            </IonCol>

                                            <IonCol>
                                                <IonItem>
                                                    <IonLabel>Autre</IonLabel>
                                                    <IonRadio slot="start" value={3} />
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonRadioGroup>
                                )
                            }
                        }
                        rules={{ required: true }}
                        // defaultValue={1}
                    />

                    {
                        origine === 1 && (
                            <AddFoireOrigineButton />
                        )
                    }
                </IonCardContent>
            </IonCard>

            <IonCard style={{ overflow: 'unset' }}>
                <IonCardHeader>
                    <IonCardSubtitle>Associer des personnes à l'affaire</IonCardSubtitle>
                    <IonCardTitle>Force de vente</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                    <AddResponsableButton />

                    <AddVendeurButton />

                    <AddRabatteurButton />
                </IonCardContent>
            </IonCard>

            <IonCard style={{ overflow: 'unset' }}>
                <IonCardContent>
                    <IonSegment value={mode} onIonChange={e => setMode(e.detail.value as string)}>
                        <IonSegmentButton value="new">
                            <IonIcon ios={personAddOutline} md={personAddSharp}></IonIcon>
                            <IonLabel>Nouveau tiers</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="existing">
                            <IonIcon ios={personOutline} md={personSharp}></IonIcon>
                            <IonLabel>Tiers existant</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>

                    {
                        mode === 'new' ? (
                            <>
                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Civilité</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (
                                                        <IonSelect value={field.value} onIonChange={field.onChange}>
                                                            {
                                                                Civility.supportedCivilities().map(
                                                                    civility => (
                                                                        <IonSelectOption key={civility.code} value={civility.code}>
                                                                            {civility.label}
                                                                        </IonSelectOption>
                                                                    )
                                                                )
                                                            }
                                                        </IonSelect>
                                                    )
                                                }}
                                                name={"customer.civility"}
                                                rules={{ required: "Civilité client obligatoire" }}
                                                defaultValue={'MR'}
                                            />
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Nom</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput placeholder="Nom" value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.name"}
                                                rules={{ required: "Nom client obligatoire" }}
                                            />
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Prénom</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput placeholder="Prénom" value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.firstName"}
                                                rules={{ required: "Prénom client obligatoire" }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <AddressFormGroup />

                                <ShippingAddressForm />

                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Téléphone Mr.</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput type={'tel'} placeholder="Téléphone Mr." value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.personalMobilePhone"}
                                                // rules={{ required: "Téléphone Mr. obligatoire" }}
                                            />
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Téléphone Mme</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput type={'tel'} placeholder="Téléphone Mme" value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.businessMobilePhone"}
                                            />
                                        </IonItem>
                                    </IonCol>

                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Tél. Fixe</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput type={'tel'} placeholder="Tél. Fixe" value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.phone"}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel position="floating">Email</IonLabel>
                                            <Controller
                                                render={({ field, fieldState, formState, }) => {
                                                    return (<IonInput type={'email'} placeholder="Email" value={field.value} onIonChange={field.onChange} />)
                                                }}
                                                name={"customer.email"}
                                                rules={{ required: "Email client obligatoire" }}
                                            />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </>
                        ) : (
                            <ExistingThirdPartyItem />
                        )
                    }

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Date proposition</IonLabel>
                                <Controller
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <IonDatetimeButton datetime="datetime" />

                                                <IonModal keepContentsMounted={true}>
                                                    <IonDatetime
                                                        id="datetime"
                                                        value={field.value}
                                                        onIonChange={field.onChange}
                                                        showDefaultButtons={true}
                                                        doneText="Valider"
                                                        cancelText="Annuler"
                                                    />
                                                </IonModal>
                                            </>
                                        )
                                    }}
                                    name={"customer.dateProposition"}
                                    defaultValue={defaultDate}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel position="floating">Durée de validité</IonLabel>
                                <Controller
                                    render={({ field }) => {
                                        return (<IonInput type={"number"} placeholder="Durée de validité"  value={field.value} onIonChange={field.onChange} />)
                                    }}
                                    name={"customer.dureeValidite"}
                                    defaultValue={15}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel>Modèle de document</IonLabel>
                                <DocumentTemplatesSelect />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        </>
    )
}

export function ShippingAddressForm({ label = 'Adresse de facturation' }: { label?: string }) {
    const name = 'customer.billingAddressIsSameAsDefaultAddress';
    const isSame = useWatch({ name, defaultValue: true })

    return (
        <>
            <Controller
                name={name}
                render={({ field }) => {
                    return (
                        <IonItem>
                            <IonCheckbox slot="start" checked={field.value} onIonChange={({ detail: { checked } }) => field.onChange(checked)}></IonCheckbox>
                            <IonLabel>L'adresse de facturation est identique à l'adresse du chantier</IonLabel>
                        </IonItem>
                    )
                }}
                defaultValue={true}
            />

            {
                !isSame && (
                    <AddressFormGroup
                        name={'billingAddress'}
                        label={label}
                    />
                )
            }
        </>
    )
}

function ExistingThirdPartyItem() {
    const [isOpen, setIsOpen] = useState(false);

    const [thirdParty, setThirdParty] = useState<ThirdParty | null>(null);

    const addressText = [
        thirdParty?.address,
        thirdParty?.zip,
        thirdParty?.town,
    ].filter(i => !!i).join(', ');

    const name = 'existingCustomer';

    const {
        field,
        formState: { errors }
    } = useController({
        name,
        rules: { required: 'Veuillez rechercher puis sélectionner un tiers existant' },
    });

    const handleDismiss = (data: any, role?: string) => {
        if (role === 'confirm') {
            setThirdParty(data);
            field.onChange({
                thirdPartyId: data.id,
                thirdPartyName: data.name,
                city: data.town,
                projectId: data?.array_options?.options_related_project || '0',
            });
        }

        setIsOpen(false);
    }

    return (
        <>
            <IonItem button detail={true} onClick={() => setIsOpen(true)}>
                {
                    !thirdParty ? (
                        <IonLabel>Sélectionnez un tiers existant</IonLabel>
                    ) : (
                        <IonLabel>
                            <h2>{thirdParty.name}</h2>
                            <p>{addressText}</p>
                        </IonLabel>
                    )
                }
            </IonItem>

            <ErrorMessage
                errors={errors}
                name={name}
                as={<IonText color="danger" className="ion-padding-start" />}
            />

            <IonModal isOpen={isOpen}>
                <SearchThirdPartyModal
                    onDismiss={handleDismiss}
                />
            </IonModal>
        </>
    )
}

interface AddressFormGroupProps {
    label?: string;
    name?: string;
}

export function AddressFormGroup({ label = 'Adresse Chantier', name = 'address' }: AddressFormGroupProps) {
    const [inputMode, setInputMode] = useState<'manual' | 'autocomplete'>('autocomplete');

    if (inputMode === 'manual') {
        return (
            <IonRow>
                <IonCol size="6">
                    <IonItem>
                        <IonLabel position="floating">{label}</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. 2 rue de la république" value={field.value} onIonChange={field.onChange} />)
                            }}
                            name={`customer.${name}.street`}
                            rules={{ required: "Adresse client obligatoire" }}
                        />
                        <IonNote slot={'helper'}>
                            <IonButton fill="clear" onClick={() => setInputMode('autocomplete')}>
                                Saisie assistée
                            </IonButton>
                        </IonNote>
                    </IonItem>
                </IonCol>

                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Code postal</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. 42000" value={field.value} onIonChange={field.onChange} />)
                            }}
                            name={`customer.${name}.postalCode`}
                            rules={{ required: "Code postal client obligatoire" }}
                        />
                    </IonItem>
                </IonCol>

                <IonCol>
                    <IonItem>
                        <IonLabel position="floating">Ville</IonLabel>
                        <Controller
                            render={({ field, fieldState, formState, }) => {
                                return (<IonInput placeholder="ex. Saint-Étienne" value={field.value} onIonChange={field.onChange} />)
                            }}
                            name={`customer.${name}.city`}
                            rules={{ required: "Ville client obligatoire" }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
        )
    }

    return (
        <IonRow>
            <IonCol size={'12'}>
                <Controller
                    render={({ field: { value, onChange }, fieldState, formState, }) => {
                        const text = value ? ([
                            [value.street ?? '', value.route ?? ''].join(' ').trim(),
                            [value.postalCode ?? '', value.city ?? ''].join(' ').trim(),
                        ].join(', ')) : '';

                        return (
                            <IonItem lines={"none"}>
                                <FormControl variant="outlined" fullWidth>
                                    <IonLabel>{label}</IonLabel>
                                    <AddressAutocomplete
                                        value={text}
                                        onChange={
                                            p => {
                                                onChange(p);
                                            }
                                        }
                                    />
                                    <FormHelperText>
                                        <IonButton fill="clear" onClick={() => setInputMode('manual')}>
                                            Saisie manuelle
                                        </IonButton>
                                    </FormHelperText>
                                </FormControl>
                            </IonItem>
                        )
                    }}
                    name={`customer.${name}`}
                    rules={{ required: "Adresse client obligatoire" }}
                />
            </IonCol>
        </IonRow>
    )
}

function DocumentTemplatesSelect() {
    const { data: docs, isLoading, error } = useQuery(['docTemplates'], listProposalDocumentTemplates, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24,
    });

    const { setValue } = useFormContext();
    const origin = useWatch({ name: 'customer.origine', defaultValue: 1 });
    const modelType = origin === 1 ? 'FOIRE' : 'TERRAIN';

    useEffect(() => {
        const model = (docs || []).find(d => d.filename.startsWith(modelType));

        if (!model) {
            return;
        }

        setValue('customer.docTemplate', model);
    }, [origin]);

    if (isLoading) {
        return (
            <>
                <IonLabel>
                    Chargement des modèles de documents. Veuillez patienter.
                </IonLabel>
                <IonSpinner name="dots" />
            </>
        )
    }

    const db = keyBy(docs,'id');

    return (
        <Controller
            name={"customer.docTemplate"}
            defaultValue={docs![0]} // Par défaut le premier document
            render={({ field: { value, onChange } }) => {
                const selectedId = value.id;

                const handleChange = (e: CustomEvent) => {
                    const id = e.detail.value;
                    const selected = db[id];
                    onChange(selected);
                }

                return (
                    <IonSelect
                        placeholder="Sélectionner modèle"
                        interface="action-sheet"
                        value={selectedId}
                        onIonChange={handleChange}
                        cancelText={"Fermer"}
                    >
                        {
                            docs!.map(
                                d => (
                                    <IonSelectOption key={d.id} value={d.id}>
                                        {d.filename}
                                    </IonSelectOption>
                                )
                            )
                        }
                    </IonSelect>
                )
            }}
        />
    )
}