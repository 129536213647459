import {useQuery} from "@tanstack/react-query";
import React, {useState} from "react";
import {searchUsers} from "../../calls/User/searchUsers";
import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import {userToString} from "../../models/user.model";
import OptionalFormLabel from "../Common/Form/OptionalFormLabel";

type UserAutocompleteProps = {
    label: string;
    placeholder: string;
    onChange?(user: any): void;
    noOptionsText?: string;
    multiple?: boolean;
    error?: any;
    optional?: boolean;
}

export default function UserAutocomplete({ label, placeholder, onChange, noOptionsText = "Aucune option", multiple, error, optional }: UserAutocompleteProps){
    const [inputValue, setInputValue] = useState("");

    const { data, isLoading } = useQuery(["users", inputValue], () =>  searchUsers({name: inputValue}), {
        // enabled: inputValue.length > 1,
        staleTime: 20 * 1000,

    });

    const options = (data?.items || [])
        .map(
            o => ({ label: userToString(o), id: o.id })
        )
    ;

    const errored = !!error;

    return (
        <FormControl fullWidth error={errored}>
            {
                !optional
                    ? (
                        <FormLabel>{label}</FormLabel>
                    )
                    : (
                        <OptionalFormLabel>{label}</OptionalFormLabel>
                    )
            }
            <Autocomplete
                sx={{
                    mt: .7
                }}
                onInputChange={(_, value) => setInputValue(value)}
                onChange={(event: any, value: any) => onChange && onChange(value)}
                options = {options}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                noOptionsText={noOptionsText}
                loading={isLoading}
                multiple={multiple}
                renderInput = {(params) => {
                    return(
                        <TextField {...params} placeholder={placeholder} size="small" variant="outlined" error={errored} />
                    )
                }
            }
            />

            {
                error && error.type === 'required' && (
                    <FormHelperText>
                        <small>{error.message}</small>
                    </FormHelperText>
                )
            }
        </FormControl>
    );
}