import React, {useEffect, useMemo} from "react";
import Page from "./Page";
import {IonContent} from "@ionic/react";
import {useListBoardsByConnectedUser} from "../components/Kanban/useListBoardsByConnectedUser";
import Board from "../components/Kanban/Board";
import {useBoardState} from "../components/Kanban/board.store";
import {keyBy} from "lodash";

export default function KanbanPage() {
    const { isLoading, isError, data } = useListBoardsByConnectedUser();

    const boards = data?.items || [];

    const [boardId, setBoardId] = useBoardState();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (boardId !== 0) {
            return;
        }

        if (boards.length === 0) {
            return;
        }

        setBoardId(+(boards[0].id))
    }, [isLoading, boards]);

    const selectedBoard = useMemo(() => keyBy(boards, 'id')[boardId], [boards, boardId]);

    return (
        <Page>
            <IonContent className="ion-padding" fullscreen>
                {
                    (isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (isError || (!isLoading && boards.length === 0)) && (
                        <>Vous n'avez aucun tableau disponible</>
                    )
                }

                {
                    (!isLoading && !isError && selectedBoard) && (
                        <Board
                            id={+selectedBoard.id}
                            title={selectedBoard.title}
                            cardElementType={selectedBoard.cardElementType as "propal"}
                            availableBoards={boards}
                        />
                    )
                }
            </IonContent>
        </Page>
    )
}