import {Company} from "../../models/company.model";
import create from "zustand";
import {devtools, persist} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {keyBy} from 'lodash';

interface CompanyState {
    lastActiveCompanyId: string; // la dernière organisation active
    updateLastActiveCompany(id: string): void;
    availableCompanies: Company[];
    setAvailableCompanies(companies: Company[], pagination: { limit: number, page: number, itemsCount: number, total: number, }): void;
    pagination: {
        limit: number,
        page: number,
        itemsCount: number,
        total: number,
    }
}

const initialState = {
    lastActiveCompanyId: '',
    availableCompanies: [],
    pagination: {
        limit: 100,
        page: 0,
        itemsCount: 0,
        total: 0,
    }
}

interface ActiveCompanyState {
    activeCompanyId: string; // l'organisation actuellement active
    setActiveCompanyId(id: string): void;
}

export const useActiveCompanyStore = create<ActiveCompanyState>()(
    persist(
        immer((set) => {
            return {
                activeCompanyId: '',
                setActiveCompanyId(id: string) {
                    set(state => {
                        state.activeCompanyId = id;
                    })
                },
            }
        }), {
            name: 'activeCompany',

            // L'organisation active est stored dans le session storage
            // Le but ? Pouvoir avoir dans les onglets des organisations actives différentes
            getStorage: () => sessionStorage,
        }
    )
)

export const useCompanyStore = create<CompanyState>()(
    devtools(
        persist(
            immer((set) => {
                return {
                    ...initialState,
                    updateLastActiveCompany(id: string) {
                        set(state => {
                            state.lastActiveCompanyId = id;
                        })
                    },
                    setAvailableCompanies(companies: Company[], pagination) {
                        set(state => {
                            const activeCompanyId = companies[0]?.id || '';

                            state.lastActiveCompanyId = activeCompanyId;
                            state.availableCompanies = companies;
                            state.pagination = pagination;

                            // Save activeCompanyId dans la sessions également
                            useActiveCompanyStore.setState(state => ({
                                ...state,
                                activeCompanyId,
                            }));
                        })
                    }
                }
            }),
            {
                name: 'company',
            }
        )
    )
);

/**
 * useActiveCompanyId va regarder d'abord dans l'onglet courant si une organisation est active
 * sinon il va prendre la dernière organisation set to active
 */
export function useActiveCompanyId() {
    const lastActiveCompanyId = useCompanyStore(state => state.lastActiveCompanyId);
    const activeCompanyId = useActiveCompanyStore(state => state.activeCompanyId);

    return activeCompanyId || lastActiveCompanyId;
}

/**
 * La diff avec useActiveCompanyId c'est qu'ici on renvoi les détails de l'entreprise
 */
export function useActiveCompany() {
    const id = useActiveCompanyId();
    return useGetCompanyDetail(id);
}

/**
 * useSetActiveCompanyId va set l'organisation sélectionnées à la fois dans le localStorage en tant que fallBack mais aussi dans le sessionStorage
 */
export function useSetActiveCompanyId() {
    const updateLastActiveCompany = useCompanyStore(state => state.updateLastActiveCompany);
    const setActiveCompanyId = useActiveCompanyStore(state => state.setActiveCompanyId);

    return (id: string) => {
        updateLastActiveCompany(id);
        setActiveCompanyId(id);
    }
}

export function useAvailableCompanies() {
    return useCompanyStore(state => state.availableCompanies);
}

export function useSetAvailableCompanies() {
    return useCompanyStore(state => state.setAvailableCompanies);
}

export function useGetCompanyDetail(id: string) {
    const companies = useAvailableCompanies();

    const db = keyBy(companies, 'id');

    return db[id];
}