import {useInterventionDetail} from "./useInterventionDetail";
import {
    AllInterventionStatus,
    Intervention,
    InterventionType,
    interventionUI,
    PoseTypeData,
    VisiteTechnique
} from "../../models/intervention.model";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonSkeletonText,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import LoadingDots from "../LoadingDots";
import InterventionStatusChip from "../Intervention/InterventionStatusChip";
import {DownloadReportButton} from "./DownloadReportButton";
import {
    addOutline,
    addSharp,
    closeOutline,
    closeSharp,
    createSharp,
    eyeOutline,
    eyeSharp,
    timeOutline,
    timeSharp,
    warning
} from "ionicons/icons";
import {FullWhiteCard} from "./FullWhiteCard";
import {InterventionPhotos} from "./InterventionPhotos";
import {InterventionDocuments} from "./InterventionDocuments";
import Page from "../../pages/Page";
import React from "react";
import {DateTime} from "luxon";
import InterventionScheduleFormModal from "../Intervention/InterventionSceduleFormModal";
import useToggleOpen from "../Common/useToggleOpen";
import {styled} from "@mui/material";
import {ContractFormModel} from "../Contracts/models";
import {useUpdateIntervention} from "../Intervention/useUpdateIntervention";
import {Proposal} from "../../models/proposal.model";
import {EditVisitetechniqueDto} from "../../calls/Interventions/VT/editVisiteTechnique";
import {ProductTypology} from "../Intervention/types";
import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import {useAffaireShowModalStore} from "../Affaire/store";
import AffaireShow from "../AffaireDetail/AffaireShow";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ProposalStatusChip} from "../AffaireDetail/ProposalStatusChip";
import {FilePickerButton, FilePickerButtonProps} from "../Kanban/Requirements/Attachments/FilePickerButton";
import {FileToUploadDto, interventionAttachFiles} from "../../calls/Interventions/attachInterventionFiles";
import CreateProposal from "../Proposals/CreateProposal";
import Box from "@mui/material/Box";
import InterventionEditContactFormModal from "../Intervention/InterventionEditContactFormModal";
import InterventionEditAddressFormModal from "../Intervention/InterventionEditAddressFormModal";

interface InterventionShowProps {
    id: string;
    disabledProposalModal?: boolean;
}

export default function InterventionShow({ id, disabledProposalModal }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention } = useInterventionDetail(id);

    const description = isLoading ? '' : interventionUI.typeTranslation[intervention!.type as InterventionType];

    return (
        <Page>
            <IonContent className="ion-no-padding">
                {
                    isLoading && (
                        <IonItem lines={"none"} color={"light"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Veuillez patienter</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !isLoading && (
                        <IonRow>
                            <IonCol size={'12'}>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonBackButton />
                                    </IonButtons>

                                    <IonTitle className={'ion-no-padding ion-align-items-center'}>
                                        {
                                            isLoading
                                                ? (<IonSkeletonText animated={true} style={{ 'width': '80px' }} />)
                                                : <>{description} {intervention?.reference} <InterventionStatusChip status={intervention?.status} /></>
                                        }
                                    </IonTitle>
                                </IonToolbar>

                                {
                                    intervention!.type === InterventionType.VISITE_TECHNIQUE && (
                                        <IonToolbar>
                                            <IonButtons slot="start">
                                                {/*<CreateProposalButton {...intervention!} />*/}

                                                <AddAttachmentButton id={intervention!.id} />
                                            </IonButtons>

                                            <IonButtons slot="end">
                                                <DownloadReportButton
                                                    interventionId={intervention!.id}
                                                    reference={intervention!.reference}
                                                />
                                            </IonButtons>
                                        </IonToolbar>
                                    )
                                }

                                {
                                    (intervention?.status === AllInterventionStatus.CANCELLED && intervention.type === InterventionType.VISITE_TECHNIQUE) && (
                                        <IonToolbar>
                                            <IonText color="danger" style={{ display: 'flex' }} className={"ion-align-items-center`"}>
                                                <IonIcon icon={warning}></IonIcon>
                                                {(intervention as VisiteTechnique).typeData.cancellationReason}
                                            </IonText>
                                        </IonToolbar>
                                    )
                                }

                                <FullWhiteCard>
                                    <IonCardContent>
                                        <IonItem>
                                            <IonLabel className="ion-text-wrap">
                                                <h3>Client</h3>
                                                <p>{intervention!.customer.name}</p>
                                            </IonLabel>
                                        </IonItem>

                                        <AddressItem id={id} />

                                        <ContactItem id={id} />

                                        <ScheduleItem id={id} />

                                        {
                                            intervention!.type === InterventionType.POSE && (
                                                <>
                                                    <IonGrid className= "ion-no-padding">
                                                        <IonRow>
                                                            <IonCol>
                                                                <IonItem>
                                                                    <IonLabel>
                                                                        <h3>Début Pose</h3>
                                                                        <p>{formatDate((intervention!.typeData as PoseTypeData).theoricalStartDate)}</p>
                                                                    </IonLabel>
                                                                </IonItem>
                                                            </IonCol>
                                                            <IonCol>
                                                                <IonItem>
                                                                    <IonLabel>
                                                                        <h3>Fin Pose</h3>
                                                                        <p>{formatDate((intervention!.typeData as PoseTypeData).theoricalEndDate)}</p>
                                                                    </IonLabel>
                                                                </IonItem>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </>
                                            )
                                        }
                                    </IonCardContent>
                                </FullWhiteCard>

                                {
                                    intervention?.proposal?.id && (
                                        <ProposalCard proposalId={intervention!.proposal.id} />
                                    )
                                }

                                <InterventionPhotos
                                    id={id}
                                    customerName={intervention!.customer.name}
                                    // proposalId={intervention!.proposal.id as string}
                                />

                                <InterventionDocuments
                                    id={id}
                                    customerName={intervention!.customer.name}
                                    // proposalId={intervention!.proposal.id as string}
                                />

                                {
                                    !disabledProposalModal && (
                                        <ProposalModal />
                                    )
                                }
                            </IonCol>
                        </IonRow>
                    )
                }
            </IonContent>
        </Page>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');

function AddressItem({ id }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention, refetch } = useInterventionDetail(id);

    const canEdit = [
        AllInterventionStatus.TO_SCHEDULE,
        AllInterventionStatus.SCHEDULED
    ].indexOf(intervention?.status as AllInterventionStatus) !== -1;

    const { open: isOpen, show: showModal, hide: hideModal } = useToggleOpen();

    if (isLoading) {
        return <></>;
    }

    const addressText = [
        intervention?.place?.address || '',
        [intervention?.place?.zipCode || '', intervention?.place?.town || ''].join(' ').trim(),
    ].filter(i => !!i).join(', ');

    return (
        <>
            <IonItem button={canEdit} detail={canEdit} detailIcon={createSharp} onClick={() => {
                if (!canEdit) return;

                showModal();
            }}>
                <IonLabel className="ion-text-wrap">
                    <h3>Adresse</h3>
                    <p>{addressText}</p>
                </IonLabel>
            </IonItem>

            {
                canEdit && (
                    <InterventionEditAddressFormModal
                        id={id}
                        isOpen={isOpen}
                        onWillDismiss={() => {
                            hideModal();
                            refetch();
                        }}
                    />
                )
            }
        </>
    )
}

function ContactItem({ id }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention, refetch } = useInterventionDetail(id);

    const canEdit = [
        AllInterventionStatus.TO_SCHEDULE,
        AllInterventionStatus.SCHEDULED
    ].indexOf(intervention?.status as AllInterventionStatus) !== -1;

    const { open: isOpen, show: showModal, hide: hideModal } = useToggleOpen();

    if (isLoading) {
        return <></>;
    }

    const customer = intervention!.customer;

    return (
        <>
            <IonItem button={canEdit} detail={canEdit} detailIcon={createSharp} onClick={() => {
                if (!canEdit) return;

                showModal();
            }}>
                <IonLabel className="ion-text-wrap">
                    <h3>Contact sur site</h3>
                    <p>{intervention?.customer?.civility || ''} {intervention?.customer?.firstName || ''} {intervention?.customer?.lastName || ''}</p>
                </IonLabel>
            </IonItem>

            <IonItem>
                <IonLabel className="ion-text-wrap">
                    <h3>Téléphone</h3>
                    <p>{intervention?.customer?.phone}</p>
                </IonLabel>
            </IonItem>

            <IonItem>
                <IonLabel className="ion-text-wrap">
                    <h3>Email</h3>
                    <p>{intervention?.customer?.email}</p>
                </IonLabel>
            </IonItem>

            {
                canEdit && (
                    <InterventionEditContactFormModal
                        id={id}
                        customerId={intervention?.customer.id || ''}
                        isOpen={isOpen}
                        onWillDismiss={() => {
                            hideModal();
                            refetch();
                        }}
                    />
                )
            }
        </>
    )
}

function ScheduleItem({ id }: InterventionShowProps) {
    const { isError, isFetched, isLoading, data: intervention, refetch } = useInterventionDetail(id);

    const description = isLoading ? '' : interventionUI.typeTranslation[intervention!.type as InterventionType];

    const toSchedule = intervention?.status === AllInterventionStatus.TO_SCHEDULE;
    const canSchedule = [
        AllInterventionStatus.TO_SCHEDULE,
        AllInterventionStatus.SCHEDULED
    ].indexOf(intervention?.status as AllInterventionStatus) !== -1;

    if (isLoading) {
        return <></>;
    }

    return (
        <>
            {
                toSchedule
                    ? (
                        <>
                            <IonItem button>
                                <IonLabel className="ion-text-wrap" color={"primary"} id={`reschedule-intervention-vt-${id}`}>
                                    <h3>Assigner un technicien et choisir une date</h3>
                                    <p>Planifier l'intervention</p>
                                </IonLabel>
                                <IonIcon md={timeSharp} ios={timeOutline} slot="end" color={"primary"} />
                            </IonItem>
                        </>
                    )
                    : (
                        <>
                            <IonItem button={canSchedule} detail={canSchedule} detailIcon={createSharp}>
                                <IonLabel id={`reschedule-intervention-vt-${id}`}>
                                    <h3>Date {description}</h3>
                                    <p>{formatDate(intervention!.date as string)}</p>
                                </IonLabel>
                            </IonItem>

                            <IonItem>
                                <IonLabel className="ion-text-wrap">
                                    <h3>Technicien</h3>
                                    <p>{intervention!.technician?.name}</p>
                                </IonLabel>
                            </IonItem>
                        </>
                    )
            }

            {
                // Pouvoir reschedule que si completed
                canSchedule && (
                    <InterventionScheduleFormModal
                        id={id}
                        trigger={`reschedule-intervention-vt-${id}`}
                        onWillDismiss={() => refetch()}
                    />
                )
            }
        </>
    )
}

function CreateProposalButton(intervention: Intervention) {
    const { open, show, hide } = useToggleOpen();

    const { id, reference, customer, place } = intervention;

    const defaultValues: { customer: ContractFormModel["customer"] } = {
        customer: {
            civility: customer.civility ?? 'MR',
            email: customer.email ?? '',
            firstName: "",
            name: customer.name,
            phone: customer.phone ?? '',
            billingAddressIsSameAsDefaultAddress: true, // adresse de livraison = adresse du chantier
            address: {
                country: 'FR',
                city: place.town ?? "",
                postalCode: place.zipCode ?? "",
                route: '',
                street: place.address ?? "",
                lat: place.coords ? place.coords[1] : 0,
                lng: place.coords ? place.coords[0] : 0,
            },
            billingAddress: {
                country: 'FR',
                city: place.town ?? "",
                postalCode: place.zipCode ?? "",
                route: '',
                street: place.address ?? "",
                lat: place.coords ? place.coords[1] : 0,
                lng: place.coords ? place.coords[0] : 0,
            },
            personalMobilePhone: '',
            businessMobilePhone: '',
            origine: 3,
            dateProposition: new Date().toISOString(),
            dureeValidite: 15,
        }
    }

    const { mutateAsync, isLoading, isSuccess, isError, data } = useUpdateIntervention(InterventionType.VISITE_TECHNIQUE);

    const qc = useQueryClient();

    const onProposalSubmitted = async (p: Proposal) => {
        const dto: EditVisitetechniqueDto = {
            proposal: {
                id: p.id,
                reference: p.ref,
                origin: p.array_options?.options_origine_affaire,
                originId: p.array_options?.options_foire_origine,
                productTypology: ProductTypology.PHOTOVOLTAIC,
            }
        };

        await mutateAsync({
            id: intervention.id,
            dto
        });

        await qc.invalidateQueries(['intervention', intervention.id]);
        hide();
    }

    return (
        <>
            <IonButton
                fill={"solid"}
                color={"primary"}
                onClick={() => show()}
                disabled={!!intervention.proposal}
            >
                <IonIcon ios={addOutline} md={addSharp} slot={"start"}/>
                Créer Devis
            </IonButton>

            <ProposalIonModal isOpen={open} onWillDismiss={hide} keepContentsMounted={false}>
                <div className="ion-padding" style={{ paddingBottom: 0, display: 'flex', justifyContent: "end" }}>
                    <IonButton fill="clear" size={"small"} onClick={hide}>
                        <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                        Fermer
                    </IonButton>
                </div>

                <Box p={2}>
                    <CreateProposal
                        defaultValues={{
                            thirdParty: {
                                id: customer.id,
                                name: customer.name,
                            },
                            visiteTechnique: {
                                id,
                                reference,
                            }
                        }}
                        onSubmitted={onProposalSubmitted}
                        onCancel={hide}
                    />
                </Box>
            </ProposalIonModal>
        </>
    )
}

const ProposalIonModal = styled(IonModal)`
    & #ion-react-wrapper {
        overflow: auto;
    }
`;

function AddAttachmentButton({ id }: { id: string }) {
    const qc = useQueryClient();

    const { mutateAsync, isLoading } = useMutation((dtos: FileToUploadDto[]) => {
        return interventionAttachFiles(id, dtos);
    });

    const onFileChange: FilePickerButtonProps["onFileChange"] = async ({ filename, name, contentType, base64Content }) => {
        const dto: FileToUploadDto = {
            filename,
            contentType,
            base64Content,
            tag: name,
            description: name,
        }

        await mutateAsync([dto]);
        await qc.invalidateQueries({ queryKey: ['intervention', id, 'files'] });
    }

    if (isLoading) {
        return (
            <IonButton color={'primary'} fill={"outline"}>
                <IonSpinner name="lines-sharp-small" />
            </IonButton>
        )
    }

    return (
        <FilePickerButton
            tooltip={"Cliquez pour joindre un fichier"}
            buttonComponentProps={{
                color: 'primary',
                fill: 'outline',
            }}
            iconSlot={"icon-only"}
            onFileChange={onFileChange}
        />
    )
}

function ProposalCard({ proposalId }: { proposalId: string }) {
    const { isLoading, data: proposal } = useProposalDetail(proposalId);

    const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0'); // On ne filtre que les produits dans les lignes du proposal

    const { show, } = useAffaireShowModalStore();

    if (isLoading) {
        return (
            <FullWhiteCard>
                <IonCardHeader style={{display: 'flex'}} className={"ion-align-items-center"}>
                    <div>
                        <IonSkeletonText animated={true} style={{ width: '70%' }} />
                    </div>
                </IonCardHeader>

                <IonCardContent>
                    <h3>
                        <IonSkeletonText animated={true} style={{ width: '80%' }}></IonSkeletonText>
                    </h3>
                    <p>
                        <IonSkeletonText animated={true} style={{ width: '60%' }}></IonSkeletonText>
                    </p>
                    <p>
                        <IonSkeletonText animated={true} style={{ width: '30%' }}></IonSkeletonText>
                    </p>
                </IonCardContent>
            </FullWhiteCard>
        )
    }

    return (
        <>
            <FullWhiteCard>
                <IonCardHeader style={{display: 'flex'}} className={"ion-align-items-center"}>
                    <div>
                        <IonCardTitle className={'ion-no-padding ion-align-items-center'}>
                            Devis {proposal!.ref}

                            <ProposalStatusChip status={proposal?.status || 0} />
                        </IonCardTitle>
                    </div>

                    <IonButton fill="clear" style={{marginLeft: 'auto'}} color={'primary'} onClick={() => show(proposalId)}>
                        <IonIcon slot={"start"} md={eyeSharp} ios={eyeOutline}/>
                        Voir
                    </IonButton>
                </IonCardHeader>

                <IonCardContent>
                    <IonList>
                        {
                            products.map(
                                (product, index) => (
                                    <IonItem key={product.id}>
                                        <IonLabel className="ion-text-wrap">
                                            <h3>{product.product_label}</h3>
                                            <p>Produit {products.length === 1 ? '' : `${(index + 1)} / ${products.length}`}</p>
                                        </IonLabel>
                                    </IonItem>
                                )
                            )
                        }
                    </IonList>
                </IonCardContent>
            </FullWhiteCard>
        </>
    )
}

function ProposalModal() {
    const { selectedProposalId, isOpen, hide } = useAffaireShowModalStore();

    return (
        <IonModal id={'InterventionProposalCard'} isOpen={isOpen} onWillDismiss={hide} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
            <div className="ion-padding" style={{ paddingBottom: 0 }}>
                <IonButton fill="clear" size={"small"} onClick={hide}>
                    <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                    Fermer
                </IonButton>
            </div>

            <AffaireShow id={selectedProposalId} parent={"InterventionShow"} />
        </IonModal>
    )
}