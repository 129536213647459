import {IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {addOutline, addSharp, filterOutline, filterSharp} from "ionicons/icons";
import PaymentTable from "./PaymentTable";
import FilterDrawer from "../Common/FilterDrawer";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import SearchPayment from "./SearchPayment";
import {usePaymentFilterForm} from "./usePaymentFilterForm";
import {FormProvider} from "react-hook-form";
import {usePaymentsQuery} from "./usePaymentsQuery";
import {PaymentsListContext} from "./usePaymentsListContext";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import {Paper} from "@mui/material";
import React from "react";
import PowerTablePagination from "../Common/Table/PowerTablePagination";

export default function PaymentList() {
    const { filters, applyFilters, resetFilters, methods } = usePaymentFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const query = usePaymentsQuery(filters, { page, limit: rowsPerPage });

    const itemsCount = query?.data?.pagination?.itemsCount || 0;

    const contextValue = {
        query,
    };

    return(
        <PaymentsListContext.Provider value={contextValue}>
            <FormProvider {...methods}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Règlements</h1>
                            <h3>Liste des règlements</h3>
                        </IonLabel>
                        <IonButtons slot={"end"}>
                            <FiltersButton/>
                            {/*<NewButton/>*/}
                        </IonButtons>
                    </IonItem>
                </IonToolbar>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <Paper sx={{ width: '100%', mt: 1 }} elevation={2}>
                                <PaymentTable />

                                <PowerTablePagination
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    totalRowsCount={itemsCount}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </Paper>

                            <FilterDrawer
                                onApply={() => {
                                    resetPage();
                                    applyFilters()
                                }}
                                onReset={() => resetFilters()}
                            >
                                <SearchPayment/>
                            </FilterDrawer>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </FormProvider>
        </PaymentsListContext.Provider>

    );
}

function NewButton() {
    return (
        <IonButton fill={"solid"} color={"primary"} size={"default"} routerLink={'/page/NewPayment'} routerDirection={"root"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            Nouveau
        </IonButton>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}