import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {CreateInterventionDto} from "../types";
import {RdvCom} from "../../../models/intervention.model";

export interface CreateRdvCommDto extends CreateInterventionDto {
    batiment: {
        description: string;
        type: string;
        puissanceCompteur: string;
        endroit: string;
        superficie: string;
        typeToiture: string;
        toiture: string;
        orientationToiture: string;
        pointDeRaccordementLePlusProche: string;
        etudeDeStructure: string;
    },
}

export async function createRdvCom(dto: CreateRdvCommDto) {
    const {data} = await interventionHttp.post<RdvCom>(Endpoint.createRdvCom, dto);

    return data;
}