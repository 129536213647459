import {useQuery} from "@tanstack/react-query";
import {searchCardComments} from "../../calls/Kanban/searchCardComments";
import {kanbanKeys} from "./kanbanKeys";

interface UseSearchCardCommentsProps {
    cardId: number;
}

export function useSearchCardComments({ cardId }: UseSearchCardCommentsProps) {
    return useQuery(kanbanKeys.cardComments(cardId), ({queryKey}) => {
        return searchCardComments({ cardId });
    }, {
        enabled: !!cardId,
    });
}