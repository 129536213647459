import {
    Box,
    Card,
    CardContent,
    Grid,
    Paper,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import LoadingDots from "../LoadingDots";
import React, {useMemo} from "react";
import {StyledTable} from "./TableauCommission";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {DateTime} from "luxon";
import {useQuery} from "@tanstack/react-query";
import {fetchExportCompta} from "../../calls/Reports/fetchExportCompta";
import {idateTime} from "../../utils";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {DatePicker} from "@mui/x-date-pickers";
import {IonButton, IonIcon} from "@ionic/react";
import {downloadOutline, downloadSharp, reloadOutline, reloadSharp} from "ionicons/icons";
import {saveAs} from "file-saver";

const cols = [
    { id: "dateRef", label: "Date", },
    // { id: "invoiceId", label: "", },
    { id: "invoiceRef", label: "Référence", },
    { id: "thirdParty", label: "Client", },
    { id: "amount", label: "Montant", },
    { id: "operationType", label: "Opération", },
    { id: "accountCode", label: "Compte", },
    { id: "accountLabel", label: "Libéllé", },
];

export default function ExportCompta() {
    const methods = useForm({
        defaultValues: {
            dateStart: DateTime.now().startOf("week").toISO(),
            dateEnd: DateTime.now().endOf("week").toISO()
        }
    });

    const { isFetching, data: response, refetch } = useQuery(['exportCompta'], () => {
        const payload = methods.getValues();
        return fetchExportCompta({
            dateStart: idateTime(DateTime.fromISO(payload.dateStart)),
            dateEnd: idateTime(DateTime.fromISO(payload.dateEnd)),
        })
    }, { enabled: false });

    const toExport = response?.toExport || [];
    const rows = response?.items ? response?.items?.flat() : [];

    const handleExport = useMemo(() => {
        return () => {
            toExport.push('');
            saveAs(new Blob([toExport.join('\r\n')]), `export_compta_${Date.now()}.txt`);
        }
    }, [toExport])

    return (
        <Grid>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardContent>
                        <FormProvider {...methods}>
                            <SearchForm onSearch={() => refetch()}/>
                        </FormProvider>
                    </CardContent>
                </Card>

                {
                    (!isFetching && !response) && (
                        <Box sx={{ p: 5, textCenter: 'center', width: '100%' }}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Veuillez sélectionner la période et cliquer ensuite sur CHARGER
                            </Typography>
                        </Box>
                    )
                }

                {
                    isFetching && (
                        <Box sx={{ p: 5 }}>
                            <LoadingDots />
                        </Box>
                    )
                }

                {
                    !!response && (
                        <Paper sx={{ width: '100%', mt: 2 }}>
                            <TableContainer component={Paper} sx={{mt: 1, minHeight: 400, maxHeight: 650 }} elevation={2}>
                                <StyledTable sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" colSpan={cols.length}>
                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                                                    <IonButton fill={"outline"} style={{ marginLeft: 20 }} onClick={() => handleExport()}>
                                                        <IonIcon slot="start" md={downloadSharp} ios={downloadOutline}></IonIcon>
                                                        Export
                                                    </IonButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            {
                                                cols.map(
                                                    col => (
                                                        <TableCell key={col.id}><b>{col.label}</b></TableCell>
                                                    )
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            rows.map((row: any) => (
                                                <TableRow key={`${row.invoiceRef}-${row.accountCode}`}>
                                                    {
                                                        cols.map(
                                                            col => {
                                                                const value = (row as any)[col.id];

                                                                return (
                                                                    <TableCell key={`${row.invoiceRef}-${row.accountCode}-${col.id}`}>
                                                                        {`${value || ''}`.trim()}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </StyledTable>
                            </TableContainer>
                        </Paper>
                    )
                }
            </Grid>
        </Grid>
    )
}

function SearchForm({ onSearch }: { onSearch(): void }) {
    return (
        <Stack direction={"row"} alignItems={"center"} spacing={.5}>
            <Controller
                name={"dateStart"}
                render={({ field: { value, onChange } , fieldState:{error}}) => {
                    return (
                        <FormControl>
                            <FormLabel>Date Début</FormLabel>
                            <DatePicker
                                value={value}
                                onChange={
                                    (v: DateTime | null) => {
                                        onChange(v ? v.toISO() : null);
                                    }
                                }
                                renderInput={(params) => <TextField {...params} size={"small"} />}
                            />
                        </FormControl>
                    )
                }}
            />

            <Controller
                name={"dateEnd"}
                render={({ field: { value, onChange } , fieldState:{error}}) => {
                    return (
                        <FormControl>
                            <FormLabel>Date Fin</FormLabel>
                            <DatePicker
                                value={value}
                                onChange={
                                    (v: DateTime | null) => {
                                        onChange(v ? v.toISO() : null);
                                    }
                                }
                                renderInput={(params) => <TextField {...params} size={"small"} />}
                            />
                        </FormControl>
                    )
                }}
            />

            <IonButton disabled={false} fill={"outline"} style={{ marginLeft: 'auto' }} onClick={onSearch}>
                <IonIcon slot="start" md={reloadSharp} ios={reloadOutline}></IonIcon>
                Charger
            </IonButton>
        </Stack>
    )
}