import {Controller} from "react-hook-form";
import {FormControl, MenuItem, Select} from "@mui/material";
import OptionalFormLabel from "../../../Common/Form/OptionalFormLabel";
import {useProposalFormContext} from "../ProposalFormContext";
import {useQuery} from "@tanstack/react-query";
import {getPaymentTerms} from "../../../../calls/Dictionnary/getPaymentTerms";

export default function PaymentConditionsControl() {
    const label = "Conditions de règlement";
    const name = "paymentConditions";

    const { submitOnBlur } = useProposalFormContext();

    const { data, isLoading } = useQuery(["dictionnary", "payment-terms"], () => getPaymentTerms());

    const paymentConditions = data || [];

    return (
        <Controller
            name={name}
            defaultValue={""}
            render={
                ({ field: { value, onChange } }) => {
                    return (
                        <FormControl variant={"outlined"} fullWidth>
                            <OptionalFormLabel>{label}</OptionalFormLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                onBlur={submitOnBlur}
                                size={"small"}
                                disabled={isLoading}
                            >
                                {
                                    paymentConditions.map(
                                        ({ id, label }) => (
                                            <MenuItem key={id} value={id}>{label}</MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                    )
                }
            }
        />
    )
}