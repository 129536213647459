import {useState} from "react";
import {PaymentFilterModel} from "./types";
import {useForm} from "react-hook-form";

export function usePaymentFilterForm() {
    const [formValues, setFormValues] = useState<PaymentFilterModel>({});
    const methods = useForm<PaymentFilterModel>();

    // Appliquer les filtres
    const applyFilters = methods.handleSubmit((values) => setFormValues(values));

    // Réinitialiser les filtres
    const resetFilters = () => {
        methods.reset();
        applyFilters();
    }

    return {
        methods,
        filters: formValues,
        applyFilters,
        resetFilters,
    }
}