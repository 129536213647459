import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {useMemo, useState} from "react";
import ThirdPartyAutocomplete from "../ThirdParty/ThirdPartyAutocomplete";
import {ThirdParty} from "../../models/third-party.model";
import {Proposal} from "../../models/proposal.model";
import {Controller, FormProvider, useController, useForm, useFormContext, useWatch} from "react-hook-form";
import {useAffaireQuery} from "../Affaire/useAffaireQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {useIsFetching, useMutation} from "@tanstack/react-query";
import {useBuildCreateInterventionBaseDto, useBuildCreateRdvCommBaseDto} from "./useBuildCreateInterventionBaseDto";
import {InterventionFormModel} from "./InterventionForm";
import {DateTime} from "luxon";
import {InterventionType, interventionUI} from "../../models/intervention.model";
import UserAutocomplete from "../ThirdParty/UserAutocomplete";
import {Autocomplete, FormHelperText, MenuItem, Select, Stack, TextField} from "@mui/material";
import {IonCheckbox, IonInput, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import {InstructionCode, instructions} from "./instructions";
import {LoadingButton} from "@mui/lab";
import {compact} from "lodash";
import {CreateVisiteTechinqueDto} from "../../calls/Interventions/VT/createVisiteTechnique";
import {CreatePoseDto} from "../../calls/Interventions/Pose/createPose";
import {useCreateNewIntervention} from "./useCreateNewIntervention";
import {generateReports} from "../../calls/Interventions/generateReports";
import {CreateSavDto} from "../../calls/Interventions/Sav/createSav";
import AddressAutocomplete from "../Common/AddressAutocomplete";
import {useUserRights} from "../Auth/auth.store";
import GenericTextFieldControl from "../Common/Form/GenericTextFieldControl";
import EmailControl from "../ThirdParty/Form/Controls/EmailControl";
import OptionalFormLabel from "../Common/Form/OptionalFormLabel";
import {useThirdPartyContacts} from "../Contact/useThirdPartyContacts";
import {contactToString} from "../../models/contact.model";

interface InterventionWorkflowStepperProps {
    type: InterventionType;
    thirdParty?: ThirdParty;
    onCreated?: () => void;
    onCancel?(): void;
}

interface StepperFormThirdPartyAwareModel extends InterventionFormModel {
    thirdParty: ThirdParty;
    proposal: Proposal;
}

interface StepperFormNoThirdPartyModel extends InterventionFormModel {
    thirdParty: {
        name: string;
        address: AddressOption,
    };
}

export default function InterventionWorkflowStepper({ type, thirdParty, onCreated, onCancel }: InterventionWorkflowStepperProps) {
    const steps = useSteps(type, { thirdPartyExists: !!thirdParty?.id });

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            type,
            thirdParty: thirdParty ? thirdPartyToInterventionFormModel(thirdParty, type) : "",
            date: null,
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().toISO(),
            duration: 1,
            desiredInterventionDate: DateTime.now().toISO(),
        }
    });

    const buildBaseDto = useBuildCreateInterventionBaseDto();
    const builBaseDto2 = useBuildCreateRdvCommBaseDto();
    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewIntervention(type);
    const reportGeneration = useMutation((id: string) => generateReports(id));

    // How many queries matching the posts prefix are fetching?
    const isFetchingAffaires = !!useIsFetching({ queryKey: ['affaire'] })

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSubmit = async (data: any) => {
        const { type, thirdParty, proposal, contact, ...restOfData } = data;
        const baseDto = {
            ...(
                type !== InterventionType.RDVCOM
                ? buildBaseDto(thirdParty, proposal)
                : builBaseDto2({ thirdParty, batiment: restOfData.batiment, contact })
            ),
            technician: restOfData.technician,
            date: restOfData.date,
            notes: restOfData.notes,
            instructions: compact(restOfData.instructions || []),
        };

        let dto = baseDto;

        if (type === InterventionType.VISITE_TECHNIQUE) {
            // Convert duree
            const duration = (data.duration || 0) * 3600; // conversion durée en seconde

            dto = {
                ...baseDto,
                duration,
            } as CreateVisiteTechinqueDto;
        }

        if (type === InterventionType.POSE) {
            dto = {
                ...baseDto,
                theoricalStartDate: data.theoricalStartDate,
                theoricalEndDate: data.theoricalEndDate,
            } as CreatePoseDto;
        }

        if ([InterventionType.ENTRETIEN, InterventionType.SAV].indexOf(type) !== -1) {
            dto = {
                ...baseDto,
                installationYear: restOfData.installationYear,
                poseTechnician: restOfData.poseTechnician,
            } as CreateSavDto;
        }

        try {
            const response = await mutateAsync(dto as any);

            if (type === InterventionType.POSE) {
                await reportGeneration.mutateAsync((response as any).id);
            }

            methods.reset();
            onCreated && onCreated();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Box sx={{ p: 2, maxWidth: 950 }}>
            <FormProvider {...methods}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {
                        steps.map(
                            (step, index) => {
                                const { Label, Content } = step;

                                return (
                                    <Step key={`intervention-stepper-key-${index}`}>
                                        <StepLabel>
                                            <Label />
                                        </StepLabel>

                                        <StepContent>
                                            <Content />

                                            <Box sx={{ mb: 2 }}>
                                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
                                                    <Button
                                                        size={"small"}
                                                        onClick={() => onCancel && onCancel()}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Annuler
                                                    </Button>

                                                    <Stack direction={"row"} justifyContent={"end"} alignItems={"center"} sx={{ width: "100%" }}>
                                                        {
                                                            index > 0 && (
                                                                <Button
                                                                    size={"small"}
                                                                    onClick={handleBack}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                >
                                                                    Précédent
                                                                </Button>
                                                            )
                                                        }

                                                        {
                                                            index < steps.length - 1 ? (
                                                                <Button
                                                                    autoFocus
                                                                    variant="contained"
                                                                    size={"small"}
                                                                    disabled={isFetchingAffaires || !methods.formState.isValid}
                                                                    onClick={handleNext}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                >
                                                                    Suivant
                                                                </Button>
                                                            ) : (
                                                                <LoadingButton
                                                                    loading={isLoading || reportGeneration.isLoading}
                                                                    autoFocus
                                                                    variant="contained"
                                                                    size={"small"}
                                                                    disabled={isFetchingAffaires || !methods.formState.isValid}
                                                                    onClick={methods.handleSubmit(onSubmit)}
                                                                    sx={{ mt: 1, mr: 1 }}
                                                                >
                                                                    Créer {interventionUI.typeTranslation[type]}
                                                                </LoadingButton>
                                                            )
                                                        }
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                )
                            }
                        )
                    }
                </Stepper>
            </FormProvider>
        </Box>
    )
}

const thirdPartyToInterventionFormModel = (thirdParty: ThirdParty, type: InterventionType) => {
    if (type !== InterventionType.RDVCOM) {
        return thirdParty;
    }

    return {
        id: thirdParty.id,
        civility: thirdParty?.array_options?.options_civilite || '',
        firstName: thirdParty.firstname,
        lastName: thirdParty.name_alias,
        email: thirdParty.email,
        phone: thirdParty.phone,
        companyName: thirdParty.name,
        address: {
            city: thirdParty.town,
            postalCode: thirdParty.zip,
            street: thirdParty.address,
            route: undefined,
            lng: Number(thirdParty?.array_options?.options_lng || '0'),
            lat: Number(thirdParty?.array_options?.options_lat || '0')
        }
    }
}

interface StepDef {
    Label: () => JSX.Element;
    Content: () => JSX.Element;
}

const CustomerSelection = {
    Label() {
        const t = useWatch({ name: 'thirdParty', defaultValue: null });
        return <>{t ? t.name : "Sélectionner le client"}</>
    },
    Content() {
        return (
            <>
                <ThirdPartyAutocomplete />
            </>
        )
    }
}

interface AddressOption {
    country: string;
    city: string;
    postalCode: string;
    route: string;
    street: string;
    lat: number;
    lng: number;
}

function InterventionAddressAutocomplete({ label = "Adresse" }: { label?: string }) {
    const name = 'thirdParty.address';
    const labelId = `${name}-address`;
    const id = `${name}-select`;

    const { setError } = useFormContext();

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController({
        name,
        defaultValue: null,
        rules: {
            required: "Adresse obligatoire",
        }
    });

    return (
        <FormControl fullWidth error={!!error}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <AddressAutocomplete
                error={!!error}
                onChange={(a) => {
                    if (!a.street || !a.postalCode) {
                        setError(name, { type: 'addressError', message: "Veuillez saisir un numéro de rue" });
                    } else {
                        onChange(a);
                    }
                }}
            />

            {
                error && (
                    <FormHelperText>
                        {error.message}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}

const BatimentSelection = {
    Label() {
        return <>Batiment et Toiture</>
    },
    Content() {

        // Batiment
        // Type de Batiment
        // Puissance du compteur
        // Endroit
        // La superficie
        // Type de toiture
        // Toiture //  plate inclinée
        // L'orientation de La toiture
        // Le point de raccordement le plus proche de
        // Etude de structure
        // Nom du gérant
        // Raison Sociale
        // Numéro de téléphone
        // Date de RDV
        // Heure de RDV
        // Adresse de La société
        // Adresse mail
        // Commentaire

        return (
            <Stack spacing={1}>
                <Stack spacing={1} direction={"row"}>
                    {/*<Controller*/}
                    {/*    name={"batiment.description"}*/}
                    {/*    defaultValue=""*/}
                    {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                    {/*        return (*/}
                    {/*            <FormControl fullWidth error={!!error}>*/}
                    {/*                <FormLabel>Batiment</FormLabel>*/}
                    {/*                <TextField*/}
                    {/*                    size="small"*/}
                    {/*                    error={!!error}*/}
                    {/*                    onChange={onChange}*/}
                    {/*                    value={value}*/}
                    {/*                    fullWidth*/}
                    {/*                    variant="outlined"*/}
                    {/*                    placeholder={"Batiment"}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Controller
                        name={"batiment.type"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel>Type de Batiment</FormLabel>
                                    <TextField
                                        size="small"
                                        error={!!error}
                                        onChange={onChange}
                                        value={value}
                                        fullWidth
                                        variant="outlined"
                                        placeholder={"Type de Batiment"}
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Stack>

                <Stack spacing={1} direction={"row"}>
                    <Controller
                        name={"batiment.puissanceCompteur"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel>Puissance du compteur</FormLabel>
                                    <TextField
                                        size="small"
                                        error={!!error}
                                        onChange={onChange}
                                        value={value}
                                        fullWidth
                                        variant="outlined"
                                        placeholder={"Puissance du compteur"}
                                    />
                                </FormControl>
                            )
                        }}
                    />

                    {/*<Controller*/}
                    {/*    name={"batiment.endroit"}*/}
                    {/*    defaultValue=""*/}
                    {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                    {/*        return (*/}
                    {/*            <FormControl fullWidth error={!!error}>*/}
                    {/*                <FormLabel>Endroit</FormLabel>*/}
                    {/*                <TextField*/}
                    {/*                    size="small"*/}
                    {/*                    error={!!error}*/}
                    {/*                    onChange={onChange}*/}
                    {/*                    value={value}*/}
                    {/*                    fullWidth*/}
                    {/*                    variant="outlined"*/}
                    {/*                    placeholder={"Endroit"}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Controller
                        name={"batiment.superficie"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel>La superficie</FormLabel>
                                    <TextField
                                        size="small"
                                        error={!!error}
                                        onChange={onChange}
                                        value={value}
                                        fullWidth
                                        variant="outlined"
                                        placeholder={"La superficie"}
                                    />
                                </FormControl>
                            )
                        }}
                    />
                </Stack>

                <Stack spacing={1} direction={"row"}>
                    <Controller
                        name={"batiment.typeToiture"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel id={"type-toiture"}>Type de toiture</FormLabel>
                                    <Select
                                        labelId="type-toiture"
                                        id="type-toiture-select"
                                        value={value}
                                        onChange={onChange}
                                        size={"small"}
                                    >
                                        <MenuItem value={"Plate"}>Plate</MenuItem>
                                        <MenuItem value={"Inclinée"}>Inclinée</MenuItem>
                                    </Select>
                                </FormControl>
                            )
                        }}
                    />

                    {/*<Controller*/}
                    {/*    name={"batiment.natureCouverture"}*/}
                    {/*    defaultValue=""*/}
                    {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                    {/*        return (*/}
                    {/*            <FormControl fullWidth error={!!error}>*/}
                    {/*                <FormLabel>Nature de la couverture</FormLabel>*/}
                    {/*                <TextField*/}
                    {/*                    size="small"*/}
                    {/*                    error={!!error}*/}
                    {/*                    onChange={onChange}*/}
                    {/*                    value={value}*/}
                    {/*                    fullWidth*/}
                    {/*                    variant="outlined"*/}
                    {/*                    placeholder={"Nature de la couverture"}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <NatureCouveture />

                    {/*<Controller*/}
                    {/*    name={"batiment.orientationToiture"}*/}
                    {/*    defaultValue=""*/}
                    {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                    {/*        return (*/}
                    {/*            <FormControl fullWidth error={!!error}>*/}
                    {/*                <FormLabel>Orientation de La toiture</FormLabel>*/}
                    {/*                <TextField*/}
                    {/*                    size="small"*/}
                    {/*                    error={!!error}*/}
                    {/*                    onChange={onChange}*/}
                    {/*                    value={value}*/}
                    {/*                    fullWidth*/}
                    {/*                    variant="outlined"*/}
                    {/*                    placeholder={"Orientation de La toiture"}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Controller
                        name={"batiment.orientationToiture"}
                        defaultValue=""
                        render={
                            ({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <FormControl fullWidth>
                                        <FormLabel id="orientationToiture-label">Orientation de La toiture</FormLabel>
                                        <Select
                                            labelId="orientationToiture-label"
                                            id="orientationToiture-select"
                                            placeholder={"Orientation de La toiture"}
                                            value={value}
                                            onChange={onChange}
                                            size={"small"}
                                        >
                                            {
                                                [
                                                    { label: 'Nord', value: 'N' },
                                                    { label: 'Nord-Est', value: 'NE' },
                                                    { label: 'Nord-Ouest', value: 'NO' },
                                                    { label: 'Sud', value: 'S' },
                                                    { label: 'Sud-Est', value: 'SE' },
                                                    { label: 'Sud-Ouest', value: 'SO' },
                                                    { label: 'Est', value: 'E' },
                                                    { label: 'Ouest', value: 'O' },
                                                    { label: 'Est-Ouest', value: 'EO' }
                                                ]
                                                    .map(
                                                        o => <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                                                    )
                                            }
                                        </Select>
                                    </FormControl>
                                )
                            }}
                    />
                </Stack>

                <Stack spacing={1} direction={"row"} justifyContent={"space-between"}>
                    <Controller
                        name={"batiment.pointDeRaccordementLePlusProche"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel>Le point de raccordement le plus proche de</FormLabel>
                                    <TextField
                                        size="small"
                                        error={!!error}
                                        onChange={onChange}
                                        value={value}
                                        fullWidth
                                        variant="outlined"
                                        placeholder={"Le point de raccordement le plus proche de"}
                                    />
                                </FormControl>
                            )
                        }}
                    />

                    <Controller
                        name={"batiment.etudeDeStructure"}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth>
                                    <FormLabel id="etudeDeStructure-label">L’étude de la structure a été faite ?</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="etudeDeStructure-label"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="Non" control={<Radio />} label="Non" />
                                    </RadioGroup>
                                </FormControl>
                            )
                        }}
                    />
                </Stack>
            </Stack>
        )
    }
}

enum TypeToiture {
    INCLINEE = 'Inclinée',
    PLATE = 'Plate',
}

enum NatureCouverture {
    ARDOISE = 'Ardoise',
    TUILE_PLATE = 'Tuile plate',
    TUILE_CANAL = 'Tuile canal',
    PLAQUE_FIBRO_CIMENT = 'Plaque fibro ciment',
    PLAQUE_FIBRO_CIMENT_AMIANTEE = 'Plaque fibro ciment Amiantée',
    BAC_ACIER = 'Bac acier',
    BITUME = 'Bitume',
    BITUME_GRAVIER = 'Bitume + Gravier',
    EPDM = 'EPDM',
}

function NatureCouveture() {
    const typeToiture = useWatch({ name: "batiment.typeToiture", defaultValue: '' });

    const options = (
        typeToiture === TypeToiture.INCLINEE ? (
            [
                NatureCouverture.ARDOISE,
                NatureCouverture.TUILE_PLATE,
                NatureCouverture.TUILE_CANAL,
                NatureCouverture.PLAQUE_FIBRO_CIMENT,
                NatureCouverture.PLAQUE_FIBRO_CIMENT_AMIANTEE,
                NatureCouverture.BAC_ACIER,
            ]
        ) : (
            [
                NatureCouverture.BITUME,
                NatureCouverture.BITUME_GRAVIER,
                NatureCouverture.BAC_ACIER,
                NatureCouverture.EPDM,
            ]
        )
    );

    return (
        <Controller
            name={"batiment.natureCouverture"}
            defaultValue=""
            render={
                ({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel id="natureCouverture-label">Nature de la couverture</FormLabel>
                            <Select
                                labelId="natureCouverture-label"
                                id="natureCouverture-select"
                                placeholder={"Nature de la couverture"}
                                value={value}
                                onChange={onChange}
                                size={"small"}
                            >
                                {
                                    options.map(
                                        o => (<MenuItem key={o} value={o}>{o}</MenuItem>)
                                    )
                                }
                            </Select>
                        </FormControl>
                    )
                }}
        />
    )
}

const NewCustomerSelection = {
    Label() {
        return <>Informations RDV</>
    },
    Content() {
        const rights = useUserRights();

        // Si block_assignment pas set on autorise l'assignation de l'intervention
        const authorizeAssignment = !rights?.amdpsales?.intervention.block_assignment;

        return (
            <Stack spacing={1}>
                <Controller
                    name={"thirdParty.companyName"}
                    defaultValue=""
                    rules={{
                        required: 'Raison sociale obligatoire'
                    }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        const label = 'Raison Sociale';

                        return (
                            <GenericTextFieldControl
                                label={label}
                                TextFieldProps={{
                                    placeholder: label,
                                    value,
                                    onChange,
                                }}
                                error={error}
                            />
                        )
                    }}
                />

                <InterventionAddressAutocomplete
                    label={"Adresse de la société"}
                />

                <Stack direction={"row"} spacing={2}>
                    <Controller
                        name={"thirdParty.civility"}
                        defaultValue={"MME"}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <FormControl fullWidth error={!!error}>
                                    <FormLabel>Civilité gérant</FormLabel>
                                    <Select
                                        labelId="civility-simple-select-label"
                                        id="civility-simple-select"
                                        value={value}
                                        onChange={onChange}
                                        size={"small"}
                                    >
                                        <MenuItem value={"MME"}>Madame</MenuItem>
                                        <MenuItem value={"M"}>Monsieur</MenuItem>
                                        <MenuItem value={"MLLE"}>Mademoiselle</MenuItem>
                                    </Select>
                                </FormControl>
                            )
                        }}
                    />

                    <Controller
                        name={"thirdParty.firstName"}
                        defaultValue=""
                        rules={{
                            required: 'Prénom gérant obligatoire'
                        }}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            const label = 'Prénom du gérant';

                            return (
                                <GenericTextFieldControl
                                    label={label}
                                    TextFieldProps={{
                                        placeholder: label,
                                        value,
                                        onChange,
                                    }}
                                    error={error}
                                />
                            )
                        }}
                    />

                    <Controller
                        name={"thirdParty.lastName"}
                        rules={{
                            required: 'Nom gérant obligatoire'
                        }}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            const label = 'Nom du gérant';

                            return (
                                <GenericTextFieldControl
                                    label={label}
                                    TextFieldProps={{
                                        placeholder: label,
                                        value,
                                        onChange,
                                    }}
                                    error={error}
                                />
                            )
                        }}
                    />
                </Stack>

                <EmailControl name={"thirdParty.email"} />

                <Controller
                    name={"thirdParty.phone"}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        const label = 'Numéro de téléphone';

                        return (
                            <GenericTextFieldControl
                                label={label}
                                TextFieldProps={{
                                    placeholder: label,
                                    value,
                                    onChange,
                                }}
                                error={error}
                            />
                        )
                    }}
                />

                {
                    authorizeAssignment && (
                        <>
                            <Controller
                                name={"date"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <OptionalFormLabel>Date et Heure du RDV</OptionalFormLabel>
                                            <DateTimePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    size={"small"}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Date et Heure du RDV"
                                                    }}
                                                />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <Controller
                                name={"technician"}
                                render={
                                    ({field}) => {
                                        return(
                                            <UserAutocomplete
                                                onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                                label={"Assigner à"}
                                                placeholder={"Assigner à"}
                                                optional
                                            />
                                        )
                                    }
                                }
                            />
                        </>
                    )
                }

                <Controller
                    name={"notes"}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <OptionalFormLabel>Notes / Commentaires</OptionalFormLabel>
                                <TextField
                                    id="notes-multiline-flexible"
                                    placeholder={"Vos notes ou commentaires ici"}
                                    multiline
                                    value={value}
                                    onChange={onChange}
                                    rows={4}
                                    maxRows={6}
                                />
                            </FormControl>
                        )
                    }}
                />
            </Stack>
        )
    }
}

const ExistingCustomerSelection = {
    Label() {
        return <>Informations RDV</>
    },
    Content() {
        const rights = useUserRights();

        // Si block_assignment pas set on autorise l'assignation de l'intervention
        const authorizeAssignment = !rights?.amdpsales?.intervention.block_assignment;

        const thirdPartyId = useWatch({ name: 'thirdParty.id' });
        const { isLoading , isError, data: contacts } = useThirdPartyContacts(thirdPartyId);

        const contactOptions = (contacts || []);

        return (
            <Stack spacing={1}>
                <Controller
                    name={"contact"}
                    defaultValue=""
                    rules={{
                        required: 'Contact obligatoire'
                    }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        const errored = !!error;

                        return (
                            <FormControl>
                                <FormLabel>Contact sur place</FormLabel>
                                <Autocomplete
                                    sx={{
                                        mt: .7
                                    }}
                                    onChange={(event: any, value: any) => onChange && onChange(value)}
                                    options = {contactOptions}
                                    getOptionLabel={o => contactToString(o)}
                                    isOptionEqualToValue={(a, b) => a.id === b.id}
                                    noOptionsText={"Contact non trouvé"}
                                    loading={isLoading}
                                    renderInput = {(params) => {
                                        return(
                                            <TextField {...params} placeholder={"Choisir contact sur place"} size="small" variant="outlined" error={errored} />
                                        )
                                    }}
                                />

                                {
                                    error && error.type === 'required' && (
                                        <FormHelperText>
                                            <small>{error.message}</small>
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                        )
                    }}
                />

                {
                    authorizeAssignment && (
                        <>
                            <Controller
                                name={"date"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <OptionalFormLabel>Date et Heure du RDV</OptionalFormLabel>
                                            <DateTimePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    size={"small"}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Date et Heure du RDV"
                                                    }}
                                                />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <Controller
                                name={"technician"}
                                render={
                                    ({field}) => {
                                        return(
                                            <UserAutocomplete
                                                onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                                label={"Assigner à"}
                                                placeholder={"Assigner à"}
                                                optional
                                            />
                                        )
                                    }
                                }
                            />
                        </>
                    )
                }

                <Controller
                    name={"notes"}
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <OptionalFormLabel>Notes / Commentaires</OptionalFormLabel>
                                <TextField
                                    id="notes-multiline-flexible"
                                    placeholder={"Vos notes ou commentaires ici"}
                                    multiline
                                    value={value}
                                    onChange={onChange}
                                    rows={4}
                                    maxRows={6}
                                />
                            </FormControl>
                        )
                    }}
                />
            </Stack>
        )
    }
}

const AffaireSelection = {
    Label() {
        const d = useWatch({ name: 'proposal', defaultValue: null });

        const productLabel = (d?.lines || []).filter((l: any) => l.product_type === '0')[0]?.product_label || '';

        return (
            <>
                {
                    d ? (
                        <>
                            <Typography variant="body2">
                                {d.ref}
                            </Typography>

                            <Typography variant="caption">
                                {productLabel}
                            </Typography>
                        </>
                    ) : (
                        "Sélectionner le dossier"
                    )
                }
            </>
        )
    },
    Content() {
        const { getValues } = useFormContext();
        const thirdParty = getValues().thirdParty;
        const { isLoading, data } = useAffaireQuery({ thirdparty_ids: thirdParty?.id }, { page: 0, limit: 10, sortorder: 'DESC' }, !!thirdParty);

        if (isLoading) {
            return (<>Chargement des affaires du client ...</>)
        }

        const items = data?.items || [];

        return (
            <Controller
                name={"proposal"}
                rules={{
                    required: true
                }}
                defaultValue={null}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="proposal-radio-buttons-group-label"
                                defaultValue="female"
                                name="proposal-buttons-group"
                            >
                                {
                                    items.map(
                                        i => {
                                            const productLabel = i.lines.filter(l => l.product_type === '0')[0]?.product_label || '';

                                            return (
                                                <FormControlLabel
                                                    key={i.ref}
                                                    checked={i.id === value?.id}
                                                    onChange={
                                                        (e, checked) => onChange(i)
                                                    }
                                                    control={<Radio />}
                                                    sx={{
                                                        mb: 1
                                                    }}
                                                    label={
                                                        <>
                                                            <Typography variant="body2">
                                                                {i.ref}
                                                            </Typography>

                                                            <Typography variant="caption">
                                                                {productLabel}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            )
                                        }
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    )
                }}
            />
        )
    }
}

const InterventionCreation = {
    Label() {
        return <>Programmation Intervention</>
    },
    Content() {
        // const types = [
        //     { label: 'Visite Technique', type: InterventionType.VISITE_TECHNIQUE },
        //     { label: 'Pose', type: InterventionType.POSE },
        //     { label: 'SAV', type: InterventionType.SAV },
        //     { label: 'entretien', type: InterventionType.ENTRETIEN },
        // ];

        const type = useWatch({ name: 'type' });

        const { setValue } = useFormContext();

        return (
            <Stack direction={"column"} spacing={1}>
                {/*<Controller*/}
                {/*    name={"type"}*/}
                {/*    defaultValue=""*/}
                {/*    render={({ field: { value, onChange }, fieldState: { error } }) => {*/}
                {/*        return (*/}
                {/*            <FormControl>*/}
                {/*                <FormLabel id="intervention-type-row-radio-buttons-group-label">Type</FormLabel>*/}
                {/*                <RadioGroup*/}
                {/*                    row*/}
                {/*                    aria-labelledby="intervention-type-radio-buttons-group-label"*/}
                {/*                    name="intervention-type-radio-buttons-group"*/}
                {/*                    value={value}*/}
                {/*                    onChange={(e, type) => onChange(type)}*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        types.map(*/}
                {/*                            t => (*/}
                {/*                                <FormControlLabel*/}
                {/*                                    key={t.label}*/}
                {/*                                    value={t.type}*/}
                {/*                                    control={<Radio />}*/}
                {/*                                    label={t.label}*/}
                {/*                                />*/}
                {/*                            )*/}
                {/*                        )*/}
                {/*                    }*/}
                {/*                </RadioGroup>*/}
                {/*            </FormControl>*/}
                {/*        )*/}
                {/*    }}*/}
                {/*/>*/}

                <Controller
                    name={"technician"}
                    render={
                        ({field}) => {
                            return(
                                <UserAutocomplete
                                    onChange={(t) => {
                                        field.onChange({ id: t.id, name: t.label });
                                        setValue("date", DateTime.now().toISO()); // update de la date on assignation
                                    }}
                                    label={"Assigner à"}
                                    placeholder={"Assigner à"}
                                />
                            )
                        }
                    }
                />

                {
                    type !== InterventionType.POSE && (
                        <Controller
                            name={"date"}
                            render={({ field: { value, onChange } , fieldState:{error}}) => {
                                return (
                                    <FormControl>
                                        <FormLabel>Date</FormLabel>
                                        <DatePicker
                                            value={value}
                                            onChange={
                                                (v: DateTime | null) => {
                                                    onChange(v ? v?.toISO() : null);
                                                }
                                            }
                                            renderInput={(params) => <TextField {...params} size={"small"} />}
                                        />
                                    </FormControl>
                                )
                            }}
                        />
                    )
                }

                {
                    [InterventionType.SAV, InterventionType.ENTRETIEN].indexOf(type) !== -1 && (
                        <>
                            <Controller
                                name={"installationYear"}
                                defaultValue={null}
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth error={!!error}>
                                            <FormLabel>Année installation</FormLabel>
                                            <TextField
                                                id="installationYear-multiline-flexible"
                                                placeholder={"ex. 2019"}
                                                size={"small"}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <Controller
                                name={"poseTechnician"}
                                render={
                                    ({field}) => {
                                        return(
                                            <UserAutocomplete
                                                onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                                label={"Poseur"}
                                                placeholder={"Technicien reponsable de la pose"}
                                            />
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"notes"}
                                defaultValue=""
                                render={({ field: { value, onChange }, fieldState: { error } }) => {
                                    return (
                                        <FormControl fullWidth error={!!error}>
                                            <FormLabel>Notes / Commentaires</FormLabel>
                                            <TextField
                                                id="notes-multiline-flexible"
                                                placeholder={"Vos notes ou commentaires ici"}
                                                multiline
                                                value={value}
                                                onChange={onChange}
                                                rows={4}
                                                maxRows={6}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </>
                    )
                }

                {
                    type === InterventionType.VISITE_TECHNIQUE && (
                        <>
                            <Controller
                                name={"observations"}
                                render={
                                    ({field, fieldState: { error} }) => {
                                        return (
                                            <FormControl>
                                                <FormLabel>Observations</FormLabel>
                                                <TextField
                                                    multiline
                                                    maxRows={3}
                                                    value={field.value} onChange={field.onChange}
                                                />
                                            </FormControl>
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"duree"}
                                defaultValue={1}
                                render={
                                    ({field }) => {
                                        return (
                                            <FormControl>
                                                <FormLabel>Durée (h)</FormLabel>
                                                <TextField
                                                    type={"number"}
                                                    size={"small"}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                            </FormControl>
                                        )
                                    }
                                }
                            />

                            <Controller
                                name={"desiredInterventionDate"}
                                render={({ field: { value, onChange} , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <FormLabel>Date installation souhaitée</FormLabel>
                                            <DatePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} size={"small"} />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />
                        </>
                    )
                }

                {
                    type === InterventionType.POSE && (
                        <>
                            <Controller
                                name={"theoricalStartDate"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <>
                                            <FormControl>
                                                <FormLabel>Date début</FormLabel>
                                                <DatePicker
                                                    value={value}
                                                    onChange={
                                                        (v: DateTime | null) => {
                                                            onChange(v ? v?.toISO() : null);
                                                        }
                                                    }
                                                    renderInput={(params) => <TextField {...params} size={"small"} />}
                                                />
                                            </FormControl>
                                        </>
                                    )
                                }}
                            />

                            <Controller
                                name={"theoricalEndDate"}
                                render={({ field: { value, onChange } , fieldState:{error}}) => {
                                    return (
                                        <FormControl>
                                            <FormLabel>Date fin</FormLabel>
                                            <DatePicker
                                                value={value}
                                                onChange={
                                                    (v: DateTime | null) => {
                                                        onChange(v ? v?.toISO() : null);
                                                    }
                                                }
                                                renderInput={(params) => <TextField {...params} size={"small"} />}
                                            />
                                        </FormControl>
                                    )
                                }}
                            />

                            <IonList>
                                <IonListHeader>
                                    <IonLabel>Instructions</IonLabel>
                                </IonListHeader>
                                {
                                    instructions.map(
                                        (i, index) => {
                                            return (
                                                <Controller
                                                    name={`instructions.${index}`}
                                                    render={({ field: { value, onChange } , fieldState:{error}}) => {
                                                        return (
                                                            <IonItem class="ion-text-wrap" key={`instructions.${index}`}>
                                                                <IonCheckbox
                                                                    slot="start"
                                                                    value={value}
                                                                    onIonChange={({ detail: { checked } }) => {
                                                                        onChange(
                                                                            checked ? i : undefined
                                                                        )
                                                                    }}
                                                                />

                                                                <IonLabel>
                                                                    <h6>{i.description}</h6>
                                                                    <p>{i.actionLabel}</p>
                                                                </IonLabel>

                                                                {
                                                                    (value?.code === InstructionCode.INST_CHQ) && (
                                                                        <Controller
                                                                            name={`instructions.${index}.data.solde`}
                                                                            render={({ field: { value, onChange } , fieldState:{error}}) => {
                                                                                return (
                                                                                    <IonInput
                                                                                        placeholder="Saisir le solde ici"
                                                                                        type={"number"}
                                                                                        value={value}
                                                                                        onIonChange={onChange}
                                                                                    />
                                                                                )
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </IonItem>
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                    )
                                }
                            </IonList>
                        </>
                    )
                }
            </Stack>
        )
    }
}

const defaultSteps: StepDef[] = [
    CustomerSelection,
    AffaireSelection,
    InterventionCreation
];


function useSteps(type: InterventionType, { thirdPartyExists = false }: { thirdPartyExists?: boolean } = {}) {
    return useMemo(() => {
        if (type === InterventionType.RDVCOM) {
            const steps = [
                BatimentSelection,
            ];

            if (!thirdPartyExists) {
                steps.push(NewCustomerSelection);
            } else {
                steps.push(ExistingCustomerSelection)
            }

            return steps;
        }

        return defaultSteps;
    }, [type])
}