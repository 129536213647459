import {Stack, TextField, Typography} from "@mui/material";
import React, {CSSProperties, useState} from "react";
import styled from "@emotion/styled";
// @ts-ignore
import BaseBoard from 'react-trello';
import {BoardSearchResult} from "../../models/kanban/board.model";
import BoardSwitchButton from "./BoardSwitchButton";
import {useBoardFilters, useBoardStore} from "./board.store";
import {ListConfig, useBoardData} from "./useBoardData";
import {CardStatus} from "../../models/kanban/card.model";
import {useSetCardStatus} from "./useSetCardStatus";
import {DatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import {IonButton, IonIcon, useIonAlert} from "@ionic/react";
import {mapOutline, mapSharp} from "ionicons/icons";
import DefaultCustomCard from "./DefaultCustomCard";
import slugify from "slugify";
import useToggleOpen from "../Common/useToggleOpen";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import InterventionList from "../Intervention/InterventionList";
import DialogContent from "@mui/material/DialogContent";
import InterventionOfSavCard from "./InterventionOfSavCard";
import {sendValidationBureauEtudeMail} from "../../calls/Mailings/sendValidationBureauEtudeMail";
import {sendRefusBureauEtudeMail} from "../../calls/Mailings/sendRefusBureauEtudeMail";
import {sendAnnulationDossierMail} from "../../calls/Mailings/sendAnnulationDossierMail";
import {useInterventionShowModalStore} from "../Intervention/store";
import InterventionShowModal from "../InterventionDetail/InterveventionShowModal";
import {ListRequirements} from "../../models/kanban/list.model";
import MeetListRequirementsDialog from "./Requirements/MeetListRequirementsDialog";
import AffaireShowModal from "../AffaireDetail/AffaireShowModal";
import {useAffaireShowModalStore} from "../Affaire/store";
import ThirdPartyShowModal from "../ThirdParty/ThirdPartyShowModal";
import {useThirdPartyShowModalStore} from "../ThirdParty/store";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface BoardProps {
    id: number;
    title: string;
    cardElementType: 'propal' | 'interventionOfSav' | 'photovoltaique';
    availableBoards: BoardSearchResult[];
    className?: string;
    style?: CSSProperties | undefined;
}

export default function Board({ className, style, availableBoards, ...props }: BoardProps) {
    const hasManyBoards = availableBoards.length > 0;

    const boardId = useBoardStore(state => state.currentBoardId);
    const boardSlug = slugify(props.title, {
        replacement: '_',  // replace spaces with replacement character, defaults to `-`
        strict: true,     // strip special characters except replacement, defaults to `false`
    }).toUpperCase();

    const boardFilters = useBoardFilters(state => {
        const {filters} = state;

        return {
            startCreateDate: filters?.startCreateDate && idateTime(filters?.startCreateDate),
            endCreateDate: filters?.endCreateDate && idateTime(filters?.endCreateDate),
        }
    });

    const { isError, isLoading, data, listConfigRecord, refresh } = useBoardData({
        boardId,
        cardElementType: props.cardElementType,
        filters: boardFilters,
    });

    const { mutateAsync: setStatusAsync, isLoading: settingStatus } = useSetCardStatus({
        cardElementType: props.cardElementType,
    });

    // const { isOpen, selectedProposalId: selectedCardId, show, hide } = useAffaireShowModalStore();

    const { isOpen, selectInterventionId, show, hide } = useInterventionShowModalStore();

    const { isOpen: isProposalModalOpen, selectedProposalId, show: showProposalModal, hide: hideProposalModal } = useAffaireShowModalStore();

    const { show: showThirdPartyModal } = useThirdPartyShowModalStore();

    const onCardClicked = (cardId: string, metadata: any, laneId: string) => {
        const isProposal = props.cardElementType === 'propal';
        const isPV = props.cardElementType === 'photovoltaique';
        const isIntervention = props.cardElementType.startsWith('intervention');

        if (isProposal) {
            return showProposalModal(cardId);
        }

        if (isPV) {
            return showThirdPartyModal(cardId);
        }

        if (isIntervention) {
            // const proposalId = props.cardElementType === 'propal' ? cardId : metadata.proposalId;
            return show(cardId);
        }
    }

    const { cardId, config: currentListConfig, requirements, dialogOpen, showDialog, hideDialog } = useCardRequirementsDialog();

    const [presentAlert] = useIonAlert();

    const handleDialogCloseReason = async (reason: 'cancel' | 'ok') => {
        if (reason === 'cancel') {
            hideDialog();
            return;
        }

        if ((reason === 'ok') && (currentListConfig && currentListConfig.applyStatus)) {
            await setStatusAsync({
                cardId: cardId, status: currentListConfig.applyStatus as CardStatus,
            });

            refresh();
        }

        hideDialog();
    }

    const onCardDragEnd = (cardId: string, sourceLaneId: string, targetLaneId: string, position: number, cardDetails: { id: string; metadata: any }) => {
        // vérification de la config pour cancel le drop si jamais la liste source est readOnly
        const config = listConfigRecord[targetLaneId];

        if (config.readOnly) {
            return false;
        }

        const requirements = config.requirements;

        // Gestion création devis requise avant de déplacer la carte dans la liste cible
        // Alerte si le devis n'existe pas alors qu'elle est nécessaire avant de déplacer la carte dans la liste cible
        if (requirements?.proposal?.exists) {
            if (!cardDetails.metadata.proposalId) {
                presentAlert({
                    header: "Devis introuvable",
                    subHeader: 'Aucun devis n\'est associé à cette VT',
                    message: "Veuillez créer le devis avant de déplacer cette carte",
                    buttons: ["OK"]
                });

                return false;
            }
        }

        if (requirements) {
            showDialog({
                cardId,
                config,
                requirements
            });

            return false;
        }

        const newStatus = config.applyStatus;

        // let finalStatus: CardStatus | undefined; // utilisé pour les status temp tels que VALIDATED_BE, CASE_SENT_MAIRIE, COMMISSIONNED_CASE

        // if (newStatus === CardStatus.VALIDATED_BE) {
        //     const { metadata: { productTypology } } = cardDetails;
        //
        //     finalStatus = (productTypology === ProductTypology.PHOTOVOLTAIC)
        //         ? CardStatus.WAITING_MAIRIE
        //         : CardStatus.WAITING_COMMERCIAL
        //     ;
        // }

        // // Envois des mails si besoin
        // if ([CardStatus.VALIDATED_BE, CardStatus.DENIED_BE, CardStatus.CANCELLED_CASE].indexOf(newStatus) !== -1) {
        //     notifyStatusByMail(cardId, newStatus);
        // }

        // S'il y un statut final cible on update avec le nouveau statut puis 500ms après on update avec le statut final
        // if (finalStatus) {
        //     setStatusAsync({ cardId: cardId, status: newStatus })
        //         .then(
        //             () => {
        //                 setTimeout(
        //                     () => {
        //                         setStatusAsync({ cardId: cardId, status: finalStatus! })
        //                             .then(
        //                                 () => refresh()
        //                             );
        //                     },
        //                     500
        //                 )
        //             }
        //         );
        //
        //     return;
        // }

        setStatusAsync({
            cardId: cardId, status: newStatus as CardStatus,
        }).then(() => refresh());
    }

    const isPlanification = (boardSlug === 'TABLEAU_PLANIFICATION');

    return (
        <div
            className={className}
            style={style}
        >
            <Stack direction={"row"} alignItems={"center"} sx={{ width: '100%' }} spacing={1}>
                {
                    hasManyBoards ? (
                        <BoardSwitchButton label={props.title} availableBoards={availableBoards} />
                    ) : (
                        <Typography variant="h5" gutterBottom sx={{ pl: '10px' }}>
                            {props.title}
                        </Typography>
                    )
                }

                <DateRange />

                {
                    isPlanification && (
                        <InterventionMapModalButton />
                    )
                }
            </Stack>


            <div>
                {
                    (isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (isError) && (
                        <>Aucune liste disponible dans ce tableau</>
                    )
                }

                {
                    (!isLoading && !isError) && (
                        <StyledBoard
                            data={data}
                            components={{
                                Card: props.cardElementType === 'interventionOfSav' ? InterventionOfSavCard : DefaultCustomCard
                            }}
                            laneDraggable={false}
                            hideCardDeleteIcon={true}
                            handleDragEnd={onCardDragEnd}
                            onCardClick={onCardClicked}
                        />
                    )
                }
            </div>

            {
                props.cardElementType.startsWith('intervention') && (
                    <InterventionShowModal
                        id={selectInterventionId}
                        isOpen={isOpen}
                        handleClose={() => {
                            if (selectInterventionId) {
                                refresh();
                                hide();
                            }
                        }}
                    />
                )
            }

            {
                (props.cardElementType === 'propal') && (
                    <AffaireShowModal
                        id={selectedProposalId}
                        isOpen={isProposalModalOpen}
                        handleClose={hideProposalModal}
                    />
                )
            }

            {
                props.cardElementType === 'photovoltaique' && (
                    <ThirdPartyShowModal />
                )
            }

            <MeetListRequirementsDialog
                cardId={cardId}
                requirements={requirements}
                open={dialogOpen}
                onClose={handleDialogCloseReason}
            />
        </div>
    )
}

function notifyStatusByMail(proposalId: string, status: CardStatus) {
    switch (status) {
        case CardStatus.VALIDATED_BE:
            return sendValidationBureauEtudeMail(proposalId);

        case CardStatus.DENIED_BE:
            return sendRefusBureauEtudeMail(proposalId);

        case CardStatus.CANCELLED_CASE:
            return sendAnnulationDossierMail(proposalId);
    }
}

function DateRange() {
    const { startCreateDate, endCreateDate } = useBoardFilters(state => state.filters);
    const updateFilters = useBoardFilters(state => state.updateFilters);

    const handleChange = ([start, end]: [DateTime | null, DateTime | null]) => {
        updateFilters({
            startCreateDate: start,
            endCreateDate: end,
        } as any);
    };

    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: 8 }}>
            <DatePicker
                label="Du"
                value={startCreateDate}
                onChange={value => handleChange([value, endCreateDate] as any) }
                renderInput={(params) => <TextField {...params} size={"small"} />}
            />

            <DatePicker
                label="Au"
                value={endCreateDate}
                onChange={value => handleChange([startCreateDate, value] as any) }
                renderInput={(params) => <TextField {...params} size={"small"} />}
            />
        </Stack>
    )
}

function InterventionMapModalButton() {
    const { open, show, hide } = useToggleOpen();

    return (
        <React.Fragment>
            <IonButton expand="block" onClick={() => show()} fill="clear">
                <IonIcon slot="start" md={mapSharp} ios={mapOutline}></IonIcon>
                Carte interventions
            </IonButton>

            <Dialog
                open={open}
                maxWidth={'xl'}
                onClose={hide}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }} color="inherit" elevation={0}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={hide}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ p: 1, minWidth: '75vw' }}>
                    <InterventionList />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const StyledBoard = styled(BaseBoard)`
    background-color: transparent!important;

    header {
        align-items: center;
        justify-content: space-between;
    }
`;

function useCardRequirementsDialog() {
    const [cardId, setCardId] = useState('');
    const [config, setConfig] = useState<ListConfig | null>(null);
    const [requirements, setRequirements] = useState<ListRequirements | null>(null);
    const [open, setOpen] = useState(false);

    return {
        cardId,
        config,
        requirements,
        dialogOpen: open,
        showDialog({ cardId, config, requirements }: { cardId: string, config: ListConfig, requirements: ListRequirements }) {
            setCardId(cardId);
            setConfig(config);
            setRequirements(requirements);
            setOpen(true);
        },
        hideDialog() {
            setCardId('');
            setConfig(null);
            setRequirements(null);
            setOpen(false);
        },
    }
}