import {useForm} from "react-hook-form";
import {ThirdPartyFilterModel} from "./types";
import create from 'zustand';

export function useThirdPartyFilterForm() {
    const [formValues, setFormValues, resetFormValues] = useThirdPartyFilterStore((state) => [state.formValues, state.setFormValues, state.resetFormValues]);
    const methods = useForm<ThirdPartyFilterModel>({
        defaultValues: formValues || {}
    });

    // Appliquer les filtres
    const applyFilters = methods.handleSubmit((values) => setFormValues(values));

    // Réinitialiser les filtres
    const resetFilters = () => {
        methods.reset();
        resetFormValues();
        applyFilters();
    }

    return{
        methods,
        filters: formValues,
        applyFilters,
        resetFilters,
    }
}

interface UseThirdPartyFilterModel {
    formValues: ThirdPartyFilterModel | null;
    setFormValues(values: ThirdPartyFilterModel): void;
    resetFormValues(): void;
}

const useThirdPartyFilterStore = create<UseThirdPartyFilterModel>((set) => ({
    formValues: null,
    setFormValues: (values) => set({ formValues: values }),
    resetFormValues: () => set({ formValues: null }),
}));