import {createContext, useContext} from "react";
import {UseQueryResult} from "@tanstack/react-query";
import {ProposalSearchResult} from "../../models/proposal.model";
import {PaginatedResponse} from "../../models/common.model";

type AffaireListContextProps = {
    query?: UseQueryResult<PaginatedResponse<ProposalSearchResult>>,
}

export const AffaireListContext = createContext<AffaireListContextProps>({});

export function useAffaireListContext() {
    return useContext(AffaireListContext);
}