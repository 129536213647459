import TableCell from '@mui/material/TableCell';
import {useAffaireListContext} from "./useAffaireListContext";
import {FormattedNumber} from "react-intl";
import {IonButton, IonChip, IonIcon, IonModal, IonRouterLink} from "@ionic/react";
import {DateTime} from "luxon";
import {ProposalSearchResult, ProposalStatus} from '../../models/proposal.model';
import {ColDef} from "../Common/Table";
import PowerTable from "../Common/Table/PowerTable";
import React from "react";
import {buildOutline, buildSharp, closeOutline, closeSharp} from "ionicons/icons";
import {Tooltip} from '@mui/material';
import InterventionFormModal from "../Intervention/InterventionFormModal";
import {InterventionType} from "../../models/intervention.model";
import AffaireShow from "../AffaireDetail/AffaireShow";
import {useAffaireShowModalStore} from "./store";
import {ProductTypology} from "../Intervention/types";
import {ProposalStatusChip} from "../AffaireDetail/ProposalStatusChip";

type ColKey = keyof ProposalSearchResult | 'marge_theorique' | 'intervention';

const cols: Array<ColDef<ColKey>> = [
    { id: 'intervention', label: 'Intervention', },
    { id: 'ref', label: 'Référence', },
    { id: 'date', label: 'Date proposition', },
    { id: 'fin_validite', label: 'Date d\'échéance', },
    { id: 'origine_label', label: 'Origine', },
    { id: 'status', label: 'Statut', },
    { id: 'thirdparty_label', label: 'Nom', },
    { id: 'thirdparty_address', label: 'Adresse', },
    { id: 'thirdparty_zip', label: 'Code Postal', },
    { id: 'thirdparty_town', label: 'Ville', },
    { id: 'total_ht', label: 'Total HT', },
    { id: 'total_tva', label: 'Montant TVA', },
    { id: 'total_ttc', label: 'Total TTC', },
    { id: 'responsable_label', label: 'Responsables', },
    { id: 'vendeur_label', label: 'Vendeur', },
    { id: 'rabatteur_label', label: 'Rabatteurs', },
    { id: 'foire_label', label: 'Foire', },
    { id: 'marge_theorique', label: 'Marge théorique', },
];

interface AffaireTableProps {
    prependInterventionCol?: boolean;
    minHeight?: number | string;
    showMode?: 'page' | 'modal';
    onInterventionScheduled?(): void
}

export default function AffaireTable({ prependInterventionCol = false, minHeight = 500, showMode = 'page', onInterventionScheduled }: AffaireTableProps) {
    const { query } = useAffaireListContext();

    const data = query?.data;

    const rows = data?.items || [];

    const tableCols = cols.filter(col => {
        return prependInterventionCol ? col : col.id !== "intervention";
    });

    const { isOpen, selectedProposalId, hide } = useAffaireShowModalStore();

    return(
        <>
            <PowerTable
                name={'affaire table'}
                cols={tableCols}
                rows={rows}
                renderCell={makeRenderCell(showMode, onInterventionScheduled)}
                loading={!!(query?.isLoading && query?.isFetching)}
                error={query?.error}
                sx={{ minHeight }}
            />
            <IonModal id={'AffaireTableModal'} isOpen={isOpen} onWillDismiss={hide} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
                <div className="ion-padding" style={{ paddingBottom: 0 }}>
                    <IonButton fill="clear" size={"small"} onClick={hide}>
                        <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                        Fermer
                    </IonButton>
                </div>

                <AffaireShow id={selectedProposalId} />
            </IonModal>
        </>
    )
}

function makeRenderCell(showMode: 'page' | 'modal', onInterventionScheduled?: () => void) {
    return function renderCell(key: ColKey, value: any, row: ProposalSearchResult) {
        if (key === "intervention") {
            return <InterventionActionCell row={row} id={row.id} onInterventionScheduled={onInterventionScheduled} />;
        }

        if (key === "ref") {
            return (
                showMode === 'page'
                    ? <RefCellLink value={value} id={row.id} />
                    : <RefCellModal value={value} id={row.id} />
            )
        }

        if (["date", "fin_validite"].indexOf(key) !== -1) {
            return <DateCell value={value} />;
        }

        if (key === "status") {
            return <StatusCell value={value} />;
        }

        if (key === "thirdparty_label") {
            return (
                <LabelCell
                    initial={row.thirdparty_initial}
                    label={row.thirdparty_label}
                />
            );
        }

        if (["total_ht", "total_tva", "total_ttc"].indexOf(key) !== -1) {
            return <PriceCell value={value} />;
        }

        if (key === 'marge_theorique') {
            return (
                <TableCell>
                    {(value || 0).toFixed(2)}
                </TableCell>
            )
        }
    }
}

function InterventionActionCell({ row: proposal, id, onInterventionScheduled }: { row: ProposalSearchResult, id: string, onInterventionScheduled?: () => void }) {
    const thirdParty = proposal.thirdparty;

    const projectId = thirdParty?.array_options?.options_related_project || '0';

    const coords = [
        Number(thirdParty?.array_options?.options_lat || '0'),
        Number(thirdParty?.array_options?.options_lng || '0'),
    ];

    const typologies: ProductTypology[] = (proposal?.lines || [])
        .map(line => line.array_options?.options_product_typology as ProductTypology)
        .filter(e => !!e)
    ;

    const baseProps = {
        proposal: {
            id: +proposal.id,
            reference: proposal?.ref,
            origin: proposal?.array_options?.options_origine_affaire,
            originId: proposal?.array_options?.options_foire_origine,
            productTypology: typologies[0],
        },
        thirdParty: {
            id: +thirdParty.id,
            name: thirdParty?.name || '',
        },
        place: {
            address: thirdParty?.address || '',
            town: thirdParty?.town || '',
            zipCode: thirdParty?.zip || '',
            coords
        },
        projectId: +projectId,
    }

    return (
        <TableCell>
            <Tooltip title="Programmer une intervention" placement={"right"}>
                <IonButton id={`shortcut-schedule-intervention-vt-${id}`} size={"small"} fill={"clear"}>
                    <IonIcon slot="icon-only" md={buildSharp} ios={buildOutline} />
                </IonButton>
            </Tooltip>

            <InterventionFormModal
                {...baseProps}
                trigger={`shortcut-schedule-intervention-vt-${id}`}
                defaultInterventionType={InterventionType.VISITE_TECHNIQUE}
                onWillDismiss={() => onInterventionScheduled && onInterventionScheduled()}
            />
        </TableCell>
    )
}

function RefCellLink({ value, id }: { value: string, id: string }) {
    return (
        <TableCell>
            <IonRouterLink routerLink={`/page/Affaires/${id}`}>{value}</IonRouterLink>
        </TableCell>
    )
}

function RefCellModal({ value, id }: { value: string, id: string }) {
    const { show } = useAffaireShowModalStore();

    return (
        <TableCell>
            <IonRouterLink style={{ cursor: 'pointer' }} onClick={() => show(id)}>{value}</IonRouterLink>
        </TableCell>
    )
}

function DateCell({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <TableCell>{d.toLocaleString(DateTime.DATE_SHORT)}</TableCell>
}

function StatusCell({ value }: { value: ProposalStatus }) {
    return (
        <TableCell>
            <ProposalStatusChip status={value} />
        </TableCell>
    )
}

function LabelCell({ initial, label }: { initial: string, label: string }) {
    return (
        <TableCell>
            <IonChip><b>{initial}</b></IonChip>
            {label}
        </TableCell>
    )
}

function PriceCell({ value }: { value: number | string }) {
    return (
        <TableCell>
            <FormattedNumber
                value={Number(value || 0)}
                style="currency"
                currency={"EUR"}
            />
        </TableCell>
    )
}
