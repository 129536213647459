import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";

const SearchEvent : React.FC = () => {

    const {control} = useFormContext();

    return (
        <>
            <IonList>
                <IonItem>
                    <IonLabel position="floating">Libellé</IonLabel>
                    <Controller
                        name={"label"}
                        render={({field}) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel position="floating">Lieu</IonLabel>
                    <Controller
                        name={"location"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel>État</IonLabel>
                    <Controller
                        name={"status"}
                        render={
                            ({field}) => {
                                return (
                                    <IonSelect cancelText={"Annuler"} placeholder="État" value={field.value}
                                               onIonChange={field.onChange}>
                                        <IonSelectOption value='50'>En cours</IonSelectOption>
                                        <IonSelectOption value='100'>Terminé</IonSelectOption>
                                        <IonSelectOption value='todo'>À faire</IonSelectOption>
                                        <IonSelectOption value='na'>N/A</IonSelectOption>
                                    </IonSelect>
                                )
                            }
                        }
                    />
                </IonItem>

                <IonItemGroup>
                    <IonItemDivider color="light">
                        <IonLabel>
                            <b>Date de début</b>
                        </IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel>Du</IonLabel>
                        <Controller
                            name={"startDateStart"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date début du'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v.startOf('day')) : null)
                                            }
                                        }
                                    />
                                )
                            }}
                        />
                    </IonItem>

                    <IonItem>
                        <IonLabel>Au</IonLabel>
                        <Controller
                            name={"startDateEnd"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date début au'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v.endOf('day')) : null)
                                            }
                                        }
                                    />
                                )
                            }}
                        />
                    </IonItem>
                </IonItemGroup>

                <IonItemGroup>
                    <IonItemDivider color="light">
                        <IonLabel>
                            <b>Date de fin</b>
                        </IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel>Du</IonLabel>
                        <Controller
                            name={"endDateStart"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date fin du'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v.startOf('day')) : null)
                                            }
                                        }
                                    />
                                )
                            }}
                        />
                    </IonItem>

                    <IonItem>
                        <IonLabel>Au</IonLabel>
                        <Controller
                            name={"endDateEnd"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date fin au'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? idateTime(v.endOf('day')) : null)
                                            }
                                        }
                                    />
                                )
                            }}
                        />
                    </IonItem>
                </IonItemGroup>
            </IonList>
        </>
    );
};

export default SearchEvent;