import {useController, useFormContext} from "react-hook-form";
import React from "react";
import {Box, FormHelperText, Stack} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddressAutocomplete from "../Common/AddressAutocomplete";

export default function InterventionEditAddressForm() {
    return (
        <Box sx={{ p: 2, maxWidth: 600 }}>
            <Stack direction={"column"} spacing={1}>
                <AddressAutocompleteController />
            </Stack>
        </Box>
    )
}

function AddressAutocompleteController({ label = "Adresse" }: { label?: string }) {
    const name = 'address';
    const labelId = `${name}-address`;
    const id = `${name}-select`;

    const { setError } = useFormContext();

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController({
        name,
        defaultValue: null,
        rules: {
            required: "Adresse obligatoire",
        }
    });

    return (
        <FormControl fullWidth error={!!error}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <AddressAutocomplete
                error={!!error}
                onChange={(a) => {
                    if (!a.street || !a.postalCode) {
                        setError(name, { type: 'addressError', message: "Veuillez saisir un numéro de rue" });
                    } else {
                        onChange(a);
                    }
                }}
            />

            {
                error && (
                    <FormHelperText>
                        {error.message}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}