import {useMutation} from "@tanstack/react-query";
import {InterventionType} from "../../models/intervention.model";
import {editVisiteTechnique, EditVisitetechniqueDto} from "../../calls/Interventions/VT/editVisiteTechnique";

export function useUpdateIntervention(type: InterventionType) {
    const handler = getHandlerByType(type);

    return useMutation(({ id, dto }: { id: string, dto: EditVisitetechniqueDto }) => {
        return handler(id, dto);
    });
}

function getHandlerByType(type: InterventionType) {
    switch (type) {
        case InterventionType.VISITE_TECHNIQUE:
            return editVisiteTechnique;

        // case InterventionType.POSE:
        //     return TODO;

        default:
            throw Error(`Oups no handler found for intervention type ${type}`)
    }
}