import {searchUsers} from "../../calls/User/searchUsers";
import {useQuery} from "@tanstack/react-query";

export function useTechniciansQuery(q: string = '') {
    const queryFn = () => searchUsers({
        name: q,
        job: 'Visite technique',
    });

    return useQuery(["technicians", q], queryFn, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
}