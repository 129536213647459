import {FormProvider, useForm} from "react-hook-form";
import ProposalForm from "./ProposalForm";
import Box from "@mui/material/Box";
import {DevTool} from "@hookform/devtools";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {zodResolver} from '@hookform/resolvers/zod';
import {CreateProposalSchema} from "./ProposalForm/validators";
import {CreateProposalFormModel} from "./ProposalForm/types";
import useCreateProposal from "./useCreateProposal";
import {LoadingButton} from "@mui/lab";
import {useHistory} from "react-router";
import {ProposalFormContext} from "./ProposalForm/ProposalFormContext";
import {Prompt, useLocation} from "react-router-dom";
import React, {useEffect, useMemo} from "react";
import {Proposal} from "../../models/proposal.model";

interface CreateProposalProps {
    defaultValues?: Partial<CreateProposalFormModel>;
    onCancel?(): void;
    onSubmitted?(p: Proposal): void;
}

export default function CreateProposal({ defaultValues, onSubmitted, onCancel }: CreateProposalProps = {}) {
    const methods = useForm<CreateProposalFormModel>({
        resolver: zodResolver(CreateProposalSchema),
        defaultValues: defaultValues || {},
    });

    const { mutateAsync, isLoading } = useCreateProposal();

    let history = useHistory();

    const location = useLocation();
    const returnUrl = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);

        return searchParams.has("returnUrl")
            ? searchParams.get("returnUrl") as string
            : ""
    }, [location]);

    const onSubmit = async (data: CreateProposalFormModel) => {
        try {
            const { id, ...restOfRes } = await mutateAsync(data);

            if (!onSubmitted) {
                // Si pas de pb Redirection après succès mutation
                history.push(returnUrl || `/page/Affaires/${id}`, {
                    proposalCreated: true,
                });
            } else {
                onSubmitted({ id, ...restOfRes });
            }
        } catch (e) {
            console.error(e);
        }
    }

    const handleCancel = () => {
        onCancel ? onCancel() : history.push(returnUrl || `/page/Affaires`);
    }

    // Demander une confirmation au refresh s'il y a eu une interaction avec le form ?
    useEffect(() => {
        if (methods.formState.isDirty) {
            window.onbeforeunload = () => true;
        } else {
            (window as any).onbeforeunload = undefined;
        }
    }, [methods.formState.isDirty]);

    return (
        <>
            <Prompt
                when={!isLoading && methods.formState.isDirty} // Demander confirmation si navigation vers autre route
                message="Êtes-vous sûr de vouloir quitter cette page ?"
            />

            <Box>
                <Typography variant="h6" component={"p"}>
                    Nouveau devis
                </Typography>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} spacing={1} mb={1} mt={1}>
                    <LoadingButton
                        loading={isLoading}
                        variant={"contained"}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </LoadingButton>

                    <Button variant={"contained"} color={"inherit"} onClick={handleCancel}>
                        Annuler
                    </Button>
                </Stack>

                <FormProvider {...methods}>
                    <ProposalFormContext.Provider value={{
                        submitOnBlur() {}
                    }}>
                        <ProposalForm
                            enableLinesCopy={true}
                        />
                    </ProposalFormContext.Provider>
                </FormProvider>

                <DevTool control={methods.control} />
            </Box>
        </>
    )
}