import {UseQueryResult} from "@tanstack/react-query";
import {Intervention} from "../../models/intervention.model";
import {createContext, useContext} from "react";
import {PaginatedResponse, PaginationResultModel} from "../../models/common.model";

type InterventionsListContextProps = {
    query?: UseQueryResult<PaginationResultModel<Intervention>>,
}

export const InterventionsListContext = createContext<InterventionsListContextProps>({});

export function useInterventionsListContext(){
    return useContext(InterventionsListContext);
}