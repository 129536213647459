import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

interface LoginPayload {
    login: string;
    password: string;
}

interface LoginResponse {
    success: {
        code: number;
        token: string;
        entity: string;
        message: string;
    };
}

export function login(payload: LoginPayload) {
    return http.post<LoginResponse>(Endpoint.userLogin, payload)
}