import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useCallback, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import {downloadOutline, downloadSharp, reloadOutline, reloadSharp} from "ionicons/icons";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {searchFoires} from "../../calls/searchFoires";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import {keyBy} from "lodash";
import Toolbar from "@mui/material/Toolbar";
import {CommissionPayeesData, fetchTableauCommissionPayees} from "../../calls/Reports/fetchTableauCommissionPayees";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    simulateTableauCommission,
    TableauCommissionSimulationResponse
} from "../../calls/Reports/simulateTableauCommission";

export default function TableauCommissionV2() {
    const tableRef = useRef();

    return (
        <IonGrid style={{ height: '100%', overflow: 'scroll' }}>
            <IonRow>
                <IonCol>
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Tableau Commission</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonCol>
            </IonRow>

            <PayeesConfiguration />
        </IonGrid>
    )
}

interface  FormModel {
    filters: {
        foireId: string;
    },
    configuration?: Configuration;
}

export interface Configuration {
    responsables: Responsables[];
    vendeurs: Vendeurs[];
    rabatteurs: Rabatteurs[];
    absents: Absents[];
    vt: Vt;
}

export interface Vt {
    tauxDebloque: number;
    taux: number;
}

export interface Responsables {
    id: string;
    tauxDebloque: number;
    tauxSiAbsent: number;
    taux: number;
}

export interface Vendeurs {
    id: string;
    tauxDebloque: number;
    tauxSiSeul: number;
    tauxSiPiqueur: number;
}

export interface Rabatteurs {
    id: string;
    tauxDebloque: number;
    taux: number;
}

export interface Absents {
    id: string;
    taux: number;
}

function PayeesConfiguration() {
    const methods = useForm<FormModel>();

    const { isFetching, data: response, refetch } = useQuery(['tableauCommission', 'payees'], () => {
        const { filters: { foireId } } = methods.getValues();
        return fetchTableauCommissionPayees({ foireId })
    }, { enabled: false });

    const payees = response?.status === "OK" ? response.data : undefined;

    return (
        <FormProvider {...methods}>
            <Grid item xs={12} md={12}>
                <Card>
                    <CardContent>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <FoireAutocomplete />

                            <ListPayeesButton onClick={methods.handleSubmit(() => refetch())} />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>

            {
                !!response && (
                    <PayeesList payees={payees!} />
                )
            }

            {
                !!response && (
                    <ProposalsSection payees={payees!} />
                )
            }
        </FormProvider>
    )
}

function FoireAutocomplete() {
    const name = 'filters.foireId';

    const { isLoading, data } = useQuery(['tableauCommission', 'foires'], () => searchFoires({}), {
        // enabled: watchedValue.length > 1,
        staleTime: 20 * 1000,
    });

    const options = (data?.data || [])
        .map(
            (o: { label: string, id: string }) => ({ label: o.label, id: o.id })
        )
    ;

    const optionsRecord = keyBy(options, 'id');

    return (
        <Controller
            name={name}
            defaultValue={null}
            rules={{
                required: "Foire obligatoire"
            }}
            render={
                ({ field: { onChange, value } }) => {
                    return (
                        <Autocomplete
                            disablePortal
                            size={"small"}
                            id="combo-box-foire"
                            options={options}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} disabled={isLoading} label="Sélectionner une foire" />}
                            noOptionsText={"Foire non trouvé"}
                            value={value ? optionsRecord[value] : null}
                            onChange={
                                (e, newValue: { label: string, id: string } | null, reason) => onChange(newValue?.id)
                            }
                        />
                    )
                }
            }
        />
    )
}

function PayeesList({ payees }: { payees: CommissionPayeesData }) {
    const { responsables, vendeurs, rabatteurs } = payees;

    return (
        <Card sx={{ mt: .5 }}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Responsables ({responsables.length})
                        </Typography>

                        <Stack direction={"column"} spacing={1} sx={{ mt: 2, width: '100%' }}>
                            {
                                responsables.map(
                                    (i, index) => (
                                        <ResponsablesConfigurationControl
                                            key={i.id}
                                            payeeId={i.id}
                                            index={index}
                                            label={i.fullName}
                                        />
                                    )
                                )
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Vendeurs ({vendeurs.length})
                        </Typography>

                        <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
                            {
                                vendeurs.map(
                                    (i, index) => (
                                        <VendeursConfigurationControl
                                            key={i.id}
                                            payeeId={i.id}
                                            index={index}
                                            label={i.fullName}
                                        />
                                    )
                                )
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Rabatteurs ({rabatteurs.length})
                        </Typography>

                        <Stack direction={"column"} spacing={1} sx={{ mt: 2 }}>
                            {
                                rabatteurs.map(
                                    (i, index) => (
                                        <RabatteursConfigurationControl
                                            key={i.id}
                                            payeeId={i.id}
                                            index={index}
                                            label={i.fullName}
                                        />
                                    )
                                )
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            VT
                        </Typography>

                        <Stack direction={"column"} alignItems={"center"} spacing={1} sx={{ mt: 2 }}>
                            {
                                vtInputs.map(
                                    i => (
                                        <ConfigurationGenericTextField
                                            key={i.id}
                                            name={i.id}
                                            label={i.label}
                                            defaultValue={i.defaultValue}
                                        />)
                                )
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ width: '100%' }}>
                    <SimulateButton />
                </Stack>
            </CardActions>
        </Card>
    )
}

function ResponsablesConfigurationControl({ payeeId, index, label }: { payeeId: string, index: number, label: string }) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`Responsable ${payeeId}`}
                id={`Responsables-${payeeId}`}
            >
                <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <PayeeIdControl name={`responsables.${index}.id`} index={index} payeeId={payeeId} />

                <Stack direction={"column"} alignItems={"center"} spacing={.5}>
                    {
                        generateResponsablesInputs(index).map(
                            i => (
                                <ConfigurationGenericTextField
                                    key={i.id}
                                    name={i.id}
                                    label={i.label}
                                    defaultValue={i.defaultValue}
                                />
                            )
                        )
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function VendeursConfigurationControl({ payeeId, index, label }: { payeeId: string, index: number, label: string }) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`Vendeur ${payeeId}`}
                id={`Vendeurs-${payeeId}`}
            >
                <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <PayeeIdControl name={`vendeurs.${index}.id`} index={index} payeeId={payeeId} />

                <Stack direction={"column"} alignItems={"center"} spacing={1}>
                    {
                        generateVendeursInputs(index).map(
                            i => (
                                <ConfigurationGenericTextField
                                    key={i.id}
                                    name={i.id}
                                    label={i.label}
                                    defaultValue={i.defaultValue}
                                />
                            )
                        )
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function RabatteursConfigurationControl({ payeeId, index, label }: { payeeId: string, index: number, label: string }) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`Rabatteur ${payeeId}`}
                id={`Rabatteurs-${payeeId}`}
            >
                <Typography>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <PayeeIdControl name={`rabatteurs.${index}.id`} index={index} payeeId={payeeId} />

                <Stack direction={"column"} spacing={1}>
                    {
                        generateRabatteursInputs(index).map(
                            i => (
                                <ConfigurationGenericTextField
                                    key={i.id}
                                    name={i.id}
                                    label={i.label}
                                    defaultValue={i.defaultValue}
                                />
                            )
                        )
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

function PayeeIdControl({ name, index, payeeId }: { name: string, index: number, payeeId: string }) {
    const realName = `configuration.${name}`;

    return (
        <Controller
            name={realName}
            defaultValue={payeeId}
            render={({ field }) => {
                return (<input type="hidden" value={field.value} />)
            }}
        />
    )
}

function ConfigurationGenericTextField({ name, label, defaultValue }: { name: string, label: string, defaultValue: number }) {
    const realName = `configuration.${name}`;
    const id = `${realName}-textField`;

    return (
        <Controller
            name={realName}
            defaultValue={defaultValue}
            render={
                ({ field }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>

                            <TextField
                                id={id}
                                type={"number"}
                                variant="outlined"
                                size={"small"}
                                value={field.value}
                                onChange={e => field.onChange(+e.target.value)}
                            />
                        </FormControl>
                    )
                }
            }
        />
    )
}

const generateResponsablesInputs = (index: number) => ([
    { id: `responsables.${index}.taux`, label: 'Taux %', defaultValue: 0 },
    { id: `responsables.${index}.tauxDebloque`, label: 'Taux débloqué %', defaultValue: 0 },
    { id: `responsables.${index}.tauxSiAbsent`, label: 'Taux si absent %', defaultValue: 0 },
]);

const generateVendeursInputs = (index: number) => [
    { id: `vendeurs.${index}.tauxDebloque`, label: 'Taux débloqué %', defaultValue: 70 },
    { id: `vendeurs.${index}.tauxSiSeul`, label: 'Taux si seul %', defaultValue: 5 },
    { id: `vendeurs.${index}.tauxSiPiqueur`, label: 'Taux si piqueur %', defaultValue: 4 },
];

const generateRabatteursInputs = (index: number) => [
    { id: `rabatteurs.${index}.tauxDebloque`, label: 'Taux débloqué %', defaultValue: 100 },
    { id: `rabatteurs.${index}.taux`, label: 'Taux rabatteur %', defaultValue: 1 },
];

const generateAbsentsInputs = (index: number) => [
    { id: `absents.${index}.taux`, label: 'Taux Absent %', defaultValue: 1 },
];

const vtInputs = [
    { id: 'vt.tauxDebloque', label: 'Taux débloqué %', defaultValue: 100 },
    { id: 'vt.taux', label: 'Taux VT %', defaultValue: 1 },
];

function ListPayeesButton({ onClick }: { onClick: any }) {
    return (
        <IonButton disabled={false} fill={"outline"} style={{ marginLeft: 20 }} onClick={onClick}>
            <IonIcon slot="start" md={reloadSharp} ios={reloadOutline}></IonIcon>
            Lister Bénéficiaires
        </IonButton>
    )
}

function SimulateButton() {
    const { getValues } = useFormContext<FormModel>();

    const { refetch, isFetching } = useQuery(['tableauCommission', 'simulation', 'results'], () => {
        const { filters: { foireId: foire }, configuration } = getValues();
        return simulateTableauCommission({
            filters: { foire },
            configuration,
        })
    }, { enabled: false });

    return (
        <IonButton disabled={isFetching} fill={"outline"} style={{ marginLeft: 20 }} onClick={() => refetch()}>
            <IonIcon slot="start" md={reloadSharp} ios={reloadOutline}></IonIcon>
            Calculer
        </IonButton>
    )
}

function ProposalsSection({ payees }: { payees: CommissionPayeesData }) {
    const { getValues } = useFormContext<FormModel>();

    const { isFetching, data: response, refetch } = useQuery<TableauCommissionSimulationResponse>(['tableauCommission', 'simulation', 'results'], { enabled: false });

    const onSubmit = (d: FormModel) => {

    }

    const rows = response?.rows || [];

    const tableRef = useRef();

    const { responsables, vendeurs, rabatteurs } = payees;

    return (
        <Grid item xs={12} md={12} sx={{ mt: 1 }}>
            {
                !!response && (
                    <Paper sx={{ width: '100%', mt: 2 }}>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 1 },
                                bgcolor: 'white'
                            }}
                        >
                            <ExportExcelButton tableRef={tableRef} disabled={isFetching} />
                        </Toolbar>

                        <TableContainer component={Paper} sx={{mt: 1, minHeight: 400, maxHeight: 650 }} elevation={2}>
                            <StyledTable ref={tableRef as any} sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={cols.length} />

                                        <BorderedCell align="center" colSpan={responsables.length * 2}>
                                            <b>Responsables</b>
                                        </BorderedCell>

                                        <BorderedCell align="center" colSpan={vendeurs.length * 3}>
                                            <b>Vendeurs</b>
                                        </BorderedCell>

                                        <BorderedCell align="center" colSpan={rabatteurs.length * 3}>
                                            <b>Rabatteurs</b>
                                        </BorderedCell>

                                        <TableCell />
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={cols.length} />

                                        {
                                            responsables.map(
                                                col => (
                                                    <BorderedCell align="center" key={col.id} colSpan={2}><b>{col.fullName}</b></BorderedCell>
                                                )
                                            )
                                        }

                                        {
                                            vendeurs.map(
                                                col => (
                                                    <BorderedCell align="center" key={col.id} colSpan={3}><b>{col.fullName}</b></BorderedCell>
                                                )
                                            )
                                        }

                                        {
                                            rabatteurs.map(
                                                col => (
                                                    <BorderedCell align="center" key={col.id} colSpan={3}><b>{col.fullName}</b></BorderedCell>
                                                )
                                            )
                                        }

                                        <TableCell />
                                    </TableRow>

                                    <TableRow>
                                        {
                                            cols.map(
                                                col => (
                                                    <BorderedCell key={col.id}><b>{col.label}</b></BorderedCell>
                                                )
                                            )
                                        }

                                        {
                                            responsables.map(
                                                col => (
                                                    <React.Fragment key={`Responsable-Header-${col.id}`}>
                                                        <BorderedCell align="center"><b>Taux</b></BorderedCell>
                                                        <BorderedCell align="center"><b>Com.</b></BorderedCell>
                                                    </React.Fragment>
                                                )
                                            )
                                        }

                                        {
                                            vendeurs.map(
                                                col => (
                                                    <React.Fragment key={`Vendeur-Header-${col.id}`}>
                                                        <BorderedCell align="center"><b>Taux débloqué</b></BorderedCell>
                                                        <BorderedCell align="center"><b>Taux</b></BorderedCell>
                                                        <BorderedCell align="center"><b>Com.</b></BorderedCell>
                                                    </React.Fragment>
                                                )
                                            )
                                        }

                                        {
                                            rabatteurs.map(
                                                col => (
                                                    <React.Fragment key={`Rabatteur-Header-${col.id}`}>
                                                        <BorderedCell align="center"><b>Taux débloqué</b></BorderedCell>
                                                        <BorderedCell align="center"><b>Taux</b></BorderedCell>
                                                        <BorderedCell align="center"><b>Com.</b></BorderedCell>
                                                    </React.Fragment>
                                                )
                                            )
                                        }

                                        <TableCell align="center"><b>Total commission</b></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        rows.map(
                                            (row) => {
                                                const { details, totalCommissionText } = row.commission;

                                                const responsablesRecord = keyBy(details.responsables, 'id');
                                                const vendeursRecord = keyBy(details.vendeurs, 'id');
                                                const rabatteursRecord = keyBy(details.rabatteurs, 'id');

                                                return (
                                                    <TableRow key={row.reference}>
                                                        {
                                                            cols.map(
                                                                col => {
                                                                    const value = (row as any)[col.id];

                                                                    return (
                                                                        <BorderedCell key={`${row.reference}-${col.id}`}>
                                                                            {`${value || ''}`.trim()}
                                                                        </BorderedCell>
                                                                    )
                                                                }
                                                            )
                                                        }

                                                        {
                                                            responsables.map(
                                                                col => (
                                                                    <React.Fragment key={`Responsable-Row-${col.id}`}>
                                                                        <BorderedCell>
                                                                            {responsablesRecord[col.id]?.tauxText}
                                                                        </BorderedCell>

                                                                        <BorderedCell>
                                                                            {responsablesRecord[col.id]?.montantCommissionText}
                                                                        </BorderedCell>
                                                                    </React.Fragment>
                                                                )
                                                            )
                                                        }

                                                        {
                                                            vendeurs.map(
                                                                col => (
                                                                    <React.Fragment key={`Vendeur-Row-${col.id}`}>
                                                                        <BorderedCell>
                                                                            {vendeursRecord[col.id]?.tauxDebloqueText}
                                                                        </BorderedCell>

                                                                        <BorderedCell>
                                                                            {vendeursRecord[col.id]?.tauxText}
                                                                        </BorderedCell>

                                                                        <BorderedCell>
                                                                            {vendeursRecord[col.id]?.montantCommissionText}
                                                                        </BorderedCell>
                                                                    </React.Fragment>
                                                                )
                                                            )
                                                        }

                                                        {
                                                            rabatteurs.map(
                                                                col => (
                                                                    <React.Fragment key={`Rabatteur-Row-${col.id}`}>
                                                                        <BorderedCell>
                                                                            {rabatteursRecord[col.id]?.tauxDebloqueText}
                                                                        </BorderedCell>

                                                                        <BorderedCell>
                                                                            {rabatteursRecord[col.id]?.tauxText}
                                                                        </BorderedCell>

                                                                        <BorderedCell>
                                                                            {rabatteursRecord[col.id]?.montantCommissionText}
                                                                        </BorderedCell>
                                                                    </React.Fragment>
                                                                )
                                                            )
                                                        }

                                                        <BorderedCell>{totalCommissionText}</BorderedCell>
                                                    </TableRow>
                                                )
                                            }
                                        )
                                    }
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                    </Paper>
                )
            }
        </Grid>
    )
}

const BorderedCell = styled(TableCell)`
    border: 1px solid rgba(224, 224, 224, 1);
`;

function ExportExcelButton({ tableRef, disabled }: { tableRef: any, disabled?: boolean }) {
    const handleExport = useCallback(() => {
        const workbook = XLSX.utils.book_new();

        workbook.Props = {
            Title: "Tableaucommission",
            Subject: "Tableaucommission",
            Author: "AMDP",
            CreatedDate: new Date(),
        }

        const worksheet = XLSX.utils.table_to_sheet(tableRef.current);

        XLSX.utils.book_append_sheet(workbook, worksheet, "Tableaucommission");

        /* generate a binary string */
        const bin = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });
        saveAs(new Blob([bin]), `Tableaucommission_${Date.now()}.xlsx`);
    }, [tableRef]);

    return (
        <IonButton disabled={disabled} fill={"outline"} style={{ marginLeft: 'auto' }} onClick={() => handleExport()}>
            <IonIcon slot="start" md={downloadSharp} ios={downloadOutline}></IonIcon>
            Export Excel
        </IonButton>
    )
}

const cols = [
    { id: 'reference', label: "Référence" },
    { id: 'proposalDate', label: "Date" },
    { id: 'foire', label: "Ville Foire" },
    { id: 'financement', label: "Financement" },
    // { id: "responsable", label: "Responsable", },
    // { id: "txResp", label: "TX Resp.", },
    // { id: "comResp", label: "Com. Resp.", },
    // { id: "responsable2", label: "Responsable 2", },
    // { id: "txResp2", label: "Tx Resp. 2", },
    // { id: "comResp2", label: "Com. Resp. 2", },
    // { id: "responsable3", label: "Responsable 3", },
    // { id: "txResp3", label: "Tx Resp. 3", },
    // { id: "comResp3", label: "Com. Resp. 3", },
    // { id: "responsable4", label: "Responsable 4", },
    // { id: "txResp4", label: "Tx Resp. 4", },
    // { id: "comResp4", label: "Com. Resp. 4", },
    { id: 'margeTheorique', label: "Marge théorique" },
    { id: "observations", label: "Observations" },
    { id: 'customerName', label: "Client" },
    { id: 'totalTTC', label: "Total TTC" },
    { id: 'totalHT', label: "Total HT" },
    { id: 'agios', label: "Agios" },
    { id: "pvHtReel", label: "PV HT Réel" },
    { id: "prixAchat", label: "Prix d'achat" },
    { id: "prixPose", label: "Prix pose" },
    { id: "titreDeLaPiece", label: "Titre de la pièce" },
    // { id :"vendeur", label: "Vendeur" },
    // { id :"txVendeur", label: "Tx com" },
    // { id :"comVendeur", label: "% com" },
    // { id :"percentComVendeur", label: "Comission" },
    // { id :"vendeur2", label: "Vendeur 2" },
    // { id :"txVendeur2", label: "Tx com" },
    // { id :"comVendeur2", label: "% com" },
    // { id :"percentComVendeur2", label: "Comission" },
    // { id: "piqueur", label: "Piqueur", },
    // { id: "txPiqueur", label: "Tx com", },
    // { id: "comPiqueur", label: "Comission", },
    // { id: "piqueur2", label: "Piqueur 2", },
    // { id: "percentPiqueur2", label: "Tx com", },
    // { id: "comPiqueur2", label: "Comission", },
    // { id: "vt", label: "VT", },
    // { id: "txVt", label: "Tx com", },
    // { id: "comVt", label: "Comission", },
    // { id: "status", label: "Etat affaire" },
    // { id: "absent", label: "Nom Absent 1", },
    // { id: "txAbsent", label: "Taux Absent 1", },
    // { id: "comAbsent", label: "Com absent 1", },
    // { id: "absent2", label: "Nom Absent 2", },
    // { id: "txAbsent2", label: "Taux Absent 2", },
    // { id: "comAbsent2", label: "Com absent 2", },
    // { id: "absent3", label: "Nom Absent 3", },
    // { id: "txAbsent3", label: "Taux Absent 3", },
    // { id: "comAbsent3", label: "Com absent 3", },
    // { id: "absent4", label: "Nom Absent 4", },
    // { id: "txAbsent4", label: "Taux Absent 4", },
    // { id: "comAbsent4", label: "Com absent 4", },
    // { id: "totalCommission", label: "Total com", },
];

export const StyledTable = styled(Table)`
    & th, tr {
        white-space: nowrap;
    }
`;

