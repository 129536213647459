import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {ListSearchResult} from "../../models/kanban/list.model";
import {Endpoint} from "../endpoint.enum";
import {Pagination} from "../types";

interface Payload {
    boardId: number;
}

export async function searchKanbanLists(payload: Payload, pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const { data } = await http.post<PaginatedResponse<ListSearchResult>>(Endpoint.searchProposalKanbanLists, payload);

    return data;
}