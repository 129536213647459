export class Contact {
  public civility_id?: any;
  public civility_code?: string;
  public civility?: string;
  public address?: string;
  public zip?: string;
  public town?: string;
  public state_id?: any;
  public poste?: string;
  public socid?: string;
  public fk_soc?: string;
  public statut?: string;
  public code?: any;
  public email?: string;
  public no_email?: any;
  public skype?: any;
  public twitter?: any;
  public facebook?: any;
  public linkedin?: any;
  public jabberid?: any;
  public photo?: any;
  public phone_pro?: string;
  public phone_perso?: string;
  public phone_mobile?: string;
  public fax?: string;
  public priv?: string;
  public birthday?: string;
  public default_lang?: any;
  public ref_facturation?: any;
  public ref_contrat?: any;
  public ref_commande?: any;
  public ref_propal?: any;
  public user_id?: any;
  public user_login?: any;
  public fk_prospectlevel?: string;
  public stcomm_id?: string;
  public statut_commercial?: string;
  public stcomm_picto?: any;
  public id: string;
  public entity: string;
  public import_key?: any;
  public array_languages?: any;
  public linkedObjectsIds?: any;
  public canvas?: any;
  public fk_project?: any;
  public contact_id?: any;
  public user?: any;
  public origin?: any;
  public origin_id?: any;
  public ref?: string;
  public ref_ext?: any;
  public status?: any;
  public country_id?: string;
  public country_code?: string;
  public region_id?: any;
  public barcode_type?: any;
  public barcode_type_coder?: any;
  public mode_reglement_id?: any;
  public cond_reglement_id?: any;
  public demand_reason_id?: any;
  public transport_mode_id?: any;
  public shipping_method_id?: any;
  public model_pdf?: any;
  public last_main_doc?: any;
  public fk_bank?: any;
  public fk_account?: any;
  public note_public?: any;
  public note_private?: any;
  public name?: any;
  public lastname: string;
  public firstname?: string;
  public date_creation?: number;
  public date_validation?: any;
  public date_modification?: number;
  public specimen?: number;
  public field?: Field;
  public socname?: string;
  public mail?: string;
}

export class Field {
  public fk_stcommcontact?: FkStcommcontact;
  public fk_prospectcontactlevel?: FkProspectcontactlevel;
}

export class FkStcommcontact {
  public enabled?: number;
}

export class FkProspectcontactlevel {
  public enabled?: number;
}

export const contactToString = (c: Contact) => {
    return `${c.civility_code || ''} ${c.firstname || ''} ${c.lastname || ''}`.trim();
}