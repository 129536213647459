import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

interface FormDefaultActionsProps {
    saveButtonText: string;
    cancelButtonText?: string;
    loading?: boolean;
    onSubmit?(): void;
    onCancel?(): void;
}

export default function FormDefaultActions({ cancelButtonText = "Annuler", saveButtonText, loading, onCancel, onSubmit }: FormDefaultActionsProps) {
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Button onClick={() => onCancel && onCancel()}>
                {cancelButtonText}
            </Button>

            <LoadingButton
                type={"submit"}
                loading={loading}
                loadingPosition="start"
                startIcon={<CheckRoundedIcon/>}
                variant="contained"
            >
                {saveButtonText}
            </LoadingButton>
        </Stack>
    )
}