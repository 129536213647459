import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type UpdateThirdPartyCompleteDto = {
    name: string;
    name_bis?: string;
    lastname?: string;
    firstname?: string;
    email?: string;
    client?: string;
    code_client?: number;
    commercial_id?: number | number[];
    typent_id?: number;
    civility_id?: string | number;
    civility_code?: string;
    address?: string,
    zip?: string,
    town?: string,
    countryId?: number,
    phone?: string,
    // entity: number,
    array_options: {
        civilite?: string
        personal_mobile_phone?: string,
        business_mobile_phone?: string,
        lat?: number,
        lng?: number;
    }
}

export type UpdateThirdPartyDto = Omit<UpdateThirdPartyCompleteDto, 'code_client' | 'countryId'> & {
    country_id: number;
};

export async function updateThirdParty(id: string, dto: UpdateThirdPartyCompleteDto): Promise<string> {
    const completeDto: UpdateThirdPartyDto = {
        ...dto,
        // client: dto.client || '2',
        country_id: dto.countryId || 1, // 1=France
    }

    const { data } = await http.put(Endpoint.updateThirdParty + `/${id}`, completeDto);

    return data;
}

