import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {TextField} from "@mui/material";
import React from "react";

interface PhoneControlProps {
    name?: string;
    label?: string;
}

export default function PhoneControl({ name = 'phone', label = 'Numéro de téléphone' }: PhoneControlProps) {
    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl fullWidth error={!!error}>
                        <FormLabel>{label}</FormLabel>
                        <TextField
                            size="small"
                            error={!!error}
                            onChange={onChange}
                            value={value}
                            fullWidth
                            variant="outlined"
                            placeholder={label}
                        />
                    </FormControl>
                )
            }}
        />
    )
}