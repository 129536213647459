import {
    BallonThermodynamiqueIcon,
    BallonThermodynamiqueWhiteIconSmall,
    PacAirAirIcon,
    PacAirAirWhiteIconSmall,
    PacAirEauIcon,
    PacAirEauWhiteIconSmall,
    PhotovoltaiqueIcon,
    PhotovoltaiqueWhiteIconSmall,
} from "./Icons";
import {ProductTypology} from "./types";

interface ProductTypologyUI {
    typologyIcon: Record<ProductTypology, any>,
    typologyIconSmall: Record<ProductTypology, any>,
    typologyColor: Record<ProductTypology, string>
}

export const productTypologyUI: ProductTypologyUI = {
    typologyIcon: {
        PAC_AIR_EAU: PacAirEauIcon,
        PAC_AIR_AIR: PacAirAirIcon,
        BALLON_THERMO: BallonThermodynamiqueIcon,
        PHOTOVOLTAIC: PhotovoltaiqueIcon,
    },
    typologyIconSmall: {
        PAC_AIR_EAU: PacAirEauWhiteIconSmall,
        PAC_AIR_AIR: PacAirAirWhiteIconSmall,
        BALLON_THERMO: BallonThermodynamiqueWhiteIconSmall,
        PHOTOVOLTAIC: PhotovoltaiqueWhiteIconSmall,
    },
    typologyColor: {
        PAC_AIR_EAU: 'var(--ion-color-pac-air-eau)',
        PAC_AIR_AIR: 'var(--ion-color-pac-air-air)',
        BALLON_THERMO: 'var(--ion-color-ballon-thermodynamique)',
        PHOTOVOLTAIC: 'var(--ion-color-photovoltaique)',
    }
}