import {SearchProductResult, searchProducts, SearchProductsPayload} from "../../calls/Products/searchProducts";
import {Pagination} from "../../calls/types";
import {QueryFunction, useQuery} from "@tanstack/react-query";

export function useProductsQuery(payload: SearchProductsPayload = {}, pagination?: Pagination) {
    const queryKey = ['products', {payload, pagination}];
    const queryFn: QueryFunction<SearchProductResult> = () => {
        return searchProducts(payload, pagination);
    }

    return useQuery<SearchProductResult>(queryKey, queryFn);
}