import {useThirdPartyBottomDrawerState} from "../useThirdPartyBottomDrawerStore";
import {useMutation, useQuery} from "@tanstack/react-query";
import {listThirdPartyFiles} from "../../../calls/ThirdParties/listThirdPartyFiles";
import {IonAvatar, IonIcon, IonItem, IonLabel, IonList} from "@ionic/react";
import {DateTime} from "luxon";
import {defaultStyles, FileIcon} from "react-file-icon";
import IonLoadingButton from "../../Common/Lab/IonLoadingButton";
import {arrowDownCircleOutline, arrowDownCircleSharp, linkOutline, linkSharp} from "ionicons/icons";
import {downloadDocument} from "../../../calls/Documents/downloadDocument";
import {saveAs} from 'file-saver';
import {useActiveCompanyId} from "../../Company/company.store";
import {getThirdParty} from "../../../calls/ThirdParties/getThirdParty";
import {uploadDocumentToEcm} from "../../../calls/Documents/uploadDocumentToEcm";
import {useRef} from "react";
import * as blobUtils from 'blob-util';

export default function DocumentTab() {
    const { currentThirdPartyId: thirdPartyId } = useThirdPartyBottomDrawerState();

    const { isLoading, data, isError, refetch } = useQuery(['3rdParty', thirdPartyId, 'files'], () => listThirdPartyFiles(thirdPartyId), {
        enabled: !!thirdPartyId
    });

    if (isLoading) {
        return <>Chargement ...</>
    }

    if (isError || data?.items.length === 0) {
        return (<>Pas de documents trouvés pour ce tiers</>)
    }

    return (
        <>
            <UploadButton thirdPartyId={thirdPartyId} onUploaded={() => refetch()} />

            <IonList>
                {
                    data?.items.map(
                        ({ filename, date, size }) => {
                            const extension = filename.split(".").pop() as string;

                            return (
                                <IonItem key={filename}>
                                    <IonAvatar slot="start">
                                        <FileIcon extension={extension} {...(defaultStyles as any)[extension]} />
                                    </IonAvatar>

                                    <IonLabel>
                                        <h3><b>{filename}</b></h3>
                                        <DateLabel value={date} />
                                    </IonLabel>

                                    <DownloadButton filename={filename} />
                                </IonItem>
                            )
                        }
                    )
                }
            </IonList>
        </>
    )
}

function DateLabel({ value }: { value: number }) {
    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <p>{d.toFormat('dd/MM/yyyy HH:mm:ss')}</p>;
}

function DownloadButton({ filename }: { filename: string }) {
    const companyId = useActiveCompanyId();

    const { currentThirdPartyId: id, currentThirdPartyName: name } = useThirdPartyBottomDrawerState();

    const folder = `${name} (${id})`;

    const file = `${folder}/${filename}`;

    const { mutateAsync, isLoading } = useMutation(() => downloadDocument({
        modulepart: 'ecm',
        original_file: file,
        attachment: 1,
        entity: +companyId,
    }));

    const handleDownload = async () => {
        const res = await mutateAsync();
        const base64 = `data:${res["content-type"]};base64,${res.content}`;
        saveAs(dataURItoBlob(base64), res.filename);
    }

    return (
        <IonLoadingButton
            loading={isLoading}
            color={"light"}
            icon={<IonIcon slot={"start"} md={arrowDownCircleSharp} ios={arrowDownCircleOutline} />}
            onClick={handleDownload}
        >
            Télécharger
        </IonLoadingButton>
    )
}

function dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
}

function UploadButton({ thirdPartyId, onUploaded }: { thirdPartyId: string, onUploaded: () => void }) {
    const { mutateAsync: uploadToEcm, isLoading } = useThirdPartyEcm(thirdPartyId);

    const fileInputRef = useRef();

    const handleClick = () => {
        (fileInputRef.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const filecontent = await blobUtils.blobToBase64String(file);

        await uploadToEcm({ filename, filecontent });

        onUploaded();
    };

    return (
        <>
            <IonLoadingButton fill="clear" onClick={() => handleClick()} loading={isLoading}>
                <IonIcon slot="start" md={linkSharp} ios={linkOutline}/>
                Joindre un fichier
            </IonLoadingButton>

            <input
                ref={fileInputRef as any}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </>
    )
}

function useThirdPartyEcm(thirdPartyId: string) {
    const { data: thirdparty } = useThirdPartyDetail(thirdPartyId);

    return useMutation(async ({ filename, filecontent }: { filename: string, filecontent: string }) => {
        // On success du download en base64 on upload le contenu et on est bon
        const subdir = `${thirdparty!.name} (${thirdparty!.id})`; // ex. Marcel Pierre (26)

        await uploadDocumentToEcm({
            filename,
            subdir,
            filecontent,
            overwriteifexists: 1, // On overwrite !
        });
    })
}

function useThirdPartyDetail(id: number | string) {
    return useQuery(['thirdParty', id], ({ queryKey }) => {
        return getThirdParty(queryKey[1] as string)
    });
}