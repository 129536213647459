import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {ShowFileDto} from "../../models/uploaded-file.model";

export class FileToUploadDto {
    public base64Content: string;
    public filename: string;
    public tag: string;
    public description: string;
    public contentType?: string;
}

export async function interventionAttachFiles(id: string, dto: FileToUploadDto[]) {
    const url = Endpoint.interventionAttachFiles.replace(':id', id);

    const {data} = await interventionHttp.post<ShowFileDto[]>(url, dto);

    return data;
}
