import React from "react";
import {useThirdPartyDetail} from "./useThirdPartyDetail";
import {IonAvatar, IonItem, IonLabel} from "@ionic/react";

export default function ThirdPartyItem({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: thirdParty } = useThirdPartyDetail(id);

    if (isLoading) {
        return <></>;
    }

    const fullName = [thirdParty?.firstname, thirdParty?.name].filter(part => !!part).join(' ');
    const addressPart = thirdParty?.address || '';
    const addressPart2 = [thirdParty?.zip, thirdParty?.town].filter(part => !!part).join(', ');

    return (
        <>
            <IonItem lines={"none"} style={{ '--background': 'white' }}>
                <IonAvatar slot="start">
                    <img alt="Avatar" src="/assets/img/Portrait_Placeholder.png" />
                </IonAvatar>
                <IonLabel>
                    <h2>{fullName}</h2>
                    <p>{addressPart}</p>
                    <p>{addressPart2}</p>
                </IonLabel>
            </IonItem>
        </>
    )
}