import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {Proposal} from "../../models/proposal.model";

export type AddContactDto = {
    id: number, // Id of commercial proposal to update
    contactid: number, // Id of contact to add
    type: string, // Type of the contact (BILLING, SHIPPING, CUSTOMER)
}

export async function addContact(dto: AddContactDto) {
    const url = Endpoint.proposalAddContact
        .replace(':id', `${dto.id}`)
        .replace(':contactid', `${dto.contactid}`)
        .replace(':type', `${dto.type}`)
    ;

    const { data } = await http.post<Proposal>(url, dto);

    return data;
}