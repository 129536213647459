import {ProposalStatus} from "../../models/proposal.model";
import {IonChip} from "@ionic/react";
import React from "react";

export function ProposalStatusChip({status}: { status: number }) {
    if (!status) {
        if (status === ProposalStatus.DRAFT) {
            return <IonChip>Brouillon</IonChip>;
        }

        return <></>;
    }

    if (status === ProposalStatus.NOT_SIGNED) {
        return <IonChip color="danger">Refusé</IonChip>
    }

    if ([ProposalStatus.VALIDATED, ProposalStatus.SIGNED].indexOf(status) !== -1) {
        return (
            <IonChip color="success">
                {{
                    [ProposalStatus.VALIDATED]: 'Validé',
                    [ProposalStatus.SIGNED]: 'Accepté et signé'
                }[status]}
            </IonChip>
        )
    }

    if (status === ProposalStatus.BILLED) {
        return <IonChip color="tertiary">Facturé</IonChip>
    }

    return <></>;
}