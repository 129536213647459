import {IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {
    Autocomplete, Box,
    Paper,
    Stack, styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchTableauMarges} from "../../calls/Reports/fetchTableauMarges";
import LoadingDots from "../LoadingDots";
import {FormattedNumber} from "react-intl";
import {DateTime} from "luxon";
import {downloadOutline, downloadSharp} from "ionicons/icons";
import {omit} from "lodash";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";

export default function TableauMarges() {
    const [foire, setFoire] = useState<string | undefined>("");
    const { isLoading, data: repsonse } = useQuery(['tableauMarges', foire], () => fetchTableauMarges({ foire }));

    const inputControl = (repsonse?.inputControls || [])[0];
    const rows = repsonse?.data.rows || [];

    const handleExport = () => {
        const headers = cols.map(col => col.label);
        const xlsxRows = rows.map(row => omit(row, ['createdAt', 'origineAffaireId', 'foireId', 'responsableIds', 'vendeurIds', 'rabatteurIds', 'status', 'prixPose', 'prixAchat']));

        const workbook = XLSX.utils.book_new();

        workbook.Props = {
            Title: "Tableau des marges",
            Subject: "Tableau des marges",
            Author: "AMDP",
            CreatedDate: new Date(),
        }

        const worksheet = XLSX.utils.json_to_sheet(xlsxRows);

        XLSX.utils.book_append_sheet(workbook, worksheet, "Tableau des marges");
        XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

        /* generate a binary string */
        const bin = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });
        saveAs(new Blob([bin]), `Tableau des marges_${Date.now()}.xlsx`);
    }

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Tableau de marges</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonCol>
            </IonRow>

            <TableContainer component={Paper} sx={{mt: 1, minHeight: 400, maxHeight: 800 }} elevation={2}>
                {
                    isLoading && (
                        <Box sx={{ p: 5 }}>
                            <LoadingDots />
                        </Box>
                    )
                }

                {
                    !isLoading && (
                        <StyledTable sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" colSpan={cols.length}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Autocomplete
                                                disablePortal
                                                size={"small"}
                                                id="combo-box-foire"
                                                options={inputControl?.options || []}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} disabled={isLoading} label="Sélectionner une foire" />}
                                                noOptionsText={"Foire non trouvé"}
                                                onChange={(e, newValue, reason) => setFoire(newValue?.id)}
                                            />

                                            <IonButton disabled={isLoading} fill={"outline"} style={{ marginLeft: 20 }} onClick={() => handleExport()}>
                                                <IonIcon slot="start" md={downloadSharp} ios={downloadOutline}></IonIcon>
                                                Export Excel
                                            </IonButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    {
                                        cols.map(
                                            col => (
                                                <TableCell key={col.id}><b>{col.label}</b></TableCell>
                                            )
                                        )
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    rows.map((row) => (
                                        <TableRow key={row.reference}>
                                            {
                                                cols.map(
                                                    col => {
                                                        const value = (row as any)[col.id];

                                                        switch (col.id) {
                                                            case "proposalDate":
                                                                return <DateCell key={col.id} value={value} />;

                                                            case "validityEnd":
                                                                return <DateCellIso key={col.id} value={value} />;

                                                            case "totalHT":
                                                            case "vatAmount":
                                                            case "totalTTC":
                                                                return <PriceCell key={col.id} value={value} />;

                                                            default:
                                                                return (
                                                                    <TableCell key={`${row.reference}-${col.id}`}>
                                                                        {value}
                                                                    </TableCell>
                                                                )
                                                        }
                                                    }
                                                )
                                            }
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </StyledTable>
                    )
                }

            </TableContainer>
        </IonGrid>
    )
}

function DateCell({ value }: { value: string }) {
    const fromFormat= 'yyyy-MM-dd';
    const toFormat= 'dd/MM/yyyy';
    const date = value ? DateTime.fromFormat(value, fromFormat).toFormat(toFormat) : '-- -- ----';
    return <TableCell>{date}</TableCell>
}

function  DateCellIso({value }: { value: string }) {
    if (!value) {
        return <TableCell></TableCell>;
    }

    const date = value;
    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
    const toFormat= 'dd/MM/yyyy';
    const d = DateTime.fromFormat(date, fromFormat);
    return <TableCell>{d.toFormat(toFormat)}</TableCell>
}

function PriceCell({ value }: { value: number | string }) {
    return (
        <TableCell>
            <FormattedNumber
                value={Number(value || 0)}
                style="currency"
                currency={"EUR"}
            />
        </TableCell>
    )
}

const cols = [
    { id: 'reference', label: "Référence" },
    { id: 'proposalDate', label: "Date proposition" },
    { id: 'validityEnd', label: "Date d'échéance" },
    { id: 'origineAffaire', label: "Origine" },
    { id: 'foire', label: "Foire" },
    // { id: 'status', label: "Statut" },
    { id: 'customerName', label: "Nom" },
    { id: 'customerAddress', label: "Adresse" },
    { id: 'customerTown', label: "Ville" },
    { id: 'customerZip', label: "Code Postal" },
    { id: 'totalHT', label: "Total HT" },
    { id: 'vatAmount', label: "Montant TVA" },
    { id: 'totalTTC', label: "Total TTC" },
    { id: 'margeTheorique', label: "Marge théorique" },
    { id: 'responsables', label: "Responsables" },
    { id: 'vendeurs', label: "Vendeurs" },
    { id: 'rabatteurs', label: "Rabatteurs" },
];

export const StyledTable = styled(Table)`
    & th, tr {
        white-space: nowrap;
    }
`;

