import AddSalesForceButton from "./AddSalesForceButton";

export default function AddVendeurButton() {
    return (
        <AddSalesForceButton
            name={'salesForce.vendeurs'}
            label={'Vendeurs'}
            placeholder={'Attacher Vendeurs'}
            multipleSelectionOnSearch={true}
        />
    )
}