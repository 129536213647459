import {FormProvider, useForm} from "react-hook-form";
import {InterventionFormModel} from "./InterventionForm";
import React, {useRef} from "react";
import {IonButton, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {DateTime} from "luxon";
import {InterventionType} from "../../models/intervention.model";
import {OverlayEventDetail} from "@ionic/core";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import InterventionScheduleForm from "./InterventionScheduleForm";
import {useUpdateIntervention} from "./useUpdateIntervention";
import {EditVisitetechniqueDto} from "../../calls/Interventions/VT/editVisiteTechnique";

interface InterventionFormModalProps {
    id: string;
    trigger: string;
    onWillDismiss?(event: IonModalCustomEvent<OverlayEventDetail>): void;
    defaultInterventionType?: InterventionType
}

export default function InterventionScheduleFormModal({ id, trigger, defaultInterventionType = InterventionType.VISITE_TECHNIQUE, onWillDismiss }: InterventionFormModalProps) {
    const methods = useForm<InterventionFormModel>({
        defaultValues: {
            type: defaultInterventionType,
            date: DateTime.now().toISO(),
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().toISO(),
            duration: 1,
            desiredInterventionDate: DateTime.now().toISO(),
        }
    });

    // const type = methods.watch('type');

    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading, isSuccess, isError, data } = useUpdateIntervention(InterventionType.VISITE_TECHNIQUE);

    const dismiss = () => {
        methods.reset();
        modal.current?.dismiss();
    }

    const onSubmit = async ({ date, technician }: InterventionFormModel) => {
        const dto: EditVisitetechniqueDto = {
            technician,
            date,
        }

        await mutateAsync({ id, dto });

        methods.reset();

        modal.current?.dismiss(id, 'confirm');
    }

    return (
        <IonModal ref={modal} backdropDismiss={false} keepContentsMounted={false} trigger={trigger} style={{ '--width': '30%', '--min-height': 400, '--backdrop-opacity': .4 }} onWillDismiss={onWillDismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Programmer intervention</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <InterventionScheduleForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
