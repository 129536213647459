import React from "react";
import {IonButton, IonIcon, IonModal} from "@ionic/react";
import {closeOutline, closeSharp} from "ionicons/icons";
import ThirdPartyShow from "../ThirdPartyDetails/ThirdPartyShow";
import {useThirdPartyShowModalStore} from "./store";

export default function ThirdPartyShowModal() {
    const { selectThirdPartyId: id, isOpen, hide: handleClose } = useThirdPartyShowModalStore();

    return (
        <IonModal isOpen={isOpen} onWillDismiss={handleClose} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
            <div className="ion-padding" style={{ paddingBottom: 0 }}>
                <IonButton fill="clear" size={"small"} onClick={handleClose}>
                    <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                    Fermer
                </IonButton>
            </div>

            <ThirdPartyShow id={id} />
        </IonModal>
    )
}