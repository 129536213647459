import Typography from "@mui/material/Typography";
import {FormLabel} from "@mui/material";

export default function OptionalFormLabel({ children }: { children: React.ReactNode }) {
    return (
        <FormLabel>
            {children}
            &nbsp;
            <Typography variant={"caption"}>
                - facultatif
            </Typography>
        </FormLabel>
    )
}