import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Contact} from "../../models/contact.model";

export async function getContact(id: string) {
    const url = Endpoint.contactId.replace(':id', id.toString());

    const {data} = await http.get<Contact>(url);

    return data;
}