import {IonButton, IonIcon, IonModal} from "@ionic/react";
import {closeOutline, closeSharp} from "ionicons/icons";
import AffaireShow from "./AffaireShow";

interface AffaireShowModalProps {
    id: string
    isOpen: boolean;
    handleClose(): void;
}

export default function AffaireShowModal({ id, isOpen, handleClose }: AffaireShowModalProps) {
    return (
        <IonModal id={'AffaireTableModal'} isOpen={isOpen} onWillDismiss={handleClose} style={{ '--backdrop-opacity': .4 }} keepContentsMounted={false}>
            <div className="ion-padding" style={{ paddingBottom: 0 }}>
                <IonButton fill="clear" size={"small"} onClick={handleClose}>
                    <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                    Fermer
                </IonButton>
            </div>

            <AffaireShow id={id} />
        </IonModal>
    )
}
