import {http} from "../../http";
import {CardStatus} from "../../models/kanban/card.model";
import {Endpoint} from "../endpoint.enum";
import {Proposal} from "../../models/proposal.model";

export async function setProposalCardStatus(cardId: number, status: CardStatus) {
    const url = Endpoint.setProposalCardStatus
        .replace(':id', cardId.toString())
        .replace(':status', status)
    ;

    const { data } = await http.post<Proposal>(url, {});

    return data;
}