import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type Payload = {
    filters: {
        foire?: string;
    },
    configuration: any;
}

type Pagination = {
    sortfield: string,
    sortorder: string,
    limit: number
}

export async function simulateTableauCommission(payload: Payload, pagination: Pagination = {sortfield: 't.rowid', sortorder: 'ASC', limit: 250}) {
    const { data } = await http.post<TableauCommissionSimulationResponse>(Endpoint.reportsTableauCommissionSimulate, {
        ...payload,
        filters: {
            ...payload.filters,
            ...pagination,
        }
    });

    return data;
}

export interface TableauCommissionSimulationResponse {
    rows: Row[];
    pagination: {
        total: number;
        page: number;
        pageCount: number;
        limit: number;
    };
}

interface Row {
    reference: string;
    proposalDate: string;
    validityEnd: string;
    createdAt: string;
    origineAffaireId: string;
    origineAffaire: string;
    foireId: string;
    foire: string;
    status: string;
    customerName: string;
    customerAddress: string;
    customerTown: string;
    customerZip: string;
    totalHT: string;
    vatAmount: string;
    totalTTC: string;
    responsableIds: string;
    vendeurIds: string;
    rabatteurIds: string;
    prixPose: string;
    prixAchat: string;
    titreDeLaPiece: string;
    margeTheorique: string;
    responsables: string;
    vendeurs: string;
    rabatteurs: string;
    financement: string;
    agios: string;
    pvHtReel: string;
    commission: Commission;
}

interface Commission {
    totalCommission: number;
    totalCommissionText: string;
    details: Details;
}

interface Details {
    responsables: Responsables[];
    vendeurs: Vendeurs[];
    rabatteurs: Rabatteurs[];
    absents: Absents[];
    techniciens: Techniciens[];
}

interface Responsables {
    id: string;
    taux: number;
    tauxText: string;
    montantCommission: number;
    montantCommissionText: string;
    role: "responsable";
}

interface Vendeurs {
    id: string;
    taux: number;
    tauxText: string;
    tauxDebloque: number;
    tauxDebloqueText: string;
    montantCommission: number;
    montantCommissionText: string;
    role: "vendeur";
}

interface Rabatteurs {
    id: string;
    taux: number;
    tauxText: string;
    tauxDebloque: number;
    tauxDebloqueText: string;
    montantCommission: number;
    montantCommissionText: string;
    role: "rabatteur";
}

interface Absents {
    id: string;
    taux: number;
    tauxText: string;
    montantCommission: number;
    montantCommissionText: string;
    role: "absent";
}

interface Techniciens {
    id: any;
    taux: number;
    tauxText: string;
    montantCommission: number;
    montantCommissionText: string;
    role: "vt";
}