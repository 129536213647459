import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import React from "react";

interface CivilityControlProps {
    name?: string;
    label?: string;
}

export default function CivilityControl({ name = 'civility', label = 'Civilité' }: CivilityControlProps) {
    return (
        <Controller
            name={name}
            defaultValue="MME"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormControl fullWidth error={!!error}>
                        <FormLabel>{label}</FormLabel>
                        <Select
                            labelId="civility-simple-select-label"
                            id="civility-simple-select"
                            value={value}
                            onChange={onChange}
                            size={"small"}
                        >
                            <MenuItem value={"MME"}>Madame</MenuItem>
                            <MenuItem value={"M"}>Monsieur</MenuItem>
                            <MenuItem value={"MLLE"}>Mademoiselle</MenuItem>
                        </Select>
                    </FormControl>
                )
            }}
        />
    )
}