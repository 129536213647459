import React from "react";
import {IonItem, IonLabel, IonToolbar} from "@ionic/react";
import InterventionForm, {InterventionFormModel} from "./InterventionForm";
import Paper from "@mui/material/Paper";
import {FormProvider, useForm} from "react-hook-form";
import {DateTime} from "luxon";
import {InterventionType} from "../../models/intervention.model";

const CreateInterventionPage = () => {

    const methods = useForm<InterventionFormModel>({
        defaultValues: {
            type: InterventionType.VISITE_TECHNIQUE,
            date: DateTime.now().toISO(),
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().toISO(),
            desiredInterventionDate: DateTime.now().toISO(),
            duration: 1,
        }
    });

    return(
        <FormProvider{...methods}>
            <Paper elevation={6}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Nouvelle Intervention</h1>
                            <h3>Création d'une nouvelle intervention</h3>
                        </IonLabel>
                    </IonItem>
                </IonToolbar>

                <InterventionForm/>
            </Paper>
        </FormProvider>

    )
}

export default CreateInterventionPage;