import {
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import {Controller, useFormContext} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import React from "react";
import {ProposalStatus} from "../../models/proposal.model";

const SearchAffaire: React.FC = () => {
    const {control} = useFormContext();

    return (
       <>
           <IonList>
               <IonItem>
                   <IonLabel position="floating">Référence</IonLabel>
                   <Controller
                       name={"reference"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel>Origine affaire</IonLabel>
                   <Controller
                       name={"origine"}
                       render={
                           ({field}) =>{
                               return(
                                   <IonSelect cancelText={"Annuler"} placeholder="Origine" value={field.value} onIonChange={field.onChange}>
                                       <IonSelectOption value="1">Foire</IonSelectOption>
                                       <IonSelectOption value="2">Terrain</IonSelectOption>
                                       <IonSelectOption value="0">Autre</IonSelectOption>
                                   </IonSelect>
                               )
                           }
                       }
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Nom</IonLabel>
                   <Controller
                       name={"thirdPartyName"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Adresse</IonLabel>
                   <Controller
                       name={"address"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Code Postal</IonLabel>
                   <Controller
                       name={"zip"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Ville</IonLabel>
                   <Controller
                       name={"town"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel>Statut</IonLabel>
                   <Controller
                       name={"status"}
                       render={
                           ({field}) =>{
                               return(
                                   <IonSelect cancelText={"Annuler"} placeholder="Statut" value={field.value} onIonChange={field.onChange}>
                                       <IonSelectOption value={ProposalStatus.VALIDATED}>Validé</IonSelectOption>
                                       <IonSelectOption value={ProposalStatus.SIGNED}>Signé</IonSelectOption>
                                       <IonSelectOption value={ProposalStatus.NOT_SIGNED}>Non signé</IonSelectOption>
                                       <IonSelectOption value={ProposalStatus.BILLED}>Facturé</IonSelectOption>
                                       <IonSelectOption value={ProposalStatus.DRAFT}>Brouillon</IonSelectOption>
                                   </IonSelect>
                               )
                           }
                       }
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Total HT</IonLabel>
                   <Controller
                       name={"amountWithoutTax"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"number"}/>}
                   />
               </IonItem>

               <IonItem>
                   <IonLabel position="floating">Total TTC</IonLabel>
                   <Controller
                       name={"amountIncludingTax"}
                       render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"number"}/>}
                   />
               </IonItem>

               <IonItemGroup>
                   <IonItemDivider color="light">
                       <IonLabel>
                           <b>Date de proposition</b>
                       </IonLabel>
                   </IonItemDivider>
                   <IonItem>
                       <IonLabel>Du</IonLabel>
                       <Controller
                           name={"startDate"}
                           render={({ field: { value, onChange }, fieldState: { error } }) => {
                               return (
                                   <HybridDatePicker
                                       value={value || null}
                                       InputProps={{
                                           size: 'small',
                                           error: !!error,
                                           placeholder: 'Date de proposition du'
                                       }}
                                       onChange={
                                           (v: DateTime | null) => {
                                               onChange(v ? idateTime(v.startOf('day')) : null)
                                           }
                                       }
                                   />
                               )
                           }}
                       />
                   </IonItem>

                   <IonItem>
                       <IonLabel>Au</IonLabel>
                       <Controller
                           name={"endDate"}
                           render={({ field: { value, onChange }, fieldState: { error } }) => {
                               return (
                                   <HybridDatePicker
                                       value={value || null}
                                       InputProps={{
                                           size: 'small',
                                           error: !!error,
                                           placeholder: 'Date de proposition au'
                                       }}
                                       onChange={
                                           (v: DateTime | null) => {
                                               onChange(v ? idateTime(v.endOf('day')) : null)
                                           }
                                       }
                                   />
                               )
                           }}
                       />
                   </IonItem>
               </IonItemGroup>
               <IonItemGroup>
                   <IonItemDivider color="light">
                       <IonLabel>
                           <b>Date échéance</b>
                       </IonLabel>
                   </IonItemDivider>
                   <IonItem>
                       <IonLabel>Du</IonLabel>
                       <Controller
                           name={"expirationDateStart"}
                           render={({ field: { value, onChange }, fieldState: { error } }) => {
                               return (
                                   <HybridDatePicker
                                       value={value || null}
                                       InputProps={{
                                           size: 'small',
                                           error: !!error,
                                           placeholder: 'Date échéance du'
                                       }}
                                       onChange={
                                           (v: DateTime | null) => {
                                               onChange(v ? idateTime(v.startOf('day')) : null)
                                           }
                                       }
                                   />
                               )
                           }}
                       />
                   </IonItem>

                   <IonItem>
                       <IonLabel>Au</IonLabel>
                       <Controller
                           name={"expirationDateEnd"}
                           render={({ field: { value, onChange }, fieldState: { error } }) => {
                               return (
                                   <HybridDatePicker
                                       value={value || null}
                                       InputProps={{
                                           size: 'small',
                                           error: !!error,
                                           placeholder: 'Date échéance au'
                                       }}
                                       onChange={
                                           (v: DateTime | null) => {
                                               onChange(v ? idateTime(v.endOf('day')) : null)
                                           }
                                       }
                                   />
                               )
                           }}
                       />
                   </IonItem>
               </IonItemGroup>

           </IonList>

           <DevTool control={control} />
       </>
    );
};

export default SearchAffaire;