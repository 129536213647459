import {Controller} from "react-hook-form";
import GenericTextFieldControl from "../../../Common/Form/GenericTextFieldControl";
import React from "react";

export default function PrivateNotesControl() {
    const name = "privateNotes";
    const label = "Notes privées"

    return (
        <Controller
            name={name}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <GenericTextFieldControl
                        label={label}
                        TextFieldProps={{
                            placeholder: label,
                            value: value || "",
                            onChange,
                            multiline: true,
                            rows: 2
                        }}
                        error={error}
                    />
                )
            }}
        />
    )
}