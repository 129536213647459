import AddSalesForceButton from "./AddSalesForceButton";

export default function AddResponsableButton() {
    return (
        <AddSalesForceButton
            name={'salesForce.responsables'}
            label={'Responsables'}
            placeholder={'Attacher Responsables'}
            multipleSelectionOnSearch={true}
        />
    )
}