import React from 'react';
import {IonContent} from "@ionic/react";


const Page: React.FC<{ id?: string }> = ({  children, id }) => {
    return (
        <IonContent className={"ion-padding"}>
            {children}
        </IonContent>
    );
};

export default Page;
