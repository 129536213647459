import {IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {addOutline, addSharp} from "ionicons/icons";
import {useProductsQuery} from "./useProductsQuery";
import ProductTable from "./ProductTable";
import {ProductsListContext} from "./useProductsListContext";
import ProductCategoryPaper from "./ProductCategoryPaper";
import {useProductFilterForm} from "./useProductFilterForm";
import React, {useState} from "react";
import {Paper} from "@mui/material";
import PowerTablePagination from "../Common/Table/PowerTablePagination";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";

export default function ProductList() {
    // Filtre simpliste par catégorie
    const [category, setCategory] = useState<string | undefined>();

    // Les filtres avancés
    const { filters, applyFilters, resetFilters, methods } = useProductFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    // Configuration de la query
    const query = useProductsQuery({
        ...filters,
        category,
    }, { page, limit: rowsPerPage });

    const itemsCount = query?.data?.pagination?.total || 0;

    const contextValue = {
        query,
    };

    return (
        <ProductsListContext.Provider value={contextValue}>
            <IonToolbar>
                <IonItem lines={"none"}>
                    <IonLabel>
                        <h1>Produits</h1>
                        <h3>Liste de produits</h3>
                    </IonLabel>
                    <IonButton fill={"solid"} color={"primary"} size={"default"}>
                        <IonIcon slot="start" md={addSharp} ios={addOutline} />
                        Nouveau
                    </IonButton>
                </IonItem>
            </IonToolbar>

            <IonRow style={{ marginTop: 5 }}>
                <IonCol size={"2"}>
                    <ProductCategoryPaper
                        onCategorySelected={(categoryId: string) => {
                            resetPage();
                            setCategory(categoryId);
                        }}
                        onReset={() => {
                            resetPage();
                            setCategory(undefined)
                        }}
                    />
                </IonCol>

                <IonCol>
                    <Paper sx={{ width: '100%' }} elevation={2}>
                        <ProductTable />

                        <PowerTablePagination
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalRowsCount={itemsCount}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        />
                    </Paper>
                </IonCol>
            </IonRow>
        </ProductsListContext.Provider>
    );
};