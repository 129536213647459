import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {CreateInterventionDto, InterventionTechnicianDto} from "../types";
import {AllInterventionStatus} from "../../../models/intervention.model";

export interface CreateSavDto extends CreateInterventionDto {
    installationYear?: string;
    poseTechnician?: InterventionTechnicianDto;
}

export async function createSav(dto: CreateSavDto) {
    const {data} = await interventionHttp.post(Endpoint.createSav, dto);

    if (data.status === AllInterventionStatus.SCHEDULED) {
        // await sendSavScheduledMail(data.id, data.proposal.id!);
    } else {
        // await sendSavCreatedMail(data.id, data.proposal.id!);
    }

    return data;
}