import {Controller} from "react-hook-form";
import UserAutocomplete from "../ThirdParty/UserAutocomplete";
import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {DateTimePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {Box, FormHelperText, Stack, TextField} from "@mui/material";

export default function InterventionScheduleForm() {
    return (
        <Box sx={{ p: 2, maxWidth: 600 }}>
            <Stack direction={"column"} spacing={1}>
            <Controller
                name={"technician"}
                rules={{
                    required: "Technicien obligatoire",
                }}
                render={
                    ({field, fieldState: { error }}) => {
                        return(
                            <UserAutocomplete
                                onChange={(t) => field.onChange({ id: t.id, name: t.label })}
                                label={"Assigner à"}
                                placeholder={"Assigner à"}
                                error={error}
                            />
                        )
                    }
                }
            />

            <Controller
                name={"date"}
                rules={{
                    required: "Date obligatoire",
                }}
                render={({ field: { value, onChange } , fieldState:{error}}) => {
                    const errored = !!error;

                    return (
                        <FormControl error={errored}>
                            <FormLabel>Date</FormLabel>
                            <DateTimePicker
                                value={value}
                                onChange={
                                    (v: DateTime | null) => {
                                        onChange(v ? v?.toISO() : null);
                                    }
                                }
                                renderInput={(params) => <TextField {...params} size={"small"} error={errored} />}
                            />

                            {
                                error && error.type === 'required' && (
                                    <FormHelperText>
                                        <small>{error.message}</small>
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }}
            />
            </Stack>
        </Box>
    )
}