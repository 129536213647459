import React from 'react';
import {
    IonCheckbox,
    IonInput,
    IonItem,
    IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {Controller, useFormContext} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {DateTime} from "luxon";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";
import {DatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";

const SearchInvoice = () => {
    const {control} = useFormContext();

    return (
       <>
            <IonList>
                <IonItem>
                    <IonLabel position="floating">Nom client</IonLabel>
                    <Controller
                        name={"thirdPartyName"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel>
                        Statut facture
                    </IonLabel>

                    <InvoiceStatuses />
                </IonItem>

                <IonItemGroup>
                    <IonItemDivider color="light">
                        <IonLabel>
                            <b>Date facturation</b>
                        </IonLabel>
                    </IonItemDivider>

                    <IonItem>
                        <IonLabel>Du</IonLabel>
                        <Controller
                            name={"startDate"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <HybridDatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date début facturation'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? v.startOf('day').toISO() : null)
                                            }
                                        }
                                    />
                                )
                            }}
                        />
                    </IonItem>

                    <IonItem>
                        <IonLabel>Au</IonLabel>
                        <Controller
                            name={"endDate"}
                            render={({ field: { value, onChange }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        value={value || null}
                                        InputProps={{
                                            size: 'small',
                                            error: !!error,
                                            placeholder: 'Date fin facturation'
                                        }}
                                        onChange={
                                            (v: DateTime | null) => {
                                                onChange(v ? v.endOf('day').toISO() : null)
                                            }
                                        }
                                        renderInput={(params) => <TextField {...params} size={"small"} />}
                                    />
                                )
                            }}
                        />
                    </IonItem>
                </IonItemGroup>

                <IonItem>
                    <Controller
                        name={`isLate`}
                        render={({ field}) => (
                            <IonItem lines={"none"}>
                                <IonCheckbox
                                    slot="start"
                                    checked={field.value}
                                    onIonChange={
                                        (e) => field.onChange(e.target.checked)
                                    }
                                />
                                <IonLabel>Facture en retard</IonLabel>
                            </IonItem>
                        )}
                    />
                </IonItem>
            </IonList>

           <DevTool control={control} />
       </>
    );
};

export default SearchInvoice;

const invoiceStatuses = [
    { label: 'Payée', value: 'paid'  },
    { label: 'Impayée', value: 'unpaid' },
]

function InvoiceStatuses() {
    return (
        <Controller
            name={`status`}
            render={({ field}) => (
                <IonSelect cancelText={"Annuler"} placeholder="Statut facture" value={field.value}
                           onIonChange={field.onChange}>
                    <IonSelectOption value='paid'>Payée</IonSelectOption>
                    <IonSelectOption value='unpaid'>Impayée</IonSelectOption>
                </IonSelect>
            )}
        />
    )
}