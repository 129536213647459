import {useThirdPartyBottomDrawerState} from "../useThirdPartyBottomDrawerStore";
import {useInterventionsQuery} from "../../Intervention/useInterventionsQuery";
import {InterventionsListContext} from "../../Intervention/useInterventionsListContext";
import InterventionTable from "../../Intervention/InterventionTable";
import {InterventionType} from "../../../models/intervention.model";

export default function PoseTab() {
    const { currentThirdPartyId: thirdPartyId } = useThirdPartyBottomDrawerState();

    const query = useInterventionsQuery({
        customerId: String(thirdPartyId),
        type: InterventionType.POSE,
    });

    if (query.isLoading) {
        return <>Chargement ...</>
    }

    if (query.isError) {
        return (<>Pas de pose trouvées pour ce tiers</>)
    }

    return (
        <InterventionsListContext.Provider value={{ query }}>
            <InterventionTable />
        </InterventionsListContext.Provider>
    )
}