export class Civility {
    private constructor(
        public label: string,
        public code: string,
    ) {}

    static MADAME = new Civility('Madame', 'MME');

    static MONSIEUR = new Civility('Monsieur', 'MR');

    static MADEMOISELLE = new Civility('Mademoiselle', 'MLE');

    public static supportedCivilities(): Array<Civility> {
        return [
            this.MADAME,
            this.MONSIEUR,
            this.MADEMOISELLE,
        ];
    }
}