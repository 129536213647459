import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface UpdateContactDto {
    lastname?: string;
    firstname?: string;
    socid?: number;
    civility_code?: string;
    address?: string;
    zip?: string;
    town?: string;
    country_id?: number;
    note_private?: string | null;
    note_public?: string | null;
    email?: string;
    phone?: string;
    phone_pro?: string;
    phone_perso?: string;
    phone_mobile?: string;
    poste?: string;
    array_options?: {
        lat?: number,
        lng?: number;
    }
}

export async function updateContact(id: string, dto: UpdateContactDto) {
    const url = Endpoint.contactId.replace(':id', id.toString());

    const {data} = await http.put<number>(url, dto);

    return data;
}