import {ListRequirements} from "../../../models/kanban/list.model";
import {createContext, useContext} from "react";
import FillInStudyInformation from "./FillInStudyInformation";
import CardAttachmentsContent from "./Attachments/CardAttachmentsContent";
import Dialog from "@mui/material/Dialog";

interface ListRequirementsDialogProps {
    cardId: string;
    requirements: ListRequirements | null,
    open: boolean;
    onClose(reason: 'cancel' | 'ok'): void;
}

export default function MeetListRequirementsDialog(props: ListRequirementsDialogProps) {
    const handleClose = (event: Object, reason: string) => {
        if (reason && reason === "backdropClick") {
            return;
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            open={props.open}
            maxWidth="lg"
            fullWidth
        >
            <MeetListRequirementsDialogContext.Provider value={props}>
                {/*{*/}
                {/*    props.requirements?.comment && (*/}
                {/*        <CardCommentContent />*/}
                {/*    )*/}
                {/*}*/}

                {
                    (props.requirements?.attachments && props.requirements?.attachments.length > 0) && (
                        <CardAttachmentsContent />
                    )
                }

                {
                    props.requirements?.fillInStudyInformation && (
                        <FillInStudyInformation />
                    )
                }
            </MeetListRequirementsDialogContext.Provider>
        </Dialog>
    )
}

export const MeetListRequirementsDialogContext = createContext<ListRequirementsDialogProps>({
    cardId: '',
    requirements: null,
    open: false,
    onClose(reason: "cancel" | "ok") {}
});

export const useMeetListRequirementsDialogContext = () => useContext<ListRequirementsDialogProps>(MeetListRequirementsDialogContext);