import TableCell from "@mui/material/TableCell";
import {FormattedNumber} from "react-intl";
import {useInvoicesListContext} from "./useInvoicesListContext";
import React from "react";
import {DateTime} from "luxon";
import {ColDef} from "../Common/Table";
import PowerTable from "../Common/Table/PowerTable";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import {IonRouterLink} from "@ionic/react";
import {InvoiceSearchResult, InvoiceStatus} from "../../models/invoice.model";

const cols: Array<ColDef<keyof InvoiceSearchResult>> = [
    { id: 'ref', label: 'Référence' },
    { id: 'date', label: 'Date facturation' },
    { id: 'date_lim_reglement', label: 'Date échéance' },
    { id: 'thirdPartyName', label: 'Client' },
    { id: 'total_ht', label: 'Montant HT' },
    { id: 'total_ttc', label: 'Montant TTC\t' },
    { id: 'totalpaid', label: 'Montant payé' },
    { id: 'remaintopay', label: 'Montant dû' },
    { id: 'status', label: 'Statut' },
];

interface PaymentTableProps {
    sx?: SxProps<Theme>;
}

export default function InvoiceTable({ sx }: PaymentTableProps) {
    const { query } = useInvoicesListContext();

    const data = query?.data;

    const rows = data?.items || [];

    return (
        <PowerTable
            name={'invoice table'}
            cols={cols}
            rows={rows}
            renderCell={renderCell}
            loading={!!query?.isLoading}
            error={query?.error}
            sx={sx}
        />
    )
}

function renderCell(key: keyof InvoiceSearchResult, value: any, row: InvoiceSearchResult) {
    if (key === "date" || key === "date_lim_reglement") {
        return <DateCell value={row[key]} />
    }

    if (['total_ht', 'total_ttc', 'totalpaid', 'remaintopay',].indexOf(key) !== -1) {
        return <PriceCell value={row[key]}/>;
    }

    if (key === 'thirdPartyName') {
        return <ThirdPartyNameCell id={row.socid} value={row.thirdPartyName} />
    }

    if (key === 'status') {
        return <StatusCell value={row.status} />;
    }
}

function DateCell({ value }: { value: number }) {
    const date = value;
    const toFormat= 'dd/MM/yyyy';
    const d = DateTime.fromSeconds(date);
    return <TableCell>{d.toFormat(toFormat)}</TableCell>
}


function PriceCell({ value }: { value: string }) {
    return (
        <TableCell className={"price"}>
            <FormattedNumber
                value={Number(value || 0)}
                style="currency"
                currency={"EUR"}
            />
        </TableCell>
    )
}

function StatusCell({ value }: { value: InvoiceStatus }) {
    if (value === InvoiceStatus.DRAFT) {
        return <TableCell>Brouillon</TableCell>;
    }

    if (value === InvoiceStatus.CLOSED) {
        return <TableCell>Payée</TableCell>;
    }

    if (value === InvoiceStatus.VALIDATED) {
        return <TableCell>Validée</TableCell>;
    }

    if (value === InvoiceStatus.ABANDONED) {
        return <TableCell>Annulée</TableCell>;
    }

    return <TableCell>{value}</TableCell>;
}

function ThirdPartyNameCell({ id, value }: { id: string, value: string }) {
    return (
        <TableCell>
            <IonRouterLink routerLink={`/page/ThirdParties/${id}`}>
                {value}
            </IonRouterLink>
        </TableCell>
    );
}