import create from "zustand";

interface FilterDrawerStore {
    isOpen: boolean;
    openDrawer(): void;
    closeDrawer(): void;
}

const useFilterDrawerStore = create<FilterDrawerStore>((set) => ({
    isOpen: false,
    openDrawer: () => set(state => ({ isOpen: true })),
    closeDrawer: () => set(state => ({ isOpen: false }))
}));

export function useFilterDrawerIsOpen() {
    return useFilterDrawerStore(s => s.isOpen);
}

export function useOpenFilterDrawer() {
    return useFilterDrawerStore(s => s.openDrawer);
}

export function useCloseFilterDrawer() {
    return useFilterDrawerStore(s => s.closeDrawer);
}