import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {UploadedFile} from "../../models/document.model";

type ElementIdentifier = { id: string | number } | { ref: string, } // soit id soit reference
type ElementType = 'thirdparty' | 'member' | 'proposal' | 'order' | 'invoice' | 'supplier_invoice' | 'shipment' | 'project';
type ElementField = 'fullname' | 'relativename' | 'name' | 'date' | 'size';
type ElementSort = {
    sortfield?: ElementField,
    sortorder?: 'ASC' | 'DESC',
}

/**
 * Return the list of documents of a dedicated element (from its ID or Ref)
 * Renvoie la liste des documents d'un élément dédié (cet élément peut être un devis, une facture etc.)
 *
 * @param idenfifier
 * @param elementType Type de l'élément c'est une facture un devis ?
 * @param sort
 */
export async function fetchElementDocuments(idenfifier: ElementIdentifier, elementType: ElementType, sort: ElementSort = {}) {
    const { data } = await http.get<UploadedFile[]>(Endpoint.documents, {
        params: {
            modulepart: elementType,
            ...idenfifier,
            ...sort,
        }
    });

    return data;
}