import {IonIcon, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {warningOutline, warningSharp} from "ionicons/icons";
import React from "react";
import {useFormContext} from "react-hook-form";
import {flattenDeep} from "lodash";
import {ContractFormModel} from "../models";

type ErrorMessage = {
    group: keyof ContractFormModel,
    message: string,
    type: string,
    ref: { name: string }
}

export default function ErrorList () {
    const {formState: { errors }} = useFormContext();

    // opération couteuse pour avoir tous les messages
    const messages: Array<ErrorMessage> = flattenDeep(
        Object.entries(errors)
            .map(
                ([group, fieldErrors]: [string, any]) => Object.values(fieldErrors)
                    .map((err: any) => ({ ...err, group }))
            )
    );

    if (messages.length === 0) {
        return <></>;
    }

    return (
        <IonList>
            <IonListHeader>
                <IonLabel color={"danger"}>
                    <h2><b>Erreurs ({messages.length})</b></h2>
                    <h3><b>Veuillez corriger les erreurs avant d'enregistrer</b></h3>
                </IonLabel>
            </IonListHeader>

            {
                messages.map(
                    (m, index) => (
                        <IonItem key={`${m.type}-${index}`} lines={"none"}>
                            <IonIcon color="danger" md={warningSharp} ios={warningOutline} slot={'start'}/>
                            <IonLabel color={"danger"}>{m.message}</IonLabel>
                        </IonItem>
                    )
                )
            }
        </IonList>
    )
}