export class TypeBranchement {
    private constructor(
            public label: string,
            public value: string | undefined,
    ) {}

    static MONOPHASE = new TypeBranchement('Monophasé', 'MONOPHASE');

    static TRIPHASE = new TypeBranchement('Triphasé', 'TRIPHASE');

    public static supportedBranchements(): Array<TypeBranchement> {
        return [
            this.MONOPHASE,
            this.TRIPHASE
        ];
    }
}