import TableCell from "@mui/material/TableCell";
import {FormattedNumber} from "react-intl";
import {usePaymentsListContext} from "./usePaymentsListContext";
import React from "react";
import {DateTime} from "luxon";
import {ColDef} from "../Common/Table";
import {PaymentSearchResult} from "../../models/payment.model";
import PowerTable from "../Common/Table/PowerTable";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import {IonRouterLink} from "@ionic/react";

const cols: Array<ColDef<keyof PaymentSearchResult>> = [
    { id: 'date', label: 'Date' },
    { id: 'invoiceRef', label: 'Pièce' },
    { id: 'paymentNo', label: 'Libellé' },
    { id: 'amount', label: 'Règlement TTC' },
    { id: 'paymentMethodCode', label: 'Mode de règlement' },
    { id: 'invoiceDueDate', label: 'Date d\'échéance' },
    { id: 'thirdPartyName', label: 'Client' },
    { id: 'note', label: 'Observation' },
];

interface PaymentTableProps {
    sx?: SxProps<Theme>;
}

export default function PaymentTable({ sx }: PaymentTableProps) {
    const { query } = usePaymentsListContext();

    const data = query?.data;

    const rows = data?.items || [];

    return (
        <PowerTable
            name={'payment table'}
            cols={cols}
            rows={rows}
            renderCell={renderCell}
            loading={!!query?.isLoading}
            error={query?.error}
            sx={sx}
        />
    )
}

function renderCell(key: keyof PaymentSearchResult, value: any, row: PaymentSearchResult) {
    if (key === "date") {
        return <DateCell value={row.date} />
    }

    if (key === 'amount') {
        return <PriceCell value={row.amount}/>;
    }

    if (key === 'paymentMethodCode') {
        return <PaymentMethodCell value={row.paymentMethodCode} />;
    }

    if (key === 'thirdPartyName') {
        return <ThirdPartyNameCell id={row.thirdPartyId} value={row.thirdPartyName} />
    }
}

function DateCell({ value }: { value: string }) {
    const date = value;
    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
    const toFormat= 'dd/MM/yyyy HH:mm:ss';
    const d = DateTime.fromFormat(date, fromFormat);
    return <TableCell>{d.toFormat(toFormat)}</TableCell>
}


function PriceCell({ value }: { value: string }) {
    return (
        <TableCell className={"price"}>
            <FormattedNumber
                value={Number(value || 0)}
                style="currency"
                currency={"EUR"}
            />
        </TableCell>
    )
}

function PaymentMethodCell({ value }: { value: string }) {
    if (value === 'CB') {
        return <TableCell>Carte bancaire</TableCell>;
    }

    if (value === 'CHQ') {
        return <TableCell>Chèque</TableCell>;
    }

    if (value === 'LIQ') {
        return <TableCell>Espèce</TableCell>;
    }

    if (value === 'PRE') {
        return <TableCell>Prélèvement</TableCell>;
    }

    if (value === 'VIR') {
        return <TableCell>Virement</TableCell>;
    }

    return <TableCell>N/A</TableCell>;
}

function ThirdPartyNameCell({ id, value }: { id: string, value: string }) {
    return (
        <TableCell>
            <IonRouterLink routerLink={`/page/ThirdParties/${id}`}>
                {value}
            </IonRouterLink>
        </TableCell>
    );
}