import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {useProposalFormContext} from "../ProposalFormContext";

export default function EndOfValidityDateControl() {
    const name = 'endOfValidityDate';
    const label = 'Date de fin de validité';

    const { submitOnBlur } = useProposalFormContext();

    return (
        <Controller
            name={name}
            defaultValue={null}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    const errored = !!error;

                    return (
                        <FormControl variant={"outlined"} fullWidth error={errored}>
                            <FormLabel>{label}</FormLabel>
                            <DatePicker
                                value={value}
                                onAccept={submitOnBlur}
                                onChange={(e: DateTime | null) => {
                                    onChange(e ? e?.toISO() : null);
                                }}
                                renderInput={
                                    (params) => (
                                        <TextField
                                            {...params}
                                            size={"small"}
                                            onBlur={submitOnBlur}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "Date de fin de validité"
                                            }}
                                        />
                                    )
                                }
                            />

                            {
                                error && (
                                    <FormHelperText>
                                        {error.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }
            }
        />
    )
}