export enum Endpoint {
    products = '/amdpeasysaleskms/products',
    product = '/products/:id',
    createThirdParty = '/amdpeasysaleskms/thirdparty',
    updateThirdParty = '/thirdparties',
    proposalsSearch = '/amdpsales/proposals/search',
    proposals = '/proposals',
    proposal = '/proposals/:id',
    proposalLines = '/proposals/:id/lines',
    proposalValidate = '/proposals/:id/validate',
    proposalSetToDraft = '/proposals/:id/settodraft',
    proposalLine = '/proposals/:id/lines/:lineId',
    proposalAcceptOrReject = '/proposals/:id/close',
    proposalInvoiced = '/proposals/:id/setinvoiced',
    proposalAvailableDocuments = '/amdpsales/proposal/available-documents',
    proposalAddContact = '/proposals/:id/contact/:contactid/:type',
    thirdParties = '/amdpsales/thirdparties/search',
    thirdParty = '/thirdparties/:id',
    documents = '/documents',
    documentBuildDoc = '/documents/builddoc',
    documentDownload = '/documents/download',
    documentUpload = '/documents/upload',
    interventions = '/interventions',
    interventionsSearch = '/interventions/search',
    categories = '/categories',
    payments = '/amdpsales/payments/search',
    filesSearch = '/amdpsales/files/search',
    agendaEvents = '/amdpsales/agenda-events/search',
    agendaEventsId = '/agendaevents/:id',
    foires = '/amdpeasysaleskms/foire',
    users = '/amdpsales/users/search',
    userId = '/users/:id',
    userLogin = '/login',
    me = '/users/login/:login',
    company = '/multicompany',
    viewCompanyLogo = '/viewimage.php?modulepart=mycompany&file=logos/thumbs/:filename&entity=:entity',
    interventionsHeader = "/interventions",
    interventionsLines = "/amdpsales/interventions/:id/lines",
    interventionsValidate = "/interventions/:id/validate",
    createVisiteTechnique = '/interventions-of-visite-technique/create',
    intervention = "/interventions/:id",
    createPose = '/interventions-of-pose/create',
    createSav = '/interventions-of-sav/create',
    createEntretien = '/interventions-of-entretien/create',
    createRdvCom = '/interventions-of-rdv-com/create',
    cloneRdvCom = '/interventions-of-rdv-com/:id/clone',
    interventionGenerateReport = '/interventions/:id/generate-reports',
    addPayment = '/invoices/paymentsdistributed',
    order = '/orders/:id',
    orderValidate = '/orders/:id/validate',
    orderClosed = '/orders/:id/close',
    invoices = '/invoices',
    invoice = '/invoices/:id',
    invoicePayments = '/invoices/:id/payments',
    invoiceValidate = '/invoices/:id/validate',
    invoicesSearch = '/amdpsales/invoices/search',
    statsLastModifiedInterventions = '/amdpsales/lastModifiedInterventions',
    statsLastModifiedProposals = '/amdpsales/lastModifiedProposals',
    statsProposals = '/amdpsales/proposalStats',
    statsGetNbInvoiceByMonthWithPrevYear = '/amdpsales/stats/getNbInvoiceByMonthWithPrevYear',
    statsGetInvoiceAmountByMonth = '/amdpsales/stats/getInvoiceAmountByMonth',
    statsGetInvoiceAmountByMonthTTC = '/amdpsales/stats/getInvoiceAmountByMonthTTC',
    interventionFiles = '/interventions/:id/files',
    interventionFileById = '/interventions/:id/file/:fileId',
    interventionAttachFiles = '/interventions/:id/attach-files',
    interventionId = '/interventions/:id',
    contacts = '/contacts',
    contactId = '/contacts/:id',
    listCustomDocuments = '/amdpsales/list-documents',
    generateProposalCustomDocument = '/amdpsales/generate-document/:proposalId/:documentId',
    reportsTableauMarge = '/amdpsales/report/tableau-marge',
    reportsTableauCommission = '/amdpsales/report/tableau-commissionnements',
    reportsExportCompta = '/amdpsales/report/export/compta',
    reportsTableauCommissionPayees = '/amdpsales/report/tableau-commissionnements/payees',
    reportsTableauCommissionSimulate = '/amdpsales/report/tableau-commissionnements/simulate',
    editVisiteTechnique = '/interventions-of-visite-technique/:id/edit',

    proposalTag = '/amdpsales/tag/proposal/:proposalId/:tag',
    proposalEventSearch = '/amdpsales/proposal/:proposalId/events/search',
    listBoardsByConnectedUser = '/amdpeasykanban/boards/search',
    searchProposalKanbanLists = '/amdpeasykanban/lists/search',
    searchKanbanCards = '/amdpeasykanban/cards/search',
    searchPhotovoltaiqueKanbanCards = '/amdpeasykanban/cards/pv/search',
    searchComptaKanbanCards = '/amdpeasykanban/cards/compta/search',
    setProposalCardStatus = '/amdpeasykanban/cards/:id/status/:status',
    addCardComment = '/amdpeasykanban/cards/:id/comments',
    searchCardComments = '/amdpeasykanban/cards/comments/search',
    setInterventionCardStatus = '/interventions/:id/status/:status',

    dictionaryPaymentTerms = '/setup/dictionary/payment_terms'
}
