import {useQuery} from "@tanstack/react-query";
import {searchAffaire} from "../../calls/Affaire/searchAffaire";
import {Pagination} from "../../calls/types";

export function useAffaireQuery(payload: any = {}, pagination: Pagination = { sortorder: 'DESC', limit: 500 }, enabled: boolean = false) {
    const queryKey = ['affaire', { payload, pagination }];

    return useQuery(queryKey, () => searchAffaire(payload, pagination), {
        enabled,
    });
}