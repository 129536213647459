import {useQuery} from "@tanstack/react-query";
import {searchAddress} from "../../calls/Interventions/addressSearch";

interface UseAddressSearchProps {
    q: string;
    type?: 'street' | 'housenumber'
}

export function useAddressSearch(props: UseAddressSearchProps) {
    const { q } = props;

    const queryFn = () => searchAddress(props);

    return useQuery(["address", "search", props], queryFn, {
        enabled: !!(q && q.length > 2),
        staleTime: 1000 * 60 * 5,
    });
}