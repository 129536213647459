import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Contact} from "../../models/contact.model";

interface Payload {
    thirdparty_ids?: string; // Thirdparty ids to filter contacts of (example '1' or '1,2,3')
    sortfield?: string; // t.rowid Sort field
    sortorder?: string; // ASC Sort order
    limit?: number; // 100 Limit for list
}

export async function listContacts(payload: Payload) {
    const {
        sortfield = 't.rowid',
        sortorder = 'DESC',
        limit = 50,
        ...restOfPayload
    } = payload;

    const {data} = await http.get<Contact[]>(Endpoint.contacts, {
        params: {
            ...restOfPayload,
            sortfield,
            sortorder,
            limit,
        }
    });

    return data;
}