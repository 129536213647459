import {Product} from "../../../models/product.model";
import {computeProductPricingRange, useProductPricingRange} from "./useProductPricingRange";
import {useIonAlert} from "@ionic/react";
import {useEffect} from "react";

type UseProductPricingAlertProps = {
    product: Product,
    tvaTx: string;
    totalTTC: string | number;
}

export function useProductPricingAlert({ product, tvaTx, totalTTC }: UseProductPricingAlertProps) {
    const { array_options: { options_prix_pro, options_prix_hors_cible }, price } = product;

    const appliedPricing = useProductPricingRange({
        prixPublic: price,
        prixPro: options_prix_pro,
        prixHorsCible: options_prix_hors_cible,
        tvaTx,
        totalTTC,
    });

    const [presentAlert] = useIonAlert();

    useEffect(() => {
        if (!appliedPricing.alertMessage) {
            return;
        }

        presentAlert({
            header: 'Attention',
            subHeader: 'Changement tarification',
            message: appliedPricing.alertMessage,
            buttons: ['OK'],
        })
    }, [appliedPricing.alertMessage]);
}

export function useProductPricingAlertFunc() {
    const [presentAlert] = useIonAlert();

    return ({ product, tvaTx, totalTTC, qty = 1 }: UseProductPricingAlertProps & { qty?: number }) => {
        const { array_options: { options_prix_pro, options_prix_hors_cible }, price } = product;

        const appliedPricing = computeProductPricingRange({
            prixPublic: price,
            prixPro: options_prix_pro,
            prixHorsCible: options_prix_hors_cible,
            tvaTx,
            totalTTC,
            qty,
        });

        if (!appliedPricing.alertMessage) {
            return;
        }

        presentAlert({
            header: 'Attention',
            subHeader: 'Changement tarification',
            message: appliedPricing.alertMessage,
            buttons: ['OK'],
        })
    }
}

type UseProductPricingAlertFuncProps = {
    products: Array<{
        product: Product,
        tvaTx: string;
        totalTTC: string | number;
        qty: number;
    }>
}

export function useProductsPricingAlertFunc() {
    const [presentAlert] = useIonAlert();

    return ({ products }: UseProductPricingAlertFuncProps) => {
        const pricings = products.map(
            ({product, tvaTx, totalTTC, qty = 1}) => {
                const { label: productLabel, array_options: { options_prix_pro, options_prix_hors_cible }, price } = product;

                return {
                    productLabel,
                    appliedPricing: computeProductPricingRange({
                        prixPublic: price,
                        prixPro: options_prix_pro,
                        prixHorsCible: options_prix_hors_cible,
                        tvaTx,
                        totalTTC,
                        qty,
                    })
                };
            }
        ).filter(({appliedPricing}) => appliedPricing.alertMessage);

        if (pricings.length === 0) {
            return;
        }

        const message = pricings.map(p => `${p.productLabel}: ${p.appliedPricing.alertMessage}`).join('<br />')

        presentAlert({
            header: 'Attention',
            subHeader: 'Changement tarification',
            message: message,
            buttons: ['OK'],
        })
    }
}