import {styled} from "@mui/material";
import {IonCard} from "@ionic/react";

export const FullWhiteCard = styled(IonCard)`
    --background: white;

    & ion-item, & ion-list {
        background: white;
        --background: white;
    }
`;