import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type ProposalHeaderDto = {
    socid: number;
    fk_project: number;
    model_pdf?: string;
    date?: number;
    duree_validite?: number;
    cond_reglement_id?: number;
    note_private?: string;
    note_public?: string;
    array_options?: {
        origine_affaire?: number,
        foire_origine?: string,
        rabatteur_ids?: string,
        responsable_ids?: string,
        vendeur_ids?: string,
        cout_revient_mo?: number;
        coeff_marge_mo?: number;
        prix_vente_mo?: number;
        coeff_marge_fo?: number;
        puissance_pv?: number;
        reduction_co2?: number;
    },
    entity: number;
}

async function createProposalHeader(dto: ProposalHeaderDto) {
    const { data } = await http.post(Endpoint.proposals, dto);

    return data;
}


export type ProposalLineDto = {
    label?: string;
    desc?: string;
    subprice?: number;
    qty?: number;
    tva_tx?: number;
    fk_product?: string;
    product_type?: number;
    special_code?: number;
    rang?: number;
    fk_unit?: string | null;
    array_options?: {
        related_product?: string,
        tx_mo?: number;
        tps_pose?: number;
        cout_fournitures?: number;
        excluded_from_total_ht?: number;
    }
}

async function attachLineToProposal(proposalId: string, dto: ProposalLineDto) {
    const url = Endpoint.proposalLines.replace(':id', proposalId);

    const { data } = await http.post(url, dto);

    return data;
}

export async function createProposal(headerDto: ProposalHeaderDto, lines: Array<ProposalLineDto>) {
    try {
        const headerId = await createProposalHeader(headerDto);

        for (const line of lines) {
            await attachLineToProposal(headerId, line);
        }

        return headerId;
    } catch (e) {
        console.error(e);
    }
}

export type ProposalDtoWithLines = ProposalHeaderDto & { lines: Array<ProposalLineDto> };

export async function createProposalWithLines(dto: ProposalDtoWithLines) {
    return await createProposalHeader(dto);
}