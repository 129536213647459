import {IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonRow} from "@ionic/react";
import React from "react";
import {FormattedNumber} from "react-intl";

interface InvoicesCountRowProps {
    totalCount: number;
    totalHT: number;
    totalTTC: number;
    totalPaid: number;
    totalRemainToPay: number;
}

export default function InvoicesCountRow(props: InvoicesCountRowProps) {
    const {
        totalCount,
        totalHT,
        totalTTC,
        totalPaid,
        totalRemainToPay,
    } = props;

    return (
        <IonRow>
            <IonCol>
                <IonCard style={{ '--background': 'white' }}>
                    <IonCardHeader>
                        <IonCardTitle>{totalCount}</IonCardTitle>
                        <IonCardSubtitle>Nombre de facture</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>

            <IonCol>
                <IonCard style={{ '--background': 'white' }}>
                    <IonCardHeader>
                        <AmountIonCardTitle value={totalHT} />
                        <IonCardSubtitle>Montant total HT</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>

            <IonCol>
                <IonCard style={{ '--background': 'white' }}>
                    <IonCardHeader>
                        <AmountIonCardTitle value={totalTTC} />
                        <IonCardSubtitle>Montant total TTC</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>

            <IonCol>
                <IonCard style={{ '--background': 'white' }}>
                    <IonCardHeader>
                        <AmountIonCardTitle value={totalPaid} />
                        <IonCardSubtitle>Montant total payé</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>

            <IonCol>
                <IonCard style={{ '--background': 'white' }}>
                    <IonCardHeader>
                        <AmountIonCardTitle value={totalRemainToPay} />
                        <IonCardSubtitle>Total restant dû</IonCardSubtitle>
                    </IonCardHeader>
                </IonCard>
            </IonCol>
        </IonRow>
    )
}

function AmountIonCardTitle({ value }: { value: number }) {
    return (
        <IonCardTitle>
            <FormattedNumber
                value={value || 0}
                style="currency"
                currency={"EUR"}
            />
        </IonCardTitle>
    )
}