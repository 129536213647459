import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {DateTime} from "luxon";
import {PaymentMethod} from "../../models/payment.model";
import {useMutation} from "@tanstack/react-query";
import {addPayment, AddPaymentDto} from "../../calls/Payments/addPayment";
import currency from "currency.js";
import {
    IonBadge,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCol,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import PaymentForm from "./PaymentForm";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import React, {useEffect} from "react";
import styled from "@emotion/styled";
import {useInvoicesQuery} from "../Invoices/useInvoicesQuery";
import {FormattedNumber} from "react-intl";

interface AddPaymentModel {
    invoiceId: string;
    amount: number;
    datepaye: string;
    paymentid: PaymentMethod;
    accountid?: number;
    num_payment?: string;
    comment?: string;
    chqemetteur?: string;
    chqbank?: string;
}

interface PaymentFormModalFromThirdPartyProps {
    thirdPartyId: string;
    isOpen: boolean;
    onClose?(): void
}

export default function PaymentFormModalFromThirdParty({ thirdPartyId, isOpen, onClose }: PaymentFormModalFromThirdPartyProps) {
    const methods = useForm<AddPaymentModel>({
        defaultValues: {
            datepaye: DateTime.now().toISO(),
            amount: 0,
        }
    });

    const { mutateAsync, isLoading, isError, error } = useMutation((dto: AddPaymentDto) => {
        return addPayment(dto);
    });

    const dismiss = () => {
        methods.reset();
        onClose && onClose()
    }

    const onSubmit = async ({ invoiceId, amount: baseAmount, datepaye: baseDatePayee, ...data }: AddPaymentModel) => {
        // Convert amount
        const amount = currency(baseAmount).toString();

        // convert date
        const datepaye = DateTime.fromISO(baseDatePayee).toSeconds();

        const dto: AddPaymentDto = {
            datepaye: Math.floor(datepaye),
            arrayofamounts: {
                [invoiceId]: {
                    amount,
                    multicurrency_amount: ""
                }
            },
            ...data,
            accountid: 1,
            closepaidinvoices: 'yes'
        }

        const id = await mutateAsync(dto);

        dismiss();
    }

    return (
        <IonModal isOpen={isOpen} backdropDismiss={false} keepContentsMounted={false} style={{ '--width': '40%', '--min-height': 400, '--backdrop-opacity': .4 }}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Saisir règlement</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <InvoiceIdCardControl thirdPartyId={thirdPartyId} />

                    <WhiteCard>
                        <IonCardHeader>
                            <IonCardSubtitle>Règlement client</IonCardSubtitle>
                        </IonCardHeader>

                        <PaymentForm />
                    </WhiteCard>
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    )
}

function InvoiceIdCardControl({ thirdPartyId }: { thirdPartyId: string }) {
    const { isLoading, data } = useInvoicesQuery({ thirdparty_ids: Number(thirdPartyId) });

    const invoices = data || [];

    const { setValue } = useFormContext();

    useEffect(() => {
        if (invoices.length === 0) {
            return;
        }

        if (invoices.length === 1) {
            const [invoice] = invoices;

             setValue('invoiceId', invoice.id);
             setValue('amount', +invoice.remaintopay);
        }

    }, [invoices]);

    if (isLoading || invoices.length === 1) {
        return <></>
    }

    return (
        <WhiteCard>
            <IonCardHeader>
                <IonCardSubtitle>Sélectionnez une facture</IonCardSubtitle>
            </IonCardHeader>

            <IonList>
                <IonRadioGroup>
                    {
                        invoices.map(
                            i => {
                                return (
                                    <IonItem key={i.id}>
                                        <IonRadio slot="start" value="grapes"></IonRadio>
                                        <IonLabel>
                                            <h3>{i.ref}</h3>
                                            <p>
                                                <b>Reçu <FormattedNumber
                                                    value={Number(i.totalpaid || 0)}
                                                    style="currency"
                                                    currency={"EUR"}
                                                /> - À percevoir <FormattedNumber
                                                    value={Number(i.remaintopay || 0)}
                                                    style="currency"
                                                    currency={"EUR"}
                                                /></b>
                                            </p>
                                        </IonLabel>
                                        <IonBadge slot="end">
                                            Total: <FormattedNumber
                                            value={Number(i.total_ttc || 0)}
                                            style="currency"
                                            currency={"EUR"}
                                        /> TTC
                                        </IonBadge>
                                    </IonItem>
                                )
                            }
                        )
                    }
                </IonRadioGroup>
            </IonList>
        </WhiteCard>
    )
}

const WhiteCard = styled(IonCard)`
    background: white;
    
    & .item {
        --background: white;
    } 
    
    & ion-list {
        background: white;
    }
`;