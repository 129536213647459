import {useParams} from "react-router";
import Page from "./Page";
import CreateProposal from "../components/Proposals/CreateProposal";
import {useThirdPartyDetail} from "../components/ThirdPartyDetails/useThirdPartyDetail";
import {IonSpinner} from "@ionic/react";
import React from "react";

export default function NewThirdPartyProposalPage() {
    const params = useParams<{ id: string }>();
    const id = params.id;

    const { isLoading, data: thirdParty     } = useThirdPartyDetail(id);

    return (
        <Page>
            {
                isLoading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center",
                        width: "100%",
                        height: '100%',
                        flexDirection: "column"
                    }}>
                        <IonSpinner name="lines"/>
                    </div>
                ) : (
                    <CreateProposal
                        defaultValues={{
                            thirdParty: {
                                id: thirdParty!.id,
                                name: thirdParty!.name,
                                projectId: thirdParty?.array_options?.options_related_project || '0'
                            },
                        }}
                    />
                )
            }
        </Page>
    )
}