import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonText, IonTextarea
} from "@ionic/react";
import {Controller, useFormContext} from "react-hook-form";
import React from "react";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import UserAutocomplete from "../ThirdParty/UserAutocomplete";
import {useHistory} from "react-router";
import {useCreateNewFoire} from "./useCreateNewFoire";
import {FoireFormModel} from "./type";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {useUser} from "../Auth/auth.store";
import { FormControl } from "@mui/material";


export default function EventForm() {
    const user = useUser();

    const { handleSubmit } = useFormContext<FoireFormModel>();

    const history = useHistory();

    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewFoire();

    const onSubmit = async (data: FoireFormModel) => {
        const { responsables, vendeurs, rabatteurs, vttistes } = data;

        const responsableIds = (responsables || []).map(r => r.id).join(',');
        const vendeurIds = (vendeurs || []).map(v => v.id).join(',');
        const rabatteurIds = (rabatteurs || []).map(r => r.id).join(',');
        const vttisteIds = (vttistes || []).map(r => r.id).join(',');

        const salesForceIds = [
            ...(responsables || []),
            ...(vendeurs || []),
            ...(rabatteurs || []),
            ...(vttistes || []),
        ].map(f => Number(f.id));

        await mutateAsync({
            ...data,
            userownerid: +(user.id),
            userassigned: salesForceIds,
            array_options: {
                responsable_ids: responsableIds,
                vendeur_ids: vendeurIds,
                rabatteur_ids: rabatteurIds,
                vttiste_ids: vttisteIds,
            }
        });

        // redirecton vers la liste des foires
        history.push('/page/Agenda/Foires');
    }

    return(
        <form>
            <Controller
                name={"label"}
                rules={{ required: 'Libellé foire obligatoire' }}
                render={
                    ({ field, fieldState: { error } }) => {
                        return (
                            <>
                                <IonItem>
                                    <IonLabel position="floating">Libellé</IonLabel>
                                    <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />
                                </IonItem>

                                {
                                    error && error.type === 'required' && (
                                        <IonText color="danger">
                                            <small>{error.message}</small>
                                        </IonText>
                                    )
                                }
                            </>
                        )
                    }
                }
            />

            <IonItem>
                <IonLabel position="floating">Lieu</IonLabel>
                <Controller
                    name={"location"}
                    render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                />
            </IonItem>

            <Controller
                name={"datep"}
                rules={{ required: 'Date début foire obligatoire' }}
                render={({ field: {  value, onChange}, fieldState: { error } }) => {
                    return (
                        <IonItem className={"ion-margin-top ion-margin-bottom"}>
                            <FormControl variant={"outlined"} fullWidth>
                                <IonLabel>Date de début</IonLabel>
                                <HybridDatePicker
                                    value={value || null}
                                    InputProps={{
                                        size: 'small',
                                        error: !!error,
                                        placeholder: 'Date début foire',
                                        sx: { mt: .7 }
                                    }}
                                    onChange={
                                        (v: DateTime | null) => {
                                            onChange(v ? idateTime(v) : null)
                                        }
                                    }
                                />
                            </FormControl>
                        </IonItem>
                    )
                }}
            />

            <Controller
                name={"datef"}
                render={({ field: { value, onChange }, fieldState: { error} }) => {
                    return (
                        <IonItem className={"ion-margin-top ion-margin-bottom"}>
                            <FormControl variant="outlined" fullWidth>
                                <IonLabel>Date de fin</IonLabel>
                                <HybridDatePicker
                                    value={value || null}
                                    InputProps={{
                                        size: 'small',
                                        error: !!error,
                                        placeholder: 'Date de fin foire',
                                        sx: { mt: .7 }
                                    }}
                                    onChange={
                                        (v: DateTime | null) => {
                                            onChange(v ? idateTime(v) : null)
                                        }
                                    }
                                />
                            </FormControl>
                        </IonItem>
                    )
                }}
            />

            <IonItem className={"ion-margin-top"}>
                <Controller
                    name={"responsables"}
                    render={
                        ({field}) => {
                            return(
                                <UserAutocomplete
                                    multiple={true}
                                    label={"Responsables"}
                                    placeholder={"Sélectionner responsables"}
                                    onChange={field.onChange}
                                />
                            )
                        }
                    }
                />
            </IonItem>

            <IonItem className={"ion-margin-top"}>
                <Controller
                    name={"vendeurs"}
                    render={
                        ({field }) => {
                            return(
                                <UserAutocomplete
                                    multiple={true}
                                    label={"Vendeurs"}
                                    placeholder={"Sélectionner vendeurs"}
                                    onChange={field.onChange}
                                />
                            )
                        }
                    }
                />
            </IonItem>

            <IonItem className={"ion-margin-top"}>
                <Controller
                    name={"rabatteurs"}
                    render={
                        ({field }) => {
                            return(
                                <UserAutocomplete
                                    multiple={true}
                                    label={"Rabatteurs"}
                                    placeholder={"Sélectionner rabatteurs"}
                                    onChange={field.onChange}
                                />
                            )
                        }
                    }
                />
            </IonItem>

            <IonItem className={"ion-margin-top"}>
                <Controller
                    name={"vttistes"}
                    render={
                        ({field }) => {
                            return(
                                <UserAutocomplete
                                    multiple={true}
                                    label={"VTTistes"}
                                    placeholder={"Sélectionner VTTistes"}
                                    onChange={field.onChange}
                                />
                            )
                        }
                    }
                />
            </IonItem>

            <IonItem className={"ion-margin-top"}>
                <IonLabel position="floating">Note</IonLabel>
                <Controller
                    name={"note"}
                    render={
                        ({field }) => (
                            <IonTextarea
                                value={field.value}
                                onIonChange={field.onChange}
                                placeholder={"Vos notes ici"}
                                rows={4}
                            />
                        )
                    }
                />
            </IonItem>

            <IonRow className={"ion-margin-top"}>
                <IonCol size={'2'} pushMd={'8'}>
                    <IonButton color="light" expand="block" size="large" routerLink={"/page/Agenda/Foires"} routerDirection="root">
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol size={'2'} pushMd={'8'}>
                    <IonLoadingButton
                        loading={isLoading}
                        onClick={handleSubmit(onSubmit)}
                        expand="block"
                        size="large"
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>

        </form>
    )
}
