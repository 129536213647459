import {Controller} from "react-hook-form";
import React from "react";
import GenericTextFieldControl from "../../../Common/Form/GenericTextFieldControl";

export default function ManagerNameControl({ name = 'managerName' }: { name?: string }) {
    const label = 'Nom du gérant';

    return (
        <Controller
            name={name}
            defaultValue=""
            rules={{
                required: "Nom du gérant obligatoire"
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <GenericTextFieldControl
                        label={label}
                        TextFieldProps={{
                            placeholder: label,
                            value,
                            onChange,
                        }}
                        error={error}
                    />
                )
            }}
        />
    )
}

export function ManagerFirstNameControl({ name = 'managerFirstName' }: { name?: string }) {
    const label = 'Prénom du gérant';

    return (
        <Controller
            name={name}
            defaultValue=""
            rules={{
                required: "Prénom du gérant obligatoire"
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <GenericTextFieldControl
                        label={label}
                        TextFieldProps={{
                            placeholder: label,
                            value,
                            onChange,
                        }}
                        error={error}
                    />
                )
            }}
        />
    )
}