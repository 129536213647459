import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

interface Payload {
    comment: string;
}

export async function addCardComment(cardId: number, payload: Payload) {
    const url = Endpoint.addCardComment.replace(":id", cardId.toString());
    const { data } = await http.post<{ id: string }>(url, payload);

    return data;
}
