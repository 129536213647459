import {IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption,} from '@ionic/react';
import {Controller, useFormContext} from "react-hook-form";
import {DevTool} from "@hookform/devtools";

const SearchThirdParty: React.FC = () => {

    const {control} = useFormContext();

    return (
        <>
            <IonList>
                <IonItem>
                    <IonLabel position="floating">ID</IonLabel>
                    <Controller
                        name={"id"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Code tiers</IonLabel>
                    <Controller
                        name={"code"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Nom</IonLabel>
                    <Controller
                        name={"name"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <Controller
                        name={"type"}
                        render={
                            ({field}) => {
                                return (
                                    <>
                                        <IonLabel>Type tiers</IonLabel>

                                        <IonSelect cancelText={"Annuler"} placeholder="Type" value={field.value} onIonChange={field.onChange}>
                                            <IonSelectOption value="1">Client</IonSelectOption>
                                            <IonSelectOption value="2">Prospect</IonSelectOption>
                                            <IonSelectOption value="3">Ni client / Ni prospect</IonSelectOption>
                                            <IonSelectOption value="4">Fournisseur</IonSelectOption>
                                        </IonSelect>
                                    </>
                                )
                            }
                        }
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Adresse</IonLabel>
                    <Controller
                        name={"address"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Code postal</IonLabel>
                    <Controller
                        name={"zip"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Ville</IonLabel>
                    <Controller
                        name={"town"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Téléphone</IonLabel>
                    <Controller
                        name={"phone"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"tel"}/>}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <Controller
                        name={"email"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"email"}/>}
                    />
                </IonItem>

            </IonList>

            <DevTool control={control} />
        </>

    );
};

export default SearchThirdParty;
