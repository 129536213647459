import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export interface CreateFoireDto {
    label: string,
    datep : string,
    datef?: string,
    location?: string,
    userownerid?: number,
    userassigned: number[],
    transparency?: number,
    note?: string,
    percentage?: number,
    array_options?: {
        vttiste_ids?: string,
        rabatteur_ids: string,
        responsable_ids: string,
        vendeur_ids: string,
    },
}

export async function createFoire(dto: CreateFoireDto) {
    const {data} = await http.post<any>(Endpoint.foires, dto);

    return data;
}