import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useController, useFormContext} from 'react-hook-form';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Collapse,
    FormControl,
    FormLabel,
    InputAdornment,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    Stack,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import {AllInterventionStatus, InterventionType, interventionUI} from "../../models/intervention.model";
import {useTechniciansQuery} from "./useTechniciansQuery";
import {userToString} from "../../models/user.model";
import {departements, departementsRecord} from "./departements";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {DatePicker} from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import {keyBy} from "lodash";
import {DateTime} from "luxon";
import {useAddressSearch} from "./useAddressSearch";
import {debounce} from '@mui/material/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function InterventionFilterButton({ open, onClick }: { open: boolean, onClick(): void }) {
    return (
        <ButtonLower
            onClick={onClick}
            variant="outlined"
            color={"inherit"}
            size={"small"}
            startIcon={open ? <FilterAltOffIcon /> : <FilterAltIcon />}
        >
            Filtres
        </ButtonLower>
    )
}

export function InterventionAdvancedSearchFormCollapse({ open, onSubmit, onClose }: { open: boolean, onClose(): void, onSubmit(): void }) {
    const {
        handleSubmit,
        reset,
        formState: { errors },
    } = useFormContext();

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper sx={{ p: 2, mb: 2 }}>
                <Stack direction={"column"} spacing={1} component={'form'} onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}>
                    <InterventionAdvancedSearchForm />

                    <Stack direction="row" spacing={1}>
                        <ButtonLower type={"button"} variant="text" onClick={onClose} sx={{ mr: 'auto' }} startIcon={<KeyboardArrowUpIcon />}>
                            Fermer
                        </ButtonLower>

                        <ButtonLower type={"button"} variant="text" onClick={() => reset()}>
                            Tout effacer
                        </ButtonLower>

                        <ButtonLower type={"submit"} variant="contained" startIcon={<SearchIcon />}>
                            Rechercher
                        </ButtonLower>
                    </Stack>
                </Stack>
            </Paper>
        </Collapse>
    )
}

function InterventionAdvancedSearchForm() {
    return (
        <Stack direction={"column"} spacing={1}>
            <Stack direction="row" spacing={.5}>
                <TechnicianControl />

                <InterventionTypeControl />

                <StatusControl />
            </Stack>

            <Stack direction="row" spacing={.5}>
                <ProductTypologiesControl />

                <DepartmentControl />
            </Stack>

            <Stack direction="row" spacing={.5}>
                <NearByAndMaxDistanceControl />
            </Stack>

            <Stack direction="row" spacing={.5}>
                <DateRangeControl />
            </Stack>
        </Stack>
    );
}

function TechnicianControl() {
    const label = "Technicien";
    const name = 'technicianId';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    const { data, isLoading, isError } = useTechniciansQuery();

    const users = data?.items || [];

    return (
        <Box sx={{ minWidth: 200 }}>
            <Controller
                name={name}
                defaultValue={""}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            {
                                isLoading ? (
                                    <Typography component="div" variant={'h2'}>
                                        <Skeleton />
                                    </Typography>
                                ) : (
                                    <Select
                                        labelId={labelId}
                                        id={id}
                                        placeholder={label}
                                        size={"small"}
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {
                                            users.map(
                                                u => (
                                                    <MenuItem key={u.id} value={u.id}>
                                                        {userToString(u)}
                                                    </MenuItem>
                                                )
                                            )
                                        }
                                    </Select>
                                )
                            }
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function StatusControl() {
    const label = "Statut";
    const name = 'status';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    const statuses = [
        AllInterventionStatus.TO_SCHEDULE,
        AllInterventionStatus.SCHEDULED,
        AllInterventionStatus.COMPLETED,
        AllInterventionStatus.PARTIALLY_COMPLETED,
        AllInterventionStatus.CANCELLED,
    ]

    return (
        <Box sx={{ minWidth: 160 }}>
            <Controller
                name={name}
                defaultValue={""}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            <Select
                                labelId={labelId}
                                id={id}
                                placeholder={label}
                                size={"small"}
                                value={value}
                                onChange={onChange}
                            >
                                {
                                    statuses.map(
                                        s => (
                                            <MenuItem key={s} value={s}>
                                                {interventionUI.statusTranslation[s]}
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function InterventionTypeControl() {
    const label = "Type d'intervention";
    const name = 'types';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    const types = [
        InterventionType.VISITE_TECHNIQUE,
        InterventionType.POSE,
        InterventionType.SAV,
        InterventionType.ENTRETIEN,
        InterventionType.RDVCOM,
    ];

    return (
        <Box sx={{ minWidth: 230 }}>
            <Controller
                name={name}
                defaultValue={[]}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            <Select
                                labelId={labelId}
                                id={id}
                                placeholder={label}
                                size={"small"}
                                multiple
                                renderValue={(selected: string[]) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {
                                            selected.map((value) => (
                                                <Chip key={value} label={(interventionUI.typeTranslation as any)[value]} />
                                            ))
                                        }
                                    </Box>
                                )}
                                value={value}
                                onChange={onChange}
                            >
                                {
                                    types.map(
                                        t => (
                                            <MenuItem key={t} value={t}>
                                                <Checkbox checked={value.indexOf(t) !== -1} />
                                                <ListItemText primary={interventionUI.typeTranslation[t]} />
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function DepartmentControl() {
    const label = "Département";
    const name = 'department';
    const labelId = `${name}-label`;
    const id = `${name}-combo-box`;

    return (
        <Box sx={{ minWidth: 250 }}>
            <Controller
                name={name}
                defaultValue={null}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    const autocompleteValue = departementsRecord[value] || null;

                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            <Autocomplete
                                disablePortal
                                id={id}
                                value={autocompleteValue}
                                onChange={
                                    (event, value) => {
                                        onChange(value?.code);
                                    }
                                }
                                options={departements}
                                getOptionLabel={o => (`${o.label} (${o.code})`)}
                                renderInput={(params) => <TextField {...params} size={"small"} />}
                            />
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function ProductTypologiesControl() {
    const label = "Famille de produit";
    const name = 'productTypologies';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    const typologies = [
        {
            code: 'PAC_AIR_AIR',
            label: "Pompe à chaleur R/R",
        },
        {
            code: 'PAC_AIR_EAU',
            label: "Pompe à chaleur R/O",
        },
        {
            code: 'PHOTOVOLTAIC',
            label: "Photovoltaïque",
        },
        {
            code: 'BALLON_THERMO',
            label: "Ballon thermo",
        },
    ];

    const typologiesRecord = keyBy(typologies, 'code');

    return (
        <Box sx={{ minWidth: 230 }}>
            <Controller
                name={name}
                defaultValue={[]}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            <Select
                                labelId={labelId}
                                id={id}
                                placeholder={label}
                                size={"small"}
                                multiple
                                renderValue={(selected: string[]) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {
                                            selected.map((value) => (
                                                <Chip key={value} label={typologiesRecord[value].label} />
                                            ))
                                        }
                                    </Box>
                                )}
                                value={value}
                                onChange={onChange}
                            >
                                {
                                    typologies.map(
                                        t => (
                                            <MenuItem key={t.code} value={t.code}>
                                                <Checkbox checked={value.indexOf(t.code) !== -1}/>
                                                <ListItemText primary={t.label} />
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function NearByAndMaxDistanceControl() {
    const label = "Intervention à proximité de cette adresse";
    const name = 'nearBy';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    return (
        <Stack sx={{ width: '100%' }} direction={"row"} justifyContent="space-between" alignItems={"center"} spacing={.5}>
            <NearByControl />

            <MaxDistanceControl />
        </Stack>
    )
}

interface NearByControlOption {
    type: string;
    geometry: {
        type: string;
        coordinates: number[];
    };
    properties: {
        id: string;
        label: string;
        context: string;
    }
}

/**
 * https://stackoverflow.com/questions/70811987/using-renderoption-in-muis-autocomplete
 * @constructor
 */
function NearByControl() {
    const label = "À proximité de cette rue";
    const name = 'nearBy';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    const [autocompleteValue, setAutocompleteValue] = React.useState<NearByControlOption | null>(null);
    const [inputValue, setInputValue] = React.useState('');

    const debounceSetInputValue = useMemo(
        () =>
            debounce(
                (v: string) => {
                    setInputValue(v);
                    if (v.length > 0) {
                        setOpen(true);
                    } else {
                        setOpen(false);
                    }
                },
                400,
            ),
        [],
    );

    const { isLoading, data } = useAddressSearch({ q: inputValue });

    const options = data?.features || [];
    const optionsRecords = keyBy(options, o => btoa((o?.geometry?.coordinates || []).join(',')));

    const [open, setOpen] = useState<boolean>(false);

    const {
        field: { value, onChange },
    } = useController({
        name,
        defaultValue: null,
    });

    useEffect(() => {
        // Pour le reset
        if (autocompleteValue && !value) {
            setAutocompleteValue(null);
        }
    }, [value]);

    return (
        <FormControl fullWidth>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <Autocomplete
                id={id}
                options={options}
                loading={isLoading}
                open={open}
                onClose={() => setOpen(false)}
                filterOptions={(x) => x}
                noOptionsText="Pas d'adresse trouvé"
                loadingText={"Recherche en cours ..."}
                getOptionLabel={(option: NearByControlOption) => option?.properties?.label || ''}
                isOptionEqualToValue={(option: NearByControlOption, value: NearByControlOption) => {
                    return option.properties.id === value.properties.id;
                }}
                renderInput={
                    (params) => <TextField
                        {...params}
                        size={"small"}
                        placeholder={"ex. rue Lecourbe, 75015 Paris"}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LocationSearchingIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                }
                onInputChange={(event, newInputValue) => {
                    debounceSetInputValue(newInputValue);
                }}
                value={autocompleteValue}
                onChange={(e, value: NearByControlOption | null) => {
                    setAutocompleteValue(value);
                    setOpen(false);
                    onChange(value?.geometry?.coordinates);
                }}
            />
        </FormControl>
    )
}

function MaxDistanceControl() {
    const label = "Rayon en km";
    const name = 'maxDistance';
    const labelId = `${name}-label`;
    const id = `${name}-select`;

    return (
        <Box sx={{ minWidth: 30 }}>
            <Controller
                name={name}
                defaultValue={50}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>
                            <TextField
                                type={"number"}
                                variant="outlined"
                                size={"small"}
                                value={value}
                                onChange={onChange}
                            />
                        </FormControl>
                    )
                }}
            />
        </Box>
    )
}

function DateRangeControl() {
    const label = "Date prévue entre";

    return (
        <FormControl fullWidth>
            <FormLabel>{label}</FormLabel>
            <Stack sx={{ width: '100%' }} direction={"row"} justifyContent="flex-start" alignItems={"center"} spacing={.5}>
                <Controller
                    name={'dateStart'}
                    defaultValue={null}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <DatePicker
                                value={value}
                                onChange={
                                    (v: DateTime | null) => {
                                        onChange(v ? v?.toISO() : null);
                                    }
                                }
                                renderInput={(params) => <TextField {...params} size={"small"} />}
                            />
                        )
                    }}
                />

                <Typography>
                    et
                </Typography>

                <Controller
                    name={'dateEnd'}
                    defaultValue={null}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <DatePicker
                                value={value}
                                onChange={
                                    (v: DateTime | null) => {
                                        onChange(v ? v?.toISO() : null);
                                    }
                                }
                                renderInput={(params) => <TextField {...params} size={"small"} />}
                            />
                        )
                    }}
                />
            </Stack>
        </FormControl>
    )
}

const ButtonLower = styled(Button)`
    text-transform: capitalize;
`;