import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getThirdParty} from "../../calls/ThirdParties/getThirdParty";

export function useThirdPartyDetail(id: number | string) {
    return useQuery(['thirdParty', id], ({queryKey}) => {
        return getThirdParty(queryKey[1] as string)
    });
}

export function useInvalidateThirdPartyDetail(id: number | string) {
    const qc = useQueryClient();

    return () => qc.invalidateQueries({ queryKey: ['thirdParty', id] });
}