import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {isNil, omitBy} from 'lodash';
import {Pagination} from "../types";
import {PaginatedResponse} from "../../models/common.model";
import {ProposalSearchResult} from "../../models/proposal.model";

export type SearchAffairePayload = {
    id?: number | string;

    thirdPartyName?: string | number;
    thirdPartyIds?: number | string; // example '1' or '1,2,3'
    address?: string | number;
    zip?: string | number;
    town?: string | number;
    code?: string | number;
    email?: string | number;
    phone?: string | number;
    origine?: string; // Origine de l'affaire 1=Foire 2=Terrain 3=Autre
    foire?: number | string; // example '1' or '1,2,3'
    startDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    endDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    amountWithoutTax?: string;
    amountIncludingTax?: string;

    status?: number | string;

    isTemplate?: boolean;
}

export async function searchAffaire(payload: SearchAffairePayload, pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const dto = omitBy(payload, v => {
        return isNil(v) || !v;
    });

    const { data } = await http.post<PaginatedResponse<ProposalSearchResult>>(Endpoint.proposalsSearch, dto, {
        params: {
            ...pagination,
            sortfield: 't.rowid',
        }
    });

    return data;
}