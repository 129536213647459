import create from "zustand";

interface State {
    isOpen: boolean;
}

interface Actions {
    showModal(): void;
    closeModal(): void;
}

const useSearchProposalTemplateDialogStore = create<State & Actions>((set) => ({
    isOpen: false,
    showModal: () => set(state => ({ isOpen: true })),
    closeModal: () => set(state => ({ isOpen: false }))
}));

export default useSearchProposalTemplateDialogStore;