import {
    IonAvatar,
    IonButton,
    IonButtons, IonCheckbox,
    IonContent,
    IonHeader, IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar, IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useSalesForceSearch} from "./hooks/useSalesForceSearch";
import {User} from "../../../models/user.model";
import LoadingDots from "../../LoadingDots";
import {alertCircleOutline, alertCircleSharp} from "ionicons/icons";
import { debounce } from 'lodash';
import {SearchbarChangeEventDetail} from "@ionic/core";
import {IonSearchbarCustomEvent} from "@ionic/core/dist/types/components";


type SearchSalesForceProps = {
    title: string,
    onUserSelected(u: User | User[]): void,
    onCancel?(): void,
    multiple?: boolean,
}

const SearchSalesForceContext = React.createContext<any>({});

const useSearchSalesForceContext = () => useContext(SearchSalesForceContext);

export default function SearchSalesForce({ title, onUserSelected, onCancel, multiple }: SearchSalesForceProps) {
    const [q, setQ] = useState("");

    const changeHandler = (event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>) => {
        setQ(event.detail.value as string);
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(changeHandler, 400)
        , []);

    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    // Si mode multiple une autre gestion
    const [selectedUsers, setSelectedUsers] = useState({});

    const addUser = (u: User) => {
        setSelectedUsers({
            ...selectedUsers,
            [u.id]: u,
        });
    };

    const rmUser = (u: User) => {
        const tmp: any = { ...selectedUsers };
        delete tmp[u.id];

        setSelectedUsers(tmp);
    };

    const multipleUserSelected = () => {
        const values = Object.values(selectedUsers) as User[];

        onUserSelected(values)
    }

    const { data, isLoading: loading } = useSalesForceSearch(q);

    const users = data?.items || [];

    return (
        <SearchSalesForceContext.Provider value={{
            selectedUsers,
            addUser,
            rmUser
        }}>
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot={'start'}>
                            <IonButton onClick={onCancel}>
                                Fermer
                            </IonButton>
                        </IonButtons>

                        <IonTitle>
                            {title || 'Chercher une personne à rattacher'}
                        </IonTitle>

                        {
                            multiple && (
                                <IonButtons slot={'primary'} onClick={() => multipleUserSelected()}>
                                    <IonButton color={"primary"}>
                                        Valider
                                    </IonButton>
                                </IonButtons>
                            )
                        }
                    </IonToolbar>
                    <IonToolbar>
                        <IonSearchbar
                            value={q}
                            onIonChange={debouncedChangeHandler}
                            placeholder={"Rechercher"}
                        />
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    {
                        loading && (
                            <IonItem lines={"none"}>
                                <IonText color={'medium'} style={{ marginRight: 5 }}>
                                    <span>Recherche en cours</span>
                                </IonText>
                                <LoadingDots />
                            </IonItem>
                        )
                    }

                    {
                        !loading && users.length === 0 && (
                            <IonText style={{ display: 'flex' }} className={'ion-align-items-center ion-margin-top'}>
                                <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                                <span style={{ marginLeft: 5 }}>Pas de personnes trouvées.</span>
                            </IonText>
                        )
                    }

                    {
                        !loading && users.length > 0 && (
                            <UserList
                                users={users}
                                multiple={multiple}
                                onSelectionChange={onUserSelected}
                            />
                        )
                    }
                </IonContent>
            </IonPage>
        </SearchSalesForceContext.Provider>
    )
}

type UserListProps = {
    users: User[],
    multiple?: boolean,
    onSelectionChange(u: User | User[]): void,
};

function UserList({ users, multiple, onSelectionChange }: UserListProps) {
    const { addUser, rmUser } = useSearchSalesForceContext();

    return (
        <IonList>
            {
                users.map(
                    u => {
                        const fullName = [
                            u.firstname,
                            u.lastname,
                        ].filter(n => !!n).join(' ');

                        if (multiple) {
                            return (
                                <UserCheckboxItem
                                    key={`multiple-${u.id}`} user={u}
                                    addUser={addUser}
                                    rmUser={rmUser}
                                />
                            )
                        }

                        return (
                            <IonItem key={u.id} button onClick={() => onSelectionChange(u)}>
                                <IonAvatar slot="start">
                                    <img alt={`Avatar ${fullName}`} src="/assets/img/ion-avatar.svg" />
                                </IonAvatar>
                                <IonLabel>
                                    <h2>{fullName}</h2>
                                </IonLabel>
                            </IonItem>
                        )
                    }
                )
            }
        </IonList>
    )
}

type UserCheckboxItemProps = {
    user: User,
    addUser(u: User): void;
    rmUser(u: User): void;
}

function UserCheckboxItem({ user, addUser, rmUser }: UserCheckboxItemProps) {
    const fullName = [
        user.firstname,
        user.lastname,
    ].filter(n => !!n).join(' ');

    return (
        <IonItem>
            <IonAvatar slot="start">
                <img alt={`Avatar ${fullName}`} src="/assets/img/ion-avatar.svg" />
            </IonAvatar>
            <IonLabel>
                <h2>{fullName}</h2>
            </IonLabel>
            <IonCheckbox
                slot="end"
                onIonChange={(e) => e.detail.checked === true ? addUser(user) : rmUser(user)}
            />
        </IonItem>
    )
}
