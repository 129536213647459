import {useStepper} from "./useStepper";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import React, {useMemo} from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

interface StepperNavigationProps {
    isLoading: boolean;
    onCancel?(): void;
}

export default function StepperNavigation({ isLoading, onCancel }: StepperNavigationProps) {
    const { activeStep, stepCount, stepsState, isNextEnabledForActiveStep, handleNext, handlePrevious } = useStepper();

    const isNextEnabled = useMemo(() => {
        return isNextEnabledForActiveStep();
    }, [activeStep, stepsState]);

    const next = () => {
        handleNext();
    }

    const previous = () => {
        handlePrevious();
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
                <Button type={"button"} size={"small"} onClick={() => onCancel && onCancel()}>
                    Annuler
                </Button>

                <Stack spacing={5} direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                    {
                        activeStep > 0 && (
                            <Button
                                size={"small"}
                                onClick={previous}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Précédent
                            </Button>
                        )
                    }

                    {
                        activeStep < stepCount ? (
                            <Button
                                autoFocus
                                variant="contained"
                                size={"small"}
                                disabled={!isNextEnabled}
                                onClick={next}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Suivant
                            </Button>
                        ) : (
                            <LoadingButton
                                loading={isLoading}
                                autoFocus
                                variant="contained"
                                size={"small"}
                                startIcon={<CheckCircleRoundedIcon />}
                                type={"submit"}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Étude terminée
                            </LoadingButton>
                        )
                    }
                </Stack>
            </Stack>
        </Box>
    )
}