/**
 *
 * @param name Name dans le formulaire car on va watch
 */
import currency from "currency.js";

type UseProductPricingRangeProps = {
    prixPublic: string;
    prixPro: string;
    prixHorsCible: string;
    tvaTx: string;
    totalTTC: string | number;
}

export function useProductPricingRange({prixPublic, prixPro, prixHorsCible, tvaTx, totalTTC}: UseProductPricingRangeProps) {
    const prixPublicHT = currency(prixPublic || 0);
    const tarifProHT = currency(prixPro || 0);
    const tarifHorsCibleHT = currency(prixHorsCible || 0);

    const coeffTva = 1 + (Number(tvaTx) / 100);

    const prixPublicTTC = prixPublicHT.multiply(coeffTva);
    const tarifProTTC = tarifProHT.multiply(coeffTva);
    const tarifHorsCibleTTC = tarifHorsCibleHT.multiply(coeffTva);

    const prixVenteTTC = currency(totalTTC);

    const [startHorsCible, endHorsCible] = [
        0,
        tarifHorsCibleTTC.value,
    ];

    const [startPro, endPro] = [
        tarifHorsCibleTTC.value,
        tarifProTTC.value,
    ];

    const [startPublic, endPublic] = [
        tarifProTTC.value,
        prixPublicTTC.value
    ];

    const isTarifHorsCible = (prixVenteTTC.value >= startHorsCible  && prixVenteTTC.value <= endHorsCible);
    const isTarifPro = (prixVenteTTC.value > startPro && prixVenteTTC.value <= endPro);
    const isTarifPublic = (prixVenteTTC.value > startPublic && prixVenteTTC.value <= endPublic);
    const isOutOfRange = prixVenteTTC.value > endPublic;

    const pricings = [
        { name: 'HORS_CIBLE', applied: isTarifHorsCible, range: [startHorsCible, endHorsCible] },
        { name: 'PRO', applied: isTarifPro, range: [startPro, endPro] },
        { name: 'PUBLIC', applied: isTarifPublic, range: [startPublic, endPublic] },
        { name: 'OUT_OF_RANGE', applied: isOutOfRange }
    ]
    // console.log('prix de vente', prixVenteTTC.value);
    // console.log('isTarifHorsCible', [startHorsCible, endHorsCible]);
    // console.log('isTarifPro', [startPro, endPro]);
    // console.log('isTarifPublic', [startPublic, endPublic]);
    // console.log('isOutOfRange', endPublic);

    const pricing = pricings.filter(p => p.applied)[0];

    let alertMessage: string = '';

    if (['HORS_CIBLE', 'PRO', 'OUT_OF_RANGE'].indexOf(pricing?.name) !== -1) {
        alertMessage = messages[pricing?.name];
    }

    return {
        pricing,
        alertMessage
    };
}

export function computeProductPricingRange({prixPublic, prixPro, prixHorsCible, tvaTx, totalTTC, qty = 1}: UseProductPricingRangeProps & { qty?: number }) {
    const prixPublicHT = currency(prixPublic || 0).multiply(qty);
    const tarifProHT = currency(prixPro || 0).multiply(qty);
    const tarifHorsCibleHT = currency(prixHorsCible || 0).multiply(qty);

    const coeffTva = 1 + (Number(tvaTx) / 100);

    const prixPublicTTC = prixPublicHT.multiply(coeffTva);
    const tarifProTTC = tarifProHT.multiply(coeffTva);
    const tarifHorsCibleTTC = tarifHorsCibleHT.multiply(coeffTva);

    const prixVenteTTC = currency(totalTTC);

    const [startHorsCible, endHorsCible] = [
        0,
        tarifHorsCibleTTC.value,
    ];

    const [startPro, endPro] = [
        tarifHorsCibleTTC.value,
        tarifProTTC.value,
    ];

    const [startPublic, endPublic] = [
        tarifProTTC.value,
        prixPublicTTC.value
    ];

    const isTarifHorsCible = (prixVenteTTC.value >= startHorsCible  && prixVenteTTC.value <= endHorsCible);
    const isTarifPro = (prixVenteTTC.value > startPro && prixVenteTTC.value <= endPro);
    const isTarifPublic = (prixVenteTTC.value > startPublic && prixVenteTTC.value <= endPublic);
    const isOutOfRange = prixVenteTTC.value > endPublic;

    const pricings = [
        { name: 'HORS_CIBLE', applied: isTarifHorsCible, range: [startHorsCible, endHorsCible] },
        { name: 'PRO', applied: isTarifPro, range: [startPro, endPro] },
        { name: 'PUBLIC', applied: isTarifPublic, range: [startPublic, endPublic] },
        { name: 'OUT_OF_RANGE', applied: isOutOfRange }
    ]
    // console.log('prix de vente', prixVenteTTC.value);
    // console.log('isTarifHorsCible', [startHorsCible, endHorsCible]);
    // console.log('isTarifPro', [startPro, endPro]);
    // console.log('isTarifPublic', [startPublic, endPublic]);
    // console.log('isOutOfRange', endPublic);

    const pricing = pricings.filter(p => p.applied)[0];

    let alertMessage: string = '';

    if (['HORS_CIBLE', 'PRO', 'OUT_OF_RANGE'].indexOf(pricing?.name) !== -1) {
        alertMessage = messages[pricing?.name];
    }

    return {
        pricing,
        alertMessage
    };
}


const messages: any = {
    HORS_CIBLE: 'Attention ! Vous basculez dans une tarification hors cible',
    PRO: 'Attention ! Vous basculez dans une tarification professionnelle',
    OUT_OF_RANGE: 'Attention ! Vous êtes au dessus de la tarification grand public'
}