import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Intervention} from "../../models/intervention.model";

export async function setInterventionCardStatus(cardId: string, status: string) {
    const url = Endpoint.setInterventionCardStatus
        .replace(':id', cardId)
        .replace(':status', status)
    ;

    const { data } = await interventionHttp.patch<Intervention>(url, {});

    return data;
}