import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type EditProposalHeaderDto = {
    socid?: number;
    fk_project?: number;
    model_pdf?: string;
    date?: number;
    fin_validite?: number;
    duree_validite?: number;
    cond_reglement_id?: number;
    note_private?: string;
    note_public?: string;
    array_options?: {
        origine_affaire?: number,
        foire_origine?: string,
        rabatteur_ids?: string,
        responsable_ids?: string,
        vendeur_ids?: string,
        cout_revient_mo?: number;
        coeff_marge_mo?: number;
        prix_vente_mo?: number;
        coeff_marge_fo?: number;
        photo_toiture_vt?: string;
        linked_vt?: string;
        puissance_pv?: number;
        reduction_co2?: number;
    },
}

export async function editProposal(id: string, headerDto: EditProposalHeaderDto) {
    const url = Endpoint.proposal.replace(':id', id);

    const { data } = await http.put(url, headerDto);

    return data;
}