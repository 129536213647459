import React from "react";
import {useThirdPartyContacts} from "./useThirdPartyContacts";
import {Autocomplete, FormControl, FormHelperText, FormLabel, TextField, Typography} from "@mui/material";
import OptionalFormLabel from "../Common/Form/OptionalFormLabel";
import {Contact, contactToString} from "../../models/contact.model";

type ContactsAutocompleteProps = {
    thirdPartyId: string;
    label: string;
    placeholder: string;
    onChange?(contact: Contact): void;
    noOptionsText?: string;
    multiple?: boolean;
    error?: any;
    optional?: boolean;
}

export default function ContactsAutocomplete({ thirdPartyId, label, placeholder, onChange, noOptionsText = "Aucune option", multiple, error, optional }: ContactsAutocompleteProps) {
    const { data, isLoading } = useThirdPartyContacts(thirdPartyId);

    const options = data || [];

    const errored = !!error;

    return (
        <FormControl fullWidth error={errored}>
            {
                !optional
                    ? (
                        <FormLabel>{label}</FormLabel>
                    )
                    : (
                        <OptionalFormLabel>{label}</OptionalFormLabel>
                    )
            }
            <Autocomplete
                sx={{
                    mt: .7
                }}
                onChange={(event: any, value: any) => onChange && onChange(value)}
                options = {options}
                getOptionLabel={o => contactToString(o)}
                renderOption={(props, o) => {
                    const addressText = [
                        o.address,
                        o.zip,
                        o.town,
                    ].filter(i => !!i).join(', ');

                    const phones = [
                            {label: 'Email', value: o.email },
                            {label: 'Tél pro.', value: o.phone_pro },
                            // {label: 'Tél perso.', value: o.phone_perso },
                            // {label: 'Tél portable', value: o.phone_mobile }
                        ]
                            .filter(c => !!c.value)
                    ;

                    return (<li {...props} style={{display: 'grid'}}>
                        <Typography variant="subtitle2" component="p">
                            {contactToString(o)}
                        </Typography>

                        {
                            phones.map(phone => (
                                <Typography key={phone.label} variant="caption" component="p">
                                    {phone.value}
                                </Typography>
                            ))
                        }
                    </li>)
                }}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                noOptionsText={noOptionsText}
                loading={isLoading}
                renderInput={(params) => {
                    return (
                        <TextField {...params} placeholder={placeholder} size="small" variant="outlined"
                                   error={errored}/>
                    )
                }
                }
            />

            {
                error && error.type === 'required' && (
                    <FormHelperText>
                        <small>{error.message}</small>
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}