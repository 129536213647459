import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type EcmUploadPayload = {
    filename: string; // ex. "mynewfile.txt",
    subdir: string; // Marcel Pierre (26) ou autre (ex. img/website)
    filecontent: string // base64
    overwriteifexists: "0" | "1" | 1 | 0
}

type Payload = EcmUploadPayload & { modulepart: "ecm", fileencoding: "base64", }

export async function uploadDocumentToEcm(basePayload: EcmUploadPayload) {
    const payload: Payload = {
        ...basePayload,
        modulepart: "ecm",
        fileencoding: "base64",
    }

    const { data } = await http.post<string>(Endpoint.documentUpload, payload);

    return data;
}