import {useActiveCompanyId} from "../Company/company.store";
import {useMutation} from "@tanstack/react-query";
import {DateTime} from "luxon";
import {createProposalWithLines, ProposalDtoWithLines, ProposalLineDto} from "../../calls/Proposals/createProposal";
import {validateProposal} from "../../calls/Proposals/validateProposal";
import {buildDocument} from "../../calls/Documents/buildDocument";
import {downloadDocument} from "../../calls/Documents/downloadDocument";
import {uploadDocumentToEcm} from "../../calls/Documents/uploadDocumentToEcm";
import {CreateProposalFormModel} from "./ProposalForm/types";
import {editProposal} from "../../calls/Proposals/editProposal";
import {getInterventionFileById} from "../../calls/Interventions/getInterventionFileById";
import {omit} from "lodash";

export default function useCreateProposal() {
    const companyId = useActiveCompanyId();

    return useMutation(async (data: CreateProposalFormModel) => {
        const { thirdParty: { name: thirdPartyName, id: thirdPartyId }, visiteTechnique } = data;

        const lines: ProposalLineDto[] = data.rows.map(
            l => ({
                label: l.product?.label || '',
                desc: l.description || '',
                qty: l.qty,
                fk_product: l.product?.id || '',
                tva_tx: l.tvaTx,
                subprice: l.priceWithoutTax,
                product_type: l.product?.productType,
                special_code: l.specialCode,
                fk_unit: l.product?.unitOfMeasureId,
                rang: l.rank,
                array_options: {
                    tx_mo: l.extraFields?.tauxMO || 0,
                    tps_pose: l.extraFields?.tpsPose || 0,
                    cout_fournitures: l.extraFields?.coutFournitures || 0,
                    excluded_from_total_ht: l?.excludeFromTotal ? 1 : 0,
                }
            })
        );

        const header: ProposalDtoWithLines = {
            // model_pdf: customer.docTemplate?.id || '',
            socid: +data.thirdParty.id,
            fk_project: +(data.thirdParty?.projectId || 0),
            entity: +companyId,
            date: DateTime.now().toSeconds(),
            duree_validite: data.periodOfValidity,
            cond_reglement_id: Number(data.paymentConditions || '0'),
            array_options: {
                cout_revient_mo: data.extraFields.coutRevientPersonnel,
                coeff_marge_mo: data.extraFields.margePersonnel,
                prix_vente_mo: data.extraFields.coutVentePersonnel,
                coeff_marge_fo: data.extraFields.margeFourniture,
                ...(visiteTechnique && ({
                    linked_vt: JSON.stringify(omit(visiteTechnique, "photoToitureBase64")) // On ne garde pas le base64 si jamais il existe
                })),
                ...(visiteTechnique?.photoToitureBase64 && {
                    photo_toiture_vt: visiteTechnique?.photoToitureBase64,
                }),
                puissance_pv: data.extraFields.puissancePV,
                reduction_co2: data.extraFields.reductionCO2,
            },
            note_private: data.privateNotes,
            note_public: data.publicNotes,
            lines,
        };

        // Création du proposal lié au tiers
        const proposalId = await createProposalWithLines(header);

        // On valide directe le devis après la création
        const validatedProposal = await validateProposal(proposalId);
        const { ref: proposalRef } = validatedProposal;

        // On va build ensuite le document associé
        await buildDocument({
            modulepart: 'propal',
            langcode: 'fr_FR',
            original_file: proposalRef,
        });

        // On success du build on lance un call pour download le fichier
        const { content: filecontent } = await downloadDocument({
            modulepart: 'propal',
            original_file: `${proposalRef}/${proposalRef}.pdf`,
            entity: +companyId,
        });

        // On success du download en base64 on upload le contenu et on est bon
        // const filename = `${proposalRef}_${docTemplate}.pdf`;
        const filename = `${proposalRef}.pdf`;
        const subdir = `${thirdPartyName} (${thirdPartyId})`; // ex. Marcel Pierre (26)

        await uploadDocumentToEcm({
            filename,
            subdir,
            filecontent,
            overwriteifexists: 1, // On overwrite ?
        });

        // Attachement de la VT
        if (visiteTechnique?.photoId) {
            const photoToiture = await getInterventionFileById(visiteTechnique.id!, visiteTechnique!.photoId!);
            await editProposal(proposalId, {
                array_options: {
                    ...header.array_options,
                    photo_toiture_vt: photoToiture.base64Content,
                    linked_vt: JSON.stringify(visiteTechnique)
                }
            })
        }

        return validatedProposal;
    });
}