import {Pagination} from "../types";
import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {CardCommentSearchResult} from "../../models/kanban/card.model";
import {Endpoint} from "../endpoint.enum";

interface Payload {
    cardId: number;
}

export async function searchCardComments(payload: Payload, pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const { data } = await http.post<PaginatedResponse<CardCommentSearchResult>>(Endpoint.searchCardComments, payload, {
        params: pagination
    });

    return data;
}