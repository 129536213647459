import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";

export interface GetInvoiceAmountByMonthResult {
    type: any[]
    mode: string
    data: [string, number, any, any][]
    title: string
    cssprefix: string
    width: string
    height: string
    MaxValue: number
    MinValue: number
    SetShading: number
    horizTickIncrement: number
    SetNumXTicks: number
    labelInterval: number
    hideXGrid: boolean
    hideXValues: boolean
    hideYGrid: boolean
    Legend: number[]
    LegendWidthMin: number
    showlegend: number
    showpointvalue: number
    showpercent: number
    combine: number
    graph: any
    mirrorGraphValues: boolean
    tooltipsTitles: any
    tooltipsLabels: any
    error: string
    bordercolor: number[]
    bgcolor: number[]
    bgcolorgrid: number[]
    datacolor: number[][]
    borderwidth: number
    YLabel: string
}


export async function getInvoiceAmountByMonth() {
    const { data } = await http.post<GetInvoiceAmountByMonthResult>(Endpoint.statsGetInvoiceAmountByMonth, {}, {
        headers: {
            'Accept-Language': 'fr-FR,fr',
        }
    });

    return data;
}