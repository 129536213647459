import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type EcmFile = {
    filename: string;
    relativename: string;
    bucket: string;
    date: number;
    size: number;
}

export async function listThirdPartyFiles(id: string | number) {
    const { data } = await http.post<{ items: EcmFile[], itemsCount: number }>(Endpoint.filesSearch, { thirdPartyId: id }, {
        params: {
            sortfield: 'date',
            sortorder: 'DESC'
        }
    });

    return data;
}