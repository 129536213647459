import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar, IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useEffect, useMemo, useState} from "react";
import {useAvailableFoiresSearch} from "./hooks/useAvailableFoiresSearch";
import {ActionComm} from "../../../models/action-comm.model";
import {DateTime} from "luxon";
import LoadingDots from "../../LoadingDots";
import {alertCircleOutline, alertCircleSharp} from "ionicons/icons";
import { debounce } from 'lodash';
import {SearchbarChangeEventDetail} from "@ionic/core";
import {IonSearchbarCustomEvent} from "@ionic/core/dist/types/components";

type SearchFoiresProps = {
    onFoireSelected(f: ActionComm): void,
    onCancel?(): void,
}

export default function SearchFoires({ onFoireSelected, onCancel }: SearchFoiresProps) {
    const [q, setQ] = useState("");

    const changeHandler = (event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>) => {
        setQ(event.detail.value as string);
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(changeHandler, 400)
        , []);

    // Stop the invocation of the debounced function
    // after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        }
    }, []);

    const { data, isLoading: loading } = useAvailableFoiresSearch(q);

    const foires = data?.items || [];

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Chercher une foire
                    </IonTitle>
                    <IonButtons slot="primary">
                        <IonButton color="primary" fill={"clear"} onClick={onCancel}>
                            Fermer
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        value={q}
                        onIonChange={debouncedChangeHandler}
                        placeholder={"Rechercher"}
                    />
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {
                    loading && (
                        <IonItem lines={"none"}>
                            <IonText color={'medium'} style={{ marginRight: 5 }}>
                                <span>Recherche en cours</span>
                            </IonText>
                            <LoadingDots />
                        </IonItem>
                    )
                }

                {
                    !loading && foires.length === 0 && (
                        <IonText style={{ display: 'flex' }} className={'ion-align-items-center ion-margin-top'}>
                            <IonIcon md={alertCircleSharp} ios={alertCircleOutline} />
                            <span style={{ marginLeft: 5 }}>Pas de foires trouvées.</span>
                        </IonText>
                    )
                }

                {
                    !loading && foires.length > 0 && (
                        <IonList>
                            {
                                foires.map(
                                    f => (
                                        <FoireItem
                                            key={f.id}
                                            foire={f}
                                            onFoireSelected={onFoireSelected}
                                        />
                                    )
                                )
                            }
                        </IonList>
                    )
                }
            </IonContent>
        </IonPage>
    )
}

function FoireItem({ foire, onFoireSelected }: { foire: ActionComm, onFoireSelected(f: ActionComm): void,}) {
    const start = DateTime.fromSeconds(foire.datep, { zone: "Europe/Paris" }).setLocale('fr');
    let end;

    if (foire.datef) {
        end = DateTime.fromSeconds(foire.datef, { zone: "Europe/Paris" }).setLocale('fr');
    }

    const datePeriodTxt = [start, end]
        .filter(d => !!d)
        .map((d) => d!.toLocaleString(DateTime.DATE_SHORT))
        .join(' - ');

    return (
        <IonItem button onClick={() => onFoireSelected(foire)}>
            <IonLabel>
                <h2>{foire.label}</h2>
                <h3>{datePeriodTxt}</h3>
            </IonLabel>
        </IonItem>
    )
}