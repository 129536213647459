import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {ShowFileDto} from "../../models/uploaded-file.model";

export async function getInterventionFileById(id: string, fileId: string): Promise<ShowFileDto> {
    const url = Endpoint.interventionFileById
        .replace(':id', id)
        .replace(':fileId', fileId);

    const {data} = await interventionHttp.get<ShowFileDto>(url);

    return data;
}
