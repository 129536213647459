import {useParams} from "react-router";
import React from "react";
import AffaireShow from "../components/AffaireDetail/AffaireShow";

export default function AffaireDetailPage() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams<{ id: string }>();

    return (
        <AffaireShow id={id} />
    )
}
