import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {AllInterventionStatus, Intervention, InterventionType} from "../../../models/intervention.model";
import {InterventionProposalDto} from "../types";

export interface EditVisitetechniqueDto {
    technician?: any,
    date?: string;
    duration?: number;
    desiredInstallationDate?: string;
    particularConditions?: string;
    observations?: string;
    proposal?: InterventionProposalDto,
    cadastre?: {
        commune: string;
        section: string;
        parcelle: string;
        prefixe: string;
        contenance: string;
    }
}

export async function editVisiteTechnique(id: string, dto: EditVisitetechniqueDto = {}) {
    const url = Endpoint.editVisiteTechnique.replace(':id', id);

    const {data} = await interventionHttp.post<Intervention>(url, dto);

    if (data.status === AllInterventionStatus.SCHEDULED) {
        if (data.type === InterventionType.POSE) {
            // await sendPoseScheduledMail(data.id, data.proposal.id!);
        }

        if (data.type === InterventionType.SAV) {
            // await sendSavScheduledMail(data.id, data.proposal.id!);
        }
    }

    return data;
}
