import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useRef} from "react";
import {useMeetListRequirementsDialogContext} from "../MeetListRequirementsDialog";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {attachSharp, closeCircleOutline, closeCircleSharp} from "ionicons/icons";
import * as blobUtils from "blob-util";
import {Button, Stack} from "@mui/material";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import {FileToUploadDto, interventionAttachFiles} from "../../../../calls/Interventions/attachInterventionFiles";
import {useMutation} from "@tanstack/react-query";

type FormModel = {
    attachments: Array<{
        description: string;
        tag: string;
        pickedFile: {
            filename: string;
            contentType: string;
            base64Content: string;
        }
    }>;
}

export default function CardAttachmentsContent() {
    const { cardId, onClose, requirements } = useMeetListRequirementsDialogContext();

    const methods = useForm<FormModel>();

    const { mutateAsync, isLoading } = useMutation((dtos: FileToUploadDto[]) => {
       return interventionAttachFiles(cardId, dtos);
    });

    const onSubmit = async (data: FormModel) => {
        const filesDto: FileToUploadDto[] = data.attachments.map(
            a => ({
                filename: a.pickedFile.filename,
                contentType: a.pickedFile.contentType,
                base64Content: a.pickedFile.base64Content,
                tag: a.tag,
                description: a.description,
            })
        );

        await mutateAsync(filesDto);
        onClose('ok');
    }

    const attachments = (requirements?.attachments || []);

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Pièces jointes</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {/*<IonText>*/}
                {/*    <p>Fichiers acceptés png, jpeg, pdf, word, excel</p>*/}
                {/*</IonText>*/}

                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <IonList lines="full">
                            {
                                attachments.map(
                                    (a, i) => (
                                        <AttachmentItem
                                            key={`AttachmentItem-${i}`}
                                            name={`attachments.${i}`}
                                            code={a.code}
                                            label={a.label}
                                            accept={a.accept}
                                        />
                                    )
                                )
                            }
                        </IonList>

                        <Stack spacing={5} direction={"row"} alignItems={"center"} justifyContent={"flex-end"} sx={{ mt: 5 }}>
                            <Button type={"button"} size={"small"} onClick={() => onClose('cancel')}>
                                Annuler
                            </Button>

                            <Button type={"submit"} variant="contained" size={"small"} disabled={isLoading} startIcon={<FileUploadRoundedIcon />}>
                                Soumettre
                            </Button>
                        </Stack>
                    </form>
                </FormProvider>
            </IonContent>
        </>
    )
}

interface AttachmentItemProps {
    name: string;
    code: string;
    label: string;
    accept?: string
    isUploading?: boolean;
}

function AttachmentItem({ name, code, label, accept, isUploading }: AttachmentItemProps) {
    const prefixer = (txt: string) => `${name}.${txt}`;

    return (
        <IonItem>
            <Controller
                name={prefixer('tag')}
                defaultValue={code}
                render={
                    ({ field }) => (
                        <input
                            type="hidden"
                            value={field.value}
                        />
                    )
                }
            />

            <Controller
                name={prefixer('description')}
                defaultValue={label}
                rules={{
                    required: "Description obligatoire"
                }}
                render={
                    ({ field }) => (
                        <IonInput
                            value={field.value}
                            onIonChange={field.onChange}
                            placeholder={"Description de la pièce jointe"}
                        />
                    )
                }
            />

            <Controller
                name={prefixer('pickedFile')}
                defaultValue={null}
                rules={{
                    required: "Pièce jointe obligatoire"
                }}
                render={
                    ({ field: { value, onChange } }) => {
                        if (isUploading) {
                            return (
                                <IonSpinner color="primary" />
                            );
                        }

                        if (value) {
                            return (
                                <IonButton slot="end" shape="round" fill="clear" onClick={() => onChange(null)}>
                                    <IonIcon slot={'end'} md={closeCircleSharp} ios={closeCircleOutline} />
                                    {value.filename}
                                </IonButton>
                            );
                        }

                        return (
                            <FilePickerItem
                                accept={accept}
                                onFileChange={onChange}
                            />
                        );
                    }
                }
            />

        </IonItem>
    )
}

interface FilePickerItemProps {
    accept?: string;
    onFileChange?(result: { filename: string, contentType: string, base64Content: string }): void;
}

const defaultAccept = 'image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

function FilePickerItem({ accept, onFileChange }: FilePickerItemProps) {
    const ref = useRef();

    const pickFile = () => {
        (ref.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const contentType = file.type;
        const base64Content = await blobUtils.blobToBase64String(file);

        onFileChange && onFileChange({
            filename,
            contentType,
            base64Content,
        });
    };

    return (
        <>
            <IonButton slot="end" shape="round" fill="outline" onClick={() => pickFile()}>
                <IonIcon slot={'start'} md={attachSharp} ios={attachSharp} />
                Joindre fichier
            </IonButton>

            <input
                ref={ref as any}
                accept={accept || defaultAccept}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
            />
        </>
    )
}