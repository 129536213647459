import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    Grid,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useCallback, useRef} from "react";
import {useQuery} from "@tanstack/react-query";
import LoadingDots from "../LoadingDots";
import {downloadOutline, downloadSharp, reloadOutline, reloadSharp} from "ionicons/icons";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {fetchTableauCommission} from "../../calls/Reports/fetchTableauCommission";
import {searchFoires} from "../../calls/searchFoires";
import {Controller, FormProvider, useForm, useWatch} from "react-hook-form";
import {keyBy} from "lodash";
import Toolbar from "@mui/material/Toolbar";

export default function TableauCommission() {
    const methods = useForm({
        defaultValues: {
            "filters": {},
            "configuration": {
                "responsable": {
                    "tauxDebloque": 0,
                    "tauxSiAbsent": 0,
                    "taux1": 0,
                    "taux2": 0,
                    "taux3": 0,
                    "taux4": 0
                },
                "vendeur": {
                    "tauxDebloque": 70,
                    "tauxSiSeul": 5,
                    "tauxSiPiqueur": 4
                },
                "rabatteur": {
                    "tauxDebloque": 100,
                    "taux": 1
                },
                "vt": {
                    "tauxDebloque": 100,
                    "taux": 1
                }
            }
        }
    });

    const { isFetching, data: response, refetch } = useQuery(['tableauCommission'], () => {
        const payload = methods.getValues();
        return fetchTableauCommission(payload)
    }, { enabled: false });

    const rows = response?.data.rows || [];

    console.log(rows)

    const tableRef = useRef();

    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Tableau Commission</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonCol>
            </IonRow>

            <FormProvider {...methods}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Typography color="h5" gutterBottom>
                            Configuration
                        </Typography>

                        <Stack direction={"column"} justifyContent={"space-between"} spacing={.5} sx={{ width: '100%' }}>
                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Responsable
                                    </Typography>

                                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 2, width: '100%' }}>
                                        {
                                            responsablesInputs.map(
                                                i => (<ConfigurationGenericTextField key={i.id} name={i.id} label={i.label} />)
                                            )
                                        }
                                    </Stack>

                                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 2, width: '100%' }}>
                                        {
                                            responsablesInputs2.map(
                                                i => (<ConfigurationGenericTextField key={i.id} name={i.id} label={i.label} />)
                                            )
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Vendeur
                                    </Typography>

                                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 2 }}>
                                        {
                                            vendeursInputs.map(
                                                i => (<ConfigurationGenericTextField key={i.id} name={i.id} label={i.label} />)
                                            )
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Rabatteur
                                    </Typography>

                                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 2 }}>
                                        {
                                            rabatteurInputs.map(
                                                i => (<ConfigurationGenericTextField key={i.id} name={i.id} label={i.label} />)
                                            )
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>

                            <Card>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        VT
                                    </Typography>

                                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 2 }}>
                                        {
                                            vtInputs.map(
                                                i => (<ConfigurationGenericTextField key={i.id} name={i.id} label={i.label} />)
                                            )
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>


                        <Stack direction={"row"} alignItems={"center"} justifyContent={"end"} sx={{ mt: 2, mb: 2 }} spacing={1}>
                            <LoadReportButton onClick={() => refetch()} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Card>
                            <CardContent>
                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <FoireAutocomplete />

                                    <LoadReportButton onClick={() => refetch()} />
                                </Stack>
                            </CardContent>
                        </Card>

                        {
                            (!isFetching && !response) && (
                                <Box sx={{ p: 5, textCenter: 'center', width: '100%' }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Veuillez procéder à la configuration des taux dans la partie de gauche et cliquer ensuite sur CHARGER
                                    </Typography>
                                </Box>
                            )
                        }

                        {
                            isFetching && (
                                <Box sx={{ p: 5 }}>
                                    <LoadingDots />
                                </Box>
                            )
                        }

                        {
                            !!response && (
                                <Paper sx={{ width: '100%', mt: 2 }}>
                                    <Toolbar
                                        sx={{
                                            pl: { sm: 2 },
                                            pr: { xs: 1, sm: 1 },
                                            bgcolor: 'white'
                                        }}
                                    >
                                        <ExportExcelButton tableRef={tableRef} disabled={isFetching} />
                                    </Toolbar>

                                    <TableContainer component={Paper} sx={{mt: 1, minHeight: 400, maxHeight: 650 }} elevation={2}>
                                        <StyledTable ref={tableRef as any} sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        cols.map(
                                                            col => (
                                                                <TableCell key={col.id}><b>{col.label}</b></TableCell>
                                                            )
                                                        )
                                                    }
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    rows.map((row: any) => (
                                                        <TableRow key={row.reference}>
                                                            {
                                                                cols.map(
                                                                    col => {
                                                                        const value = (row as any)[col.id];

                                                                        // switch (col.id) {
                                                                        //     case "proposalDate":
                                                                        //         return <DateCell key={col.id} value={value} />;
                                                                        //
                                                                        //     case "validityEnd":
                                                                        //         return <DateCellIso key={col.id} value={value} />;
                                                                        //
                                                                        //     case "totalHT":
                                                                        //     case "vatAmount":
                                                                        //     case "totalTTC":
                                                                        //     case "pvHtReel":
                                                                        //     case "prixAchat":
                                                                        //     case "prixPose":
                                                                        //         return <PriceCell key={col.id} value={value} />;
                                                                        //
                                                                        //     case "etatAffaire":
                                                                        //         return <StatusCell key={col.id} value={value} />;

                                                                        return (
                                                                            <TableCell key={`${row.reference}-${col.id}`}>
                                                                                {`${value || ''}`.trim()}
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </StyledTable>
                                    </TableContainer>
                                </Paper>
                            )
                        }
                    </Grid>
                </Grid>
            </FormProvider>

        </IonGrid>
    )
}

function FoireAutocomplete() {
    const name = 'filters.foire';

    const watchedValue = useWatch({ name, defaultValue: '' });

    const { isLoading, data } = useQuery(['foires'], () => searchFoires({ label: watchedValue }), {
        // enabled: watchedValue.length > 1,
        staleTime: 20 * 1000,
    });

    const options = (data?.data || [])
        .map(
            (o: { label: string, id: string }) => ({ label: o.label, id: o.id })
        )
    ;

    const optionsRecord = keyBy(options, 'id');

    return (
        <Controller
            name={name}
            defaultValue={null}
            render={
                ({ field: { onChange, value } }) => {
                    return (
                        <Autocomplete
                            disablePortal
                            size={"small"}
                            id="combo-box-foire"
                            options={options}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} disabled={isLoading} label="Sélectionner une foire (optionnel)" />}
                            noOptionsText={"Foire non trouvé"}
                            value={value ? optionsRecord[value] : null}
                            onChange={
                                (e, newValue: { label: string, id: string } | null, reason) => onChange(newValue?.id)
                            }
                        />
                    )
                }
            }
        />
    )
}

function ConfigurationGenericTextField({ name, label }: { name: string, label: string }) {
    const realName = `configuration.${name}`;
    const id = `${realName}-textField`;

    return (
        <Controller
            name={realName}
            render={
                ({ field }) => {
                    return (
                        <FormControl fullWidth>
                            <FormLabel htmlFor={id}>{label}</FormLabel>

                            <TextField
                                id={id}
                                type={"number"}
                                variant="outlined"
                                size={"small"}
                                value={field.value}
                                onChange={e => field.onChange(+e.target.value)}
                            />
                        </FormControl>
                    )
                }
            }
        />
    )
}

const responsablesInputs = [
    { id: 'responsable.tauxDebloque', label: 'Taux débloqué' },
    { id: 'responsable.taux1', label: 'Taux 1' },
    { id: 'responsable.taux2', label: 'Taux 2' },
];

const responsablesInputs2 = [
    { id: 'responsable.taux3', label: 'Taux 3' },
    { id: 'responsable.taux4', label: 'Taux 4 et plus' },
    { id: 'responsable.tauxSiAbsent', label: 'Taux si absent' },
];

const vendeursInputs = [
    { id: 'vendeur.tauxDebloque', label: 'Taux débloqué', },
    { id: 'vendeur.tauxSiSeul', label: 'Taux si seul', },
    { id: 'vendeur.tauxSiPiqueur', label: 'Taux si piqueur', },
];

const rabatteurInputs = [
    { id: 'rabatteur.tauxDebloque', label: 'Taux débloqué', },
    { id: 'rabatteur.taux', label: 'Taux rabatteur', },
];

const vtInputs = [
    { id: 'vt.tauxDebloque', label: 'Taux débloqué', },
    { id: 'vt.taux', label: 'Taux VT', },
];

function LoadReportButton({ onClick }: { onClick: any }) {
    return (
        <IonButton disabled={false} fill={"outline"} style={{ marginLeft: 20 }} onClick={onClick}>
            <IonIcon slot="start" md={reloadSharp} ios={reloadOutline}></IonIcon>
            Charger
        </IonButton>
    )
}

function ExportExcelButton({ tableRef, disabled }: { tableRef: any, disabled?: boolean }) {
    const handleExport = useCallback(() => {
        const workbook = XLSX.utils.book_new();

        workbook.Props = {
            Title: "Tableaucommission",
            Subject: "Tableaucommission",
            Author: "AMDP",
            CreatedDate: new Date(),
        }

        const worksheet = XLSX.utils.table_to_sheet(tableRef.current);

        XLSX.utils.book_append_sheet(workbook, worksheet, "Tableaucommission");

        /* generate a binary string */
        const bin = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });
        saveAs(new Blob([bin]), `Tableaucommission_${Date.now()}.xlsx`);
    }, [tableRef]);

    return (
        <IonButton disabled={disabled} fill={"outline"} style={{ marginLeft: 'auto' }} onClick={() => handleExport()}>
            <IonIcon slot="start" md={downloadSharp} ios={downloadOutline}></IonIcon>
            Export Excel
        </IonButton>
    )
}

const cols = [
    { id: 'reference', label: "Référence" },
    { id: 'proposalDate', label: "Date" },
    { id: 'foire', label: "Ville Foire" },
    { id: 'financement', label: "Financement" },
    { id: "responsable", label: "Responsable", },
    { id: "txResp", label: "TX Resp.", },
    { id: "comResp", label: "Com. Resp.", },
    { id: "responsable2", label: "Responsable 2", },
    { id: "txResp2", label: "Tx Resp. 2", },
    { id: "comResp2", label: "Com. Resp. 2", },
    { id: "responsable3", label: "Responsable 3", },
    { id: "txResp3", label: "Tx Resp. 3", },
    { id: "comResp3", label: "Com. Resp. 3", },
    { id: "responsable4", label: "Responsable 4", },
    { id: "txResp4", label: "Tx Resp. 4", },
    { id: "comResp4", label: "Com. Resp. 4", },
    { id: 'margeTheorique', label: "Marge théorique" },
    { id: "observations", label: "Observations" },
    { id: 'customerName', label: "Client" },
    { id: 'totalTTC', label: "Total TTC" },
    { id: 'totalHT', label: "Total HT" },
    { id: 'agios', label: "Agios" },
    { id: "pvHtReel", label: "PV HT Réel" },
    { id: "prixAchat", label: "Prix d'achat" },
    { id: "prixPose", label: "Prix pose" },
    { id: "titreDeLaPiece", label: "Titre de la pièce" },
    { id :"vendeur", label: "Vendeur" },
    { id :"txVendeur", label: "Tx com" },
    { id :"comVendeur", label: "% com" },
    { id :"percentComVendeur", label: "Comission" },
    { id :"vendeur2", label: "Vendeur 2" },
    { id :"txVendeur2", label: "Tx com" },
    { id :"comVendeur2", label: "% com" },
    { id :"percentComVendeur2", label: "Comission" },
    { id: "piqueur", label: "Piqueur", },
    { id: "txPiqueur", label: "Tx com", },
    { id: "comPiqueur", label: "Comission", },
    { id: "piqueur2", label: "Piqueur 2", },
    { id: "percentPiqueur2", label: "Tx com", },
    { id: "comPiqueur2", label: "Comission", },
    { id: "vt", label: "VT", },
    { id: "txVt", label: "Tx com", },
    { id: "comVt", label: "Comission", },
    { id: "etatAffaire", label: "Etat affaire" },
    { id: "absent", label: "Nom Absent 1", },
    { id: "txAbsent", label: "Taux Absent 1", },
    { id: "comAbsent", label: "Com absent 1", },
    { id: "absent2", label: "Nom Absent 2", },
    { id: "txAbsent2", label: "Taux Absent 2", },
    { id: "comAbsent2", label: "Com absent 2", },
    { id: "absent3", label: "Nom Absent 3", },
    { id: "txAbsent3", label: "Taux Absent 3", },
    { id: "comAbsent3", label: "Com absent 3", },
    { id: "absent4", label: "Nom Absent 4", },
    { id: "txAbsent4", label: "Taux Absent 4", },
    { id: "comAbsent4", label: "Com absent 4", },
    { id: "totalCommission", label: "Total com", },
];

export const StyledTable = styled(Table)`
    & th, tr {
        white-space: nowrap;
    }
`;

