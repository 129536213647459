import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList, IonProgressBar, IonSearchbar,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {searchThirdParties} from "../../calls/ThirdParties/searchThirdParties";

export default function SearchThirdPartyModal({ onDismiss, }: { onDismiss: (data?: any | null, role?: string) => void; }) {
    const [inputValue, setInputValue] = useState<string>("");

    const { data, isLoading, isFetching } = useQuery(["searchThirdParty", inputValue], () => searchThirdParties({ name: inputValue }, { sortorder: 'DESC', limit: 20 }), {
        // only fetch search terms longer than 2 characters
        enabled: inputValue.length > 2,
        // refresh cache after 10 seconds (watch the network tab!)
        staleTime: 10 * 1000
    });

    const thirdParties = data?.items || [];

    const loading = isLoading && isFetching;

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Rechercher un tiers</IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="medium" onClick={() => onDismiss(null, 'cancel')}>
                            Fermer
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar
                        placeholder={'Rechercher un tiers'}
                        debounce={1000}
                        onIonChange={(e) => setInputValue(e.detail.value as string)}
                    />
                    { loading && <IonProgressBar type="indeterminate"/> }
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                {
                    isLoading ? (
                        <></>
                    ) : (
                        <IonList lines="full">
                            {
                                thirdParties.map(
                                    t => {
                                        const addressText = [
                                            t.address,
                                            t.zip,
                                            t.town,
                                        ].filter(i => !!i).join(', ');

                                        return (
                                            <IonItem key={t.id} detail={true} button onClick={() => onDismiss(t, 'confirm')}>
                                                <IonLabel>
                                                    <h3>{t.name}</h3>
                                                    <p>{addressText}</p>
                                                </IonLabel>
                                            </IonItem>
                                        )
                                    }
                                )
                            }
                        </IonList>
                    )
                }
            </IonContent>
        </>
    )
}