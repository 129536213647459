import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {isEmpty, isNil, omitBy} from 'lodash';
import {Pagination} from "../types";
import {PaginatedResponse} from "../../models/common.model";
import {ThirdParty} from "../../models/third-party.model";

export type SearchThirdPartiesPayload = {
    id?: number | string;

    /**
     * Set to 1 to show only customers
     * Set to 2 to show only prospects
     * Set to 3 to show only those are not customer neither prospect
     * Set to 4 to show only suppliers
     */
    type?: number | string;

    name?: string | number;
    address?: string | number;
    zip?: string | number;
    town?: string | number;
    code?: string | number;
    email?: string | number;
    phone?: string | number;
}

export async function searchThirdParties(payload: SearchThirdPartiesPayload | null, pagination: Pagination = { sortorder: 'DESC', limit: 500 }) {
    const dto = omitBy(payload, value => isNil(value) || isEmpty(value));

    const { data } = await http.post<PaginatedResponse<ThirdParty>>(Endpoint.thirdParties, dto, {
        params: {
            sortfield: 't.rowid',
            ...pagination,
        }
    });

    return data;
}