import React, {useEffect, useRef} from 'react'
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {IonContent} from "@ionic/react";
import fr from '@fullcalendar/core/locales/fr'
import {CalendarOptions} from "@fullcalendar/react";
import {useEventsQuery} from "./useEventsQuery";
import {DateTime} from "luxon";
import {searchInterventions, SearchInterventionsPayload} from "../../calls/Interventions/searchInterventions";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {PaginationResultModel} from "../../models/common.model";
import {Intervention, interventionUI} from "../../models/intervention.model";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default function EventCalendar() {
    return (
        <IonContent fullscreen className={"ion-padding"}>
            <PowerCalendar
                locale={fr}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ]}
                initialView="dayGridMonth"
                contentHeight={'87vh'}
            />
        </IonContent>
    )
}

/**
 * Obligé d'implémenter comme ça car la version react ne se rend pas correctement pas
 * @param props
 * @constructor
 */
function PowerCalendar(props: CalendarOptions) {
    const ref = useRef(null);

    const { data, isLoading } = useEventsQuery({
        foireOnly: true,
    }, { page: 0, limit: 500 });

    const foires = (data?.items || []).map(
        f => {
            const start = DateTime.fromSeconds(f.datep, { zone: "Europe/Paris" }).setLocale('fr').toISO();
            const end = f.datef ? DateTime.fromSeconds(f.datef, { zone: "Europe/Paris" }).setLocale('fr').toISO() : ""

            return  {
                id: f.id,
                title: (f.label + ' ' + f.location).trim(),
                start,
                end,
            }
        }
    );

    const { interventions = [], isLoading: interventionsLoading } = useAgendaInterventionsQuery({
        dateStart: DateTime.now().startOf("year").setLocale('fr').toISO(),
        dateEnd: DateTime.now().endOf("year").setLocale('fr').toISO(),
    });

    useEffect(() => {
        setTimeout(() => {
            const el = ref.current;

            // https://fullcalendar.io/docs/initialize-globals
            const calendar = new Calendar(el as any, {
                ...props,
                eventDidMount: function(info) {
                    // var tooltip = new Tooltip(info.el, {
                    //     title: info.event.extendedProps.description,
                    //     placement: 'top',
                    //     trigger: 'hover',
                    //     container: 'body'
                    // });

                    const description = info.event.extendedProps.description;

                    if (!description) {
                        return;
                    }

                    tippy(info.el, {
                        content: description,
                    });
                },
                events: [
                    ...foires,
                    ...interventions,
                ]
            });

            calendar.render();
        })
    }, [foires, interventions]);

    return (<div id={"calendar"} ref={ref}></div>)
}

function useAgendaInterventionsQuery(payload: SearchInterventionsPayload = {}) {
    const queryKey = ['agenda', 'interventions', payload];
    const queryFn: QueryFunction<PaginationResultModel<Intervention>> = () => {
        return searchInterventions(payload);
    }

    const { isLoading, data } = useQuery(queryKey, queryFn, {
        select(data) {
            return data.docs.map(
                doc => {
                    const description = (interventionUI.typeTranslation as any)[doc.type];

                    return {
                        id: doc.id,
                        title: doc.customer.name,
                        description: `${doc.customer.name} (${description})`,
                        start: doc.date,
                    }
                }
            )
        }
    });

    return {
        interventions: data,
        isLoading,
    }
}