// @ts-ignore
import * as rt from 'react-trello/dist/styles/Base';

// @ts-ignore
import Tag from 'react-trello/dist/components/Card/Tag';
import {Box, Stack, styled, Typography} from "@mui/material";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import React from "react";

const {
    CardHeader,
    CardRightContent,
    CardTitle,
    Detail,
    Footer,
    MovableCardWrapper
} = rt;

export default function InterventionOfSavCard(props: any) {
    const {
        showDeleteButton,
        style,
        tagStyle,
        onClick,
        onDelete,
        onChange,
        className,
        id,
        title,
        label,
        description,
        tags,
        cardDraggable,
        alert: CardAlert,
        alertProps,
        error,
        editable,
        notes,
        t
    } = props;

    return (
        <StyledMovableCardWrapper
            data-id={id}
            onClick={onClick}
            style={style}
            className={`${className} ${error ? 'error' : ''}`.trim()}
        >
            <CardHeader>
                <CardTitle draggable={cardDraggable}>
                    {title}
                </CardTitle>
                <CardRightContent>
                    {label}
                </CardRightContent>
                {/*{showDeleteButton && <DeleteButton onClick={this.onDelete} />}*/}
            </CardHeader>

            <Detail style={{ marginBottom: 5 }}>
                {description}

                {
                    notes && (
                        <Box sx={{ mt: 1 }}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <InfoRoundedIcon fontSize={"small"} color={"error"}/>
                                <Typography variant={"caption"} color={"error"}>
                                    {notes}
                                </Typography>
                            </Stack>
                        </Box>
                    )
                }
            </Detail>

            {tags && tags.length> 0 && (
                <Footer>
                    {tags.map((tag: any) => (
                        <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
                    ))}
                </Footer>
            )}
        </StyledMovableCardWrapper>
    )
}

const StyledMovableCardWrapper = styled(MovableCardWrapper)`
    &.error {
        border: solid 2px red;
    }
`;