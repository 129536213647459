import { PaymentsListContext } from "../../Payment/usePaymentsListContext";
import {usePaymentsQuery} from "../../Payment/usePaymentsQuery";
import {useThirdPartyBottomDrawerState} from "../useThirdPartyBottomDrawerStore";
import PaymentTable from "../../Payment/PaymentTable";

export default function PaymentTab() {
    const { currentThirdPartyId: thirdPartyId } = useThirdPartyBottomDrawerState();

    const query = usePaymentsQuery({ thirdPartyId: Number(thirdPartyId) });

    if (query.isLoading) {
        return <>Chargement ...</>
    }

    if (query.isError) {
        return (<>Pas de règlements trouvés pour ce tiers</>)
    }

    return (
        <PaymentsListContext.Provider value={{ query }}>
            <PaymentTable />
        </PaymentsListContext.Provider>
    )
}