import {FormProvider, useForm} from "react-hook-form";
import Paper from "@mui/material/Paper";
import {IonItem, IonLabel, IonToolbar} from "@ionic/react";
import React from "react";
import EventForm from "./EventForm";

const CreateFoirePage = () => {
    const methods = useForm();

    return(
        <FormProvider{...methods}>
            <Paper>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Nouvelle Foire</h1>
                            <h3>Création d'une nouvelle foire</h3>
                        </IonLabel>
                    </IonItem>
                </IonToolbar>

                <EventForm />
            </Paper>
        </FormProvider>
    )
}

export default CreateFoirePage;