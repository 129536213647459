import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {CreateInterventionDto} from "../types";
import {VisiteTechnique} from "../../../models/intervention.model";

export interface CreateVisiteTechinqueDto extends CreateInterventionDto {
    duration?: number;
    desiredInterventionDate?: string;
    particularConditions?: string;
    observations?: string;
}

export async function createVisiteTechnique(dto: CreateVisiteTechinqueDto) {
    const {data} = await interventionHttp.post<VisiteTechnique>(Endpoint.createVisiteTechnique, dto);

    return data;
}