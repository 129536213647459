import React from 'react';
import ThirdPartyForm from "./ThirdPartyForm";
import {Box, Paper, Stack, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {ThirdPartyFormModel} from "./Form/types";
import {useUser} from "../Auth/auth.store";
import {useActiveCompanyId} from "../Company/company.store";
import {useMutation} from "@tanstack/react-query";
import {createThirdParty, CreateThirdPartyDto} from "../../calls/ThirdParties/createThirdParty";
import {useHistory} from "react-router";
import {DevTool} from "@hookform/devtools";
import FormDefaultActions from "../Common/Form/FormDefaultActions";

function CreateThirdPartyPage() {
    const methods = useForm<ThirdPartyFormModel>({
        defaultValues: {
            isBusiness: true, // Gestion des tiers en mode PRO par défaut
        }
    });

    const user = useUser();
    const companyId = useActiveCompanyId();

    const { mutateAsync, isLoading } = useMutation(
        (dto: Omit<CreateThirdPartyDto, 'entity'>) => createThirdParty({
            ...dto,
            entity: +companyId,
        })
    );

    let history = useHistory();

    const onSubmit = async ({ isBusiness, name, manager, commercial, ...data }: ThirdPartyFormModel) => {
        const commercialIds = !commercial ? Number(user.id) : Number(commercial.id);

        const { thirdpartyId } = await mutateAsync({
            name: !name ? `${data.firstName} ${data.lastName}`: name,
            civility_id: isBusiness ? manager.civility : data.civility, // civilité pour le contact par défaut
            name_bis: isBusiness ? manager.name : data.lastName,
            firstname: isBusiness ? manager.firstName : data.firstName,
            email: data.email,
            phone: data.phone,
            client: data.type.toString(),
            address: `${data.address.street || ''} ${data.address.route || ''}`.trim(),
            zip: data.address.postalCode,
            town: data.address.city,
            commercial_id: commercialIds, // l'id commercial
            typent_id: 8, // 8 = particulier = on demande à créer le contact également
            array_options: {
                civilite: data.civility,
                personal_mobile_phone: data.personalMobilePhone,
                business_mobile_phone: data.businessMobilePhone,
                lat: data.address.lat || 0,
                lng: data.address.lng || 0,
            }
        });

        // Redirection après succès mutation
        history.push(`/page/ThirdParties/${thirdpartyId}`);
    }

    return (
        <Paper elevation={6} style={{ width: 650 }}>
            <Stack direction={"column"} justifyContent={"start"} p={2}>
                <Typography variant="h5">
                    Nouveau Tiers
                </Typography>

                <Typography variant="caption">
                    Création d'une nouvelle fiche
                </Typography>
            </Stack>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <Box p={2}>
                        <ThirdPartyForm />
                    </Box>

                    <Box p={2} mt={1}>
                        <FormDefaultActions
                            saveButtonText="Créer tiers"
                            loading={isLoading}
                            onCancel={() => history.push("/page/ThirdParties")}
                        />
                    </Box>
                </form>
            </FormProvider>

            <DevTool control={methods.control} />
        </Paper>
    );
};

export default CreateThirdPartyPage;