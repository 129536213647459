import React from "react";

export interface UsePowerTablePaginationHelperReturn {
    page: number;
    rowsPerPage: number;
    offset: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onRowsPerPageChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    resetPage(): void;
}

export function usePowerTablePaginationHelper(): UsePowerTablePaginationHelperReturn {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const options = React.useMemo(
        () => ({
            page,
            rowsPerPage,
            offset: (page * rowsPerPage) + 1
        }),
        [page, rowsPerPage],
    );

    const resetPage = () => setPage(0);

    const onPageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return {
        ...options,
        onPageChange,
        onRowsPerPageChange,
        resetPage,
    }
}