// https://dribbble.com/shots/20174752-Kirrivan-CRM-Lead-Details
// https://dribbble.com/shots/20128537-Kirrivan-CRM-Lead-Preview

import {useInvalidateThirdPartyDetail, useThirdPartyDetail} from "./useThirdPartyDetail";
import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import InterventionsCard from "./InterventionsCard";
import PaymentCard from "./PaymentCard";
import Page from "../../pages/Page";
import ThirdPartyItem from "./ThirdPartyItem";
import AffaireCard from "./AffaireCard";
import DocumentCard from "./DocumentCard";
import ThirdPartyContactItem from "./ThirdPartyContactItem";
import {checkmarkOutline, checkmarkSharp, pencilOutline, pencilSharp} from "ionicons/icons";
import useToggleOpen from "../Common/useToggleOpen";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Civility} from "../../valueObjects/Civility";
import React, {useState} from "react";
import AddressFormGroup from "../AddressFormGroup";
import {ThirdParty} from "../../models/third-party.model";
import {useMutation} from "@tanstack/react-query";
import {updateThirdParty} from "../../calls/ThirdParties/updateThirdParty";
import ContactsCard from "./ContactsCard";

export default function ThirdPartyShow({ id }: { id: string }) {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    /// let { id } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: thirdParty } = useThirdPartyDetail(id);

    if (isError) {
        return <>Error</>;
    }

    if (isLoading && !isError) {
        return <></>;
    }

    return (
        <Page>
            <IonContent>
                <IonRow>
                    <IonCol size={'8'}>
                        {/*<IonToolbar>*/}
                        {/*    <IonButtons slot="start">*/}
                        {/*        <IonBackButton />*/}
                        {/*    </IonButtons>*/}
                        {/*</IonToolbar>*/}

                        <IonCard style={{ background: 'white' }}>
                            <IonCardContent>
                                <IonGrid style={{ padding: 0 }}>
                                    <IonRow>
                                        <IonCol>
                                            <ThirdPartyItem id={id} />
                                        </IonCol>
                                        <IonCol>
                                            <ThirdPartyContactItem id={id} />
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>

                                <EditThirdPartyBlock id={id} />
                            </IonCardContent>
                        </IonCard>

                        <AffaireCard id={id} />

                        <InterventionsCard id={id} thirdParty={thirdParty} />
                    </IonCol>

                    <IonCol size={'4'}>
                        <ContactsCard thirdPartyId={id} />

                        <PaymentCard id={id} />

                        {/*<TemplatesCard thirdPartyId={id} />*/}

                        <DocumentCard id={id} />
                    </IonCol>
                </IonRow>
            </IonContent>
        </Page>
    )
}

function EditThirdPartyBlock({ id }: { id: string }) {
    const { isError, isFetched, isLoading, data: thirdParty } = useThirdPartyDetail(id);

    const { open, show, hide } = useToggleOpen();

    return (
        <>
            <div style={{ display: 'flex', width: '100%', justifyContent: "end" }}>
                <IonButton size="small" fill="clear" onClick={() => show()}>
                    <IonIcon slot="start" md={pencilSharp} ios={pencilOutline} />
                    Modifier
                </IonButton>
            </div>

            {
                (!isLoading && thirdParty) && (
                    <Modal isOpen={open} hide={hide} thirdParty={thirdParty} />
                )
            }
        </>
    )
}

type FormModel = {
    customer: {
        civility: string,
        name: string,
        firstName: string,
        personalMobilePhone: string,
        businessMobilePhone: string,
        phone: string,
        email: string,
    }
}

function Modal({ isOpen, hide, thirdParty }: { isOpen: boolean, hide(): void, thirdParty: ThirdParty }) {
    const fullName = [thirdParty?.firstname, thirdParty?.name].filter(part => !!part).join(' ');

    const [segment, setSegment] = useState<'main' | 'address'>('main');

    return (
        <IonModal isOpen={isOpen} style={{ '--width': '35%' }} keepContentsMounted={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{fullName}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => hide()}>Fermer</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonSegment value={segment} onIonChange={e => setSegment(e.detail.value as 'main')}>
                    <IonSegmentButton value="main">
                        <IonLabel>Général</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="address">
                        <IonLabel>Adresse</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {
                    segment === 'main' && (
                        <MainForm thirdParty={thirdParty} onSave={() => hide()} />
                    )
                }

                {
                    segment === 'address' && (
                        <EditAddressForm thirdParty={thirdParty} onSave={() => hide()} />
                    )
                }
            </IonContent>
        </IonModal>
    )
}

function MainForm({ thirdParty, onSave }: { thirdParty: ThirdParty, onSave(): void }) {
    const phone1 = thirdParty?.array_options?.options_personal_mobile_phone || "";
    const phone2 = thirdParty?.array_options?.options_business_mobile_phone || "";

    const methods = useForm<FormModel>({
        defaultValues: {
            customer: {
                civility: thirdParty?.array_options?.options_civilite || '',
                name: thirdParty?.name || '',
                firstName: thirdParty?.name_alias || '',
                personalMobilePhone: phone1,
                businessMobilePhone: phone2,
                phone: thirdParty?.phone || '',
                email: thirdParty?.email || '',
            },
        }
    });

    const { mutateAsync } = useMutation((data: FormModel["customer"]) => {
        return updateThirdParty(thirdParty.id, {
            civility_id: data.civility,
            name: data.name,
            name_bis: data.firstName,
            lastname: data.name,
            firstname: data.firstName,
            email: data.email,
            phone: data.phone,
            array_options: {
                civilite: data.civility,
                personal_mobile_phone: data.personalMobilePhone,
                business_mobile_phone: data.businessMobilePhone,
            }
        })
    });

    const invalidateThirdParty = useInvalidateThirdPartyDetail(thirdParty.id);

    const onSubmit = async ({ customer }: FormModel) => {
        await mutateAsync(customer);
        await invalidateThirdParty();
        onSave();
    }

    return (
        <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Civilité</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (
                                        <IonSelect value={field.value} onIonChange={field.onChange} cancelText={"Fermer"}>
                                            {
                                                Civility.supportedCivilities().map(
                                                    civility => (
                                                        <IonSelectOption key={civility.code} value={civility.code}>
                                                            {civility.label}
                                                        </IonSelectOption>
                                                    )
                                                )
                                            }
                                        </IonSelect>
                                    )
                                }}
                                name={"customer.civility"}
                                // rules={{ required: "Civilité client obligatoire" }}
                                defaultValue={'MME'}
                            />
                        </IonItem>
                    </IonCol>

                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Nom</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput placeholder="Nom" value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.name"}
                                rules={{ required: "Nom client obligatoire" }}
                            />
                        </IonItem>
                    </IonCol>

                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Prénom</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput placeholder="Prénom" value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.firstName"}
                                // rules={{ required: "Prénom client obligatoire" }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Téléphone Mr.</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput type={'tel'} placeholder="Téléphone Mr." value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.personalMobilePhone"}
                                // rules={{ required: "Téléphone Mr. obligatoire" }}
                            />
                        </IonItem>
                    </IonCol>

                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Téléphone Mme</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput type={'tel'} placeholder="Téléphone Mme" value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.businessMobilePhone"}
                            />
                        </IonItem>
                    </IonCol>

                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Tél. Fixe</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput type={'tel'} placeholder="Tél. Fixe" value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.phone"}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel position="floating">Email</IonLabel>
                            <Controller
                                render={({ field, fieldState, formState, }) => {
                                    return (<IonInput type={'email'} placeholder="Email" value={field.value} onIonChange={field.onChange} />)
                                }}
                                name={"customer.email"}
                                rules={{ required: "Email client obligatoire" }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <div style={{ padding: 5, display: 'flex', justifyContent: 'end' }}>
                    <IonButton type={"submit"} fill="solid">
                        <IonIcon slot="start" md={checkmarkSharp} ios={checkmarkOutline} />
                        Enregistrer
                    </IonButton>
                </div>
            </form>
        </FormProvider>
    )
}

type AddressForm = {
    customer: {
        billingAddressIsSameAsDefaultAddress: boolean;
    }
    address: {
        street: string;
        route: string;
        postalCode: string;
        city: string;
        lat: number;
        lng: number;
    }
}

function EditAddressForm({ thirdParty, onSave }: { thirdParty: ThirdParty, onSave(): void }) {
    const methods = useForm<AddressForm>();

    const { mutateAsync } = useMutation((address: AddressForm["address"]) => {
        return updateThirdParty(thirdParty.id, {
            name: thirdParty.name,
            address: `${address.street || ''} ${address.route || ''}`.trim(),
            zip: address.postalCode,
            town: address.city,
            array_options: {
                lat: address.lat || 0,
                lng: address.lng || 0,
            }
        })
    });

    const invalidateThirdParty = useInvalidateThirdPartyDetail(thirdParty.id);

    const onSubmit = async ({ address, customer }: AddressForm) => {
        await mutateAsync(address);
        await invalidateThirdParty();
        onSave();
    }

    return (
        <FormProvider {...methods}>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <AddressFormGroup label={"Nouvelle Adresse"} />

                {/*<ShippingAddressForm label={"Nouvelle Adresse de facturation"} />*/}

                <div style={{ padding: 5, display: 'flex', justifyContent: 'end' }}>
                    <IonButton type={"submit"} fill="solid" onClick={() => {}}>
                        <IonIcon slot="start" md={checkmarkSharp} ios={checkmarkOutline} />
                        Enregistrer
                    </IonButton>
                </div>
            </form>
        </FormProvider>
    )
}