import {TablePagination} from "@mui/material";
import React from "react";

interface PowerTablePaginationProps {
    /**
     * The zero-based index of the current page.
     */
    page?: number;

    /**
     * The number of rows per page.
     *
     * Set -1 to display all the rows.
     */
    rowsPerPage?: number;

    /**
     * The total number of rows.
     *
     * To enable server side pagination for an unknown number of items, provide -1.
     */
    totalRowsCount?: number;

    /**
     * Callback fired when the page is changed.
     *
     * @param {React.MouseEvent<HTMLButtonElement> | null} event The event source of the callback.
     * @param {number} page The page selected.
     */
    onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;

    /**
     * Callback fired when the number of rows per page is changed.
     *
     * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event The event source of the callback.
     */
    onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export default function PowerTablePagination(props: PowerTablePaginationProps) {
    const {
        page = 0,
        rowsPerPage = 20,
        totalRowsCount = 0,
        onPageChange,
        onRowsPerPageChange,
    } = props;

    return (
        <TablePagination
            rowsPerPageOptions={[20, 100, 250, 500, 1000]}
            component="div"
            count={totalRowsCount}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={"Nb de lignes"}
            page={page}
            onPageChange={(...args) => onPageChange && onPageChange(...args)}
            onRowsPerPageChange={(...args) => onRowsPerPageChange && onRowsPerPageChange(...args)}
            labelDisplayedRows={({ from, to, count }) => {
                return `Ligne ${from} à ${to} sur ${count} au total`;
            }}
        />
    )
}