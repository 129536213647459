export enum InstructionCode {
    INST_CI = 'INST_CI',
    INST_AS = 'INST_AS',
    INST_VT = 'INST_VT',
    INST_PLVT = 'INST_PLVT',
    INST_MRE = 'INST_MRE',
    INST_CE = 'INST_CE',
    INST_FCMES = 'INST_FCMES',
    INST_CHQ = 'INST_CHQ',
    INST_SOF = 'INST_SOF',
}

export const instructions: Array<{ code: InstructionCode, description: string, actionLabel: string }> = [
    {
        "code": InstructionCode.INST_CI,
        "description": "Compte rendu d'installation",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_AS,
        "description": "Attestation Simplifiée (X2)",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_VT,
        "description": "Visite Technique",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_PLVT,
        "description": "Problèmes liés à la visite du technicien",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_MRE,
        "description": "Mandat de representation enedis (si My Light)",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_CE,
        description: "Certificat d'etancheite (si PAC R/R)",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_FCMES,
        description: "Fiche controle mise en service airwell (si PAC R/R)",
        "actionLabel": "À faire signer"
    },
    {
        "code": InstructionCode.INST_CHQ,
        description: "Chèque du solde",
        "actionLabel": "Prendre le règlement"
    },
    {
        "code": InstructionCode.INST_SOF,
        description: "Financement SOFINCO",
        "actionLabel": "Faire signer le bordereau"
    },
]
