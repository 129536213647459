import React from "react";
import {Button, Menu, MenuItem, Typography} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {BoardSearchResult} from "../../models/kanban/board.model";
import {useBoardStore} from "./board.store";

interface BoardSwitchButtonProps {
    label: string;

    availableBoards: BoardSearchResult[];
}

export default function BoardSwitchButton({ label, availableBoards }: BoardSwitchButtonProps) {
    const [currentBoardId, setCurrentBoardId] = useBoardStore(state => [state.currentBoardId, state.setCurrentBoardId]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (boardId: number) => {
        setCurrentBoardId(boardId);
        handleClose();
    }

    return (
        <>
            <Button
                id="board-switch-button"
                aria-controls={open ? 'board-select-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color={"inherit"}
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    textTransform: 'capitalize'
                }}
            >
                <Typography variant="h5">
                    {label}
                </Typography>
            </Button>

            <Menu
                id="board-select-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    availableBoards.map(
                        (board, index) => (
                            <MenuItem key={`Board-${board.id}`} onClick={() => handleMenuClick(+board.id)} disabled={currentBoardId === +board.id}>
                                {board.title}
                            </MenuItem>
                        )
                    )
                }
            </Menu>
        </>
    )
}