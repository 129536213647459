import {IonApp, IonContent, IonPage, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import Page from './pages/Page';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';

import ThirdPartyList from "./components/ThirdParty/ThirdPartyList";
import AffaireList from "./components/Affaire/AffaireList";
import InterventionList from "./components/Intervention/InterventionList";
import DashboardPage from "./components/Dasboard/DashboardPage";
import ProductList from "./components/Product/ProductList";
import CreateThirdPartyPage from "./components/ThirdParty/CreateThirdPartyPage";
import CreateInterventionPage from "./components/Intervention/CreateInterventionPage";
import CreatePaymentPage from "./components/Payment/CreatePaymentPage";
import NewContract from "./components/Contracts/NewContract";
import CreateFoirePage from "./components/Agenda/CreateFoirePage";
import LoginPage from "./pages/Login";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import AccountingPage from "./pages/AccountingPage";
import AgendaPage from "./pages/AgendaPage";
import AppBar from "./components/Common/AppBar";
import AffaireDetailPage from "./pages/AffaireDetailPage";
import InterventionDetail from "./pages/InterventionDetail";
import ReportsPage from "./pages/ReportsPage";
import ThirdPartyDetail from "./pages/ThirdPartyDetail";
import KanbanPage from "./pages/KanbanPage";
import ConfirmationDialog from "./components/Common/Confirmation/ConfirmationDialog";
import {useUserRights} from "./components/Auth/auth.store";
import CreateProposal from "./components/Proposals/CreateProposal";
import EditProposal from "./components/Proposals/EditProposal";
import NewThirdPartyProposalPage from "./pages/NewThirdPartyProposalPage";


setupIonicReact();

const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path="/" exact={true}>
                        <Redirect to="/page" />
                    </Route>

                    <Route path="/Login" exact={true}>
                        <LoginPage />
                    </Route>

                    <ProtectedRoute path="/page">
                        <PageNestedRoutes />
                    </ProtectedRoute>
                </IonRouterOutlet>
            </IonReactRouter>

            <ConfirmationDialog />
        </IonApp>
    );
};

export default App;

function PageNestedRoutes() {
    const match = useRouteMatch();

    const url = (path: string) => `${match.path}/${path}`;

    const rights = useUserRights();

    return (
        <IonPage>
            <AppBar />

            <IonContent style={{ margingTop: '50px' }}>
                <IonRouterOutlet>
                    <ProtectedRoute path={match.path} exact={true}>
                        {
                            rights?.amdpsales?.intervention.intervention_only ? (
                                <Redirect to={url("Interventions")} />
                            ) : (
                                <Redirect to={url("ThirdParties")} />
                            )
                        }
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Dashboard")} exact={true}>
                        <Page>
                            <DashboardPage/>
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("ThirdParties")} exact={true}>
                        <Page>
                            <ThirdPartyList />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("ThirdParties/:id")} exact={true}>
                        <Page>
                            <ThirdPartyDetail />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("ThirdParties/:id/NewProposal")} exact={true}>
                        <NewThirdPartyProposalPage />
                    </ProtectedRoute>


                    <ProtectedRoute path={url("Affaires")} exact={true}>
                        <Page>
                            <AffaireList />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Affaires/:id")} exact={true}>
                        <AffaireDetailPage />
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewContract")} exact={true}>
                        <Page>
                            <NewContract />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewProposal")} exact={true}>
                        <Page>
                            <CreateProposal />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Proposal/:id")} exact={true}>
                        <Page>
                            <EditProposal />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Interventions")} exact={true}>
                        <Page>
                            <InterventionList />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Interventions/:id")} exact={true}>
                        <InterventionDetail />
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Products")} exact={true}>
                        <Page>
                            <ProductList />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewThirdParty")} exact={true}>
                        <Page>
                            <CreateThirdPartyPage />
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewIntervention")} exact={true}>
                        <Page>
                            <CreateInterventionPage/>
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewPayment")} exact={true}>
                        <Page>
                            <CreatePaymentPage/>
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("NewEvent")} exact={true}>
                        <Page>
                            <CreateFoirePage/>
                        </Page>
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Accounting")}>
                        <AccountingPage />
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Agenda")}>
                        <AgendaPage />
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Reports")}>
                        <ReportsPage />
                    </ProtectedRoute>

                    <ProtectedRoute path={url("Tableaux")}>
                        <KanbanPage />
                    </ProtectedRoute>
                </IonRouterOutlet>
            </IonContent>
        </IonPage>
    )
}