import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonNote,
    IonRadio,
    IonRadioGroup,
    IonRow
} from "@ionic/react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import styled from "@emotion/styled";
import React, {useState} from "react";
import {logoEuro} from "ionicons/icons";
import currency from "currency.js";
import {InstallationProductType} from "../models";
import {normalizeFormValues, sumNormalizedLines} from "../utils";

export default function ModePaiementItem() {
    const title = 'Mode de paiement';

    // const products = useWatch({
    //     name: "mode-paiement.products",
    //     defaultValue: [],
    // });

    return (
        <Card title={title} />
    )
}

const productKeys = [...Object.keys(InstallationProductType), 'installation'];

type CardProps = {
    title: string,
}

const percentIcon = '/assets/icon/percent.svg';

function Card({ title }: CardProps) {
    const {watch} = useFormContext();

    const [total, setTotal] = useState({
        ht: currency(0),
        montantTva: currency(0),
        ttc: currency(0)
    });

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type);

            // Si le champs qui change n'est pas filter on ne fait rien
            // on refresh la liste uniquement quand c'est filter qui change
            const keyIndex = productKeys.findIndex(k => (name || '').startsWith(k));
            if (keyIndex < 0) {
                return;
            }

            const results = normalizeFormValues(value as any);

            // Récupération de toutes les lignes pour avoir la somme totale
            const { ht, montantTva, ttc } = sumNormalizedLines(Object.values(results).flat());

            setTotal({ ht, montantTva, ttc })
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const [cardOpen, setCardOpen] = useState(false);

    const pacAirEauActions = useWatch({ name: 'pacAirEau.actions', defaultValue: '' });
    const isReleveChaudiere = pacAirEauActions === 'RELEVE';

    return (
        <IonCard style={{ border: '2px solid var(--ion-color-mode-paiement)' }}>
            <IonCardHeader style={{ display: 'flex', background: 'var(--ion-color-mode-paiement)' }} className={"ion-align-items-center"}>
                <div>
                    <IonCardTitle style={{ color: 'white' }}>{title}</IonCardTitle>
                </div>

                <IonButton fill="outline" style={{ marginLeft: 'auto' }} color={'light'} onClick={() => setCardOpen(v => !v)}>
                    {cardOpen ? 'Fermer' : 'Ouvrir'}
                </IonButton>
            </IonCardHeader>

            <IonCardContent style={{ paddingTop: 12 }}>
                <IonGrid>
                    {
                        cardOpen && (
                            <>
                                {
                                    !isReleveChaudiere && (
                                        <IonRow>
                                            <IonCol>
                                                <Item>
                                                    <IonLabel position={"floating"}>CEE :</IonLabel>
                                                    <Controller
                                                        name={"modePaiement.montant_cee"}
                                                        render={({ field: { value, onChange } }) => {
                                                            return (
                                                                <IonInput type={'number'} placeholder="Montant CEE €" value={value} onIonChange={onChange} />
                                                            )
                                                        }}
                                                    />
                                                </Item>
                                            </IonCol>

                                            <IonCol>
                                                <Item>
                                                    <IonLabel position={"floating"}>MA PRIME RENOV’ :</IonLabel>
                                                    <Controller
                                                        name={"modePaiement.montant_maprimerenov"}
                                                        render={({ field: { value, onChange } }) => {
                                                            return (
                                                                <IonInput type={'number'} placeholder="Montant MA PRIME RENOV’" value={value} onIonChange={onChange} />
                                                            )
                                                        }}
                                                    />
                                                </Item>
                                            </IonCol>
                                        </IonRow>
                                    )
                                }

                                <IonRow>
                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>VENTE A CREDIT :</IonLabel>
                                            <Controller
                                                name={"modePaiement.vente_credit"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Nb de mois" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>DIFFÉRÉS DE :</IonLabel>
                                            <Controller
                                                name={"modePaiement.nb_differe"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Nombre de différés" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>PRIX AU COMPTANT :</IonLabel>
                                            <Controller
                                                name={"modePaiement.prix_comptant"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Prix au comptant" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>TEAG :</IonLabel>
                                            <Controller
                                                name={"modePaiement.teag"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="TEAG" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>VERSEMENT INITIAL :</IonLabel>
                                            <Controller
                                                name={"modePaiement.versement_initial"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Versement initial" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>MONTANT MENSUALITE :</IonLabel>
                                            <Controller
                                                name={"modePaiement.montant_mensualite"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Montant mensualité" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>NOMBRE MENSUALITE :</IonLabel>
                                            <Controller
                                                name={"modePaiement.nb_mensualite"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Nombre mensualité" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>COÛT TOTAL :</IonLabel>
                                            <Controller
                                                name={"modePaiement.cout_total"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Coût total" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>
                                </IonRow>

                                <Controller
                                    name={"modePaiement.autre_moyen_paiement"}
                                    render={({ field: { value, onChange } }) => {
                                        return (
                                            <IonRadioGroup value={value} onIonChange={({ detail }) => onChange(detail.value)}>
                                                <IonRow>
                                                    <IonCol size={"4"}>
                                                        <Item lines={"none"}>
                                                            <IonLabel>MOYENS DE PAIEMENT : AU COMPTANT :</IonLabel>
                                                        </Item>
                                                    </IonCol>

                                                    <IonCol>
                                                        <Item>
                                                            <IonRadio slot="start" color={'mode-paiement'} value="ESPECES" />
                                                            <IonLabel>ESPÈCE</IonLabel>
                                                        </Item>
                                                    </IonCol>

                                                    <IonCol>
                                                        <Item>
                                                            <IonRadio slot="start" color={'mode-paiement'} value="CHEQUE" />
                                                            <IonLabel>CHÈQUE</IonLabel>
                                                        </Item>
                                                    </IonCol>
                                                </IonRow>
                                            </IonRadioGroup>
                                        )
                                    }}
                                />

                                <IonRow>
                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>ACOMPTE DE</IonLabel>
                                            <Controller
                                                name={"modePaiement.acompte"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Acompte en %" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                            <IonIcon icon={percentIcon} slot="end" />
                                            <IonNote slot="helper" className="ion-text-wrap">
                                                A REMETTRE AU CONSEILLER APRES EXPIRATION DE DELAI DE REFLEXION PREVU A L’ARTICLE L121-17 DU CODE DE LA CONSOMMATION
                                            </IonNote>
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>DU MONTANT DE L'ACHAT SOIT</IonLabel>
                                            <Controller
                                                name={"modePaiement.montant_acompte"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Montant de l'achat" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <Item>
                                            <IonLabel position={"floating"}>SOLDE ÉGALE À</IonLabel>
                                            <Controller
                                                name={"modePaiement.solde"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Solde en %" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                            <IonIcon icon={percentIcon} slot="end" />
                                        </Item>
                                    </IonCol>

                                    <IonCol>
                                        <Item>
                                            <IonCheckbox slot="start" color={'mode-paiement'} />
                                            <IonLabel position={"floating"}>DU MONTANT DE L’ACHAT, SOIT :</IonLabel>
                                            <Controller
                                                name={"modePaiement.montant_solde"}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <IonInput type={'number'} placeholder="Montant de l'achat" value={value} onIonChange={onChange} />
                                                    )
                                                }}
                                            />
                                        </Item>
                                    </IonCol>
                                </IonRow>
                            </>
                        )
                    }

                    <TotalRow>
                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>HT</IonNote>
                                <IonInput placeholder="TOTAL HT" readonly value={total.ht.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>
                                    TVA
                                </IonNote>
                                <IonInput placeholder="TVA" readonly value={total.montantTva.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>

                        <IonCol>
                            <IonItem lines={"none"}>
                                <IonNote slot={'start'}>TTC</IonNote>
                                <IonInput placeholder="TOTAL TTC" readonly value={total.ttc.toString()} />
                                <IonIcon icon={logoEuro} />
                            </IonItem>
                        </IonCol>
                    </TotalRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    )
}

const TotalRow = styled(IonRow)`
    padding: 0;
    
    ion-col {
        padding: 0;
    }

    ion-col ion-item {
        font-weight: bold;
    }
    
    ion-col:first-of-type ion-item {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    
    ion-col:not(last-child) ion-item {
        --background: #ccddd6;
    }

    ion-col:last-child {
        --background: var(--ion-color-mode-paiement);
    }

    ion-col:last-child ion-note {
        color: white;
    }
    
    ion-col:last-child ion-item {
        --background: var(--ion-color-mode-paiement);
    }

    ion-col:last-child ion-item {
        border-radius: 8px;
    }
    
    ion-col ion-input {
        background: white;
        border-radius: 8px;
        margin-top: 6px;
        margin-bottom: 6px;
        --padding-start: 8px!important;
        font-weight: bold;
        font-size: 1.2em;
    }

    ion-icon {
        color: white;
    }
`;

const Item = styled(IonItem)`
    &.item-interactive.ion-focused, &.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-mode-paiement);
    }

    &.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
        color: var(--ion-color-mode-paiement);
    }
`;

