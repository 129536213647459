import {IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToolbar} from "@ionic/react";
import {addOutline, addSharp, filterOutline, filterSharp} from "ionicons/icons";
import EventTable from "./EventTable";
import FilterDrawer from "../Common/FilterDrawer";
import {useEventsQuery} from "./useEventsQuery";
import {EventsListContext} from "./useEventsListContext";
import SearchEvent from "./SearchEvent";
import {FormProvider} from "react-hook-form";
import {useEventFilterForm} from "./useEventFilterForm";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import PowerTablePagination from "../Common/Table/PowerTablePagination";
import React from "react";
import {Paper} from "@mui/material";

export default function FoireList() {

    const { filters, applyFilters, resetFilters, methods } = useEventFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const query = useEventsQuery({
        ...filters,
        foireOnly: true,
    }, { page, limit: rowsPerPage });

    const itemsCount = query?.data?.pagination?.itemsCount || 0;

    const contextValue = {
        query,
    };

    return(
        <FormProvider {...methods}>
            <EventsListContext.Provider value={contextValue}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Foires</h1>
                            <h3>Lister et organiser les Foires</h3>
                        </IonLabel>
                        <IonButtons slot={"end"}>
                            <FiltersButton/>
                            <NewButton/>
                        </IonButtons>
                    </IonItem>
                </IonToolbar>

                <Paper style={{ marginTop: 5 }}>
                    <EventTable eventType={"foire"} />

                    <PowerTablePagination
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalRowsCount={itemsCount}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Paper>

                <FilterDrawer
                    loading={query.isLoading}
                    onApply={() => {
                        resetPage();
                        applyFilters()
                    }}
                    onReset={() => resetFilters()}
                >
                    <SearchEvent />
                </FilterDrawer>
            </EventsListContext.Provider>
        </FormProvider>

    )
}

function NewButton() {
    return (
        <IonButton fill={"solid"} color={"primary"} size={"default"} routerLink={'/page/NewEvent'} routerDirection={"root"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            Nouvelle
        </IonButton>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}