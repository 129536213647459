import {InterventionType, interventionUI} from "../../models/intervention.model";
import {IonChip} from "@ionic/react";
import React from "react";

export default function InterventionTypeChip({ type }: { type?: InterventionType }) {
    if (!type) {
        return <></>;
    }

    const label = interventionUI.typeTranslation[type];
    const color = interventionUI.typeColorMapping[type];

    return <IonChip color={color || 'medium'}>{label || type}</IonChip>;
}
