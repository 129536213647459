import AddSalesForceButton from "./AddSalesForceButton";

export default function AddRabatteurButton() {
    return (
        <AddSalesForceButton
            name={'salesForce.rabatteurs'}
            label={'Rabatteurs / Téléconseillers'}
            placeholder={'Attacher Rabatteurs / Téléconseillers'}
            multipleSelectionOnSearch={true}
        />
    )
}