import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {searchAffaire} from "../../../../calls/Affaire/searchAffaire";
import {useQuery} from "@tanstack/react-query";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Proposal} from "../../../../models/proposal.model";
import useSearchProposalTemplateDialogStore from "./useSearchProposalTemplateDialogStore";
import {ProposalLineFormModel} from "../../../Proposals/ProposalForm/types";
import {Skeleton} from '@mui/material';

interface SearchProposalTemplatesDialogProps {
    onClose?(lines: ProposalLineFormModel[]): void;
}

export default function SearchProposalTemplatesDialog({ onClose }: SearchProposalTemplatesDialogProps) {
    const { isOpen, closeModal } = useSearchProposalTemplateDialogStore();

    const { data, isLoading, } = useQuery(['proposal', 'templates'], () => searchAffaire({
        isTemplate: true,
    }));

    const proposals = data?.items || [];

    const noProposals = !isLoading && proposals.length === 0;

    const [selectedProposal, setSelectedProposal] = useState<Proposal | null>(null);

    const handleSelection = () => {
        if (!selectedProposal || !selectedProposal.lines || (selectedProposal.lines && selectedProposal.lines.length === 0)) {
            return;
        }

        const lines = selectedProposal.lines;

        onClose && onClose(
            lines.map(
                line => {
                    return {
                        id: line.id,
                        product: {
                            id: line.fk_product,
                            label: line.product_label,
                            unitOfMeasureId: line.fk_unit,
                            productType: Number(line.product_type || 0),
                        },
                        description: line.description || line.label,
                        qty: Number(line.qty),
                        priceWithoutTax: Number(line.subprice),
                        discount: line.remise,
                        tvaTx: Number(line.tva_tx),
                        specialCode: Number(line.special_code),
                        rank: Number(line.rang),
                        blockAutoRecalculation: false,
                        excludeFromTotal: line.array_options?.options_excluded_from_total_ht === "1",
                        extraFields: {
                            tpsPose: Number(line.array_options?.options_tps_pose || 0),
                            tauxMO: Number(line.array_options?.options_tx_mo || 0),
                            coutFournitures: Number(line.array_options?.options_cout_fournitures || 0),
                        },
                    }
                }
            )
        );

        closeModal();
    };

    return (
        <Dialog onClose={() => closeModal()} open={isOpen}>
            <DialogTitle>Copiez les lignes depuis</DialogTitle>
            <DialogContent>
                {
                    isLoading ? (
                        <React.Fragment>
                            <Typography variant="subtitle1" component="p">
                                <Skeleton width={"100%"} />
                            </Typography>

                            <Typography variant="subtitle2" component="p">
                                <Skeleton width={"80%"} />
                            </Typography>

                            <Typography variant="caption" component="p">
                                <Skeleton width={"45%"} />
                            </Typography>
                        </React.Fragment>
                    ) : (
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="buttons-group-proposal-templates"
                                name="proposal-templates"
                            >
                                {
                                    noProposals && (
                                        <Typography variant="caption" component="p">
                                            Pas de model de devis trouvé
                                        </Typography>
                                    )
                                }

                                {
                                    proposals.map(
                                        p => {
                                            return (
                                                <FormControlLabel
                                                    key={p.id}
                                                    checked={p.id === selectedProposal?.id}
                                                    control={<Radio />}
                                                    label={
                                                        <>
                                                            <Typography variant="subtitle1" component="p">
                                                                {p.ref}
                                                            </Typography>

                                                            <Typography variant="subtitle2" component="p">
                                                                {p.array_options?.options_proposal_template_description || "--"}
                                                            </Typography>

                                                            <Typography variant="caption" component="p">
                                                                {p.lines?.length || 0} ligne(s)
                                                            </Typography>
                                                        </>
                                                    }
                                                    onChange={
                                                        () => {
                                                            setSelectedProposal(p)
                                                        }
                                                    }
                                                />
                                            )
                                        }
                                    )
                                }
                            </RadioGroup>
                        </FormControl>
                    )
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={() => closeModal()}>
                    Annuler
                </Button>

                <Button onClick={handleSelection} variant={"contained"} disabled={!selectedProposal} autoFocus>
                    Copier lignes
                </Button>
            </DialogActions>
        </Dialog>
    )
}