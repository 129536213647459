import create from "zustand";

interface StoreModel {
    isOpen: boolean;

    selectThirdPartyId: string;

    show(thirdPartyId: string): void;
    hide(): void;
}

export const useThirdPartyShowModalStore = create<StoreModel>((set) => ({
    isOpen: false,
    selectThirdPartyId: '',
    show: (thirdPartyId: string) => set({ isOpen: true, selectThirdPartyId: thirdPartyId }),
    hide: () => set({ isOpen: false, selectThirdPartyId: '' }),
}));