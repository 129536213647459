import ThirdPartyAutocomplete from "../../../ThirdParty/ThirdPartyAutocomplete";
import {useFormContext, useWatch} from "react-hook-form";
import {useEffect} from "react";
import {ThirdParty} from "../../../../models/third-party.model";

export default function ThirdPartyControl() {
    const label = 'Client';

    const { setValue } = useFormContext();

    const thirdParty: ThirdParty | undefined = useWatch({ name: 'thirdParty', exact: true });

    useEffect(() => {
        if (!thirdParty) {
            return;
        }

        const projectId = thirdParty.array_options?.options_related_project;
        setValue("thirdParty.projectId", projectId);
    }, [thirdParty]);

    return (
        <ThirdPartyAutocomplete label={label} />
    )
}