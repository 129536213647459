import {Pagination} from "../../calls/types";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {PaginatedResponse} from "../../models/common.model";
import {ActionComm} from "../../models/action-comm.model";
import {searchEvents, SearchEventsPayload} from "../../calls/Agenda/searchEvents";

export function useEventsQuery(payload: SearchEventsPayload = {}, pagination?: Pagination) {
    const queryKey = ['events', {payload, pagination}];
    const queryFn: QueryFunction<PaginatedResponse<ActionComm>> = () => {
        return searchEvents(payload, pagination);
    }

    return useQuery<PaginatedResponse<ActionComm>>(queryKey, queryFn);
}