import create from "zustand";

interface StoreModel {
    isOpen: boolean;

    selectedContactId: string;

    openDialog(contactId: string): void;
    closeDialog(): void;
}

export const useEditContactDialogStore = create<StoreModel>((set) => ({
    isOpen: false,
    selectedContactId: '',
    openDialog: (contactId: string) => set({ isOpen: true, selectedContactId: contactId }),
    closeDialog: () => set({ isOpen: false, selectedContactId: '' }),
}));