import {PaymentsListContext} from "../Payment/usePaymentsListContext";
import {usePaymentsQuery} from "../Payment/usePaymentsQuery";
import PaymentTable from "../Payment/PaymentTable";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon} from "@ionic/react";
import {addOutline, addSharp} from "ionicons/icons";
import React, {useState} from "react";
import {Stack} from "@mui/material";
import PaymentFormModalFromThirdParty from "../Payment/PaymentFormModalFromThirdParty";

export default function PaymentCard({ id }: { id: string }) {
    const query = usePaymentsQuery({ thirdPartyId: Number(id) });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <IonCard style={{ background: 'white' }}>
                <IonCardHeader>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <IonCardTitle>Règlements</IonCardTitle>

                        <IonButton fill="clear" expand={"block"} onClick={() => setIsOpen(true)}>
                            <IonIcon slot={"start"} ios={addOutline} md={addSharp} />
                            Saisir règlement
                        </IonButton>
                    </Stack>
                </IonCardHeader>
                <IonCardContent>
                    {

                        (query.isLoading) && (<>Chargement ...</>)
                    }

                    {
                        (query.isError) && (<>Pas de règlements trouvés pour ce tiers</>)
                    }

                    {
                        (!query.isLoading && !query.isError) && (
                            <PaymentsListContext.Provider value={{ query }}>
                                <PaymentTable sx={{ minHeight: 180 }} />
                            </PaymentsListContext.Provider>
                        )
                    }
                </IonCardContent>
            </IonCard>

            <PaymentFormModalFromThirdParty
                thirdPartyId={id}
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    query.refetch();
                }}
            />
        </>
    )
}