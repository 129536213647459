import {IonButton, IonIcon, IonPopover} from "@ionic/react";
import {businessOutline, businessSharp, chevronDownOutline, chevronDownSharp} from "ionicons/icons";
import {useActiveCompany} from "./company.store";
import {CompaniesPopoverContent} from "./CompaniesPopoverContent";

export default function CompanySwitchButton() {
    const detail = useActiveCompany();

    return (
        <>
            <IonButton fill="outline" color={"primary"} id="trigger-companies-popover">
                <IonIcon slot="start" md={businessSharp} ios={businessOutline} />
                {detail.label}
                <IonIcon slot="end" md={chevronDownSharp} ios={chevronDownOutline} />
            </IonButton>
            <IonPopover trigger="trigger-companies-popover" triggerAction="click" size="auto" showBackdrop={false} dismissOnSelect={true}>
                <CompaniesPopoverContent />
            </IonPopover>
        </>
    )
}