import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import Order from "../../models/order.model";

/**
 * Récupérer une commande
 * @param id
 */
export async function getOrder(id: number | string) {
    const url = Endpoint.order.replace(':id', `${id}`);
    const { data } = await http.get<Order>(url);

    return data;
}