import React from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonToolbar} from "@ionic/react";
import InvoicesStats from "../Reports/InvoicesStats";
import InvoicesCountRow from "../Invoices/InvoicesCountRow";
import {useSearchInvoicesQuery} from "../Invoices/useSearchInvoicesQuery";
import {idateTime} from "../../utils";
import {DateTime} from "luxon";
import {useInvoiceFilterForm} from "../Invoices/useInvoiceFilterForm";
import {FormProvider} from "react-hook-form";
import FilterDrawer from "../Common/FilterDrawer";
import SearchInvoice from "../Invoices/SearchInvoice";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {filterOutline, filterSharp} from "ionicons/icons";
import PaymentList from "../Payment/PaymentList";

export default function DashboardPage () {
    return (
        <>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonItem lines={"none"}>
                            <IonLabel>
                                <h1>Dashboard</h1>
                            </IonLabel>
                        </IonItem>
                    </IonToolbar>
                </IonHeader>

                <InvoiceCountWrapper />

                <PaymentList />

                <InvoicesStats />
            </IonContent>
        </>
    );
};

function InvoiceCountWrapper() {
    const { filters: { startDate, endDate, ...filters }, applyFilters, resetFilters, methods } = useInvoiceFilterForm();

    const period = {
        ...(startDate && {
            startDate: DateTime.fromISO(startDate),
        }),
        ...(endDate && {
            endDate: DateTime.fromISO(endDate),
        })
    }

    const query = useSearchInvoicesQuery({
        ...filters,
        ...(period.startDate && {
            startDate: idateTime(period.startDate),
        }),
        ...(period.endDate && {
            endDate: idateTime(period.endDate),
        })
    }, { page: 0, limit: 500 });

    const totalCount = query?.data?.pagination?.itemsCount || 0;
    const totalHT = query?.data?.totalAmountHT || 0;
    const totalTTC = query?.data?.totalAmountTTC || 0;
    const totalPaid = query?.data?.totalPaid || 0;
    const totalRemainToPay = query?.data?.totalRemainToPay || 0;

    return (
        <FormProvider {...methods}>
            <IonToolbar>
                <IonItem lines={"none"}>
                    <IonLabel>
                        <h1>Factures</h1>
                        <h3>
                            Liste des factures sur la période du {period.startDate ? period.startDate?.toFormat('dd/MM/yyyy') : '-'} au du {period.endDate ? period.endDate?.toFormat('dd/MM/yyyy') : '-'}
                        </h3>
                    </IonLabel>
                    <IonButtons slot={"end"}>
                        <FiltersButton/>
                    </IonButtons>
                </IonItem>
            </IonToolbar>

            <InvoicesCountRow
                totalCount={totalCount}
                totalHT={totalHT}
                totalTTC={totalTTC}
                totalPaid={totalPaid}
                totalRemainToPay={totalRemainToPay}
            />

            <FilterDrawer
                onApply={() => {
                    // resetPage();
                    applyFilters()
                }}
                onReset={() => resetFilters()}
            >
                <SearchInvoice/>
            </FilterDrawer>
        </FormProvider>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}