import create from "zustand";
import {immer} from 'zustand/middleware/immer'

interface State {
    activeStep: number;
    stepCount: number;
    stepsState: Record<string, {
       isNextEnabled?: boolean;
       isCompleted?: boolean;
    }>;
}

interface Actions {
    enableNextOnActiveStep(): void;
    handleNext(): void;
    handlePrevious(): void;
    isNextEnabledForActiveStep(): boolean;
    isActiveStepCompleted(): boolean;
}

const defaultState: State = {
    activeStep: 0,
    stepCount: 1,
    stepsState: {},
}

export const useStepper = create<State & Actions>()(
    immer(
        (set, get) => ({
            ...defaultState,
            enableNextOnActiveStep() {
                set(state => {
                    if (!state.stepsState[state.activeStep]) {
                        state.stepsState[state.activeStep] = {};
                    }

                    state.stepsState[state.activeStep].isNextEnabled = true;
                });
            },
            handleNext() {
                set((state) => {
                    if (state.activeStep < state.stepCount) {
                        state.stepsState[state.activeStep].isCompleted = true;
                        state.activeStep += 1;
                    }
                })
            },
            handlePrevious() {
                set((state) => {
                    if (state.activeStep > 0) {
                        state.activeStep -= 1;
                    }
                })
            },
            isNextEnabledForActiveStep() {
                const activeStepState = get().stepsState[get().activeStep];

                return activeStepState ? activeStepState.isNextEnabled! : false;
            },
            isActiveStepCompleted() {
                const activeStepState = get().stepsState[get().activeStep];

                return activeStepState ? activeStepState.isCompleted! : false;
            }
        })
    )
);