import {isEmpty, isNil, omitBy} from "lodash";
import {User} from "../../models/user.model";
import {PaginatedResponse} from "../../models/common.model";
import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";

export type SearchUserPayload = {
    name?: string;
    job?: string;
}

type Pagination = {
    sortfield: string,
    sortorder: 'DESC',
    limit: number,
}

export async function searchUsers (payload: SearchUserPayload = {}, pagination: Pagination = {sortfield: 't.rowid', sortorder: 'DESC', limit: 100}) {
    const  dto = omitBy(payload, v => isNil(v) || isEmpty(v));

    const { data } = await http.post<PaginatedResponse<User>>(Endpoint.users, dto, {
        params : {
            ...pagination
        }
    });

    return data;
}

