import {MenuItem, Select, Stack} from "@mui/material";
import GenericTextFieldController from "../Common/Form/GenericTextFieldController";
import {Controller} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import React from "react";

export default function ContactForm() {
    return (
        <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2}>
                <Controller
                    name={"civility"}
                    defaultValue={"MME"}
                    render={({ field: { value, onChange }, fieldState: { error } }) => {
                        return (
                            <FormControl fullWidth error={!!error}>
                                <FormLabel>Civilité</FormLabel>
                                <Select
                                    labelId="civility-simple-select-label"
                                    id="civility-simple-select"
                                    value={value}
                                    onChange={onChange}
                                    size={"small"}
                                >
                                    <MenuItem value={"MME"}>Madame</MenuItem>
                                    <MenuItem value={"M"}>Monsieur</MenuItem>
                                    <MenuItem value={"MLLE"}>Mademoiselle</MenuItem>
                                </Select>
                            </FormControl>
                        )
                    }}
                />

                <GenericTextFieldController
                    name="firstname"
                    label={"Prénom"}
                    optional
                    textFieldProps={{
                        placeholder: "Prénom du contact"
                    }}
                />

                <GenericTextFieldController
                    name="lastname"
                    label={"Nom"}
                    textFieldProps={{
                        placeholder: "Nom du contact"
                    }}
                />
            </Stack>

            <GenericTextFieldController
                name="email"
                label={"Email"}
                optional
                textFieldProps={{
                    placeholder: "Email du contact"
                }}
            />

            <GenericTextFieldController
                name="job"
                label={"Poste / Fonction"}
                optional
                textFieldProps={{
                    placeholder: "Poste / Fonction"
                }}
            />

            <Stack direction={"row"} spacing={2}>
                <GenericTextFieldController
                    name="phone"
                    label={"Tél pro."}
                    optional
                    textFieldProps={{
                        placeholder: "Tél pro."
                    }}
                />

                <GenericTextFieldController
                    name="phonePerso"
                    label={"Tél perso."}
                    optional
                    textFieldProps={{
                        placeholder: "Tél perso."
                    }}
                />

                <GenericTextFieldController
                    name="phoneMobile"
                    label={"Tél portable"}
                    optional
                    textFieldProps={{
                        placeholder: "Tél portable"
                    }}
                />
            </Stack>

            <GenericTextFieldController
                name="address"
                label={"Adresse"}
                optional
                textFieldProps={{
                    placeholder: "ex. 12 rue de la République"
                }}
            />

            <Stack direction={"row"} spacing={2}>
                <GenericTextFieldController
                    name="zip"
                    label={"Code postal"}
                    optional
                    textFieldProps={{
                        placeholder: "Code postal"
                    }}
                />

                <GenericTextFieldController
                    name="town"
                    label={"Ville"}
                    optional
                    textFieldProps={{
                        placeholder: "Ville du contact"
                    }}
                />
            </Stack>
        </Stack>
    )
}