import {InterventionCustomerDto, InterventionPlaceDto} from "./types";
import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {Intervention} from "../../models/intervention.model";

export interface EditInterventionDto {
    technician?: any,
    date?: string;
    customer?: Partial<InterventionCustomerDto>;
    place?: InterventionPlaceDto;
}

export async function editIntervention(id: string, dto: EditInterventionDto = {}) {
    const url = Endpoint.interventionId.replace(':id', id);

    const {data} = await interventionHttp.put<Intervention>(url, dto);

    return data;
}
