import {interventionHttp} from "../../../http";
import {Endpoint} from "../../endpoint.enum";
import {InterventionProposalDto} from "../types";
import {InterventionType, RdvCom} from "../../../models/intervention.model";

export interface CloneRdvCom {
    proposal?: InterventionProposalDto;
    source?: string;
    sourceType?: InterventionType;
    unsetTechnician?: boolean;
    unsetOriginalDate?: boolean;
}

export async function cloneRdvCom(id: string, dto: CloneRdvCom) {
    const url = Endpoint.cloneRdvCom.replace(':id', id);

    const {data} = await interventionHttp.post<RdvCom>(url, dto);

    return data;
}