import {DateTime} from "luxon";

/**
 * Prend un luxon DateTime et renvoi le retour
 * @return    string Date in a string YYYY-MM-DD HH:MM:SS
 * @param d
 */
export function idateTime(d: DateTime)
{
    return d.toFormat('yyyy-MM-dd HH:mm:ss');
}

/**
 *
 * Date ISO en Instance Luxon DateTime
 * @param date
 * @param format
 */
export const formatISOString = (date: string, format: string = 'dd/MM/yyyy HH:mm') => DateTime
    .fromISO(date, { zone: "Europe/Paris" })
    .setLocale('fr')
    .toFormat(format)
;

export const convertToNumber = (val?: string | number | unknown) => {
    if (!val) {
        return 0;
    }

    return Number(val.toString().replace(',', '.'));
}