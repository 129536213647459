import {ThirdParty} from "../../models/third-party.model";
import {Proposal} from "../../models/proposal.model";
import {ProductTypology} from "./types";
import {useActiveCompanyId} from "../Company/company.store";
import {CreateInterventionDto} from "../../calls/Interventions/types";
import {useUser} from "../Auth/auth.store";
import {Contact} from "../../models/contact.model";

export function useBuildCreateInterventionBaseDto() {
    const activeCompanyId = useActiveCompanyId();

    return (thirdParty: ThirdParty, proposal: Proposal): CreateInterventionDto => {
        const proposalId = proposal.id || '0';
        const thirdPartyId = thirdParty.id || '0';

        const typologies: ProductTypology[] = (proposal?.lines || [])
            .map(line => line.array_options?.options_product_typology as ProductTypology)
            .filter(e => !!e)
        ;

        const coords = [
            Number(thirdParty?.array_options?.options_lng || '0'), // Lng
            Number(thirdParty?.array_options?.options_lat || '0'), // Lat
        ];

        return {
            proposal: {
                id: proposalId,
                reference: proposal.ref,
                origin: proposal?.array_options?.options_origine_affaire,
                originId: proposal?.array_options?.options_foire_origine,
                productTypology: typologies[0],
            },
            customer: {
                id: thirdPartyId,
                name: thirdParty?.name || '',
            },
            place: {
                address: thirdParty?.address || '',
                town: thirdParty?.town || '',
                zipCode: thirdParty?.zip || '',
                coords
            },
            entity: activeCompanyId,
        }
    }
}

interface AddressOption {
    country: string;
    city: string;
    postalCode: string;
    route: string;
    street: string;
    lat: number;
    lng: number;
}

interface RdvCommThirdParty {
    id?: string;
    civility: string;
    name: string;
    lastName: string;
    firstName: string;
    email: string;
    address: {
        street: string;
        route: string;
        city: string;
        country: string;
        postalCode: string;
        lat: number;
        lng: number;
    },
    phone: string;
    companyName: string;
}

interface RdvCommBatiment {
    description?: string;
    type?: string;
    puissanceCompteur?: string;
    endroit?: string;
    superficie?: string;
    typeToiture?: string;
    toiture?: string;
    orientationToiture?: string;
    pointDeRaccordementLePlusProche?: string;
    etudeDeStructure?: string;
}

export function useBuildCreateRdvCommBaseDto() {
    const activeCompanyId = useActiveCompanyId();
    const user = useUser();

    return ({ thirdParty, batiment, contact }: { thirdParty: RdvCommThirdParty, batiment: RdvCommBatiment, contact?: Contact }): any => {
        const {
            id,
            civility,
            name,
            firstName,
            lastName,
            email,
            companyName,
            phone,
            address
        } = thirdParty;

        const {
            city,
            postalCode,
            street,
            route,
            lng, lat
        } = address;

        const coords = [
            Number(lng || '0'), // Lng
            Number(lat || '0'), // Lat
        ];

        return {
            customer: {
                id,
                civility: civility || contact?.civility_code || '',
                name: name || companyName || '',
                firstName: firstName || contact?.firstname || '',
                lastName: lastName || contact?.lastname || '',
                email: contact?.firstname || email || '',
                phone: contact?.phone_pro || phone || '',
                companyName: companyName || '',
                commercialId: user.id,
            },
            place: {
                address: [street || '', route || ''].map(p => p.trim()).join(' ').trim(),
                town: city || '',
                zipCode: postalCode || '',
                coords
            },
            batiment,
            entity: activeCompanyId,
        }
    }
}