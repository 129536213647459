import create from "zustand";

interface StoreModel {
    isOpen: boolean;

    selectInterventionId: string;

    show(proposalId: string): void;
    hide(): void;
}

export const useInterventionShowModalStore = create<StoreModel>((set) => ({
    isOpen: false,
    selectInterventionId: '',
    show: (interventionId: string) => set({ isOpen: true, selectInterventionId: interventionId }),
    hide: () => set({ isOpen: false, selectInterventionId: '' }),
}));