import {useRouteMatch} from "react-router-dom";
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import styled from "@emotion/styled";

type NavLinkProps = {
    title: string;
    to: string;
    mdIcon: string;
    iosIcon: string;
    activeOnlyWhenExact?: boolean;
}

export default function SideNavLink({title, to, mdIcon, iosIcon, activeOnlyWhenExact}: NavLinkProps) {
    const match = useRouteMatch({
        path: to,
        exact: activeOnlyWhenExact
    });

    const isActive = !!match;
    const color = isActive ? "primary" : "default";

    return (
        <StyledIonItem lines="none" button routerLink={to} routerDirection="root" color={color}>
            <IonIcon slot="start" md={mdIcon} ios={iosIcon}/>
            <IonLabel>{title}</IonLabel>
        </StyledIonItem>
    )
}

const StyledIonItem = styled(IonItem)`
    border-radius: 5px;
    
    &.active {
        --background: rgba(var(--ion-color-primary-tint-rgb), .9);
        font-weight: bold;
    }
`;