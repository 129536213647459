import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import Wordmark from "../Wordmark";
import {
    briefcaseOutline,
    briefcaseSharp,
    buildOutline,
    buildSharp,
    calendarNumberOutline,
    calendarNumberSharp,
    ellipsisHorizontal,
    ellipsisHorizontalOutline,
    gridOutline,
    gridSharp,
    peopleOutline,
    peopleSharp,
    personCircleOutline,
    personCircleSharp,
    powerOutline,
    powerSharp,
    speedometerOutline,
    speedometerSharp,
    statsChartOutline,
    statsChartSharp,
} from "ionicons/icons";
import {useHistory, useLocation} from "react-router-dom";
import {useLogout, useUser, useUserRights} from "../Auth/auth.store";
import styled from "@emotion/styled";
import {userToString} from "../../models/user.model";
import CompanySwitchButton from "../Company/CompanySwitchButton";
import {useMemo} from "react";

const solarPanel = '/assets/icon/solar-panel.svg';
const coinsPanel = '/assets/icon/coins.svg';

interface MenuLinkProps {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const menuLinks: MenuLinkProps[] = [
    { title: 'Dashboard', url: '/page/Dashboard', mdIcon: speedometerSharp, iosIcon: speedometerOutline },
    { title: "Annuaire", url: "/page/ThirdParties", mdIcon: peopleSharp, iosIcon: peopleOutline },
    { title: "Interventions", url: "/page/Interventions", mdIcon: buildSharp, iosIcon: buildOutline },
    { title: 'Agenda', url: '/page/Agenda', mdIcon: calendarNumberSharp, iosIcon: calendarNumberOutline },
    { title: 'Tableaux', url: '/page/Tableaux', mdIcon: gridSharp, iosIcon: gridOutline },
];

const hiddenMenuLinks: MenuLinkProps[] = [
    { title: "Devis", url: "/page/Affaires", mdIcon: briefcaseSharp, iosIcon: briefcaseOutline },
    { title: 'Produits', url: '/page/Products', mdIcon: solarPanel, iosIcon: solarPanel },
    { title: 'Comptabilité', url: '/page/Accounting', mdIcon: coinsPanel, iosIcon: coinsPanel },
];

export default function AppBar() {
    const reportMenuItem = useMemo(() => ({
        title: 'Rapports',
        url: '/page/Reports',
        mdIcon: statsChartSharp,
        iosIcon: statsChartOutline
    }), []);

    const user = useUser();
    // TODO à rm asap
    const canShowReport = ["amdp demo", "hadidadavid"].indexOf(user?.login || '') !== -1;

    const rights = useUserRights();

    return (
        <IonHeader>
            <IonToolbar style={{ '--background': 'white', '--min-height': 'unset' }}>
                <IonRow className={"ion-align-items-center ion-justify-content-start"}>
                    <IonCol>
                        <IonButtons>
                            <LogoButton routerLink={"/page/Dashboard"} routerDirection="root">
                                <IonTitle>
                                    <Wordmark />
                                </IonTitle>
                            </LogoButton>
                            {
                                menuLinks
                                    .filter(m => {
                                        if (rights?.amdpsales?.intervention.intervention_only) {
                                            return m.title === "Interventions"
                                        }

                                        return !!m.title;
                                    })
                                    .map(link => <MenuLink key={link.url} {...link} />
                                )
                            }

                            {
                                canShowReport && (
                                    <MenuLink key={reportMenuItem.url} {...reportMenuItem} />
                                )
                            }

                            <IonButton fill="clear" id="trigger-menu-popover">
                                <IonIcon slot="start" md={ellipsisHorizontal} ios={ellipsisHorizontalOutline} />
                            </IonButton>
                            <IonPopover arrow trigger="trigger-menu-popover" triggerAction="click" size="auto" showBackdrop={false} dismissOnSelect={true}>
                                <IonContent className="ion-no-padding">
                                    <IonList>
                                        {
                                            hiddenMenuLinks.map(
                                                menu => {
                                                    return (
                                                        <IonItemLink key={menu.url} {...menu} />
                                                    )
                                                }
                                            )
                                        }
                                    </IonList>
                                </IonContent>
                            </IonPopover>
                        </IonButtons>
                    </IonCol>
                </IonRow>

                <IonButtons slot="end">
                    <CompanySwitchButton />

                    <IonButton fill="clear" id="trigger-user-popover">
                        <IonIcon slot="start" md={personCircleSharp} ios={personCircleOutline} />
                    </IonButton>
                    <IonPopover arrow trigger="trigger-user-popover" triggerAction="click" size="auto" showBackdrop={false} dismissOnSelect={true}>
                        <UserPopoverContent />
                    </IonPopover>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    )
}

const LogoButton = styled(IonButton)`
    text-transform: unset;
    
    &:hover {
        --background-hover: none;
    }
`;

function MenuLink({ title, url, mdIcon, iosIcon }: MenuLinkProps) {
    const location = useLocation();

    const isActive = location.pathname.startsWith(url);
    const color = isActive ? "primary" : "default";

    return (
        <StyledIonButton
            fill={"clear"}
            expand="full"
            size="large"
            routerLink={url}
            routerDirection="root"
            color={color}
            className={isActive ? 'active' : ''}
        >
            <IonIcon slot="start" md={mdIcon} ios={iosIcon} />
            {title}
        </StyledIonButton>
    )
}

function IonItemLink({ title, url, mdIcon, iosIcon }: MenuLinkProps) {
    const location = useLocation();

    const isActive = location.pathname.startsWith(url);
    const color = isActive ? "primary" : "default";

    return (
        <IonItem button lines={'none'} color={color} routerLink={url} routerDirection="root">
            <IonIcon slot="start" md={mdIcon} ios={iosIcon} />
            <IonLabel>
                <h2>{title}</h2>
            </IonLabel>
        </IonItem>
    )
}

function UserPopoverContent() {
    const user = useUser();
    const logoutAndRedirect = useLogoutAndRedirect();

    return (
        <IonContent className="ion-no-padding">
            <IonList>
                <IonItem lines={"full"}>
                    <IonLabel>
                        <h2>{userToString(user)}</h2>
                        <p>@{user.login}</p>
                    </IonLabel>
                </IonItem>

                <IonItem lines={"none"} button onClick={logoutAndRedirect}>
                    <IonIcon slot="start" md={powerOutline} ios={powerSharp} />
                    <IonLabel>
                        Déconnexion
                    </IonLabel>
                </IonItem>
            </IonList>
        </IonContent>
    )
}

function useLogoutAndRedirect() {
    let history = useHistory();
    const logout = useLogout();

    return () => {
        // reset du state
        logout();

        // redirection après logout
        history.push('/Login');
    }
}

const StyledIonButton = styled(IonButton)`
    text-transform: capitalize;
    min-width: 140px;
    
    &:hover {
        background: rgba(var(--ion-color-primary-tint-rgb), .1);
        color: var(--ion-color-primary, #3880ff);
    }
    
    &.active {
        background: rgba(var(--ion-color-primary-tint-rgb), .1);
        font-weight: bold;
        border-bottom: 2px solid var(--ion-color-primary-tint);
    }
`;