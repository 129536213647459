import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

interface Payload {
    sortfield?: string; // t.rowid Sort field
    sortorder?: string; // ASC Sort order
    limit?: number; // 100 Limit for list
    page?: number;
    active?: 0 | 1;
}

interface GetPaymentTermsResult {
    id: string;
    code: string;
    sortorder: string;
    label: string;
    descr: string;
    type_cdr: string;
    nbjour: string;
    decalage: string;
    module: string;
}

export async function getPaymentTerms(payload: Payload = {}) {
    const {data} = await http.get<GetPaymentTermsResult[]>(Endpoint.dictionaryPaymentTerms, {
        params: payload,
    });

    return data;
}