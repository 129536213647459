import {Controller, useController} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddressAutocomplete from "../../../Common/AddressAutocomplete";
import {FormHelperText, Grid, MenuItem, Select, Stack, styled, TextField} from "@mui/material";
import React, {createContext, useContext, useMemo, useState} from "react";
import Button from "@mui/material/Button";

export default function AddressControl({ name = 'address',  label = "Adresse" }: { name?: string; label?: string }) {
    const [inputMode, setInputMode] = useState<'manual' | 'autocomplete'>('autocomplete');

    if (inputMode === 'manual') {
        return (
            <>
                <DefaultAddressControl name={name} />

                <FormHelperText style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button color={"primary"} onClick={() => setInputMode('autocomplete')}>
                        Saisie assistée
                    </Button>
                </FormHelperText>

            </>
        )
    }

    return (
        <>
            <AddressAutocompleteControl
                name={name}
                label={label}
            />

            <FormHelperText style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 0
            }}>
                <Button color={"primary"} onClick={() => setInputMode('manual')}>
                    Saisie manuelle
                </Button>
            </FormHelperText>
        </>
    )
}

const StyledGrid = styled(Grid)`
    width: 100%;
    
    & > .MuiGrid-item:first-of-type {
        padding-left: 0;
    }

    & > .MuiGrid-item {
        padding-top: 0;
    }
`;

const AddressFormContext = createContext<{ prefixer(txt: string): string }>({
    prefixer(txt): string {
        return txt;
    }
});

function useAddressFormPrefixer() {
    const { prefixer } = useContext(AddressFormContext);

    return prefixer;
}

function AddressAutocompleteControl({ name = 'address',  label = "Adresse" }: { name?: string; label?: string }) {
    const labelId = `${name}-address`;
    const id = `${name}-select`;

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController({
        name,
        defaultValue: "",
        rules: {
            required: "Adresse obligatoire",
        }
    });

    const errored = !!error;

    return (
        <FormControl fullWidth error={errored}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <AddressAutocomplete
                error={errored}
                onChange={onChange}
            />

            {
                error && (
                    <FormHelperText>
                        {error.message}
                    </FormHelperText>
                )
            }
        </FormControl>
    )
}

function DefaultAddressControl({ name = 'address' }: { name?: string }) {
    return (
        <AddressFormContext.Provider value={{
            prefixer(txt: string): string {
                return name ? `${name}.${txt}` : txt;
            }
        }}>
            <Stack direction={"column"} spacing={1}>
                <StreetControl />

                <StyledGrid container spacing={2}>
                    <Grid item xs={12} md={4} sx={{ pl: 0 }}>
                        <PostalCodeControl />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CityControl />
                    </Grid>
                </StyledGrid>

                <LatLngControl />

                <CountryControl />
            </Stack>
        </AddressFormContext.Provider>
    )
}

const t = (txt: string) => txt;

function StreetControl() {
    const prefixer = useAddressFormPrefixer();
    const name = prefixer("street");
    const label = t("Adresse");

    return (
        <Controller
            name={name}
            defaultValue={""}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    const errored = !!error;

                    return (
                        <FormControl variant={"outlined"} fullWidth error={errored}>
                            <FormLabel>{label}</FormLabel>
                            <TextField
                                variant="outlined"
                                size={"small"}
                                placeholder={t("Exemple: 2 rue des Thuyas")}
                                value={value}
                                onChange={onChange}
                                error={errored}
                            />

                            {
                                error && (
                                    <FormHelperText>
                                        {error.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }
            }
        />
    )
}

function PostalCodeControl() {
    const prefixer = useAddressFormPrefixer();
    const name = prefixer("postalCode");
    const label = t("Code postal");

    return (
        <Controller
            name={name}
            defaultValue={""}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    const errored = !!error;

                    return (
                        <FormControl variant={"outlined"} fullWidth error={errored}>
                            <FormLabel>{label}</FormLabel>
                            <TextField
                                variant="outlined"
                                size={"small"}
                                placeholder={label}
                                value={value}
                                onChange={onChange}
                                error={errored}
                            />

                            {
                                error && (
                                    <FormHelperText>
                                        {error.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }
            }
        />
    )
}

function CityControl() {
    const prefixer = useAddressFormPrefixer();
    const name = prefixer("city");
    const label = t("Ville");

    return (
        <Controller
            name={name}
            defaultValue={""}
            render={
                ({ field: { value, onChange }, fieldState: { error} }) => {
                    const errored = !!error;

                    return (
                        <FormControl variant={"outlined"} fullWidth error={errored}>
                            <FormLabel>{label}</FormLabel>
                            <TextField
                                variant="outlined"
                                size={"small"}
                                placeholder={label}
                                value={value}
                                onChange={onChange}
                                error={errored}
                            />

                            {
                                error && (
                                    <FormHelperText>
                                        {error.message}
                                    </FormHelperText>
                                )
                            }
                        </FormControl>
                    )
                }
            }
        />
    )
}

function LatLngControl() {
    const prefixer = useAddressFormPrefixer();
    const latName = prefixer("coords.0");
    const latLabel = t("Latitude");
    const lngName = prefixer("coords.1");
    const lngLabel =t("Longitude");

    return (
        <Stack direction={"row"} spacing={2}>
            <Controller
                name={latName}
                defaultValue={0}
                render={
                    ({ field: { value, onChange }, fieldState: { error} }) => {
                        const errored = !!error;

                        return (
                            <FormControl variant={"outlined"} fullWidth error={errored}>
                                <FormLabel>{latLabel}</FormLabel>
                                <TextField
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                            type: 'number'
                                        }
                                    }}
                                    size={"small"}
                                    placeholder={latLabel}
                                    value={value}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            onChange(parseFloat(e.target.value));
                                        } else {
                                            onChange(null);
                                        }
                                    }}
                                    error={errored}
                                />

                                {
                                    error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                        )
                    }
                }
            />

            <Controller
                name={lngName}
                defaultValue={0}
                render={
                    ({ field: { value, onChange }, fieldState: { error} }) => {
                        const errored = !!error;

                        return (
                            <FormControl variant={"outlined"} fullWidth error={errored}>
                                <FormLabel>{lngLabel}</FormLabel>
                                <TextField
                                    variant="outlined"
                                    type={"number"}
                                    size={"small"}
                                    placeholder={lngLabel}
                                    value={value}
                                    onChange={(e) => {
                                        if (e.target.value) {
                                            onChange(parseFloat(e.target.value));
                                        } else {
                                            onChange(null);
                                        }
                                    }}
                                    error={errored}
                                />

                                {
                                    error && (
                                        <FormHelperText>
                                            {error.message}
                                        </FormHelperText>
                                    )
                                }
                            </FormControl>
                        )
                    }
                }
            />
        </Stack>
    )
}

function CountryControl() {
    const prefixer = useAddressFormPrefixer();
    const name = prefixer("country");
    const label = t("Pays");

    const countries = useCountries();

    return (
        <Controller
            name={name}
            defaultValue={"FR"}
            render={
                ({ field: { value, onChange } }) => {
                    return (
                        <FormControl variant={"outlined"} fullWidth>
                            <FormLabel>{label}</FormLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                size={"small"}
                            >
                                {
                                    countries.map(
                                        ({ code, label }) => (
                                            <MenuItem key={code} value={code}>{label}</MenuItem>
                                        )
                                    )
                                }
                            </Select>
                            {/*<Autocomplete*/}
                            {/*    disablePortal*/}
                            {/*    getOptionLabel={(option) => option.label}*/}
                            {/*    isOptionEqualToValue={(a, b) => a.code === b.code}*/}
                            {/*    options={countries}*/}
                            {/*    renderInput={(params) => {*/}
                            {/*        return (*/}
                            {/*            <TextField*/}
                            {/*                {...params}*/}
                            {/*                size={"small"}*/}
                            {/*                placeholder={label}*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*    // value={value}*/}
                            {/*    onSelect={onChange}*/}
                            {/*/>*/}
                        </FormControl>
                    )
                }
            }
        />
    )
}

function useCountries(lang = 'fr') {
    return useMemo(() => {
        // For context to others looking here, the solution loops over all possible combinations of two characters (AA, AB, AC, …)
        // and checks if Intl knows it as a valid country code.
        const A = 65
        const Z = 90

        const countryName = new Intl.DisplayNames([lang], { type: 'region' });
        const countries: Array<{ code: string, label: string }> = [];

        for(let i= A; i <= Z; ++i) {
            for(let j= A; j <= Z; ++j) {
                const code = String.fromCharCode(i) + String.fromCharCode(j);
                const label = countryName.of(code);

                if (code !== label) {
                    countries.push({ code, label: label! });
                }
            }
        }

        return countries;
    }, [lang]);
}