import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import Invoice from "../../models/invoice.model";

/**
 * Récupérer une commande
 * @param id
 */
export async function getInvoice(id: number | string) {
    const url = Endpoint.invoice.replace(':id', `${id}`);
    const { data } = await http.get<Invoice>(url);

    return data;
}