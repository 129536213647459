import {Pagination} from "../types";
import {isNil, omitBy} from "lodash";
import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {Endpoint} from "../endpoint.enum";
import {ActionComm} from "../../models/action-comm.model";

export type SearchEventsPayload = {
    ownerIds?: string | number, // User ids filter field (owners of event). Example: '1' or '1,2,3'
    label?: string,
    status?: 0 | 50 | 100 | 'na' | 'todo' | 'done' , // 0, 'na'=Not applicable, '50'=Running already started, '100'=Done, 'done'=Done, 'todo'=Todo
    startDateStart?: string , // YYY-MM-DD HH:MM:SS
    startDateEnd?: string, // YYY-MM-DD HH:MM:SS
    endDateStart?: string,
    endDateEnd?: string ,
    note?: string,
    foireOnly?: boolean,
    location?: string,
}

export async function searchEvents(payload: SearchEventsPayload = {}, pagination: Pagination = {sortorder: 'DESC', limit: 500}) {
    const dto = omitBy(payload, v => isNil(v) || v === "");

    const {data} = await http.post<PaginatedResponse<ActionComm>>(Endpoint.agendaEvents, dto, {
        params: {
            sortfield: 't.id',
            ...pagination,
        }
    });

    return data;
}