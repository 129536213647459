import {useMutation} from "@tanstack/react-query";
import {Stack, styled} from "@mui/material";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover
} from "@ionic/react";
import {addOutline, addSharp, createOutline, createSharp, trashOutline, trashSharp} from "ionicons/icons";
import React, {useState} from "react";
import {contactToString} from "../../models/contact.model";
import CreateContactDialog from "../Contact/CreateContactDialog";
import useConfirm from "../Common/Confirmation/useConfirm";
import {deleteContact} from "../../calls/Contacts/deleteContact";
import EditContactDialog from "../Contact/EditContactDialog";
import {useEditContactDialogStore} from "../Contact/useEditContactDialogStore";
import {useThirdPartyContacts} from "../Contact/useThirdPartyContacts";

interface ContactsCardProps {
    thirdPartyId: string;
}

export default function ContactsCard({ thirdPartyId }: ContactsCardProps) {
    const { isLoading , isError, data, refetch } = useThirdPartyContacts(thirdPartyId);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClose = (reason?: 'created') => {
        if (reason === 'created') {
            refetch();
        }

        setIsOpen(false);
    }

    const contacts = data || [];

    return (
        <>
            <IonCard style={{ background: 'white' }}>
                <IonCardHeader>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <IonCardTitle>Contacts</IonCardTitle>

                        <IonButton fill="clear" expand={"block"} onClick={() => setIsOpen(true)}>
                            <IonIcon slot={"start"} ios={addOutline} md={addSharp} />
                            Ajouter
                        </IonButton>
                    </Stack>
                </IonCardHeader>
                <IonCardContent>
                    {

                        (isLoading) && (<>Chargement ...</>)
                    }

                    {
                        (isError) && (<>Pas de règlements trouvés pour ce tiers</>)
                    }

                    {
                        (!isLoading && !isError) && (
                            <>
                                <IonList style={{ background: 'white' }}>
                                    {
                                        contacts.map(
                                            c => {
                                                const addressText = [
                                                    c.address,
                                                    c.zip,
                                                    c.town,
                                                ].filter(i => !!i).join(', ');

                                                const phones = [
                                                        {label: 'Tél pro.', value: c.phone_pro },
                                                        {label: 'Tél perso.', value: c.phone_perso },
                                                        {label: 'Tél portable', value: c.phone_mobile }
                                                    ]
                                                        .filter(c => !!c.value)
                                                ;

                                                return (
                                                    <React.Fragment key={c.id}>
                                                        <IonItem key={c.id} detail={false} lines={"none"} style={{ '--background': 'white' }}>
                                                            <IonLabel>
                                                                <h3 id={`Contact-${c.id}`}>
                                                                    {contactToString(c)}
                                                                </h3>

                                                                {
                                                                    c.poste && (
                                                                        <h4>{c.poste}</h4>
                                                                    )
                                                                }

                                                                {
                                                                    c.email && (
                                                                        <p className={"ion-margin-bottom"}>{c.email}</p>
                                                                    )
                                                                }

                                                                {
                                                                    phones.map(
                                                                        phone => (<p key={phone.label}>{phone.label}: {phone.value}</p>)
                                                                    )
                                                                }
                                                            </IonLabel>
                                                            <EditContactButton
                                                                id={c.id}
                                                            />
                                                            <DeleteContactButton
                                                                id={c.id}
                                                                thirdPartyId={thirdPartyId}
                                                                label={contactToString(c)}
                                                            />
                                                        </IonItem>

                                                        <IonPopover
                                                            key={`Contact-Popover-${c.id}`}
                                                            style={{ '--background': 'white' }}
                                                            trigger={`Contact-${c.id}`}
                                                            triggerAction="hover"
                                                            keepContentsMounted={true}
                                                            showBackdrop={false}
                                                        >
                                                            <IonContent class="ion-no-padding">
                                                                <IonList style={{ '--background': 'white' }}>
                                                                    <IonItem key={c.id} id={`Contact-${c.id}`} detail={false} lines={"none"} style={{ '--background': 'white' }}>
                                                                        <IonLabel className={"ion-text-wrap"}>
                                                                            <h2 style={{marginBottom: 10}}>{c.name}
                                                                                {contactToString(c)}
                                                                            </h2>
                                                                            <p><b>Email:</b> {c.email || 'N/A'}</p>
                                                                            <p><b>Tél:</b> {c.phone_pro || 'N/A'}</p>
                                                                            <p><b>Adresse:</b> {addressText || 'N/A'}
                                                                            </p>
                                                                        </IonLabel>
                                                                    </IonItem>
                                                                </IonList>
                                                            </IonContent>
                                                        </IonPopover>
                                                    </React.Fragment>
                                                )
                                            }
                                        )
                                    }
                                </IonList>
                            </>
                        )
                    }
                </IonCardContent>
            </IonCard>

            <CreateContactDialog
                thirdPartyId={Number(thirdPartyId)}
                open={isOpen}
                onClose={handleClose}
            />

            <EditContactDialog
                thirdPartyId={Number(thirdPartyId)}
                onClose={reason => {
                    if (reason === 'updated') {
                        refetch();
                    }
                }}
            />
        </>
    )
}

function EditContactButton({ id }: { id: string }) {
    const { openDialog } = useEditContactDialogStore();

    return (
        <IonButton
            slot={"end"}
            shape="round"
            fill={"clear"}
            color={"medium"}
            size="small"
            onClick={() => openDialog(id)}
            title={"Modifier le contact"}
        >
            <IonIcon slot="icon-only" md={createSharp} ios={createOutline} />
        </IonButton>
    )
}

function DeleteContactButton({ id, thirdPartyId, label }: { id: string, thirdPartyId: string, label: string }) {
    const confirm = useConfirm();

    const { refetch } = useThirdPartyContacts(thirdPartyId);
    const { isLoading, mutateAsync } = useMutation(() => deleteContact(id));

    const onClick = async () => {
        const confirmed = await confirm({
            title: "Confirmation suppression",
            message: <>Êtes-vous sûr de vouloir supprimer le contact <b>{label}</b> ?</>
        });

        if (!confirmed) {
            return;
        }

        await mutateAsync();
        await refetch();
    }

    return (
        <IonButton
            slot={"end"}
            shape="round"
            fill={"clear"}
            color={"medium"}
            size="small"
            disabled={isLoading}
            onClick={onClick}
            title={"Supprimer le contact"}
        >
            <IonIcon slot="icon-only" md={trashSharp} ios={trashOutline} />
        </IonButton>
    )
}

const StyledIonCard = styled(IonCard)`
    --background: white;

    & ion-item, & ion-list {
        background: white;
        --background: white;
    }
`;