import CardCommentControl from "./CardCommentControl";
import React, {FormEventHandler} from "react";
import {Box, Button} from "@mui/material";

interface CardCommentFormProps {
    onSubmit: FormEventHandler | undefined;
    loading?: boolean;
}

export default function CardCommentForm({ onSubmit, loading }: CardCommentFormProps) {
    return (
        <form noValidate onSubmit={onSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardCommentControl />

                <Button type={"submit"} variant="contained" size={"small"} sx={{ mt: 1, ml: 'auto' }} disabled={loading}>
                    Enregistrer
                </Button>
            </Box>
        </form>
    )
}