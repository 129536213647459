import {styled} from "@mui/material";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)`
    width: 100%; // Set TextField width to fill the cell
    height: 100%; // Set TextField height to 100% of the cell (optional for vertical filling)
    box-sizing: border-box; // Ensure TextField fills the entire cell including borders
    font-size: inherit; // Inherit font size from parent
    background: white;

    .MuiInputBase-input, .MuiInputBase-root {
        padding: 0!important;
    }

    .MuiInputBase-root {
        border-radius: unset;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`;