import create from "zustand";

interface StoreModel {
    isOpen: boolean;

    selectedProposalId: string;

    show(proposalId: string): void;
    hide(): void;
}

export const useAffaireShowModalStore = create<StoreModel>((set) => ({
    isOpen: false,
    selectedProposalId: '',
    show: (proposalId: string) => set({ isOpen: true, selectedProposalId: proposalId }),
    hide: () => set({ isOpen: false, selectedProposalId: '' }),
}));