import {useQuery} from "@tanstack/react-query";
import {searchEvents} from "../../../../calls/Agenda/searchEvents";

export function useAvailableFoiresSearch(q: string = '') {
    const queryFn = () =>searchEvents({
        label: q,
        status: 'todo',
        foireOnly: true,
    })

    return useQuery(["foires", q], queryFn);
}