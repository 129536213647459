import {FormProvider, useForm} from "react-hook-form";
import InterventionForm, {InterventionFormModel} from "./InterventionForm";
import React, {useRef} from "react";
import {IonButton, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {DateTime} from "luxon";
import {useCreateNewIntervention} from "./useCreateNewIntervention";
import {InterventionType} from "../../models/intervention.model";
import {CreateInterventionDto} from "../../calls/Interventions/types";
import {useActiveCompanyId} from "../Company/company.store";
import {CreateVisiteTechinqueDto} from "../../calls/Interventions/VT/createVisiteTechnique";
import {CreatePoseDto} from "../../calls/Interventions/Pose/createPose";
import {OverlayEventDetail} from "@ionic/core";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import {ProductTypology} from "./types";
import {compact} from "lodash";
import {useMutation} from "@tanstack/react-query";
import {generateReports} from "../../calls/Interventions/generateReports";

interface InterventionFormModalProps {
    trigger: string;
    onWillDismiss?(event: IonModalCustomEvent<OverlayEventDetail>): void;
    defaultInterventionType: InterventionType
    proposal: {
        id: number;
        reference: string;
        origin: string;
        originId?: string;
        productTypology?: ProductTypology;
    },
    thirdParty: {
        id: number,
        name: string,
    },
    place: {
        address: string;
        zipCode: string;
        town: string;
        coords: number[];
    }
    projectId: number,
}

export default function InterventionFormModal({ trigger, defaultInterventionType, onWillDismiss, ...restOfProps }: InterventionFormModalProps) {
    const activeCompanyId = useActiveCompanyId();

    const methods = useForm<InterventionFormModel>({
        defaultValues: {
            type: defaultInterventionType,
            date: DateTime.now().toISO(),
            theoricalStartDate: DateTime.now().toISO(),
            theoricalEndDate: DateTime.now().toISO(),
            duration: 1,
            desiredInterventionDate: DateTime.now().toISO(),
        }
    });

    const { proposal, thirdParty, place } = restOfProps;

    const type = methods.watch('type');

    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading, isSuccess, isError, data } = useCreateNewIntervention(type);
    const reportGeneration = useMutation((id: string) => generateReports(id));

    const dismiss = () => {
        methods.reset();
        modal.current?.dismiss();
    }

    const onSubmit = async ({ date, technician, instructions, ...data }: InterventionFormModel) => {
        // Get des coords
        const [lat, lng] = place.coords;

        const baseDto: CreateInterventionDto = {
            proposal: {
                id: proposal.id.toString(),
                reference: proposal.reference,
                origin: proposal.origin,
                originId: proposal.originId,
                productTypology: proposal.productTypology,
            },
            customer: {
                id: thirdParty.id.toString(),
                name: thirdParty.name,
            },
            place: {
                address: place.address,
                zipCode: place.zipCode,
                town: place.town,
                coords: [Number(lng), Number(lat)]
            },
            entity: activeCompanyId,
            technician,
            date,
            instructions: compact(instructions || []),
        }

        let dto;

        if (type === InterventionType.VISITE_TECHNIQUE) {
            // Convert duree
            const duration = (data.duration || 0) * 3600; // conversion durée en seconde

             dto = {
                ...baseDto,
                duration,
            } as CreateVisiteTechinqueDto;
        }


        if (type === InterventionType.POSE) {
            dto = {
                ...baseDto,
                theoricalStartDate: data.theoricalStartDate,
                theoricalEndDate: data.theoricalEndDate,
            } as CreatePoseDto;
        }

        const response = await mutateAsync(dto as any);

        if (type === InterventionType.POSE) {
            await reportGeneration.mutateAsync((response as any).id);
        }

        methods.reset();

        modal.current?.dismiss(response, 'confirm');
    }

    return (
        <IonModal ref={modal} backdropDismiss={false} keepContentsMounted={false} trigger={trigger} style={{ '--width': '30%', '--min-height': 400, '--backdrop-opacity': .4 }} onWillDismiss={onWillDismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Programmer intervention</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <InterventionForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
