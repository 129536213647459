import {createContext, useContext} from "react";
import {UseQueryResult} from "@tanstack/react-query";
import {ThirdParty} from "../../models/third-party.model";
import {PaginatedResponse} from "../../models/common.model";

type ThirdPartiesListContextProps = {
    query?: UseQueryResult<PaginatedResponse<ThirdParty>>,
}

export const ThirdPartiesListContext = createContext<ThirdPartiesListContextProps>({});

export function useThirdPartiesListContext() {
    return useContext(ThirdPartiesListContext);
}