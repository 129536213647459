import {InterventionsListContext, useInterventionsListContext} from "./useInterventionsListContext";
import {IonButton, IonIcon} from "@ionic/react";
import {filterOutline, filterSharp} from "ionicons/icons";
import {useInterventionsQuery} from "./useInterventionsQuery";
import {FormProvider} from "react-hook-form";
import {useInterventionFilterForm} from "./useInterventionFilterForm";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import React, {useState} from "react";
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    Chip,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import InterventionFilterButton, {InterventionAdvancedSearchFormCollapse} from "./InterventionFilterButton";
import InterventionsMap from "./InterventionsMap";
import {DateTime} from "luxon";
import {compact, isEmpty, isNull, isUndefined, omitBy} from "lodash";
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import {productTypologyUI} from "./product-typology";
import HelpIcon from '@mui/icons-material/Help';
import InterventionTypeChip from "./InterventionTypeChip";
import AddIcon from '@mui/icons-material/Add';
import useToggleOpen from "../Common/useToggleOpen";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import InterventionWorkflowStepper from "./InterventionWorkflowStepper";
import {AllInterventionStatus, Intervention, InterventionType, interventionUI} from "../../models/intervention.model";
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from "@mui/material/IconButton";
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import {useInterventionMapStore} from "./intervention-map.store";
import DialogContent from '@mui/material/DialogContent';
import {useInterventionDetail} from "../InterventionDetail/useInterventionDetail";
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import {getInterventionFiles} from "../../calls/Interventions/getInterventionFiles";
import {useQuery} from "@tanstack/react-query";
import * as blobUtil from 'blob-util';
import {saveAs} from "file-saver";
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import InterventionStatusChip from "./InterventionStatusChip";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {ProductTypology} from "./types";
import ControlledInterventionScheduleFormModal from "./ControlledInterventionScheduleFormModal";
import {ThirdParty} from "../../models/third-party.model";
import {useUserRights} from "../Auth/auth.store";

export default function InterventionList(){
    const { filters, applyFilters, resetFilters, methods } = useInterventionFilterForm();
    const { page, rowsPerPage, offset, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const parsedFilters = omitBy(filters, v => isEmpty(v) || isNull(v) || isUndefined(v));

    const query = useInterventionsQuery({
        ...parsedFilters,
        maxDistance: parsedFilters.maxDistance ? Number(parsedFilters.maxDistance) : undefined,
        offset: offset - 1,
        limit: rowsPerPage
    });

    const itemsCount = query?.data?.totalDocs || 0;

    const contextValue = {
        query,
    };

    return(
        <FormProvider {...methods}>
            <InterventionsListContext.Provider value={contextValue}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sx={{ height: '94vh', padding: 0 }}>
                        <InterventionsMap />
                        <SelectedInterventionDialog />
                    </Grid>

                    <Grid item xs={12} md={4} sx={{
                        padding: 0,
                        maxHeight: '95vh',
                        overflow: 'scroll',
                    }}>
                        <InterventionsHeader onSubmit={
                            () => {
                                applyFilters();
                            }
                        } />
                        <InterventionCards />
                    </Grid>
                </Grid>
            </InterventionsListContext.Provider>
        </FormProvider>

    )
}

function InterventionsHeader({ onSubmit }: { onSubmit(): void }) {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const itemsCount = data?.totalDocs || 0;

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 2 }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                    Interventions ({itemsCount})
                </Typography>

                <Stack direction="row" alignItems={"center"} spacing={1}>
                    <NewButton />

                    <InterventionFilterButton open={open} onClick={() => open ? handleClose() : handleClick()} />
                </Stack>
            </Stack>

            <InterventionAdvancedSearchFormCollapse
                open={open}
                onSubmit={onSubmit}
                onClose={handleClose}
            />
        </>
    )
}

const InterventionCard = styled(Card)`
    // cursor: pointer;

    &:hover {
        background: #f8f8f8;
    }

    &.selected {
        background: #f8f8f8;
    }
`;

function InterventionCards() {
    const {query} = useInterventionsListContext();

    const data = query?.data;

    const cards = data?.docs || [];

    const isLoading = query?.isLoading;
    const isError = query?.isError;
    const isEmpty = !(isLoading && isError) && (cards.length === 0);

    const setSelectedInterventionId = useInterventionMapStore(s => s.setSelectedId);

    if (isLoading) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="87vh"
                maxHeight="87vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ mb: 1 }}>
                        Chargement en cours ...
                    </Typography>

                    <CircularProgress color="inherit" size="1.5rem" />
                </div>
            </Stack>
        )
    }

    if (isEmpty) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="87vh"
                maxHeight="87vh"
            >
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" component="div">
                        Aucune intervention trouvée
                    </Typography>

                    <Typography variant="caption" component="div">
                        Veuillez modifier les filtres ou lancer une nouvelle recherche
                    </Typography>
                </div>
            </Stack>
        )
    }

    return (
        <React.Fragment>
            <Stack direction="column" spacing={.5}>
                {
                    cards.map(
                        c => {
                            const plannedDate = DateTime.fromISO(c?.date || '',{zone:"Europe/Paris"}).setLocale('fr');
                            const addressText = compact([c.place.address,c.place.zipCode, c.place.town]).join(', ');
                            const technician = c.technician;

                            const typology = c.proposal?.productTypology || ProductTypology.PHOTOVOLTAIC;
                            const Icon = typology ? productTypologyUI.typologyIcon[typology!] : () => <HelpIcon color={"inherit"} />;
                            const typologyLabel = productTypologyLabel[typology || ''] || 'N/A';
                            const typologyColor = typology ? productTypologyUI.typologyColor[typology] : "#F0F0F0";
                            const iconColor = typologyColor || "rgba(0, 0, 0, 0.54)";

                            const notes = c.notes;

                            const toSchedule = c?.status === AllInterventionStatus.TO_SCHEDULE;

                            return (
                                <InterventionCard key={c.id} sx={{ width: '100%', border: `solid 2px ${typologyColor}` }} elevation={0}>
                                    <CardHeader
                                        avatar={
                                            <Tooltip title={typologyLabel}>
                                                <Avatar sx={{ bgcolor: 'white' }} variant={"square"}>
                                                    <Icon />
                                                </Avatar>
                                            </Tooltip>
                                        }
                                        title={
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{
                                                '& ion-chip' : {
                                                    margin: 0
                                                }
                                            }}>
                                                <Typography variant="body1" component="div">
                                                    {c.customer.name}
                                                </Typography>

                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} sx={{ ml: 'auto' }}>
                                                    <Chip size={"small"} label={c.reference} onClick={() => setSelectedInterventionId(c.id)}/>

                                                    <InterventionTypeChip type={c.type} />

                                                    <InterventionStatusChip status={c?.status} />
                                                </Stack>
                                            </Stack>
                                        }
                                        subheader={
                                            <Typography gutterBottom variant="body2" component="div">
                                                {
                                                    plannedDate.isValid
                                                        ? `Prévue le ${plannedDate.toLocaleString(DateTime.DATETIME_MED)}`
                                                        : 'Non planifiée'
                                                }
                                            </Typography>
                                        }
                                        sx={{ pb: 0 }}/>

                                    <List dense sx={{ p: 0 }}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PlaceIcon sx={{ color: iconColor }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={addressText}
                                                secondary={"Adresse"}
                                            />
                                        </ListItem>

                                        <ListItem
                                            secondaryAction={
                                                toSchedule
                                                    ? (
                                                        <ScheduleInterventionButton
                                                            id={c.id}
                                                            refetch={() => query?.refetch()}
                                                        />
                                                    )
                                                    : <></>
                                            }
                                        >
                                            <ListItemIcon>
                                                <PersonIcon sx={{ color: iconColor }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={technician?.name || 'Non assigné'}
                                                secondary={"Technicien"}
                                            />
                                        </ListItem>

                                        {
                                            notes && (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <CommentIcon sx={{ color: iconColor }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={notes}
                                                        secondary={"Notes / Commentaires"}
                                                    />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </InterventionCard>
                            )
                        }
                    )
                }
            </Stack>
        </React.Fragment>
    )
}

function ScheduleInterventionButton({ id, refetch }: { id: string, refetch: any }) {
    const { open, show, hide } = useToggleOpen();

    const rights = useUserRights();

    // Bloquer l'assignation de l'intervention si ce param est à true
    if (rights?.amdpsales?.intervention.block_assignment) {
        return null;
    }

    return (
        <>
            <IconButton edge="end" aria-label="assign" onClick={show}>
                <EditCalendarRoundedIcon />
            </IconButton>

            <ControlledInterventionScheduleFormModal
                id={id}
                isOpen={open}
                onWillDismiss={() => {
                    hide();
                    refetch();
                }}
            />
        </>
    )
}

const StyledInterventionDialog = styled(Dialog)`
    .MuiPaper-root {
        height: 90vh;
        max-height: 90vh;
        min-width: 465px;
    }
`;

function SelectedInterventionDialog() {
    const [selectedId, setSelectedId] = useInterventionMapStore(s => [s.selectedId, s.setSelectedId]);
    const { data: intervention, isLoading, refetch } = useInterventionDetail(selectedId);

    const open = !!selectedId;

    const handleClose = () => setSelectedId('');

    return (
        <StyledInterventionDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle id="draggable-dialog-title">
                {
                    !isLoading && (
                        <>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{
                                '& ion-chip' : {
                                    margin: 0
                                }
                            }}>
                                Intervention {intervention?.reference}

                                <IconButton aria-label="close" size="small" onClick={handleClose}>
                                    <CloseRoundedIcon fontSize="inherit" />
                                </IconButton>
                            </Stack>

                            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} spacing={1} sx={{
                                '& ion-chip' : {
                                    margin: 0
                                }
                            }}>
                                <InterventionTypeChip type={intervention?.type} />
                                <InterventionStatusChip status={intervention?.status} />
                            </Stack>
                        </>
                    )
                }
            </DialogTitle>
            <DialogContent>
                {
                    isLoading ? (
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%"
                            maxHeight="100%"
                        >
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress color="inherit" size="1.5rem" />
                            </div>
                        </Stack>
                    ) : (
                        <>
                            <InterventionDialogItems {...intervention!} refetch={refetch} />
                        </>
                    )
                }
            </DialogContent>
        </StyledInterventionDialog>
    )
}

function InterventionDialogItems({ refetch, ...intervention }: Intervention & { refetch: any }) {
    const plannedDate = DateTime.fromISO(intervention?.date || '',{zone:"Europe/Paris"}).setLocale('fr');
    const addressText = compact([intervention.place.address, intervention.place.zipCode, intervention.place.town]).join(', ');
    const technician = intervention.technician;

    const toSchedule = intervention?.status === AllInterventionStatus.TO_SCHEDULE;

    return (
        <>
            <List
                dense
                sx={{ p: 0 }}
                subheader={<ListSubheader>Informations générales</ListSubheader>}
            >
                <ListItem>
                    <ListItemIcon>
                        <AccessTimeFilledRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography gutterBottom variant="body2" component="div">
                                {
                                    plannedDate.isValid
                                        ? `Prévue le ${plannedDate.toLocaleString(DateTime.DATETIME_MED)}`
                                        : 'Non planifiée'
                                }
                            </Typography>
                        }
                        secondary={"Date intervention"}
                    />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <ApartmentRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intervention?.customer?.companyName || 'N/A'}
                        secondary={"Raison Sociale"}
                    />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={addressText}
                        secondary={"Adresse"}
                    />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <LocalPhoneRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intervention?.customer?.phone}
                        secondary={"Téléphone"}
                    />
                </ListItem>

                <ListItem>
                    <ListItemIcon>
                        <AlternateEmailRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={intervention?.customer?.email}
                        secondary={"Email"}
                    />
                </ListItem>

                <ListItem
                    secondaryAction={
                        toSchedule
                            ? (
                                <ScheduleInterventionButton
                                    id={intervention.id}
                                    refetch={() => refetch()}
                                />
                            )
                            : <></>
                    }
                >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={technician?.name || 'Non assigné'}
                        secondary={"Technicien"}
                    />
                </ListItem>
            </List>

            <InterventionPhotos {...intervention} />

            <InterventionAttachments {...intervention} />

            <InterventionEvents id={intervention.id} />
        </>
    )
}

function InterventionPhotos(intervention: Intervention) {
    const {data: files = []} = useQuery(['intervention', intervention?.id, 'files'], () => getInterventionFiles(intervention?.id));

    const documents = files.filter(f => f.contentType.startsWith('image/'));

    const download = async (base64: string, filename: string) => {
        const blob = await blobUtil.base64StringToBlob(base64);
        saveAs(blob, filename);
    }

    return (
        <List
            dense
            sx={{ p: 0 }}
            subheader={<ListSubheader>Photos ({ documents.length })</ListSubheader>}
        >
            {
                documents?.length === 0 ? (
                    <ListItem>
                        <ListItemText
                            primary={"Pas de fichiers joints à cette intervention"}
                        />
                    </ListItem>
                ) : (

                    <>
                        {
                            documents.map(
                                doc => {
                                    return (
                                        <ListItem
                                            key={doc.filename}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="download">
                                                    <ArrowCircleDownRoundedIcon />
                                                </IconButton>
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton
                                                onClick={() => download(doc.base64Content, doc.filename)}
                                            >
                                                <ListItemText
                                                    primary={doc.filename}
                                                    secondary={doc.description}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                            )
                        }
                    </>
                )
            }
        </List>
    )
}

function InterventionAttachments(intervention: Intervention) {
    const {data: files = []} = useQuery(['intervention', intervention?.id, 'files'], () => getInterventionFiles(intervention?.id));

    const documents = files.filter(f => f.contentType === 'application/pdf');

    const download = async (base64: string, filename: string) => {
        const blob = await blobUtil.base64StringToBlob(base64);
        saveAs(blob, filename);
    }

    return (
        <List
            dense
            sx={{ p: 0 }}
            subheader={<ListSubheader>Fichiers joints ({ documents.length })</ListSubheader>}
        >
            {
                documents?.length === 0 ? (
                    <ListItem>
                        <ListItemText
                            primary={"Pas de fichiers joints à cette intervention"}
                        />
                    </ListItem>
                ) : (

                    <>
                        {
                            documents.map(
                                doc => {
                                    return (
                                        <ListItem
                                            key={doc.filename}
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="download">
                                                    <ArrowCircleDownRoundedIcon />
                                                </IconButton>
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton
                                                onClick={() => download(doc.base64Content, doc.filename)}
                                            >
                                                <ListItemText
                                                    primary={doc.filename}
                                                    secondary={doc.description}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                }
                            )
                        }
                    </>
                )
            }
        </List>
    )
}

function InterventionEvents({ id }: { id: string }) {
    const {data: intervention} = useInterventionDetail(id);

    const events = intervention?.events || [];

    return (
        <List
            dense
            sx={{ p: 0 }}
            subheader={<ListSubheader>Évènements ({ events.length })</ListSubheader>}
        >
            {
                events.length === 0 ? (
                    <ListItem>
                        <ListItemText
                            primary={"Pas d'évènements sur cette intervention"}
                        />
                    </ListItem>
                ) : (

                    <>
                        {
                            events.map(
                                (event, i) => {
                                    const label = interventionUI.eventTypeTranslation[event.type];
                                    const dateTxt = DateTime.fromISO(event.date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');

                                    return (
                                        <ListItem
                                            key={`${event.type}-${i}`}
                                        >
                                            <ListItemText
                                                primary={label}
                                                secondary={dateTxt}
                                            />
                                        </ListItem>
                                    )
                                }
                            )
                        }
                    </>
                )
            }
        </List>
    )
}

const productTypologyLabel: Record<string, string> = {
    PAC_AIR_AIR: "Pompe à chaleur R/R",
    PAC_AIR_EAU: "Pompe à chaleur R/O",
    PHOTOVOLTAIC: "Photovoltaïque",
    BALLON_THERMO: "Ballon thermo",
}

interface NewButtonProps {
    thirdParty?: ThirdParty;
    onCreated?(): void;
}

export function NewButton({ thirdParty, onCreated }: NewButtonProps = {}) {
    const { open, show, hide } = useToggleOpen();
    const [type, setType] = useState<InterventionType | null>(null);
    const {query} = useInterventionsListContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value?: InterventionType) => {
        setAnchorEl(null);

        if (!value) {
            return;
        }

        setType(value);
        show();
    };

    const onInterventionCreated = () => {
        onCreated && onCreated();
        query?.refetch();
        hide();
    }

    const onCancel = () => {
        hide();
    }

    const rights = useUserRights();

    return (
        <>
            {
                rights?.amdpsales?.intervention.intervention_only ? (
                    <ButtonLower
                        type={"button"}
                        variant="outlined"
                        color="primary"
                        size={"small"}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setType(InterventionType.RDVCOM);
                            show();
                        }}
                    >
                        Nouveau RDV
                    </ButtonLower>
                ) : (
                    <>
                        <ButtonLower type={"button"} variant="outlined" color="primary" size={"small"} startIcon={<AddIcon />} onClick={handleClick}>
                            Nouvelle
                        </ButtonLower>

                        <Menu
                            id="intervention-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={() => handleClose()}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleClose(InterventionType.RDVCOM)}>Rdv Commercial</MenuItem>
                            <MenuItem onClick={() => handleClose(InterventionType.VISITE_TECHNIQUE)}>Visite Technique</MenuItem>
                            <MenuItem onClick={() => handleClose(InterventionType.POSE)}>Pose</MenuItem>
                            <MenuItem onClick={() => handleClose(InterventionType.ENTRETIEN)}>Entretien</MenuItem>
                            <MenuItem onClick={() => handleClose(InterventionType.SAV)}>SAV</MenuItem>
                        </Menu>
                    </>
                )
            }

            <Dialog onClose={hide} open={open} sx={{
                '.MuiPaper-root': {
                    minWidth: 950
                }
            }}>

                {
                    type && (
                        <>
                            <DialogTitle>Programmer {interventionUI.typeTranslation[type]}</DialogTitle>

                            <InterventionWorkflowStepper
                                type={type}
                                thirdParty={thirdParty}
                                onCreated={onInterventionCreated}
                                onCancel={onCancel}
                            />
                        </>
                    )
                }
            </Dialog>
        </>
    )
}

export function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}

const ButtonLower = styled(Button)`
    text-transform: capitalize;
`;