import lodash, {isNil, omitBy} from "lodash";
import {http} from "../http";
import {Endpoint} from "./endpoint.enum";

type SearchFoiresPayload = {
    label?: string,
    percentage?: number | string,
}

type Pagination = {
    sortfield: string,
    sortorder: 'ASC' | 'DESC',
    limit: number,
}

const mappings: Record<string, { field: string, operator: string, transform?(v: any): any }> = {
    percentage: {
        field: 't.percent',
        operator: '='
    },
    label: {
        field: 't.label',
        operator: 'like',
        transform: (value: string) => `'%${value}%'`,
    },
}

export async function searchFoires(payload: SearchFoiresPayload, pagination: Pagination = { sortfield: 't.id', sortorder: 'DESC', limit: 500 }) {
    const dto = omitBy(payload, isNil);

    const sqlfilters = buildSqlFilters(dto);

    const params = lodash.omitBy({
        ...sqlfilters,
        pagination_data: true,
        ...pagination
    }, lodash.isNil);

    const { data } = await http.get(Endpoint.foires, {
        params,
    });

    return data;
}

const buildSqlFilters = (payload: SearchFoiresPayload) => {
    const sanitized =  lodash.omitBy(payload, v => (lodash.isNil(v) || lodash.isEmpty(v)));

    const entries = Object.entries(sanitized);

    if (entries.length === 0) {
        return {};
    }

    const filters = entries
        .map(([key, value]) => {
            const mapping = mappings[key];
            let filterValue = value;

            if (!mapping.transform) {
                if (typeof value === 'string' || (value as any) instanceof String) {
                    filterValue = `'${value}'`;
                }

                if (typeof value === 'boolean') {
                    filterValue = value ? 1 : 0;
                }
            } else {
                filterValue = mapping.transform(filterValue);
            }

            return `${mapping.field} ${mapping.operator} ${filterValue}`;
        });

    return { sqlfilters: `(${filters.join(' AND ')})` };
}
