export class User {
  public id: string;
  public statut?: string;
  public employee?: string;
  public civility_code?: string;
  public gender?: string;
  public birth?: string;
  public email?: string;
  public personal_email?: string;
  public job?: string;
  public signature?: string;
  public address?: string;
  public zip?: string;
  public town?: string;
  public state_id?: any;
  public office_phone?: string;
  public office_fax?: string;
  public user_mobile?: string;
  public personal_mobile?: string;
  public admin?: string;
  public login?: string;
  public entity?: string;
  public datec?: string;
  public datem?: number;
  public socid?: any;
  public contact_id?: any;
  public fk_member?: any;
  public fk_user?: any;
  public fk_user_expense_validator?: any;
  public fk_user_holiday_validator?: any;
  public clicktodial_url?: any;
  public clicktodial_login?: any;
  public clicktodial_poste?: any;
  public datelastlogin?: number;
  public datepreviouslogin?: number;
  public datestartvalidity?: string;
  public dateendvalidity?: string;
  public photo?: any;
  public lang?: any;
  public rights?: UserRights;
  public user_group_list?: any;
  public parentof?: any;
  public accountancy_code?: string;
  public thm?: any;
  public tjm?: any;
  public salary?: any;
  public salaryextra?: any;
  public weeklyhours?: any;
  public color?: string;
  public dateemployment?: string;
  public dateemploymentend?: string;
  public default_c_exp_tax_cat?: any;
  public default_range?: any;
  public fk_warehouse?: any;
  public import_key?: any;
  public array_languages?: any;
  public linkedObjectsIds?: any;
  public canvas?: any;
  public fk_project?: any;
  public user?: any;
  public origin?: any;
  public origin_id?: any;
  public ref?: string;
  public ref_ext?: any;
  public status?: any;
  public country_id?: any;
  public country_code?: string;
  public region_id?: any;
  public barcode_type?: any;
  public barcode_type_coder?: any;
  public mode_reglement_id?: any;
  public cond_reglement_id?: any;
  public demand_reason_id?: any;
  public transport_mode_id?: any;
  public last_main_doc?: any;
  public fk_bank?: any;
  public fk_account?: any;
  public note_public?: string;
  public note_private?: string;
  public name?: any;
  public lastname?: string;
  public firstname?: string;
  public civility_id?: any;
  public date_creation?: any;
  public date_validation?: any;
  public date_modification?: any;
  public specimen?: number;
  public liste_limit?: number;
}

export interface UserRights {
    amdpsales?: {
        intervention: {
            intervention_only: 0 | 1;
            block_assignment: 0 | 1;
        }
    },
    user?: {
        user: {},
        self: {},
        user_advance: {},
        self_advance: {},
        group_advance: {}
    }
    facture?: Facture
    propale?: Propale
    produit?: Produit
    projet?: Projet
    commande?: Commande
    societe?: Societe
    categorie?: Categorie
    service?: Service
    export?: Export
    import?: Import
    agenda?: Agenda
    ecm?: Ecm
    api?: Api
    multicompany?: Multicompany
    cron?: Cron
    amdpeasykanban?: Amdpeasykanban
    propal?: Propal2
}

export interface Facture {
    lire: number
    creer: number
    invoice_advance: InvoiceAdvance
    paiement: number
    supprimer: number
    facture: Facture2
}

export interface InvoiceAdvance {
    unvalidate: number
    validate: number
    send: number
    reopen: number
}

export interface Facture2 {
    export: number
}

export interface Propale {
    lire: number
    creer: number
    propal_advance: PropalAdvance
    supprimer: number
    export: number
}

export interface PropalAdvance {
    validate: number
    send: number
    close: number
}

export interface Produit {
    lire: number
    creer: number
    supprimer: number
    export: number
    ignore_price_min_advance: number
}

export interface Projet {
    lire: number
    creer: number
    supprimer: number
    export: number
    all: All
}

export interface All {
    lire: number
    creer: number
    supprimer: number
}

export interface Commande {
    lire: number
    creer: number
    order_advance: OrderAdvance
    supprimer: number
    commande: Commande2
}

export interface OrderAdvance {
    validate: number
    send: number
    close: number
    annuler: number
}

export interface Commande2 {
    export: number
}

export interface Societe {
    lire: number
    creer: number
    supprimer: number
    export: number
    thirdparty_paymentinformation_advance: ThirdpartyPaymentinformationAdvance
    client: Client
    contact: Contact
}

export interface ThirdpartyPaymentinformationAdvance {
    write: number
}

export interface Client {
    voir: number
}

export interface Contact {
    lire: number
    creer: number
    supprimer: number
    export: number
}

export interface Categorie {
    lire: number
    creer: number
    supprimer: number
}

export interface Service {
    lire: number
    creer: number
    supprimer: number
    export: number
}

export interface Export {
    lire: number
    creer: number
}

export interface Import {
    run: number
}

export interface Agenda {
    myactions: Myactions
    allactions: Allactions
    export: number
}

export interface Myactions {
    read: number
    create: number
    delete: number
}

export interface Allactions {
    read: number
    create: number
    delete: number
}

export interface Ecm {
    read: number
    upload: number
    setup: number
}

export interface Api {
    apikey: Apikey
}

export interface Apikey {
    generate: number
}

export interface Multicompany {
    read: number
    write: number
    delete: number
    thirdparty: Thirdparty
    contact: Contact2
    product: Product
    propal: Propal
    propal_advance: PropalAdvance2
}

export interface Thirdparty {
    read: number
    write: number
    delete: number
}

export interface Contact2 {
    read: number
    write: number
    delete: number
}

export interface Product {
    read: number
    write: number
    delete: number
}

export interface Propal {
    read: number
    write: number
    close: number
    delete: number
}

export interface PropalAdvance2 {
    validate: number
    send: number
}

export interface Cron {
    read: number
    create: number
    delete: number
    execute: number
}

export interface Amdpeasykanban {
    board: Board
}

export interface Board {
    read: number
    write: number
    delete: number
}

export interface Propal2 {
    lire: number
    creer: number
    propal_advance: PropalAdvance3
    supprimer: number
    export: number
}

export interface PropalAdvance3 {
    validate: number
    send: number
    close: number
}


/**
 * Fonction pour retourner un équivament en string d'un User
 * @param firstname
 * @param lastname
 * @param login
 */
export function userToString({ firstname, lastname, login }: User) {
    // get des différentes parties qui identifie un utilisateur - nom et prénom
    const parts = [firstname, lastname].filter(e => !!e);

    // On regarde ce qui reste du filter et s'il y a des élément on join pour obetnir un string, sinon on prend le login en dernier recours
    return parts.length > 0 ? parts.join(' ') : login;
}