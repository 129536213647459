import {Controller} from "react-hook-form";
import GenericTextFieldControl from "../../../Common/Form/GenericTextFieldControl";
import {useProposalFormContext} from "../ProposalFormContext";

export default function PeriodOfValidityControl() {
    const name = "periodOfValidity";
    const label = "Durée de validité (en jours)";

    const { submitOnBlur } = useProposalFormContext();

    return (
        <Controller
            name={name}
            defaultValue={"15"}
            render={
                ({ field: { value, onChange }, fieldState: { error } }) => {
                    return (
                        <GenericTextFieldControl
                            label={label}
                            error={error}
                            TextFieldProps={{
                                value,
                                onChange,
                                onBlur: submitOnBlur,
                            }}
                        />
                    )
                }
            }
        />
    )
}