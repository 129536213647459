import React from 'react';
import {
    IonCheckbox,
    IonInput,
    IonItem, IonItemDivider,
    IonItemGroup,
    IonLabel,
    IonList
} from "@ionic/react";
import {Controller, useFormContext} from "react-hook-form";
import {PaymentMethod} from "../../models/payment.model";
import {DevTool} from "@hookform/devtools";
import {DateTime} from "luxon";
import {idateTime} from "../../utils";
import HybridDatePicker from "../Common/Lab/HybridDatePicker";

const SearchPayment = () => {
    const {control} = useFormContext();

    return (
       <>
            <IonList>
                <IonItem>
                    <IonLabel>Date début</IonLabel>
                    <Controller
                        name={"startDate"}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <HybridDatePicker
                                    value={value || null}
                                    InputProps={{
                                        size: 'small',
                                        error: !!error,
                                        placeholder: 'Date début'
                                    }}
                                    onChange={
                                        (v: DateTime | null) => {
                                            onChange(v ? idateTime(v.startOf('day')) : null)
                                        }
                                    }
                                />
                            )
                        }}
                    />
                </IonItem>

                <IonItem>
                    <IonLabel>Date fin</IonLabel>
                    <Controller
                        name={"endDate"}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            return (
                                <HybridDatePicker
                                    value={value || null}
                                    InputProps={{
                                        size: 'small',
                                        error: !!error,
                                        placeholder: 'Date fin'
                                    }}
                                    onChange={
                                        (v: DateTime | null) => {
                                            onChange(v ? idateTime(v.endOf('day')) : null)
                                        }
                                    }
                                />
                            )
                        }}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Pièce</IonLabel>
                    <Controller
                        name={"invoiceRef"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Libellé</IonLabel>
                    <Controller
                        name={"paymentNo"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Montant</IonLabel>
                    <Controller
                        name={"amount"}
                        render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"number"} />}
                    />
                </IonItem>
                <IonItemGroup>
                    <IonItemDivider color="light">
                        <IonLabel>
                            <b>Mode de règlement</b>
                        </IonLabel>
                    </IonItemDivider>

                    <PaymentMethodItems />
                </IonItemGroup>
            </IonList>

           <DevTool control={control} />
       </>
    );
};

export default SearchPayment;

const paymentMethods = [
    { label: 'Carte bancaire', value:PaymentMethod.CB  },
    { label: 'Chèque', value: PaymentMethod.CHEQUE },
    { label: 'Espèce', value: PaymentMethod.ESPECE },
    { label: 'Prélèvement', value:PaymentMethod.PRELEVEMENT },
    { label: 'Virement', value: PaymentMethod.VIREMENT  },
]

function PaymentMethodItems() {
    return (
        <>
            {
                paymentMethods.map(
                    ({ label, value }, index) => {
                        return (
                            <Controller
                                key={label}
                                name={`paymentMethod.${index}`}
                                render={({ field}) => (
                                    <IonItem key={label} lines={"none"}>
                                        <IonCheckbox
                                            slot="start"
                                            checked={!!field.value}
                                            onIonChange={
                                                (e) => field.onChange(e.target.checked ? value : undefined)
                                            }
                                        />
                                        <IonLabel>{label}</IonLabel>
                                    </IonItem>
                                )}
                            />
                        )
                    }
                )
            }
        </>
    )
}