import {ActionComm} from "../../models/action-comm.model";
import {UseQueryResult} from "@tanstack/react-query";
import {createContext, useContext} from "react";
import {PaginatedResponse} from "../../models/common.model";

type EventsListContextProps = {
    query?: UseQueryResult<PaginatedResponse<ActionComm>>,
}

export const EventsListContext = createContext<EventsListContextProps>({});

export function useEventsListContext(){
    return useContext(EventsListContext);
}