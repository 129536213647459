import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";

interface ConfirmationDialogProps {
    open: boolean;
    title: React.ReactNode;
    message: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
    onAccept?(): void;
    onCancel?(): void;
}

export default function StatelessConfirmationDialog({ open, onAccept, onCancel, title, message, confirmText = 'Confirmer', cancelText = 'Annuler' }: ConfirmationDialogProps) {
    const handleAccept = () => onAccept && onAccept();
    const handleClose = () => onCancel && onCancel();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"text"}>
                    {cancelText}
                </Button>

                <Button onClick={handleAccept} variant={"contained"} autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}