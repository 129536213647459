import axios from "axios";

interface Payload {
    q: string;
    type?: 'street' | 'housenumber';
}

interface Response {
    type: string;
    version: string;
    features: Array<{
        type: string;
        geometry: {
            type: string;
            coordinates: number[];
        };
        properties: Properties;
    }>;
    attribution: string;
    licence: string;
    query: string;
    filters: { type: string }
    limit: number;
}


interface Properties {
    label: string;
    score: number;
    id: string;
    name: string;
    postcode: string;
    citycode: string;
    x: number;
    y: number;
    city: string;
    district: string;
    context: string;
    type: string;
    importance: number;
    street: string;
}

const url = 'https://api-adresse.data.gouv.fr/search/?q=Rue%20Lecourbe%2075015%20Paris&type=street&autocomplete=1'

export async function searchAddress({ q, type = 'street' }: Payload) {
    const { data } = await axios.get<Response>(url, {
        params: {
            q,
            type,
            autocomplete: 1
        }
    });

    return data;
}