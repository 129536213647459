import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useThirdPartiesListContext} from "./useThirdPartiesListContext";
import styled from "@emotion/styled";
import React, {PropsWithChildren} from "react";
import {useThirdPartyBottomDrawerState} from "./useThirdPartyBottomDrawerStore";
import {ErrorOverlay, LoadingOverlay} from "../Common/Table/PowerTable";
import {Slide} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {IonModal, IonRouterLink} from "@ionic/react";

export default function ThirdPartyTable() {
    const { query } = useThirdPartiesListContext();

    const loading = query?.isLoading && query?.isFetching;

    const rows = query?.data?.items || [];

    return (
        <TableContainer sx={{ background: 'white', maxHeight: 760, minHeight: 500 }}>
            <StyledTable aria-label="thirdparty table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Code client</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Adresse</TableCell>
                        <TableCell>Code Postal</TableCell>
                        <TableCell>Ville</TableCell>
                        <TableCell>Téléphone Mr.</TableCell>
                        <TableCell>Téléphone Mme</TableCell>
                        <TableCell>Téléphone Fixe</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>

                {
                    // ErrorOverlay si jamais erreur
                    query?.isError && (
                        <ErrorOverlay colSpan={8} />
                    )
                }

                {
                    // LoadingOverlay on loading
                    loading && (
                        <LoadingOverlay colSpan={8} />
                    )
                }

                {
                    !loading && (
                        <TableBody>
                            {
                                rows.map(
                                    (row) => (
                                        <Row key={row.id} id={row.id} name={row.name}>
                                            <TableCell>{row.code_client}</TableCell>
                                            <NameCell name={row.name} rowId={row.id} />
                                            <ThirdPartyTypeCell value={row.client} />
                                            <TableCell>{row.address}</TableCell>
                                            <TableCell>{row.zip}</TableCell>
                                            <TableCell>{row.town}</TableCell>
                                            <TableCell>{row.phone}</TableCell>
                                            <TableCell>{row.array_options?.options_personal_mobile_phone}</TableCell>
                                            <TableCell>{row.array_options?.options_business_mobile_phone}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                        </Row>
                                    )
                                )
                            }
                        </TableBody>
                    )
                }
            </StyledTable>
        </TableContainer>
    );
}

const StyledTable = styled(Table)`
    & th {
        font-weight: bold;
    }
`;

const SpanLink = styled.span`
    cursor: pointer;
    text-decoration: underline; 
    font-weight: bold;
    color: var(--ion-color-primary)
`;

function NameCell({ name, rowId }: { name: string, rowId: string }) {
    return (
        <TableCell>
            <IonRouterLink routerLink={`/page/ThirdParties/${rowId}`}>
                {name}
            </IonRouterLink>
        </TableCell>
    )
}

function ThirdPartyTypeCell({ value }: { value: string }) {
    if (value === '2') {
        return <TableCell>Prospect</TableCell>;
    }

    if (value === '1') {
        return <TableCell>Client</TableCell>;
    }

    if (value === '0') {
        return <TableCell>Fournisseur</TableCell>;
    }

    return <TableCell>N/A</TableCell>;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Row(props: PropsWithChildren<{ id: string, name: string }>) {
    // si le bottom drawer est ouvert, c'est qu'il y a un tiers courant
    const { currentThirdPartyId: id } = useThirdPartyBottomDrawerState();

    // on le déclare alors actif si les ids sont égaux
    const isActive = id === props.id;

    // const openBottomDrawer = useSetCurrentThirdPartyAndOpenDrawer();
    //
    // const [open, setOpen] = React.useState(false);
    //
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    //
    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <>
            <StyledTablesRow
                className={isActive ? 'active' : ''}

                // on click on open le drawer avec l'id et le nom du tiers
                // onClick={() => handleClickOpen()}
            >
                {props.children}
            </StyledTablesRow>

            {/*<IonModalFullScreen isOpen={open}>*/}
            {/*    <div className="ion-padding" style={{ paddingBottom: 0 }}>*/}
            {/*        <IonButton fill="clear" size={"small"} onClick={handleClose}>*/}
            {/*            <IonIcon slot="start" md={closeSharp} ios={closeOutline} />*/}
            {/*            Revenir à la liste*/}
            {/*        </IonButton>*/}
            {/*    </div>*/}

            {/*    <ThirdPartyShow id={props.id} />*/}
            {/*</IonModalFullScreen>*/}
        </>
    )
}

const IonModalFullScreen =  styled(IonModal)`
    --height: 100%;
    --width: 100%;
`;

const StyledTablesRow = styled(TableRow)`
    &:hover {
      cursor: pointer;    
    }
    
    &.active {
        border: 2px solid var(--ion-color-primary);
        background-color: #eefafd;
    }
`;