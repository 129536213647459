import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {CardSearchResult} from "../../models/kanban/card.model";
import {Endpoint} from "../endpoint.enum";

interface Payload {
    startCreateDate?: string; // "2023-05-01 00:00:00",
    endCreateDate?: string; // "2023-05-25 23:59:59"
}

export async function searchPhotovoltaiqueKanbanCards(payload: Payload) {
    const { data } = await http.post<PaginatedResponse<CardSearchResult & { interventionStatus: string }>>(Endpoint.searchPhotovoltaiqueKanbanCards, payload);

    return data;
}