import create from 'zustand';

interface ThirdPartyBottomDrawerStore {
    isOpen: boolean; // drawer ouvert ou pas

    currentThirdPartyId: string; // l'id du tiers courant
    currentThirdPartyName: string; // Le nom de la personne courante

    setCurrentThirdPartyAndOpenDrawer(id: string, name: string): void;

    openDrawer(): void;
    
    closeDrawer(): void;
}

const useThirdPartyBottomDrawerStore = create<ThirdPartyBottomDrawerStore>((set) => ({
    isOpen: false,
    currentThirdPartyId: '',
    currentThirdPartyName: '',
    setCurrentThirdPartyAndOpenDrawer: (id: string, name: string) => set({ currentThirdPartyId: id, currentThirdPartyName: name, isOpen: true }),
    openDrawer: () => set(state => ({ isOpen: true })),
    closeDrawer: () => set(state => ({ isOpen: false, currentThirdPartyId: '', currentThirdPartyName: '' }))
}));

export function useThirdPartyBottomDrawerState() {
    const isOpen = useThirdPartyBottomDrawerStore(s => s.isOpen);
    const currentThirdPartyId = useThirdPartyBottomDrawerStore(s => s.currentThirdPartyId);
    const currentThirdPartyName = useThirdPartyBottomDrawerStore(s => s.currentThirdPartyName);

    return {
        isOpen,
        currentThirdPartyId,
        currentThirdPartyName,
    }
}

export function useSetCurrentThirdPartyAndOpenDrawer() {
    return useThirdPartyBottomDrawerStore(s => s.setCurrentThirdPartyAndOpenDrawer);
}

export function useOpenThirdPartyBottomDrawer() {
    return useThirdPartyBottomDrawerStore(s => s.openDrawer);
}

export function useCloseThirdPartyBottomDrawer() {
    return useThirdPartyBottomDrawerStore(s => s.closeDrawer);
}