import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Proposal} from "../../models/proposal.model";

interface ProposalAcceptOrRejectDto {
    status: 2 | 3, // status (integer): Must be 2 (accepted) or 3 (refused)
    note_private?: string,
}

async function acceptOrRejectProposal(proposalId: string, dto: ProposalAcceptOrRejectDto) {
    const { data } = await http.post<Proposal>(Endpoint.proposalAcceptOrReject.replace(':id', proposalId), dto);

    return data;
}

export function acceptProposal(proposalId: string) {
    return acceptOrRejectProposal(proposalId, { status: 2 });
}

export function rejectProposal(proposalId: string) {
    return acceptOrRejectProposal(proposalId, { status: 3 });
}