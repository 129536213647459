import Page from "./Page";
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import {IonContent, IonList, IonListHeader, IonMenu, IonRouterOutlet, IonSplitPane} from "@ionic/react";
import {
    calendarNumberOutline,
    calendarNumberSharp,
    calendarOutline,
    calendarSharp,
} from "ionicons/icons";
import {AppPage} from "./index";
import Calendar from "../components/Agenda/Calendar";
import FoireList from "../components/Agenda/FoireList";
import SideNavLink from "../components/Common/Menu/SideNavLink";

const pages: AppPage[] = [
    {
        title: 'Agenda',
        url: '/Calendar',
        mdIcon: calendarNumberSharp,
        iosIcon: calendarNumberOutline,
    },
    {
        title: 'Foires',
        url: '/Foires',
        mdIcon: calendarSharp,
        iosIcon: calendarOutline
    },
]

export default function AgendaPage() {
    const match = useRouteMatch();

    return (
        <Page>
            <IonSplitPane contentId="AgendaPage-main" style={{ '--side-width': 300 }}>
                <IonMenu type={"overlay"} contentId="main">
                    <IonContent>
                        <IonList id="labels-list" className={"ion-padding"}>
                            <IonListHeader>
                                <b>Agenda</b>
                            </IonListHeader>
                            {
                                pages
                                    .filter(p => !p.disabled) // Ne filtrer que les pages à afficher
                                    .map(
                                        (p, index) => (
                                            <SideNavLink
                                                key={index}
                                                title={p.title}
                                                to={`${match.url}${p.url}`}
                                                mdIcon={p.mdIcon}
                                                iosIcon={p.iosIcon}
                                            />
                                        )
                                    )
                            }
                        </IonList>
                    </IonContent>
                </IonMenu>

                <IonRouterOutlet id={"AgendaPage-main"}>
                    <Route path={match.path} exact={true}>
                        <Redirect to={`${match.url}/Calendar`} />
                    </Route>
                    <Route path={`${match.url}/Calendar`} component={Calendar} />
                    <Route path={`${match.url}/Foires`} component={FoireList} />
                </IonRouterOutlet>
            </IonSplitPane>
        </Page>
    )
}

