export interface IVatRate {
    label: string;
    value: string;
}

export class VatRate {
    static Tva_55 = {
        label: '5.5 %',
        value: 5.5
    };

    static Tva_10 = {
        label: '10 %',
        value: 10
    };

    static Tva_20 = {
        label: '20 %',
        value: 20
    };

    public static PacAirEauRates() {
        return [
            this.Tva_55,
            this.Tva_20,
        ]
    }

    public static PacAirAirRates() {
        return [
            this.Tva_10,
            this.Tva_20,
        ]
    }

    public static PhotovoltaiqueRates() {
        return [
            this.Tva_10,
            this.Tva_20,
        ]
    }

    public static BallonThermodynamiqueRates() {
        return [
            this.Tva_55,
            this.Tva_10,
            this.Tva_20,
        ]
    }

    public static AutreMaterielRates() {
        return [
            this.Tva_10,
            this.Tva_20,
        ]
    }
}

export const TVA_55 = 5.5;
export const TVA_10 = 10;
export const TVA_20 = 20;

export const tvaTauxReduitByProduct: Record<string, number> = {
    pacAirEau: TVA_55,
    pacAirAir: TVA_10,
    ballonThermodynamique: TVA_55,
    panneauxPhotovoltaique: TVA_10,
    autreMateriel: TVA_10,
}