import {useState} from "react";
import {InvoiceFilterModel} from "./types";
import {useForm} from "react-hook-form";
import {DateTime} from "luxon";

export function useInvoiceFilterForm() {
    const defaultValues = {
        startDate: DateTime.now().startOf('month').toISO(),
        endDate: DateTime.now().endOf('month').toISO()
    }

    const [formValues, setFormValues] = useState<InvoiceFilterModel>(defaultValues);
    const methods = useForm<InvoiceFilterModel>({
        defaultValues,
    });

    // Appliquer les filtres
    const applyFilters = methods.handleSubmit((values) => setFormValues(values));

    // Réinitialiser les filtres
    const resetFilters = () => {
        methods.reset();
        applyFilters();
    }

    return {
        methods,
        filters: formValues,
        applyFilters,
        resetFilters,
    }
}