import {Card, CardContent, Grid, Menu, Stack} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TableRow, {TableRowProps} from '@mui/material/TableRow';
import ThirdPartyControl from "./Controls/ThirdPartyControl";
import PeriodOfValidityControl from "./Controls/PeriodOfValidityControl";
import PaymentConditionsControl from "./Controls/PaymentConditionsControl";
import InlineTableControl, {InlineTableControlProps} from "../../Common/Form/Table/InlineTableControl";
import {
    InlineTableColDef,
    InlineTableRenderCellParams,
    InlineTableRenderInputParams
} from "../../Common/Form/Table/types";
import ProductAutocomplete, {ProductAutocompleteOption} from "../../Product/ProductAutocomplete";
import React, {PropsWithChildren, useEffect, useMemo} from "react";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {FormattedNumber} from "react-intl";
import PublicNotesControl from "./Controls/PublicNotesControl";
import PrivateNotesControl from "./Controls/PrivateNotesControl";
import {ProposalLineFormModel} from "./types";
import {groupBy, mapValues, sum, sumBy} from "lodash";
import GenericTextFieldController from "../../Common/Form/GenericTextFieldController";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Check from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as DOMPurify from 'dompurify';
import EndOfValidityDateControl from "./Controls/EndOfValidityDateControl";
import {useProposalFormContext} from "./ProposalFormContext";
import {convertToNumber} from "../../../utils";

interface ProposalFormProps {
    hiddenControls?: {
        thirdParty?: true;
        periodOfValidity?: true;
    };
    additionalControls?: {
        endOfValidityDate?: true;
    };
    enableLinesCopy?: InlineTableControlProps["enableLinesCopy"];
    onLineEdited?: InlineTableControlProps["onLineEdited"];
    onLineDeleted?: InlineTableControlProps["onLineDeleted"];
}

export default function ProposalForm({ enableLinesCopy, onLineEdited, onLineDeleted, hiddenControls = {}, additionalControls = {} }: ProposalFormProps = {}) {
    const { submitOnBlur } = useProposalFormContext();

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <Stack spacing={1}>
                            {
                                !hiddenControls.thirdParty && (
                                    <ThirdPartyControl />
                                )
                            }

                            {
                                additionalControls.endOfValidityDate && (
                                    <EndOfValidityDateControl />
                                )
                            }

                            {
                                !hiddenControls.periodOfValidity && (
                                    <PeriodOfValidityControl />
                                )
                            }

                            <PaymentConditionsControl />

                            {/*<OrderDateControl />*/}

                            <Stack spacing={1} direction={"row"}>
                                {/*<ExternalOrderNoControl />*/}

                                {/*<EstimatedDeliveryDateControl />*/}
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={6}>
                        <Stack spacing={1}>
                            <Stack spacing={1} direction={"row"} alignItems={"center"}>
                                <GenericTextFieldController
                                    name={"extraFields.puissancePV"}
                                    label={"Puissance PV"}
                                    textFieldProps={{
                                        onBlur: submitOnBlur,
                                    }}
                                />

                                <GenericTextFieldController
                                    name={"extraFields.reductionCO2"}
                                    label={"Réduction CO2"}
                                    textFieldProps={{
                                        onBlur: submitOnBlur,
                                    }}
                                />
                            </Stack>

                            <Stack spacing={1} direction={"row"} alignItems={"center"}>
                                <GenericTextFieldController
                                    name={"extraFields.coutRevientPersonnel"}
                                    label={"Taux H revient"}
                                    defaultValue={"30"}
                                    textFieldProps={{
                                        onBlur: submitOnBlur,
                                    }}
                                />

                                <GenericTextFieldController
                                    name={"extraFields.margePersonnel"}
                                    label={"K vente MO (en %)"}
                                    defaultValue={"45"}
                                    textFieldProps={{
                                        onBlur: submitOnBlur,
                                    }}
                                />

                                {/*<NoteControl name={"privateNotes"} label={t("Notes privées")} />*/}

                                {/*<NoteControl name={"publicNotes"} label={t("Notes publiques")} />*/}

                                <PrixHoraireVenteMainOeuvre />
                            </Stack>

                            <Stack spacing={1} direction={"row"} style={{ width: '33%' }}>
                                <GenericTextFieldController
                                    name={"extraFields.margeFourniture"}
                                    label={"K vente FO (en %)"}
                                    defaultValue={"45"}
                                    textFieldProps={{
                                        onBlur: submitOnBlur,
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={12} mt={2}>
                        {/*<OrderLinesControl name={"lines"} />*/}
                        <ProposalLinesControl
                            enableLinesCopy={enableLinesCopy}
                            onLineEdited={onLineEdited}
                            onLineDeleted={onLineDeleted}
                        />
                    </Grid>

                    <Grid item xs={6} md={8}>
                        <Stack spacing={1}>
                            <PublicNotesControl />

                            <PrivateNotesControl />
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4}>
                        <AllTotals />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

function PrixHoraireVenteMainOeuvre() {
    const name = "extraFields.coutVentePersonnel";
    const label = "Taux H vente";

    const { setValue } = useFormContext();

    const liveCoutRevientPersonnel = useWatch({ name: 'extraFields.coutRevientPersonnel', defaultValue: '30' });
    const liveMargePersonnel = useWatch({ name: 'extraFields.margePersonnel', defaultValue: '45' });

    useEffect(() => {
        const coutRevientPersonnel = convertToNumber(liveCoutRevientPersonnel);
        const margePersonnel = 1 + (convertToNumber(liveMargePersonnel) / 100);

        const result = (coutRevientPersonnel * margePersonnel).toFixed(2);

        setValue(name, result);
    }, [liveCoutRevientPersonnel, liveMargePersonnel]);

    return (
        <GenericTextFieldController
            name={name}
            label={label}
            textFieldProps={{
                disabled: true,
                variant: "filled",
                hiddenLabel: true,
                sx: {
                    "& .MuiInputBase-input": {
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)!important',
                    }
                }
            }}
        />
    )
}


const cols: InlineTableColDef[] = [
    {
        field: 'product',
        label: 'Produit',
        width: 250,
        renderCell({value, error}: InlineTableRenderCellParams<ProductAutocompleteOption>) {
            return <span className={!!error ? "error" : ""}>{value.label}</span>
        },
        renderInput({ value, onChange, rowIndex, formContext, textFieldProps }: InlineTableRenderInputParams<ProductAutocompleteOption>) {
            const { setValue } = formContext;

            return (
                <ProductAutocomplete
                    value={value}
                    onChange={
                        (e, product) => {
                            if (!product) {
                                return;
                            }

                            onChange(product);

                            setValue(`rows.${rowIndex}.description`, product.description, { shouldValidate: true });
                            setValue(`rows.${rowIndex}.priceWithoutTax`, product.priceWithoutTax, { shouldValidate: true });
                            setValue(`rows.${rowIndex}.tvaTx`, product.tvaTx, { shouldValidate: true });

                            setValue(`rows.${rowIndex}.extraFields.tpsPose`, product.extraFields?.tpsPose, { shouldValidate: true });
                            setValue(`rows.${rowIndex}.extraFields.coutFournitures`, product.extraFields?.coutFournitures, { shouldValidate: true });
                        }
                    }
                    textFieldProps={textFieldProps}
                />
            )
        }
    },
    {
        field: 'description',
        label: 'Description',
        width: 250,
        inputType: 'textarea',
        renderCell({ value }: InlineTableRenderCellParams) {
            const html = DOMPurify.sanitize(value);
            return <span dangerouslySetInnerHTML={{ __html: html }}/>
        }
    },
    {
        field: 'qty',
        label: 'Qté',
        width: 150,
        defaultValue: '1',
    },
    {
        field: 'extraFields.tpsPose',
        label: 'Tps Pose',
        width: 150,
        defaultValue: '1',
    },
    {
        field: 'extraFields.tauxMO',
        label: 'MO U',
        width: 150,
        CellContentWrapper: CoutMainOeuvreWatcher,
        renderCell({ value }: InlineTableRenderCellParams) {
            return (
                <FormattedNumber
                    value={Number(value)}
                    minimumFractionDigits={2}
                />
            )
        },
    },
    {
        field: 'extraFields.coutFournitures',
        label: 'FO U',
        width: 150,
    },
    {
        field: 'priceWithoutTax',
        label: 'P.U HT',
        width: 150,
        CellContentWrapper: PriceWithoutTaxWatcher,
        renderCell({ value }: InlineTableRenderCellParams) {
            return (
                <FormattedNumber
                    value={Number(value)}
                    minimumFractionDigits={2}
                />
            )
        },
    },
    {
        field: 'discount',
        label: 'Remise',
        width: 150,
        renderCell({ value }: InlineTableRenderCellParams) {
            if (!value) {
                return null;
            }

            return (
                <FormattedNumber
                    style='percent'
                    value={Number(value || 0) / 100}
                    minimumFractionDigits={2}
                />
            )
        }
    },
    {
        field: 'tvaTx',
        label: 'TVA',
        width: 150,
        renderCell({ value }: InlineTableRenderCellParams) {
            return (
                <FormattedNumber
                    style='percent'
                    value={Number(value || 0) / 100}
                    minimumFractionDigits={2}
                />
            )
        }
    },
    {
        field: 'priceWithTax',
        label: 'Prix TTC',
        disabledEdition: true,
        width: 150,
        renderCell({ value, field, rowIndex }: InlineTableRenderCellParams) {
            return (<TotalTTC rowIndex={rowIndex} />)
        }
    },
    {
        field: 'actions',
        label: '',
        disabledEdition: true,
        width: 50,
        renderCell({ rowIndex, remove }: InlineTableRenderCellParams) {
            return (
                <LineActions
                    rowIndex={rowIndex}
                    remove={remove}
                />
            )
        }
    }
];

function LineActions({ rowIndex, remove }: { rowIndex: number, remove: InlineTableRenderCellParams["remove"] }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="delete-row"
                size={"small"}
                onClick={handleClick}
                sx={{
                    padding: 0,
                    "&:hover": {
                        backgroundColor: 'transparent',
                    }
                }}
            >
                <MoreVertIcon fontSize="inherit" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Controller
                    name={`rows.${rowIndex}.blockAutoRecalculation`}
                    render={
                        ({ field: { value, onChange }}) => {
                            return (
                                <MenuItem onClick={
                                    () => {
                                        onChange(!value);
                                        handleClose();
                                    }
                                }>
                                    <ListItemIcon>
                                        {value ? <Check /> : null}
                                    </ListItemIcon>
                                    Bloquer le recalcul automatique
                                </MenuItem>
                            )
                        }
                    }
                />

                <Controller
                    name={`rows.${rowIndex}.excludeFromTotal`}
                    render={
                        ({ field: { value, onChange }}) => {
                            return (
                                <MenuItem onClick={
                                    () => {
                                        onChange(!value);
                                        handleClose();
                                    }
                                }>
                                    <ListItemIcon>
                                        {value ? <Check /> : null}
                                    </ListItemIcon>
                                    Ne pas inclure dans le calcul du total
                                </MenuItem>
                            )
                        }
                    }
                />

                <Divider />

                <MenuItem onClick={
                    () => {
                        remove(rowIndex);
                        handleClose();
                    }
                }>
                    <ListItemIcon>
                        <DeleteIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText>Supprimer la ligne</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}

function CoutMainOeuvreWatcher({ rowIndex, children }: PropsWithChildren<{ rowIndex: number }>) {
    const { setValue } = useFormContext();

    const liveBlockAutoRecalculation = useWatch({ name: `rows.${rowIndex}.blockAutoRecalculation` });
    const liveTpsPose = useWatch({ name: `rows.${rowIndex}.extraFields.tpsPose` });
    const liveGlobalTauxMO = useWatch({ name: `extraFields.coutVentePersonnel` }); // Taux horaire Main d'Oeuvre

    const name = `rows.${rowIndex}.extraFields.tauxMO`;

    useEffect(() => {
        // Si le calcul auto est bloqué sur la ligne: ne rien faire
        if (liveBlockAutoRecalculation) {
            return;
        }

        const tpsPose = convertToNumber(liveTpsPose);
        const tauxGlobalMO = convertToNumber(liveGlobalTauxMO);
        const result = (tpsPose * tauxGlobalMO).toFixed(2);
        setValue(name, result);
    }, [liveBlockAutoRecalculation, liveTpsPose, liveGlobalTauxMO]);

    return (
       <>{children}</>
    )
}

function PriceWithoutTaxWatcher({ rowIndex, children }: PropsWithChildren<{ rowIndex: number }>) {
    const { setValue } = useFormContext();

    const liveBlockAutoRecalculation = useWatch({ name: `rows.${rowIndex}.blockAutoRecalculation` });
    const liveTauxMO = useWatch({ name: `rows.${rowIndex}.extraFields.tauxMO` });
    const liveMargeFO = useWatch({ name: `extraFields.margeFourniture` }); // Marge appliquée au cout des fournitures
    const liveCoutFournitures = useWatch({ name: `rows.${rowIndex}.extraFields.coutFournitures` });

    const name = `rows.${rowIndex}.priceWithoutTax`;

    useEffect(() => {
        // Si le calcul auto est bloqué sur la ligne: ne rien faire
        if (liveBlockAutoRecalculation) {
            return;
        }

        const coeffFO = 1 + (convertToNumber(liveMargeFO) / 100);
        const coutFO = convertToNumber(liveCoutFournitures) * coeffFO;
        const coutMO = convertToNumber(liveTauxMO);

        const result = (coutMO + coutFO).toFixed(2);

        setValue(name, result);
    }, [liveBlockAutoRecalculation, liveTauxMO, liveMargeFO, liveCoutFournitures]);

    return <>{children}</>;
}

function TotalTTC({ rowIndex }: { rowIndex: number }) {
    const liveQty = useWatch({ name: `rows.${rowIndex}.qty` });
    const livePriceHT = useWatch({ name: `rows.${rowIndex}.priceWithoutTax` });
    const liveTvaTx = useWatch({ name: `rows.${rowIndex}.tvaTx` });
    const liveDiscount = useWatch({ name: `rows.${rowIndex}.discount` });

    const result = useMemo(() => {
        const coeffTva = 1 + (convertToNumber(liveTvaTx) / 100);
        const coeffDiscount = 1 - (convertToNumber(liveDiscount) / 100);

        const priceHT = convertToNumber(livePriceHT);
        const qty = convertToNumber(liveQty);

        return ((priceHT * qty) * coeffDiscount) * coeffTva;
    }, [liveQty, livePriceHT, liveTvaTx, liveDiscount]);

    return (
        <FormattedNumber
            value={result}
            minimumFractionDigits={2}
        />
    )
}

interface ProposalLinesControlProps {
    enableLinesCopy?: ProposalFormProps["enableLinesCopy"];
    onLineEdited?: ProposalFormProps["onLineEdited"];
    onLineDeleted?: ProposalFormProps["onLineDeleted"];
}

function ProposalLinesControl({ enableLinesCopy, onLineEdited, onLineDeleted }: ProposalLinesControlProps = {}) {
    return (
        <InlineTableControl
            columns={cols}
            sectionFieldName={"description"}
            slots={{
                tableRow: CustomTableRow,
            }}
            enableLinesCopy={enableLinesCopy}
            onLineEdited={onLineEdited}
            onLineDeleted={onLineDeleted}
        />
    )
}

function CustomTableRow({ rowIndex, children, ...restOfProps }: PropsWithChildren<TableRowProps & { rowIndex: number }>) {
    const isAutoRecalculationBlocked = useWatch({ name: `rows.${rowIndex}.blockAutoRecalculation` });
    const isExcludedFromTotal = useWatch({ name: `rows.${rowIndex}.excludeFromTotal` });

    const title = isAutoRecalculationBlocked ? "Le calcul automatique est bloquée pour cette ligne" : (
        isExcludedFromTotal ? "Cette ligne est exclue du calcul total" : ""
    );

    const styles = useMemo(() => {
        if (isAutoRecalculationBlocked) {
            return ({
                background: '#FFFFED',
                borderLeft: '2px solid #FFAA1D',
            })
        }

        if (isExcludedFromTotal) {
            return ({
                background: '#F6F6F6',
                borderLeft: '2px dashed #313131',
            })
        }

        return {};
    }, [isAutoRecalculationBlocked, isExcludedFromTotal]);

    return (
        <TableRow
            {...restOfProps}
            style={styles}
            title={title}
        >
            {children}
        </TableRow>
    )
}

function formatPercent(value: number) {
    const option = {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };

    const formatter = new Intl.NumberFormat("fr-FR", option);
    return formatter.format(value);
}

function formatCurrency(value: number) {
    const option = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    const formatter = new Intl.NumberFormat("fr-FR", option);
    return formatter.format(value);
}

function AllTotals() {
    // Add delete button
    // Voir le ONBLUR

    const rows: ProposalLineFormModel[] | undefined = useWatch({ name: 'rows' });

    const tvaTotals = (rows || [])
        .filter(r => !r.excludeFromTotal) // Ne pas tenir compte des lignes marqués comme à exclure du total
        .map(r => {
            // Gestion et calcul des remises
            const discount = convertToNumber(r.discount);
            const coeffDiscount = discount / 100;
            const qty = convertToNumber(r.qty) || 1;
            const priceWithoutTax = (convertToNumber(r.priceWithoutTax) * qty) * (1 - coeffDiscount);

            // Calcul du montant TVA
            const tvaTx = convertToNumber(r.tvaTx);
            const coeffTva = tvaTx / 100;
            const priceWithTax = priceWithoutTax * (1 + coeffTva);
            const tvaAmount = priceWithTax - priceWithoutTax;

            return {
                id: r.tvaTx,
                tvaTx: {
                    value: coeffTva,
                    text: formatPercent(coeffTva),
                },
                tvaAmount: {
                    value: tvaAmount,
                    text: formatCurrency(tvaAmount),
                },
                priceWithoutTax: {
                    value: priceWithoutTax,
                    text: formatCurrency(priceWithoutTax)
                },
                priceWithTax: {
                    value: priceWithTax,
                    text: formatCurrency(priceWithTax)
                }
            }
        });

    const tvaGroup = mapValues(
        groupBy(tvaTotals, i => i.tvaTx.text),
        i => sumBy(i, sd => sd.tvaAmount.value)
    );

    const totalHT = sum(tvaTotals.map(tva => tva.priceWithoutTax.value));
    const totalTTC = sum(tvaTotals.map(tva => tva.priceWithTax.value));

    return (
        <Table>
            <TableBody>
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        Montant hors taxes
                    </TableCell>
                    <TableCell align="right"><b>{formatCurrency(totalHT)}</b></TableCell>
                </TableRow>

                {
                    Object.entries(tvaGroup)
                        .filter(([tvaTx, amount]) => amount > 0) // Afficher le bloc tva que si montant positif
                        .map(
                            ([tvaTx, amount]) => {
                                return (
                                    <TableRow
                                        key={tvaTx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            TVA {tvaTx}
                                        </TableCell>
                                        <TableCell align="right">{formatCurrency(amount)}</TableCell>
                                    </TableRow>
                                )
                            }
                        )
                }

                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        Total TTC
                    </TableCell>
                    <TableCell
                        align="right"
                        style={{
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                        }}
                    >{formatCurrency(totalTTC)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}