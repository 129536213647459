// @ts-ignore
import * as rt from 'react-trello/dist/styles/Base';

// @ts-ignore
import Tag from 'react-trello/dist/components/Card/Tag';
import {styled} from "@mui/material";

const {
    CardHeader,
    CardRightContent,
    CardTitle,
    Detail,
    Footer,
    MovableCardWrapper
} = rt;

export default function DefaultCustomCard(props: any) {
    const {
        showDeleteButton,
        style,
        tagStyle,
        onClick,
        onDelete,
        onChange,
        className,
        id,
        title,
        label,
        description,
        tags,
        cardDraggable,
        alert: CardAlert,
        alertProps,
        error,
        editable,
        t
    } = props;

    return (
        <StyledMovableCardWrapper
            data-id={id}
            onClick={onClick}
            style={style}
            className={`${className} ${error ? 'error' : ''}`.trim()}
        >
            <CardHeader>
                <CardTitle draggable={cardDraggable}>
                    {title}
                </CardTitle>
                <CardRightContent>
                    {label}
                </CardRightContent>
                {/*{showDeleteButton && <DeleteButton onClick={this.onDelete} />}*/}
            </CardHeader>

            <Detail style={{ marginBottom: 5 }}>
                {description}

                <br />

                {
                    CardAlert && (
                        <CardAlert {...alertProps} />
                    )
                }
            </Detail>

            {tags && tags.length> 0 && (
                <Footer>
                    {tags.map((tag: any) => (
                        <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
                    ))}
                </Footer>
            )}
        </StyledMovableCardWrapper>
    )
}

const StyledMovableCardWrapper = styled(MovableCardWrapper)`
    &.error {
        border: solid 2px red;
    }
`;