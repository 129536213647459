import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type Payload = {
    foireId: string; // fail ici le naming a prit le suffice id contrairement à tout ce qui se fait dans toute l'app
}

type Pagination = {
    sortfield: string,
    sortorder: string,
    limit: number
}

export async function fetchTableauCommissionPayees(payload: Payload) {
    const { data } = await http.post<CommissionPayeesReturn>(Endpoint.reportsTableauCommissionPayees, payload);

    return data;
}

export type CommissionPayeesReturn = CommissionPayeesReturnNoData | CommissionPayeesReturnOk;

interface CommissionPayeesReturnOk {
    status: 'OK';
    data: CommissionPayeesData;
}

interface CommissionPayeesReturnNoData {
    status: 'NO_DATA';
}

export interface CommissionPayeesData {
    responsables: Payee[];
    vendeurs: Payee[];
    rabatteurs:Payee[];
}

interface Payee {
    id: string;
    firstname: string;
    lastname: string;
    fullName: string;
}