import {Endpoint} from "../endpoint.enum";
import {User} from "../../models/user.model";
import axios from "axios";
import {http} from "../../http";

type Payload = {
    login: string;
    includepermissions?: 0 | 1;
}

/**
 * Récupérer des infos sur mon utilisateur
 * Get properties of an user object by login
 */
export function getMe({ login, includepermissions = 0 }: Payload, token: string) {
    const url = http.defaults.baseURL + Endpoint.me.replace(":login", login);
    return axios.get<User>(url, {
        params: { includepermissions },
        headers: {
            DOLAPIKEY: token,
        }
    });
}