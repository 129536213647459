import {useQuery} from "@tanstack/react-query";
import * as blobUtil from "blob-util";
import {saveAs} from "file-saver";
import {
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonList
} from "@ionic/react";
import {cloudDownloadOutline, cloudDownloadSharp, documentText} from "ionicons/icons";
import {getInterventionFiles} from "../../calls/Interventions/getInterventionFiles";
import JSZip from "jszip";
import {useActiveCompany} from "../Company/company.store";
import {FullWhiteCard} from "./FullWhiteCard";
import React from "react";
import LoadingDots from "../LoadingDots";

const circleDownIcon = '/assets/icon/circle-down.svg';

export function InterventionDocuments({ id, customerName }: { id: string, customerName: string }) {
    const company = useActiveCompany();

    // const {data: proposal} = useProposalDetail(proposalId);

    const {data: files = [], refetch, isLoading} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));
    const isEmpty = (files && files.length === 0) && !isLoading;

    // Tous les autres fichiers sauf les images
    const documents = files.filter(f => !f.contentType.startsWith('image/'));

    const downloadZip = () => {
        const folderName = `Documents ${customerName} ${company.name}`;

        const zip = new JSZip();

        documents.forEach((image, index) => {
            const src = `${image.base64Content}`;

            zip.file(image.filename, src, {base64: true});
        })

        zip.generateAsync({type: "blob"}).then((content) => {
            saveAs(content, `${folderName}.zip`);
        });
    }

    const download = async (base64: string, filename: string) => {
        const blob = await blobUtil.base64StringToBlob(base64);
        saveAs(blob, filename);
    }

    return (
        <FullWhiteCard>
            <IonCardHeader style={{display: 'flex'}} className={"ion-align-items-center"}>
                <div>
                    <IonCardTitle>Documents ({documents.length})</IonCardTitle>
                </div>

                <IonButton fill="clear" style={{marginLeft: 'auto'}} color={'primary'} disabled={isLoading || isEmpty}
                           onClick={() => downloadZip()}>
                    <IonIcon slot={"start"} md={cloudDownloadSharp} ios={cloudDownloadOutline}/>
                    Télécharger
                </IonButton>
            </IonCardHeader>

            <IonCardContent>
                {
                    isLoading ? (
                        <LoadingDots/>
                    ) : (
                        <IonList>
                            {
                                documents.map(
                                    doc => (
                                        <IonItem key={doc.filename} button onClick={() => download(doc.base64Content, doc.filename)}>
                                            <IonIcon slot={'start'} icon={documentText} />
                                            <IonLabel>
                                                <h3>{doc.filename}</h3>
                                                <p>{doc.description}</p>
                                            </IonLabel>
                                            <IonIcon icon={circleDownIcon} slot="end"></IonIcon>
                                        </IonItem>
                                    )
                                )
                            }

                            {
                                (!isLoading && documents.length === 0) && (
                                    <>Pas de documents trouvés pour cette intervention</>
                                )
                            }
                        </IonList>
                    )
                }
            </IonCardContent>
        </FullWhiteCard>
    );
}