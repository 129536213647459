import {
    IonButton, IonButtons,
    IonIcon, IonItem, IonLabel, IonToolbar
} from "@ionic/react";
import {addSharp, addOutline, filterSharp, filterOutline} from "ionicons/icons";
import ThirdPartyTable from "./ThirdPartyTable";
import {ThirdPartiesListContext} from "./useThirdPartiesListContext";
import {useThirdPartiesQuery} from "./useThirdPartiesQuery";
import React from "react";
import ThirdPartyBottomDrawer from "./Drawer/ThirdPartyBottomDrawer";
import {FormProvider} from "react-hook-form";
import {useThirdPartyFilterForm} from "./useThirdPartyFilterForm";
import FilterDrawer from "../Common/FilterDrawer";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import SearchThirdParty from "./SearchThirdParty";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import { Paper } from "@mui/material";
import PowerTablePagination from "../Common/Table/PowerTablePagination";

export default function ThirdPartyList() {
    const { filters, applyFilters, resetFilters, methods } = useThirdPartyFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const query = useThirdPartiesQuery(filters,{ page, limit: rowsPerPage }, !!filters);

    const itemsCount = query?.data?.pagination?.itemsCount || 0;

    const contextValue = {
        query,
    };

    return (
        <FormProvider {...methods}>
            <ThirdPartiesListContext.Provider value={contextValue}>
                <IonToolbar>
                    <IonItem lines={"none"}>
                        <IonLabel>
                            <h1>Annuaire</h1>
                            <h3>Liste détaillée des tiers</h3>
                        </IonLabel>

                        <IonButtons slot={"end"}>
                            <FiltersButton/>
                            <NewButton/>
                        </IonButtons>
                    </IonItem>
                </IonToolbar>

                <Paper sx={{ width: '100%', mt: 1 }} elevation={2}>
                    <ThirdPartyTable />

                    <PowerTablePagination
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalRowsCount={itemsCount}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </Paper>

                <FilterDrawer
                    loading={query.isLoading && query.isFetching}
                    onApply={() => {
                        resetPage();
                        applyFilters();
                    }}
                    onReset={() => resetFilters()}
                >
                    <SearchThirdParty />
                </FilterDrawer>

                <ThirdPartyBottomDrawer />
            </ThirdPartiesListContext.Provider>
        </FormProvider>

    )
}

function NewButton() {
    return (
        <IonButton fill={"solid"} color={"primary"} size={"default"} routerLink={'/page/NewThirdParty'} routerDirection={"root"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            Nouveau
        </IonButton>
    )
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return(
        <>
            <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
                <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
                Filtres
            </IonButton>
        </>
    )
}