import create from 'zustand'
import {DateTime} from "luxon";

interface BoardState {
    currentBoardId: number;
    setCurrentBoardId(id: number): void;

}

export const useBoardStore = create<BoardState>((set) => ({
    currentBoardId: 0,
    setCurrentBoardId: (id) => set(() => ({ currentBoardId: id })),
}))

export function useBoardState(): [number, (id: number) => void] {
    return useBoardStore(state => [state.currentBoardId, state.setCurrentBoardId]);
}

interface BoardFilters {
    startCreateDate?: DateTime;
    endCreateDate?: DateTime;
}

interface BoardFiltersState {
    filters: BoardFilters;
    updateFilters(values: BoardFilters): void;
}

export const useBoardFilters = create<BoardFiltersState>((set) => ({
    filters: {
        startCreateDate: DateTime.now().startOf("year"),
        endCreateDate: DateTime.now().endOf("year"),
    },
    updateFilters: (values) => set(() => ({ filters: values })),
}))