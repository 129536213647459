import React, {useRef} from "react";
import * as blobUtils from "blob-util";
import {IonButton, IonIcon} from "@ionic/react";
import {JSX} from "@ionic/core/components";
import {attachSharp} from "ionicons/icons";

const defaultAccept = 'image/png,image/jpeg,image/jpg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export interface FilePickerButtonProps {
    label?: string;
    buttonComponentProps?: {
        color?: JSX.IonButton["color"];
        slot?: 'start' | 'end';
        shape?: "round";
        fill?: "clear" | "default" | "outline" | "solid";
    };
    iconSlot?: 'start' | 'end' | 'icon-only';
    accept?: string;
    tooltip?: string;
    onFileChange?(result: {
        filename: string, // Nom complet du fichier extension comprise
        name: string, // Nom du fichier sans extension
        contentType: string,
        base64Content: string
    }): void;
}

export function FilePickerButton({label = 'Joindre', buttonComponentProps = {}, iconSlot = 'start', accept, tooltip, onFileChange}: FilePickerButtonProps) {
    const ref = useRef();

    const pickFile = () => {
        (ref.current as any)?.click();
    };

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];

        // handle the selected file
        if (!file) {
            return;
        }

        const filename = file.name;
        const name = filename.split('.').slice(0, -1).join('.') || filename;
        const contentType = file.type;
        const base64Content = await blobUtils.blobToBase64String(file);

        onFileChange && onFileChange({
            filename,
            name,
            contentType,
            base64Content,
        });
    };

    return (
        <>
            <IonButton
                {...buttonComponentProps}
                onClick={() => pickFile()}
                title={tooltip || ''}
            >
                <IonIcon slot={iconSlot} md={attachSharp} ios={attachSharp}/>
                {label}
            </IonButton>

            <input
                ref={ref as any}
                accept={accept || defaultAccept}
                type="file"
                style={{display: "none"}}
                onChange={handleFileChange}
            />
        </>
    )
}