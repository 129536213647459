import {UseQueryResult} from "@tanstack/react-query";
import {createContext, useContext} from "react";
import {PaginatedResponse} from "../../models/common.model";
import {InvoiceSearchResult} from "../../models/invoice.model";

type InvoicesListContextProps = {
    query?: UseQueryResult<PaginatedResponse<InvoiceSearchResult>>
}

export const InvoicesListContext = createContext<InvoicesListContextProps>({});

export function useInvoicesListContext() {
    return useContext(InvoicesListContext);
}