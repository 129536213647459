import {useQuery} from "@tanstack/react-query";
import {listBoardsByConnectedUser} from "../../calls/Kanban/listBoardsByConnectedUser";
import {useUser} from "../Auth/auth.store";

export function useListBoardsByConnectedUser() {
    const user = useUser();

    return useQuery(['boards', user.id], ({queryKey}) => {
        return listBoardsByConnectedUser();
    }, {
        enabled: !!user,
    });
}