import lodash from "lodash";
import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Product} from "../../models/product.model";
import {Pagination} from "../types";

export type SearchProductsPayload = {
    category?: string | number;
    includesubproducts?: boolean;
    typeBranchement?: string;
    typeBloc?: string;
    isIndoorUnit?: boolean;
    typeBallon?: string;
    volumeMaximumBassin?: number;
    puissance?: number;
    capacity?: number;
    reference?: string;
}

export type SearchProductResult = {
    data: Array<Product>; // Déprécié - Gardé pour la compatibilité
    items?: Array<Product>; // À utiliser à la place de data
    pagination: {
        limit: number,
        page: number,
        page_count: number,
        total: number,
    }
}

const mappings: Partial<Record<keyof SearchProductsPayload, string>> = {
    typeBranchement: 'ef.type_branchement',
    typeBloc: 'ef.type_bloc',
    isIndoorUnit: 'ef.is_indoor_unit',
    typeBallon: 'ef.type_ballon',
    volumeMaximumBassin: 'ef.volume_maximum_bassin',
    puissance: 'ef.puissance',
    capacity: 'ef.capacity',
    reference: 't.ref'
}

export async function searchProducts({ category, includesubproducts, ...extraFields }: SearchProductsPayload = {}, pagination: Pagination = {sortorder: 'DESC', limit: 100}): Promise<SearchProductResult> {
    const sqlfilters = buildSqlFilters(extraFields);

    const params = lodash.omitBy({
        category,
        includesubproducts,
        ...sqlfilters,
        pagination_data: true,
        sortfield: 't.ref',
        ...pagination,
    }, lodash.isNil);

    const { data } = await http.get<SearchProductResult>(Endpoint.products, { params });

    return data;
}

const buildSqlFilters = (payload: Omit<SearchProductsPayload, 'category'>) => {
    const sanitized =  lodash.omitBy(payload, lodash.isNil);

    const entries = Object.entries(sanitized);

    if (entries.length === 0) {
        return {};
    }

    const filters = entries
        .map(([key, value]) => {
            const field = mappings[key as 'reference'];
            let filterValue = value;

            if (typeof value === 'string' || (value as any) instanceof String) {
                filterValue = `'${value}'`;
            }

            if (typeof value === 'boolean') {
                filterValue = value ? 1 : 0;
            }

            if (key === 'isIndoorUnit') {
                return (
                    value === 0
                        ? `${field} is null`
                        : `${field} = 1`
                );
            }

            if (key === 'reference') {
                return (
                    `((t.ref:like:'${value}%') OR (t.label:like:'${value}%'))`
                )
            }

            return `${field} = ${filterValue}`;
        });

    return { sqlfilters: `(${filters.join(' AND ')})` };
}