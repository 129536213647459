import {UseQueryResult} from "@tanstack/react-query";
import {createContext, useContext} from "react";
import {PaginatedResponse} from "../../models/common.model";
import {PaymentSearchResult} from "../../models/payment.model";

type PaymentsListContextProps = {
    query?: UseQueryResult<PaginatedResponse<PaymentSearchResult>>
}

export const PaymentsListContext = createContext<PaymentsListContextProps>({});

export function usePaymentsListContext() {
    return useContext(PaymentsListContext);
}