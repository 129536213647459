import {useState} from "react";
import {useForm} from "react-hook-form";
import {EventFilterForm} from "./type";

export function useEventFilterForm() {
    const [formValues, setFormValues] = useState<EventFilterForm>({});
    const methods = useForm<EventFilterForm>();

    // Appliquer les filtres
    const applyFilters = methods.handleSubmit((values) => setFormValues(values));

    // Réinitialiser les filtres
    const resetFilters = () => {
        methods.reset();
        applyFilters();
    }

    return {
        methods,
        filters: formValues,
        applyFilters,
        resetFilters,
    }
}