import {CardStatus} from "../../models/kanban/card.model";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {DateTime} from "luxon";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function TimeoutBEAlert({ cardId, status, statusUpdatedAt }: { cardId: string, status: CardStatus, statusUpdatedAt: string }) {
    if (status !== CardStatus.TIMEOUT_BE) {
        return null;
    }

    if (!statusUpdatedAt) {
        return null;
    }

    const fromFormat= 'yyyy-MM-dd HH:mm:ss';
    const toFormat= 'dd/MM/yyyy HH:mm:ss';
    const sentAt = DateTime.fromFormat(statusUpdatedAt, fromFormat);
    const raw = sentAt.toFormat(toFormat);
    const relative = sentAt.toRelativeCalendar();

    return (
        <Box sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center" gap={1}>
                <WarningRoundedIcon fontSize={"small"} color={"error"}/>
                <Typography variant={"caption"} color={"error"}>
                    <b>En attente BE depuis le {raw}</b><br />
                    {relative}
                </Typography>
            </Stack>
        </Box>
    )
}