import {IonButton, IonIcon, IonItem, IonLabel, IonSpinner, IonText} from "@ionic/react";
import React, {PropsWithChildren, useEffect} from "react";
import {useMeetListRequirementsDialogContext} from "../MeetListRequirementsDialog";
import {Controller, FormProvider, useController, useForm, useFormContext, useWatch} from "react-hook-form";
import {addOutline, addSharp, closeOutline, closeSharp} from "ionicons/icons";
import {FileToUploadDto, interventionAttachFiles} from "../../../../calls/Interventions/attachInterventionFiles";
import {useMutation} from "@tanstack/react-query";
import {FilePickerButton} from "../Attachments/FilePickerButton";
import useToggleOpen from "../../../Common/useToggleOpen";
import {useInterventionDetail} from "../../../InterventionDetail/useInterventionDetail";
import {ContractFormModel} from "../../../Contracts/models";
import {useUpdateIntervention} from "../../../Intervention/useUpdateIntervention";
import {InterventionType, VisiteTechnique} from "../../../../models/intervention.model";
import {Proposal} from "../../../../models/proposal.model";
import {EditVisitetechniqueDto} from "../../../../calls/Interventions/VT/editVisiteTechnique";
import {ProductTypology} from "../../../Intervention/types";
import {cloneRdvCom} from "../../../../calls/Interventions/RdvCom/cloneRdvCom";
import Box from "@mui/material/Box";
import NewProposal from "../../../Proposals/CreateProposal";
import StepperNavigation from "./StepperNavigation";
import Stepper from "@mui/material/Stepper";
import {useStepper} from "./useStepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {List, ListItem, ListItemText, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {HighlightOff} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";

type FormModel = {
    proposal: Proposal | null,
    attachments: Array<{
        description: string;
        tag?: string;
        pickedFile: {
            filename: string;
            contentType: string;
            base64Content: string;
        }
    }>;
}

export default function FillInStudyInformation() {
    const { cardId, onClose } = useMeetListRequirementsDialogContext();

    const methods = useForm<FormModel>();

    const { mutateAsync, isLoading } = useMutation((dtos: FileToUploadDto[]) => {
        return interventionAttachFiles(cardId, dtos);
    });

    const onSubmit = async (data: FormModel) => {
        const filesDto: FileToUploadDto[] = data.attachments.map(
            a => ({
                filename: a.pickedFile.filename,
                contentType: a.pickedFile.contentType,
                base64Content: a.pickedFile.base64Content,
                tag: a.tag || 'Étude',
                description: a.description,
            })
        );

        await mutateAsync(filesDto);
        onClose('ok');
    }

    return (
        <>
            <DialogTitle>Renseigner les données de l'étude</DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <MainStepper>
                            <Step>
                                <StepLabel>
                                    Documents études
                                </StepLabel>
                            </Step>

                            <Step>
                                <StepLabel>
                                    Création devis
                                </StepLabel>
                            </Step>
                        </MainStepper>

                        <MainStepperContent interventionId={cardId} />

                        <StepperNavigation
                            isLoading={isLoading}
                            onCancel={() => onClose('cancel')}
                        />
                    </form>
                </FormProvider>
            </DialogContent>
        </>
    )
}

function MainStepper({ children }: PropsWithChildren<{}>) {
    const { activeStep } = useStepper();

    return (
        <Stepper activeStep={activeStep} orientation={"horizontal"} alternativeLabel>
            {children}
        </Stepper>
    )
}

function MainStepperContent({ interventionId }: { interventionId: string }) {
    const { activeStep } = useStepper();

    const Content = () => {
        switch (activeStep) {
            case 0:
                return <Attachments />;
            case 1:
                return <CreateProposal interventionId={interventionId} />;
            default:
                return <></>;
        }
    }

    return (
        <Box p={4}>
            <Content />
        </Box>
    )
}

function Attachments() {
    return (
        <>
            <List>
                {
                    [
                        {
                            label: "Joindre les documents de l’étude",
                            tag: 'Étude',
                            accept: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        },
                        {
                            label: "Vue toiture de l'implantation des panneaux photovoltaïques (format .png ou .jpeg)",
                            tag: "Vue Toiture",
                            accept: "image/png,image/jpg,image/jpeg,image/gif",
                        },
                    ].map(
                        ({ label, accept, tag }, i) => (
                            <AttachmentItem
                                key={`AttachmentItem-${i}`}
                                name={`attachments.${i}`}
                                label={label}
                                tag={tag}
                                accept={accept}
                            />
                        )
                    )
                }
            </List>

            <AttachmentsNavigationManager />
        </>
    )
}

function AttachmentsNavigationManager() {
    const { enableNextOnActiveStep } = useStepper();

    const attachments: FormModel["attachments"] = useWatch({ name: 'attachments', defaultValue: [] });

    useEffect(() => {
        const filePicked = attachments?.filter(a => !!a.pickedFile).length === 2;

        if (filePicked) {
            enableNextOnActiveStep();
        }
    }, [attachments]);

    return null;
}

interface CreateProposalProps {
    interventionId: string;
}

function CreateProposal({ interventionId }: CreateProposalProps) {
    const { isLoading, data: intervention } = useInterventionDetail<VisiteTechnique>(interventionId);

    const { open: proposalModalOpened, show: showProposalModal, hide: hideProposalModal } = useToggleOpen();

    const { field: proposalField, fieldState: {  error: proposalFieldError } } = useController({
        name: 'proposal',
        rules: { required: "La création du devis est obligatoire" },
    });

    const getPhotoToiture = useGetPhotoToiture();

    const { mutateAsync: updateIntervention, isLoading: interventionUpdating, isSuccess, isError } = useUpdateIntervention(InterventionType.VISITE_TECHNIQUE);

    const { mutateAsync: cloneRdv } = useMutation(({ rdvId, proposal: p }: { rdvId: string, proposal: Proposal }) => {
        return cloneRdvCom(rdvId, {
            proposal: {
                id: p.id,
                reference: p.ref,
                origin: p.array_options?.options_origine_affaire,
                originId: p.array_options?.options_foire_origine,
                productTypology: ProductTypology.PHOTOVOLTAIC,
            },

            // On unset la date orginale
            unsetOriginalDate: true,

            // On trace les sources de cette intervention
            source: interventionId,
            sourceType: InterventionType.VISITE_TECHNIQUE,
        });
    });

    if (isLoading) {
        return <></>;
    }

    const { id, reference, customer, place } = intervention!;

    const defaultValues: { customer: ContractFormModel["customer"] } = {
        customer: {
            civility: customer.civility ?? 'MR',
            email: customer.email ?? '',
            firstName: "",
            name: customer.name,
            phone: customer.phone ?? '',
            billingAddressIsSameAsDefaultAddress: true, // adresse de livraison = adresse du chantier
            address: {
                country: 'FR',
                city: place.town ?? "",
                postalCode: place.zipCode ?? "",
                route: '',
                street: place.address ?? "",
                lat: place.coords ? place.coords[1] : 0,
                lng: place.coords ? place.coords[0] : 0,
            },
            billingAddress: {
                country: 'FR',
                city: place.town ?? "",
                postalCode: place.zipCode ?? "",
                route: '',
                street: place.address ?? "",
                lat: place.coords ? place.coords[1] : 0,
                lng: place.coords ? place.coords[0] : 0,
            },
            personalMobilePhone: '',
            businessMobilePhone: '',
            origine: 3,
            dateProposition: new Date().toISOString(),
            dureeValidite: 15,
        }
    }

    const onProposalSubmitted = async (p: Proposal) => {
        const dto: EditVisitetechniqueDto = {
            proposal: {
                id: p.id,
                reference: p.ref,
                origin: p.array_options?.options_origine_affaire,
                originId: p.array_options?.options_foire_origine,
                productTypology: ProductTypology.PHOTOVOLTAIC,
            }
        };

        // On update la VT sur laquelle on est en associant le devis venant d'être créé
        await updateIntervention({
            id: intervention!.id,
            dto
        });

        // On clone le RDV COM source de cette VT pour le réassigner au commercial
        // afin qu'il puisse repartir en renégociation avec le client avec le devis en main
        await cloneRdv({
            rdvId: intervention!.source as string, // Dans ce component on traite des VTs qui ont forcément une source de type RDVCOM
            proposal: p,
        });

        // Set du proposal dans le form
        proposalField.onChange(p);

        // Hide du mosal de création
        hideProposalModal();
    }

    const handleClose = (event: Object, reason: string) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        hideProposalModal();
    }

    return (
        <>
            <IonItem>
                <IonLabel>
                    <h3>Élaborer le devis pour finaliser l'étude</h3>
                    <p>Le devis créé sera transmis au commercial en charge du dossier</p>
                </IonLabel>

                {
                    proposalFieldError && (
                        <IonText color="danger">
                            <p>{proposalFieldError.message}</p>
                        </IonText>
                    )
                }

                {
                    !proposalField.value ? (
                        <IonButton slot="end" fill="outline" onClick={() => showProposalModal()}>
                            <IonIcon ios={addOutline} md={addSharp} slot={"start"}/>
                            Créer devis
                        </IonButton>
                    ) : (
                        <IonText slot="end" color={"primary"}>
                            Devis créé {proposalField.value.ref}
                        </IonText>
                    )
                }
            </IonItem>

            <Dialog
                open={proposalModalOpened}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
            >
                <div className="ion-padding" style={{ paddingBottom: 0, display: 'flex', justifyContent: "end" }}>
                    <IonButton fill="clear" size={"small"} onClick={hideProposalModal}>
                        <IonIcon slot="start" md={closeSharp} ios={closeOutline} />
                        Fermer
                    </IonButton>
                </div>

                <Box p={2}>
                    <NewProposal
                        defaultValues={{
                            thirdParty: {
                                id: customer.id,
                                name: customer.name,
                            },
                            visiteTechnique: {
                                id: interventionId,
                                reference,
                                photoToitureBase64: getPhotoToiture(),
                            }
                        }}
                        onSubmitted={onProposalSubmitted}
                        onCancel={hideProposalModal}
                    />
                </Box>
            </Dialog>
        </>
    )
}

function useGetPhotoToiture() {
    const { getValues } = useFormContext();

    return () => {
        const attachments = getValues().attachments;

        const photoToiture = attachments.find((a: { tag: string }) => a.tag === 'Vue Toiture');

        if (!photoToiture) {
            return "";
        }

        return photoToiture.pickedFile.base64Content;
    }
}

interface AttachmentItemProps {
    label: string;
    name: string;
    accept: string;
    tag?: string;
    isUploading?: boolean;
}

function AttachmentItem({ name, label, tag, accept, isUploading }: AttachmentItemProps) {
    const prefixer = (txt: string) => `${name}.${txt}`;

    return (
        <ListItem>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                <Controller
                    defaultValue={label}
                    name={prefixer('description')}
                    rules={{
                        required: "Description obligatoire"
                    }}
                    render={
                        ({ field }) => (
                            <ListItemText
                                style={{
                                    maxWidth: '28rem'
                                }}
                                primary={field.value}
                            />
                        )
                    }
                />

                <Controller
                    name={prefixer('pickedFile')}
                    defaultValue={null}
                    rules={{
                        required: "Pièce jointe obligatoire"
                    }}
                    render={
                        ({ field: { value, onChange }, fieldState: { error } }) => {
                            if (isUploading) {
                                return (
                                    <IonSpinner color="primary" />
                                );
                            }

                            if (value) {
                                return (
                                    <Button
                                        type={"button"}
                                        size={"small"}
                                        onClick={() => onChange(null)}
                                        endIcon={<HighlightOff />}
                                    >
                                        {value.filename}
                                    </Button>
                                );
                            }

                            return (
                                <>
                                    {
                                        !!error && (
                                            <IonText color="danger">
                                                <p>Photo vue toiture obligatoire</p>
                                            </IonText>
                                        )
                                    }

                                    <FilePickerButton
                                        buttonComponentProps={{
                                            fill: "outline"
                                        }}
                                        accept={accept}
                                        onFileChange={onChange}
                                    />
                                </>
                            );
                        }
                    }
                />
            </Stack>

            <Controller
                name={prefixer('tag')}
                defaultValue={tag || ""}
                render={
                    ({ field }) => {
                        return <input type="hidden" {...field} />
                    }
                }
            />
        </ListItem>
    )
}

