import Page from "./Page";
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import {IonContent, IonList, IonListHeader, IonMenu, IonRouterOutlet, IonSplitPane} from "@ionic/react";
import {readerOutline, readerSharp,} from "ionicons/icons";
import {AppPage} from "./index";
import SideNavLink from "../components/Common/Menu/SideNavLink";
import TableauMarges from "../components/Reports/TableauMarges";
import TableauCommissionV2 from "../components/Reports/TableauCommissionV2";

const pages: AppPage[] = [
    {
        title: 'Tableau des marges',
        url: '/TableauMarges',
        mdIcon: readerSharp,
        iosIcon: readerOutline,
    },
    {
        title: 'Tableau commission',
        url: '/TableauCommission',
        mdIcon: readerSharp,
        iosIcon: readerOutline,
    },
]

export default function ReportsPage() {
    const match = useRouteMatch();

    return (
        <Page>
            <IonSplitPane contentId="ReportsPage-main" style={{ '--side-width': 300 }}>
                <IonMenu type={"overlay"} contentId="main">
                    <IonContent>
                        <IonList id="labels-list" className={"ion-padding"}>
                            <IonListHeader>
                                <b>Rapports</b>
                            </IonListHeader>
                            {
                                pages
                                    .filter(p => !p.disabled) // Ne filtrer que les pages à afficher
                                    .map(
                                        (p, index) => (
                                            <SideNavLink
                                                key={index}
                                                title={p.title}
                                                to={`${match.url}${p.url}`}
                                                mdIcon={p.mdIcon}
                                                iosIcon={p.iosIcon}
                                            />
                                        )
                                    )
                            }
                        </IonList>
                    </IonContent>
                </IonMenu>

                <IonRouterOutlet id={"ReportsPage-main"}>
                    <Route path={match.path} exact={true}>
                        <Redirect to={`${match.url}/TableauMarges`} />
                    </Route>
                    <Route path={`${match.url}/TableauMarges`} component={TableauMarges} />
                    <Route path={`${match.url}/TableauCommission`} component={TableauCommissionV2} />
                </IonRouterOutlet>
            </IonSplitPane>
        </Page>
    )
}

