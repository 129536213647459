import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {Payment} from "../../models/payment.model";

/**
 * Récupérer une commande
 * @param id
 */
export async function listInvoicePayments(id: number | string) {
    const url = Endpoint.invoicePayments.replace(':id', `${id}`);
    const { data } = await http.get<Payment[]>(url);

    return data;
}