import {FormProvider, useForm} from "react-hook-form";
import React, {useRef} from "react";
import {IonButton, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {OverlayEventDetail} from "@ionic/core";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import {useMutation} from "@tanstack/react-query";
import {editIntervention, EditInterventionDto} from "../../calls/Interventions/editIntervention";
import InterventionEditAddressForm from "./InterventionEditAddressForm";

type FormModel = {
    address: {
        street: string;
        route: string;
        city: string;
        country: string;
        postalCode: string;
        lat: number;
        lng: number;
    }
}

interface InterventionFormModalProps {
    id: string;
    defaultValues?: FormModel;
    isOpen: boolean;
    onWillDismiss?(event: IonModalCustomEvent<OverlayEventDetail>): void;
}

export default function InterventionEditAddressFormModal({ id, defaultValues, isOpen, onWillDismiss }: InterventionFormModalProps) {
    const methods = useForm<FormModel>({
        defaultValues: defaultValues || {},
    });

    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading, isSuccess, isError, data } = useMutation(({ id, dto }: { id: string, dto: EditInterventionDto }) => {
        return editIntervention(id, dto);
    });

    const dismiss = () => {
        methods.reset();
        modal.current?.dismiss();
    }

    const onSubmit = async (data: FormModel) => {
        const {
            city,
            postalCode,
            street,
            route,
            lng, lat
        } = data.address;

        const coords = [
            Number(lng || '0'), // Lng
            Number(lat || '0'), // Lat
        ];

        const dto: EditInterventionDto = {
            place: {
                address: [street || '', route || ''].map(p => p.trim()).join(' ').trim(),
                town: city || '',
                zipCode: postalCode || '',
                coords,
            },
        }

        await mutateAsync({ id, dto });

        methods.reset();

        modal.current?.dismiss(id, 'confirm');
    }

    return (
        <IonModal ref={modal} backdropDismiss={false} keepContentsMounted={false} isOpen={isOpen} style={{ '--width': '30%', '--height': '38rem', '--backdrop-opacity': .4 }} onWillDismiss={onWillDismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Modification adresse</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <FormProvider {...methods}>
                    <InterventionEditAddressForm />
                </FormProvider>
            </IonContent>

            <IonRow className={"ion-margin-top"}>
                <IonCol>
                    <IonButton expand="block" color="light" onClick={dismiss}>
                        Annuler
                    </IonButton>
                </IonCol>

                <IonCol>
                    <IonLoadingButton
                        expand="block"
                        loading={isLoading}
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Enregistrer
                    </IonLoadingButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
}
