import {Pagination} from "../types";
import lodash from "lodash";
import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export async function searchProductCategories(filters: any = {}, pagination: Pagination = {sortorder: 'DESC', limit: 100}) {
    const params = lodash.omitBy({
        ...filters,
        sqlfilters: '(t.type:=:0)', // ne lister que les tags de type 0 cad Produit
        sortfield: 't.rowid',
        ...pagination,
    }, lodash.isNil);

    const { data } = await http.get(Endpoint.categories, { params });

    return data;
}