import axios, {AxiosRequestConfig} from "axios";
import {useAuthStore} from "../components/Auth/auth.store";
import {useActiveCompanyStore, useCompanyStore} from "../components/Company/company.store";

export const BASE_URL = 'https://j6koK8P1w9iT4dhj-lambda-green-bo.amdp-sales.com';

const http = axios.create({ baseURL: BASE_URL + '/api/index.php' });

const getRequestHeaders = () => {
    const token = useAuthStore.getState().token || '';
    const entity = getActiveEntity();

    return {
        DOLAPIKEY: token,
        DOLAPIENTITY: entity,
    };
};

// Une copie en mode getter de useActiveCompanyId()
const getActiveEntity = () => {
    return useActiveCompanyStore.getState().activeCompanyId || useCompanyStore.getState().lastActiveCompanyId;
}

const requestInterceptor = (config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        ...getRequestHeaders(),
    };

    return config;
}

const errorInterceptor = (error: any) => {
    const {config, data} = error.response;

    return Promise.reject(error);
}

http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(undefined, errorInterceptor);

export { http };
