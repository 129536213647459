import {
    IonDatetime,
    IonDatetimeButton,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTextarea
} from "@ionic/react";
import React from "react";
import {Controller} from "react-hook-form";


export default function PaymentForm() {
    return(
        <form>
            <IonItem>
                <IonLabel>Date</IonLabel>
                <Controller
                    name={"datepaye"}
                    render={({ field }) => {
                        return (
                            <>
                                <IonDatetimeButton datetime="datetime" />
                                <IonModal keepContentsMounted={true}>
                                    <IonDatetime
                                        id="datetime"
                                        value={field.value}
                                        onIonChange={field.onChange}
                                        showDefaultButtons={true}
                                        doneText="Valider"
                                        cancelText="Annuler"
                                    />
                                </IonModal>
                            </>
                        )
                    }}
                />
            </IonItem>

            <IonItem>
                <IonLabel position="floating">Montant règlement</IonLabel>
                <Controller
                    name={"amount"}
                    render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"number"} />}
                    rules={{ required: true }}
                />
            </IonItem>

            <IonItem>
                <IonLabel>Mode de règlement</IonLabel>
                <Controller
                    name={"paymentid"}
                    render={
                        ({field}) => {
                            return(
                                <IonSelect cancelText={"Annuler"} placeholder="Mode de règlement" value={field.value} onIonChange={field.onChange}>
                                    <IonSelectOption value={6}>Carte bancaire</IonSelectOption>
                                    <IonSelectOption value={7}>Chèque</IonSelectOption>
                                    <IonSelectOption value={4}>Espèce</IonSelectOption>
                                    <IonSelectOption value={3}>Ordre de prélèvement</IonSelectOption>
                                </IonSelect>
                            )
                        }
                    }
                    rules={{ required: true }}
                />
            </IonItem>

            <IonItem>
                <IonLabel position="floating">Numéro opération (chèque / virement)</IonLabel>
                <Controller
                    name={"num_payment"}
                    render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                />
            </IonItem>

            <IonItem>
                <IonLabel position="floating">Émetteur (chèque / virement)</IonLabel>
                <Controller
                    name={"chqemetteur"}
                    render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                />
            </IonItem>

            <IonItem>
                <IonLabel position="floating">Banque</IonLabel>
                <Controller
                    name={"chqbank"}
                    render={({field }) => <IonInput value={field.value} onIonChange={field.onChange} type={"text"} />}
                />
            </IonItem>

            <IonItem>
                <IonLabel position="floating">Commentaires</IonLabel>
                <Controller
                    name={"comment"}
                    render={({field }) => <IonTextarea rows={3} value={field.value} onIonChange={field.onChange} placeholder={"Vos commentaires ici"} />}
                />
            </IonItem>
        </form>
    )
}