import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {Pagination} from "../types";

export type SearchInvoicesPayload = {
    thirdparty_ids?: number | string; // example '1' or '1,2,3'
    status?: 'draft' | 'unpaid' | 'paid' | 'cancelled'
}

export async function searchInvoices(payload: SearchInvoicesPayload = {}, pagination: Pagination  = {sortorder: 'DESC', limit: 500}) {
    const { data } = await http.get<SearchInvoicesResult[]>(Endpoint.invoices, {
        params: {
            ...payload,
            ...pagination
        },
    });

    return data;
}

interface SearchInvoicesResult {
    brouillon: any;
    socid: string;
    fk_user_author: string;
    fk_user_valid: string;
    date: number;
    datem: number;
    date_livraison: any;
    delivery_date: any;
    ref_client: any;
    type: string;
    remise_absolue: any;
    remise_percent: any;
    total_ht: string;
    total_tva: string;
    total_localtax1: string;
    total_localtax2: string;
    total_ttc: string;
    revenuestamp: string;
    close_code: any;
    close_note: any;
    paye: string;
    module_source: any;
    pos_source: any;
    fk_fac_rec_source: any;
    fk_facture_source: any;
    date_lim_reglement: number;
    cond_reglement_code: any;
    mode_reglement_code: any;
    fk_bank: any;
    lines: Line[];
    line: any;
    fac_rec: any;
    date_pointoftax: string;
    fk_multicurrency: string;
    multicurrency_code: string;
    multicurrency_tx: string;
    multicurrency_total_ht: string;
    multicurrency_total_tva: string;
    multicurrency_total_ttc: string;
    situation_cycle_ref: any;
    situation_counter: any;
    situation_final: string;
    retained_warranty: string;
    retained_warranty_date_limit: string;
    retained_warranty_fk_cond_reglement: string;
    id: string;
    entity: string;
    import_key: any;
    array_languages: any;
    linkedObjectsIds: any;
    canvas: any;
    fk_project: string;
    contact_id: any;
    user: any;
    origin: any;
    origin_id: any;
    ref: string;
    ref_ext: any;
    statut: string;
    status: string;
    country_id: any;
    country_code: any;
    state_id: any;
    region_id: any;
    mode_reglement_id: string;
    cond_reglement_id: string;
    demand_reason_id: any;
    transport_mode_id: any;
    shipping_method_id: any;
    model_pdf: any;
    last_main_doc: string;
    fk_account: any;
    note_public: any;
    note_private: any;
    name: any;
    lastname: any;
    firstname: any;
    civility_id: any;
    date_creation: number;
    date_validation: number;
    date_modification: number;
    specimen: number;
    fk_incoterms: string;
    label_incoterms: any;
    location_incoterms: string;
    cond_reglement_doc: any;
    user_author: string;
    user_valid: string;
    totalpaid: string;
    totalcreditnotes: any;
    totaldeposits: any;
    remaintopay: string;
}

interface Line {
    fk_facture: string;
    fk_parent_line: any;
    desc: string;
    ref_ext: string;
    localtax1_type: string;
    localtax2_type: string;
    fk_remise_except: any;
    rang: string;
    fk_fournprice: any;
    pa_ht: string;
    marge_tx: string;
    marque_tx: number;
    remise_percent: string;
    special_code: string;
    origin: any;
    origin_id: any;
    fk_code_ventilation: number;
    date_start: string;
    date_end: string;
    situation_percent: string;
    fk_prev_id: any;
    multicurrency_subprice: string;
    multicurrency_total_ht: string;
    multicurrency_total_tva: string;
    multicurrency_total_ttc: string;
    label: any;
    ref: string;
    libelle: string;
    product_type: string;
    product_ref: string;
    product_label: string;
    product_desc: string;
    qty: string;
    subprice: string;
    price: any;
    fk_product: string;
    vat_src_code: string;
    tva_tx: string;
    localtax1_tx: string;
    localtax2_tx: string;
    remise: any;
    total_ht: string;
    total_tva: string;
    total_localtax1: string;
    total_localtax2: string;
    total_ttc: string;
    date_start_fill: any;
    date_end_fill: any;
    buy_price_ht: any;
    buyprice: any;
    info_bits: string;
    fk_user_author: any;
    fk_user_modif: any;
    id: string;
    rowid: string;
    fk_unit: any;
    date_debut_prevue: any;
    date_debut_reel: any;
    date_fin_prevue: any;
    date_fin_reel: any;
    entity: any;
    import_key: any;
    array_languages: any;
    linkedObjectsIds: any;
    canvas: any;
    statut: any;
    status: any;
    state_id: any;
    region_id: any;
    demand_reason_id: any;
    transport_mode_id: any;
    last_main_doc: any;
    fk_bank: any;
    fk_account: any;
    lines: any;
    date_creation: any;
    date_validation: any;
    date_modification: any;
    specimen: number;
    description: string;
    fk_product_type: string;
    code_ventilation: string;
    fk_accounting_account: string;
}