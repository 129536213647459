import {createContext, useContext} from "react";

type ProposalFormContextType = {
    submitOnBlur(): void;
}

export const ProposalFormContext = createContext<ProposalFormContextType>({
    submitOnBlur: () => {},
});

export function useProposalFormContext() {
    return useContext(ProposalFormContext);
}