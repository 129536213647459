import {ConfirmDialogProps, useConfirmationStore} from "./confirmation.store";

export default function useConfirm() {
    const store = useConfirmationStore();

    return (p: Omit<ConfirmDialogProps, 'open'>) => {
        store.showConfirm(p);

        return new Promise((resolve) => {
            const unsub = useConfirmationStore.subscribe(s => s.confirmed, (confirmed) => {
                resolve(confirmed);
                unsub();
            });
        })
    }
}