import {AllInterventionStatus, InterventionType} from "../../models/intervention.model";

export interface InterventionFilterModel {
    status?: AllInterventionStatus;
    technicianId?: string;
    type?: InterventionType;
    customerId?: string;
    customerName?: string;
    nearBy?: [number, number];
    maxDistance?: number; // lié à nearBy
    reference?: string;
    thirdPartyIds?: number | string; // example '1' or '1,2,3'
    thirdPartyName?: string;
    description?: string;
    assignee?: number | string;
    startDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
    endDate?: string; // Date in a string YYYY-MM-DD HH:MM:SS
}

export enum ProductTypology {
    PAC_AIR_AIR =  'PAC_AIR_AIR',
    PAC_AIR_EAU =  'PAC_AIR_EAU',
    PHOTOVOLTAIC =  'PHOTOVOLTAIC',
    BALLON_THERMO =  'BALLON_THERMO',
}