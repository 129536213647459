import {PaymentMethod, PaymentMethodCode, PaymentSearchResult} from "../../models/payment.model";
import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Pagination} from "../types";
import {PaginatedResponse} from "../../models/common.model";
import {compact, isNil, omitBy} from "lodash";

export type SearchPaymentPayload = {
    id?: string,
    thirdPartyId?: number,
    reference?: string,
    paymentMethod?: PaymentMethod[];
    paymentMethodCode?: PaymentMethodCode,
}

export async function searchPayments(payload: SearchPaymentPayload = {}, pagination: Pagination  = {sortorder: 'DESC', limit: 500}) {
    const dto = {
        ...omitBy(payload, isNil), // on clean la payload
        paymentMethod: compact(payload.paymentMethod), // on clean l'array des payment method
    };

    const params = {
        ...pagination,
        sortfield: 't.ref',
    };

    const {data} = await http.post<PaginatedResponse<PaymentSearchResult>>(Endpoint.payments, dto, { params });

    return data;
}