import {IonButton, IonIcon, IonItem, IonLabel, useIonModal} from "@ionic/react";
import {addOutline, addSharp, searchSharp, searchOutline} from "ionicons/icons";
import {useController} from "react-hook-form";
import SearchFoires from "./SearchFoires";

export default function AddFoireOrigineButton() {
    const {
        field,
    } = useController({
        name: 'customer.foireOrigine',
        rules: {
            required: "Foire d'origine obligatoire si origine affaire Foire"
        }
    })

    const [present, dismiss] = useIonModal(SearchFoireModal, {
        onDismiss: (data: string, role: string) => {
            (role === 'confirm') && field.onChange(data);
            dismiss(data, role);
        },
    });

    const foire = field.value;

    if (foire) {
        return (
            <IonItem>
                <IonLabel>
                    <h2><b>{field.value.label}</b></h2>
                    <h3>Foire d'origine</h3>
                </IonLabel>
                <IonButton slot={"end"} fill={"clear"} onClick={() => present()}>
                    <IonIcon slot="start" md={searchSharp} ios={searchOutline} />
                    Rechercher
                </IonButton>
            </IonItem>
        )
    }

    return (
        <IonItem button onClick={() => present()} detail color={"light"}>
            <IonIcon slot="start" md={addSharp} ios={addOutline} />
            <IonLabel>
                Choisir la foire d'origine
            </IonLabel>
        </IonItem>
    )
}

const SearchFoireModal = ({ onDismiss, }: { onDismiss: (data?: any | null | undefined | number, role?: string) => void; }) => {
    return (
        <SearchFoires
            onFoireSelected={f => onDismiss(f, 'confirm')}
            onCancel={() => onDismiss()}
        />
    )
}