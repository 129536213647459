import {useMutation} from "@tanstack/react-query";
import {setProposalCardStatus} from "../../calls/Kanban/setProposalCardStatus";
import {CardStatus} from "../../models/kanban/card.model";
import {setInterventionCardStatus} from "../../calls/Kanban/setInterventionCardStatus";

interface UseSetCardStatusProps {
    cardElementType?: 'propal' | 'interventionOfSav' | 'interventionOfRdv' | 'interventionOfVT' | 'photovoltaique';
}

export function useSetCardStatus({ cardElementType = 'propal' }: UseSetCardStatusProps = {}) {
    const mutationFn = getMutationFn(cardElementType) as any;

    return useMutation(({ cardId, status }: { cardId: any, status: CardStatus }) => mutationFn(cardId, status));
}

function getMutationFn(cardElementType: UseSetCardStatusProps["cardElementType"]) {
    switch (cardElementType) {
        case "propal":
            return setProposalCardStatus;

        case "interventionOfSav":
        case "interventionOfRdv":
        case "interventionOfVT":
            return setInterventionCardStatus;

        default:
            return setProposalCardStatus;
    }
}