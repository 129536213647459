import {BASE_URL} from "../../http";
import {Endpoint} from "../../calls/endpoint.enum";
import {IonThumbnail} from "@ionic/react";
import {useActiveCompany} from "./company.store";

export default function CompanyLogoThumbnail() {
    const detail = useActiveCompany();

    const logoUrl = BASE_URL + Endpoint.viewCompanyLogo
        .replace(':filename', detail.logo_mini)
        .replace(':entity', detail.id);

    return (
        <IonThumbnail slot="start">
            <img alt={`Logo ${detail.name}`} src={logoUrl} />
        </IonThumbnail>
    )
}