import {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {useCreateNewContractMultipleProposals} from "./hooks/useCreateNewContractMultipleProposals";
import {IonButton, IonCol, IonGrid, IonHeader, IonItem, IonLabel, IonRow, IonTitle, IonToolbar} from "@ionic/react";
import CustomerForm from "./CustomerForm";
import {DateTime} from "luxon";
import ModePaiementItem from "./ModePaiement/ModePaiementItem";
import {normalizeFormValues} from "./utils";
import ObservationsItem from "./Observations";
import EmargementItem from "./Emargement";
import {ContractFormModel} from "./models";
import PanneauPhotovoltaiqueItem from "./PanneauPhotovoltaique/PanneauPhotovoltaiqueItem";
import ErrorList from "./Errors";
import {DevTool} from "@hookform/devtools";
import {mapValues} from "lodash";
import {useUser} from "../Auth/auth.store";
import {Proposal} from "../../models/proposal.model";

interface NewContractProps {
    defaultValues?: Partial<ContractFormModel>;
    onCancel?(): void;
    onSubmitted?(p: Proposal): void;
}

function NewContract({ defaultValues, onCancel: onCancelCb, onSubmitted }: NewContractProps = {}) {
    const methods = useForm({
        defaultValues,
    });

    let history = useHistory();

    const user = useUser()
;
    const { mutateAsync: mutateAsyncContract, isLoading, isSuccess, isError, data } = useCreateNewContractMultipleProposals();

    const onSubmit = async (data: any, e: any) => {
        const { existingCustomer, customer, salesForce, observations, installation } = data as ContractFormModel;

        const responsableIds = (salesForce.responsables || []).map(r => r.id).join(',');
        const vendeurIds = (salesForce.vendeurs || []).map(v => v.id).join(',');
        const rabatteurIds = (salesForce.rabatteurs || []).map(r => r.id).join(',');

        const proposalDateSeconds = DateTime.fromISO(customer.dateProposition).toSeconds();

        const results = normalizeFormValues(data);

        const proposalLines = mapValues(results, lines => {
            return lines!.map(
                ({ product, poseId, pose, ...value }) => {
                    const fk_product = product ? product.id : (poseId ? `${poseId}` :"");

                    return {
                        desc: product ? product.description : pose,
                        qty: value.qty || 1,
                        fk_product,
                        tva_tx: value.tvaTx,
                        subprice: value.totalHT,
                        product_type: product ? 0 : 1,
                        array_options: {
                            ...(value.extraFields || {}), // spread des extra fields pour les produits qui en ont
                            related_product: fk_product
                        }
                    }
                }
            )
        });

        const proposals = Object.entries(proposalLines).map(
            ([key, lines]) => {
                return {
                    header: {
                        model_pdf: customer.docTemplate?.id || '',
                        date: proposalDateSeconds,
                        duree_validite: customer.dureeValidite,
                        array_options: {
                            origine_affaire: customer.origine,
                            foire_origine: customer.foireOrigine && customer.foireOrigine.id,
                            responsable_ids: responsableIds,
                            vendeur_ids: vendeurIds,
                            rabatteur_ids: rabatteurIds,
                            // installation_day_delai_max: installation.delaiMaximalInstallation || 0,
                        },
                        note_private: observations
                    },
                    lines,
                }
            }
        );

        const validatedProposal = await mutateAsyncContract({
            existingThirdParty: existingCustomer,
            thirdParty: existingCustomer ? undefined : ({
                name: `${customer.firstName} ${customer.name}`,
                name_bis: customer.name,
                firstname: customer.firstName,
                email: customer.email,
                phone: customer.phone,
                address: `${customer.address.street} ${customer.address.route || ''}`.trim(),
                zip: customer.address.postalCode,
                town: customer.address.city,
                commercial_id: Number(user.id), // l'id de l'user
                typent_id: 8, // 8 = particulier = on demande à créer le contact également
                civility_id: customer.civility, // civilité pour le contact par défaut
                array_options: {
                    civilite: customer.civility,
                    personal_mobile_phone: customer.personalMobilePhone,
                    business_mobile_phone: customer.businessMobilePhone,
                    lat: customer.address.lat || 0,
                    lng: customer.address.lng || 0,
                }
            }),
            proposals,
            billingAddress: !customer.billingAddressIsSameAsDefaultAddress ? ({
                lastname: customer.name,
                firstname: customer.firstName,
                civility_code: customer.civility,
                address: `${customer.billingAddress?.street} ${customer.billingAddress?.route || ''}`.trim(),
                zip: customer.billingAddress?.postalCode || '',
                town: customer.billingAddress?.city || '',
                phone: customer.phone,
                phone_mobile: customer.personalMobilePhone,
                phone_perso: customer.businessMobilePhone,
                array_options: {
                    lat: customer.billingAddress?.lat || 0,
                    lng: customer.billingAddress?.lng || 0,
                }
            }) : undefined,
            docTemplateName: customer.docTemplate?.filename || '', // pour les besoins de l'upload
        });

        // Si callback on renvoi le devis validé
        if (onSubmitted) {
            onSubmitted(validatedProposal!);
            return;
        }

        // Si pas de cb Redirection après succès mutation
        history.push('/page/ThirdParties');
    };

    const onError = (errors: any, e: any) => console.log(errors, e);

    const onCancel = () => {
        // Si callback on cancel
        if (onCancelCb) {
            return onCancelCb();
        }

        // Sinon on utilise l'history pour changer de page
        history.push('/');
    }

    useEffect(() => {
        return () => {
            methods.reset(); // On reset le tout en quittant cette page
        }
    }, []);

    return (
        <>
            <IonToolbar>
                <IonItem lines={"none"}>
                    <IonLabel>
                        <h1>Nouvelle affaire</h1>
                        <h3>Création d'une nouvelle affaire</h3>
                    </IonLabel>
                </IonItem>
            </IonToolbar>

            <IonHeader collapse="condense">
                <IonToolbar>
                    <IonTitle size="large">Nouveau contrat</IonTitle>
                </IonToolbar>
            </IonHeader>

            <FormProvider {...methods}>
                <CustomerForm />

                <IonGrid>
                    {/*<PacAirEauItem />*/}

                    {/*<PacAirAirItem />*/}

                    {/*<BallonThermodynamiqueItem />*/}

                    <PanneauPhotovoltaiqueItem />

                    {/*<AutreMaterielItem />*/}

                    {/*<InstallationItem />*/}

                    <ModePaiementItem />

                    <ObservationsItem />

                    <EmargementItem />
                </IonGrid>

                <ErrorList />

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton color="light" expand="block" size="large" onClick={onCancel}>
                                Annuler
                            </IonButton>
                        </IonCol>

                        <IonCol>
                            <IonButton expand="block"
                                       size="large"
                                       onClick={methods.handleSubmit(onSubmit)}
                                       disabled={isLoading || isSuccess}
                            >
                                Enregister
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </FormProvider>

            <DevTool control={methods.control} />
        </>
    );
}

export default NewContract;