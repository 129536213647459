import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonToolbar
} from "@ionic/react";
import {filterOutline, filterSharp} from "ionicons/icons";
import FilterDrawer from "../Common/FilterDrawer";
import {useOpenFilterDrawer} from "../Common/FilterDrawer/store";
import SearchInvoice from "./SearchInvoice";
import {useInvoiceFilterForm} from "./useInvoiceFilterForm";
import {FormProvider} from "react-hook-form";
import {useSearchInvoicesQuery} from "./useSearchInvoicesQuery";
import {InvoicesListContext} from "./useInvoicesListContext";
import {usePowerTablePaginationHelper} from "../Common/Table/usePowerTablePaginationHelper";
import {Paper} from "@mui/material";
import React from "react";
import PowerTablePagination from "../Common/Table/PowerTablePagination";
import InvoiceTable from "./InvoiceTable";
import {FormattedNumber} from "react-intl";
import {idateTime} from "../../utils";
import {DateTime} from "luxon";

export default function InvoiceList() {
    const { filters: { startDate, endDate, ...filters }, applyFilters, resetFilters, methods } = useInvoiceFilterForm();
    const { page, rowsPerPage, onPageChange, onRowsPerPageChange, resetPage } = usePowerTablePaginationHelper();

    const period = {
        ...(startDate && {
            startDate: DateTime.fromISO(startDate),
        }),
        ...(endDate && {
            endDate: DateTime.fromISO(endDate),
        })
    }

    const query = useSearchInvoicesQuery({
        ...filters,
        ...(period.startDate && {
            startDate: idateTime(period.startDate),
        }),
        ...(period.endDate && {
            endDate: idateTime(period.endDate),
        })
    }, { page, limit: rowsPerPage });

    const itemsCount = query?.data?.pagination?.itemsCount || 0;
    const totalHT = query?.data?.totalAmountHT || 0;
    const totalTTC = query?.data?.totalAmountTTC || 0;
    const totalPaid = query?.data?.totalPaid || 0;
    const totalRemainToPay = query?.data?.totalRemainToPay || 0;

    const contextValue = {
        query,
    };

    return(
        <InvoicesListContext.Provider value={contextValue}>
            <FormProvider {...methods}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonToolbar>
                                <IonItem lines={"none"}>
                                    <IonLabel>
                                        <h1>Factures</h1>
                                        <h3>
                                            Liste des factures sur la période du {period.startDate ? period.startDate?.toFormat('dd/MM/yyyy') : '-'} au du {period.endDate ? period.endDate?.toFormat('dd/MM/yyyy') : '-'}
                                        </h3>
                                    </IonLabel>
                                    <IonButtons slot={"end"}>
                                        <FiltersButton/>
                                    </IonButtons>
                                </IonItem>
                            </IonToolbar>

                            <IonRow>
                                <IonCol>
                                    <IonCard style={{ '--background': 'white' }}>
                                        <IonCardHeader>
                                            <IonCardTitle>{itemsCount}</IonCardTitle>
                                            <IonCardSubtitle>Nombre de facture</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>

                                <IonCol>
                                    <IonCard style={{ '--background': 'white' }}>
                                        <IonCardHeader>
                                            <AmountIonCardTitle value={totalHT} />
                                            <IonCardSubtitle>Montant total HT</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>

                                <IonCol>
                                    <IonCard style={{ '--background': 'white' }}>
                                        <IonCardHeader>
                                            <AmountIonCardTitle value={totalTTC} />
                                            <IonCardSubtitle>Montant total TTC</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>

                                <IonCol>
                                    <IonCard style={{ '--background': 'white' }}>
                                        <IonCardHeader>
                                            <AmountIonCardTitle value={totalPaid} />
                                            <IonCardSubtitle>Montant total payé</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>

                                <IonCol>
                                    <IonCard style={{ '--background': 'white' }}>
                                        <IonCardHeader>
                                            <AmountIonCardTitle value={totalRemainToPay} />
                                            <IonCardSubtitle>Total restant dû</IonCardSubtitle>
                                        </IonCardHeader>
                                    </IonCard>
                                </IonCol>
                            </IonRow>

                            <Paper sx={{ width: '100%', mt: 1 }} elevation={2}>
                                <InvoiceTable />

                                <PowerTablePagination
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    totalRowsCount={itemsCount}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </Paper>

                            <FilterDrawer
                                onApply={() => {
                                    resetPage();
                                    applyFilters()
                                }}
                                onReset={() => resetFilters()}
                            >
                                <SearchInvoice/>
                            </FilterDrawer>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </FormProvider>
        </InvoicesListContext.Provider>

    );
}

function FiltersButton() {
    const openFilterDrawer = useOpenFilterDrawer();

    return (
        <IonButton fill="outline" color={"primary"} size={"default"} onClick={() => openFilterDrawer()}>
            <IonIcon slot="start" md={filterSharp} ios={filterOutline}/>
            Filtres
        </IonButton>
    )
}

function AmountIonCardTitle({ value }: { value: number }) {
    return (
        <IonCardTitle>
            <FormattedNumber
                value={value || 0}
                style="currency"
                currency={"EUR"}
            />
        </IonCardTitle>
    )
}