import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";

export async function generateReports(id: string) {
    const url = Endpoint.interventionGenerateReport.replace(':id', id);

    const {data} = await interventionHttp.post(url, {}, {
        responseType: 'blob',
    });

    return data;
}