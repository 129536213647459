import {UseQueryResult} from "@tanstack/react-query";
import {createContext, useContext} from "react";
import {SearchProductResult} from "../../calls/Products/searchProducts";


type ProductsListContextProps = {
    query?: UseQueryResult<SearchProductResult>,
}

export const ProductsListContext = createContext<ProductsListContextProps>({});

export function useProductsListContext() {
    return useContext(ProductsListContext);
}