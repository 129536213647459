import {PaymentMethod} from "../../models/payment.model";
import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

export type AddPaymentDto = {
    arrayofamounts: Record<string, { amount: string, multicurrency_amount?: string }>;
    datepaye: number; // form timestamp
    paymentid: PaymentMethod;
    accountid?: number;
    num_payment?: string;
    comment?: string;
    chqemetteur?: string;
    chqbank?: string;
    closepaidinvoices?: 'yes' | 'no';
}

/**
 * Add a payment to pay partially or completely one or several invoices.
 * @param dto
 */
export async function addPayment(dto: AddPaymentDto) {
    const { data } = await http.post<number>(Endpoint.addPayment, dto);

    return data;
}