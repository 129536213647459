import React from 'react';
import {useParams} from "react-router";
import InterventionShow from "../components/InterventionDetail/InterventionShow";

export default function InterventionDetailPage() {
    const { id } = useParams<{ id: string }>();

    return (
        <InterventionShow id={id} />
    )
}

