import {Pagination} from "../../calls/types";
import {QueryFunction, useQuery} from "@tanstack/react-query";
import {searchProductCategories} from "../../calls/Products/searchProductCategories";
import {Category} from "../../models/category.model";

export function useProductCategoriesQuery(payload: any = {}, pagination?: Pagination) {
    const queryKey = ['categories']; // pour le moment on a pas beaucoup de catégorie donc clé simpliste

    const queryFn: QueryFunction<Category[]> = () => {
        return searchProductCategories(payload, pagination);
    }

    return useQuery<Category[]>(queryKey, queryFn);
}