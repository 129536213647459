import z, {TypeOf} from "zod";

export const createContactSchema = z.object({
    lastname: z.string().min(1, "Nom obligatoire"),
    firstname: z.string().optional(),
    civility: z.string().optional(),
    address: z.string().optional(),
    zip: z.string().optional(),
    town: z.string().optional(),
    countryId: z.coerce.number().optional().default(1), // 1 = France
    email: z.string().email("Email invalide").optional().or(z.literal('')),
    notePrivate: z.string().optional().nullish(),
    notePublic: z.string().optional().nullish(),
    phone: z.string().optional(),
    job: z.string().optional(),
    phonePerso: z.string().optional(),
    phoneMobile: z.string().optional(),
});

export type EditContactFormModel = TypeOf<typeof createContactSchema>;

export const updateContactSchema = createContactSchema
    .partial()
;

export type UpdateContactFormModel = TypeOf<typeof updateContactSchema>;