import React from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {createContactSchema, EditContactFormModel} from "./Form";
import {zodResolver} from "@hookform/resolvers/zod";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ContactForm from "./ContactForm";
import {useMutation} from "@tanstack/react-query";
import {createContact} from "../../calls/Contacts/createContact";
import {LoadingButton} from "@mui/lab";

interface CreateContactDialogProps {
    thirdPartyId: number;
    open: boolean;
    onClose?: (reason?: 'created') => void;
}

export default function CreateContactDialog(props: CreateContactDialogProps) {
    const { open, onClose } = props;

    return (
        <Dialog
            open={open}
            onClose={() => onClose && onClose()}
            aria-labelledby="create-contact-dialog-title"
            aria-describedby="create-contact-dialog-description"
        >
            <MainContent {...props} />
        </Dialog>
    )
}

function MainContent(props: CreateContactDialogProps) {
    const { thirdPartyId, onClose } = props;

    const methods = useForm<EditContactFormModel>({
        resolver: zodResolver(createContactSchema),
    });

    const { isLoading, mutateAsync } = useMutation((data: EditContactFormModel) => {
        return createContact({
            socid: thirdPartyId,
            lastname: data.lastname,
            firstname: data.firstname,
            email: data.email,
            civility_code: data.civility,
            address: data.address,
            zip: data.zip,
            town: data.town,
            country_id: data.countryId,
            note_private: data.notePrivate,
            note_public: data.notePublic,
            phone_pro: data.phone,
            phone_perso: data.phonePerso,
            phone_mobile: data.phoneMobile,
            poste: data.job,
        });
    });

    const onSubmit: SubmitHandler<EditContactFormModel> = async (values) => {
        await mutateAsync(values);
        onClose && onClose('created');
    }

    return (
        <FormProvider {...methods}>
            <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogTitle id="alert-dialog-title">
                    Nouveau contact
                </DialogTitle>
                <DialogContent>
                    <ContactForm />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose && onClose()} variant={"text"}>
                        Annuler
                    </Button>

                    <LoadingButton
                        type={"submit"}
                        variant={"contained"}
                        loading={isLoading}
                        autoFocus
                    >
                        Ajouter
                    </LoadingButton>
                </DialogActions>
            </form>
        </FormProvider>
    )
}