import * as z from "zod";
import {convertToNumber} from "../../../utils";

export const ProposalLineSchema = z.object({
    product: z.object({
        id: z.string().optional().nullish(),
        label: z.string().min(1, {message: 'Libellé produit obligatoire'}).optional().nullish(),
        unitOfMeasureId: z.string().optional().nullish(),
        productType: z.coerce.number().optional(), // 0=Produit, 1=Service, 9=Titre
    }),

    description: z.string().optional().nullish(),

    qty: z.preprocess(convertToNumber, z.number({
        required_error: "Qté obligatoire",
        invalid_type_error: "Qté invalide"
    })
        .min(1, {message: 'Qté invalide'})
        .default(1)
    ),

    extraFields: z.object({
        tpsPose: z.preprocess(convertToNumber, z.number().default(0)),
        tauxMO: z.preprocess(convertToNumber, z.number().default(0)),
        coutFournitures: z.preprocess(convertToNumber, z.number().default(0))
    })
        .partial()
        .optional(),

    priceWithoutTax: z.preprocess(convertToNumber, z.number({
        required_error: "Prix HT obligatoire",
        invalid_type_error: "Prix HT invalide"
    })
        .gte(0, {message: 'Prix HT invalide'})
        .default(0)
    ),

    discount: z.preprocess(convertToNumber, z.number({
        invalid_type_error: "Remise invalide"
    })
        .gte(0, {message: 'Remise invalide'})
        .default(0)
        .optional()
    ),

    tvaTx: z.preprocess(convertToNumber, z.number({
        invalid_type_error: "Taux TVA invalide"
    })
        .gte(0, {message: 'TVA invalide'})
        .default(0)
        .optional()
    ),

    specialCode: z.coerce.number().default(0),

    rank: z.coerce.number({
        invalid_type_error: "Rang invalide"
    }),

    blockAutoRecalculation: z.boolean().default(false).nullish(),
    excludeFromTotal: z.boolean().default(false).nullish()
});

export const ProposalHeaderSchema = z.object({
    thirdParty: z.object({
        id: z.string().min(1, {message: 'Required'}),
        name: z.string().min(1, {message: 'Required'}),
        projectId: z.string().optional(),
    }, {
        invalid_type_error: "Tiers obligatoire",
        required_error: "Tiers invalide",
    }),

    periodOfValidity: z.coerce.number({
        invalid_type_error: "Durée de validité invalide"
    })
        .min(1, {message: 'Durée de validité obligatoire'}),

    paymentConditions: z.coerce.number({
        required_error: "Conditions de règlement est obligatoire",
        invalid_type_error: "Conditions de règlement invalide",
    }),

    extraFields: z.object({
        coutRevientPersonnel: z.preprocess(convertToNumber, z.number().default(0)), // Cout horaire de la main d'oeuvre. Combien me coute mon personnel ?
        margePersonnel: z.preprocess(convertToNumber, z.number().default(0)), // Coefficient utilisé pour marger le cout de revient de la main d'oeuvre
        coutVentePersonnel: z.preprocess(convertToNumber, z.number().default(0)), // Prix de vente prestation (marge comprise)
        margeFourniture: z.preprocess(convertToNumber, z.number().default(0)), // Coefficent utilisé pour marger sur les fournitures achetées (les coût des fournitures étant dans la fiche produit)
        puissancePV: z.preprocess(convertToNumber, z.number().gt(0, "Puissance PV obligatoire")), // Puissance PV se retrouvant dans la page de garde du devis
        reductionCO2: z.preprocess(convertToNumber, z.number().gt(0, "Réduction CO2 obligatoire")), // Réduction CO2 se retrouvant dans la page de garde du devis
    }),

    visiteTechnique: z.object({
        id: z.string(),
        reference: z.string(),
        date: z.coerce.date().optional(),
        photoId: z.string().optional(),
        photoToitureBase64: z.string().optional(), // La photo ici n'existe que lors de la complétion de l'étude. Dans une création de devis normal elle n'existe pas.
    })
        .optional()
        .nullish(),

    publicNotes: z.string().optional(),
    privateNotes: z.string().optional(),
});

export const CreateProposalSchema = ProposalHeaderSchema.extend({
    rows: z.array(ProposalLineSchema),
});

export const EditProposalHeaderSchema = ProposalHeaderSchema
    .omit({
        thirdParty: true,
    })
    .extend({
        endOfValidityDate: z.coerce.date().optional()
    })
    .partial();

export const EditProposalLineSchema = ProposalLineSchema
    .extend({
        id: z.string()
    })
;

export const EditProposalSchema = EditProposalHeaderSchema
    .extend({
        rows: z.array(EditProposalLineSchema),
    })
;