import {useInterventionsQuery} from "../Intervention/useInterventionsQuery";
import {InterventionsListContext} from "../Intervention/useInterventionsListContext";
import InterventionTable from "../Intervention/InterventionTable";
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";
import {NewButton} from "../Intervention/InterventionList";
import {ThirdParty} from "../../models/third-party.model";

export default function InterventionsCard({ id, thirdParty }: { id: string, thirdParty: ThirdParty }) {
    const query = useInterventionsQuery({
        customerId: String(id),
        // type: InterventionType.VISITE_TECHNIQUE
    });

    return (
        <IonCard style={{ background: 'white' }}>
            <IonCardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IonCardTitle>Actions</IonCardTitle>

                <NewButton thirdParty={thirdParty} onCreated={() => query.refetch()} />
            </IonCardHeader>
            <IonCardContent>
                {
                    (query.isLoading) && (
                        <>Chargement ...</>
                    )
                }

                {
                    (query.isError || query.data?.docs.length === 0) && (
                        <>Pas d'actions trouvées pour ce tiers</>
                    )
                }

                {
                    (!query.isLoading && !query.isError && query.data?.docs.length > 0) && (
                        <InterventionsListContext.Provider value={{ query }}>
                            <InterventionTable sx={{ minHeight: 250 }} showMode={'modal'} />
                        </InterventionsListContext.Provider>
                    )
                }
            </IonCardContent>
        </IonCard>
    )
}