import React from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {EditContactFormModel, updateContactSchema} from "./Form";
import {zodResolver} from "@hookform/resolvers/zod";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ContactForm from "./ContactForm";
import {useMutation, useQuery} from "@tanstack/react-query";
import {LoadingButton} from "@mui/lab";
import {updateContact} from "../../calls/Contacts/updateContact";
import {getContact} from "../../calls/Contacts/getContact";
import {IonSpinner} from "@ionic/react";
import {useEditContactDialogStore} from "./useEditContactDialogStore";
import {contactToString} from "../../models/contact.model";

interface EditContactDialogProps {
    thirdPartyId: number;
    onClose?: (reason?: 'updated') => void;
}

export default function EditContactDialog(props: EditContactDialogProps) {
    const { selectedContactId, isOpen, closeDialog } = useEditContactDialogStore();

    const { isLoading} = useGetContactQuery(selectedContactId);

    const handleClose = (reason?: 'updated') => {
        props.onClose && props.onClose(reason);
        closeDialog();
    }

    if (!selectedContactId) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose()}
            aria-labelledby="create-contact-dialog-title"
            aria-describedby="create-contact-dialog-description"
        >
            {
                isLoading ? (
                    <DialogContent>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            width: "100%",
                            height: '100%',
                            flexDirection: "column"
                        }}>
                            <IonSpinner name="lines"/>
                        </div>
                    </DialogContent>
                ) : (
                    <MainContent
                        {...props}
                        onClose={handleClose}
                    />
                )
            }
        </Dialog>
    )
}

function useGetContactQuery(id: string) {
    return useQuery(['contact', id], () => getContact(id), {
        enabled: !!id,
    });
}

function MainContent(props: EditContactDialogProps) {
    const { thirdPartyId, onClose} = props;

    const { selectedContactId: contactId, closeDialog } = useEditContactDialogStore();

    const { data: contact } = useGetContactQuery(contactId);

    const methods = useForm<EditContactFormModel>({
        resolver: zodResolver(updateContactSchema),
        defaultValues: {
            lastname: contact!.lastname,
            firstname: contact!.firstname,
            email: contact!.email,
            civility: contact!.civility_code,
            address: contact!.address,
            zip: contact!.zip,
            town: contact!.town,
            countryId: contact!.country_id ? Number(contact!.country_id) : undefined,
            notePrivate: contact!.note_private,
            notePublic: contact!.note_public,
            phone: contact!.phone_pro,
            phonePerso: contact!.phone_perso,
            phoneMobile: contact!.phone_mobile,
            job: contact!.poste,
        }
    });

    const {isLoading, mutateAsync} = useMutation((data: EditContactFormModel) => {
        return updateContact(contactId, {
            socid: thirdPartyId,
            lastname: data.lastname,
            firstname: data.firstname,
            email: data.email,
            civility_code: data.civility,
            address: data.address,
            zip: data.zip,
            town: data.town,
            country_id: data.countryId,
            note_private: data.notePrivate,
            note_public: data.notePublic,
            phone_pro: data.phone,
            phone_perso: data.phonePerso,
            phone_mobile: data.phoneMobile,
            poste: data.job,
        });
    });

    const onSubmit: SubmitHandler<EditContactFormModel> = async (values) => {
        await mutateAsync(values);
        onClose && onClose('updated');
    }

    return (
        <FormProvider {...methods}>
            <form noValidate autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
                <DialogTitle id="alert-dialog-title">
                    {contactToString(contact!)}
                </DialogTitle>
                <DialogContent>
                    <ContactForm />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} variant={"text"}>
                        Annuler
                    </Button>

                    <LoadingButton
                        type={"submit"}
                        variant={"contained"}
                        loading={isLoading}
                        autoFocus
                    >
                        Enregistrer
                    </LoadingButton>
                </DialogActions>
            </form>
        </FormProvider>
    )
}