import React from "react";
import ThirdPartyShow from "../components/ThirdPartyDetails/ThirdPartyShow";
import {useParams} from "react-router";

// https://dribbble.com/shots/20174752-Kirrivan-CRM-Lead-Details
// https://dribbble.com/shots/20128537-Kirrivan-CRM-Lead-Preview

export default function ThirdPartyDetail() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams<{ id: string }>();

    return <ThirdPartyShow id={id} />
}

