import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type DownloadDocumentPayload = {
    modulepart?: string;
    original_file?: string;
    attachment?: number,
    entity: number,
}

export async function deleteDocument(payload: DownloadDocumentPayload): Promise<void> {
    const { data } = await http.delete(Endpoint.documents, {
        params: payload,
    });

    return data;
}