import * as React from 'react';
import {PropsWithChildren, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, {TableRowProps} from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import {styled, TextFieldProps} from "@mui/material";
import {InlineTableColDef} from "./types";
import {Controller, FieldArrayWithId, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {v4 as uuidv4} from 'uuid';
import {StyledTextField} from "./StyledTextField";
import {AddCircle} from "@mui/icons-material";
import {FormattedNumber} from "react-intl";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import * as DOMPurify from 'dompurify';
import SearchProposalTemplatesDialog from "./SearchProposalTemplatesDialog";
import useSearchProposalTemplateDialogStore from "./useSearchProposalTemplateDialogStore";

const StyledTable = styled(Table)`
    & .MuiTableRow-root {
        vertical-align: top;
    }
    
    & .MuiTableRow-root:hover {
        background-color: rgba(0, 0, 0, 0.055)
    }
    
    & .MuiTableCell-root {
        padding: 0.5rem 0.3rem;
    }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily, // Use theme font for consistency
    fontSize: theme.typography.body1.fontSize, // Set consistent font size
    "&:has(.editing)": {
        background: "white",
        borderBottom: "2px solid",
        borderColor: theme.palette.primary.light,

        "&:has(.Mui-error)": {
            borderColor: theme.palette.error.light,
        }
    },

    "&:has(.error)": {
        borderBottom: "2px solid",
        borderColor: theme.palette.error.light,
    }
}));

export interface InlineTableControlProps {
    columns: InlineTableColDef[];
    sectionFieldName?: string;
    slots?: {
        tableRow: React.FC<PropsWithChildren<TableRowProps & { rowIndex: number }>>;
    },
    enableLinesCopy?: boolean;
    onLineEdited?(field: FieldArrayWithId<FormValues, "rows", "trackingId">, rowIndex: number): void;
    onLineDeleted?(field: FieldArrayWithId<FormValues, "rows", "trackingId">): void;
}

interface FormValues {
    rows: Array<{
        id: string,
        new?: boolean,
        product?: { productType: number },
        qty?: number;
        priceWithoutTax?: number;
        rank: number;
        specialCode?: number;
        blockAutoRecalculation?: boolean;
    }>
}

const subtotalModuleNumber = 104777;

export default function InlineTableControl({ columns: cols, sectionFieldName = 'label', slots, enableLinesCopy, onLineEdited, onLineDeleted }: InlineTableControlProps) {
    const editableCols = cols.filter(c => !c.disabledEdition);

    const formContext = useFormContext();

    const { fields, append, remove } = useFieldArray<FormValues, 'rows', 'trackingId'>({
        name: 'rows',
        keyName: 'trackingId'
    });

    const [editingCell, setEditingCell] = useState<{ rowId: string; fieldName: string; index: number } | null>(null);
    const textFieldRefs = useRef<Record<string, HTMLInputElement>>({});

    const handleCellClick = (rowId: string, fieldName: string, index: number) => {
        const editingIndex = cols.filter(c => !c.disabledEdition).findIndex(c => c.field === fieldName);

        if (editingIndex < 0) {
            return;
        }

        const isEditing = (editingCell?.rowId === rowId && editingCell?.fieldName === fieldName);

        if (isEditing) {
            return;
        }

        setEditingCell({rowId, fieldName, index});

        // Schedule a callback to focus the TextField after the update
        setTimeout(() => {
            if (textFieldRefs.current[`${rowId}-${fieldName}`]) {
                textFieldRefs.current[`${rowId}-${fieldName}`].select();
            }
        }, 0);
    };

    // Pour terminer l'édition d'une ligne
    const endRowEdition = (rowIndex: number, { notify = true }: { notify?: boolean } = {}) => {
        if (notify && onLineEdited) {
            onLineEdited(formContext.getValues(`rows.${rowIndex}`), rowIndex);
        }

        setEditingCell(null);
    }

    const handleOnInputBlur = (rowIndex: number) => () => {
        endRowEdition(rowIndex);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
        if (!editingCell) return;

        const {rowId, fieldName, index: colIndex} = editingCell;
        const currentRowIndex = fields.findIndex(field => field.id === rowId);

        const isLastRow = currentRowIndex === fields.length - 1;
        const isLastCell = colIndex === editableCols.length - 1;

        switch (event.key) {
            case 'Tab':
                if (event.shiftKey) {
                    // Move to previous cell (next column)
                    const nextCell = editableCols[colIndex - 1];
                    if (nextCell) {
                        handleCellClick(rowId, nextCell.field, colIndex - 1);
                    } else {
                        endRowEdition(currentRowIndex);
                    }
                } else {
                    if (isLastCell) {
                        // Move to next cell (next row or first cell of current row)
                        const nextRow = fields[currentRowIndex + 1];
                        if (nextRow) {
                            handleCellClick(nextRow.id, editableCols[0].field, 0);
                        } else {
                            endRowEdition(currentRowIndex);
                        }
                    } else {
                        // Move to next cell (next column)
                        const nextCell = editableCols[colIndex + 1];
                        handleCellClick(rowId, nextCell.field, colIndex + 1);
                    }
                }
                break;
            case 'Escape':
                endRowEdition(currentRowIndex, { notify: false }); // On termine l'édition mais comme c'est une annulation on ne notifie pas
                break;
            case 'Enter':
                // Pour le moment on enter on Termine l'édition
                endRowEdition(currentRowIndex);

                // À voir plus tard
                if (isLastRow) {
                    // Terminer l'édition si dernière ligne
                    // endRowEdition(currentRowIndex);
                } else {
                    // Move to the next row on Enter
                    // handleCellClick(fields[currentRowIndex + 1].id, editableCols[0].field, 0);
                }
                break;
            default:
                break;
        }
    };

    const handleAddRow = () => {
        const newRowId = uuidv4();
        const fieldName = editableCols[0].field;
        const lastRow = fields.at(-1);
        const newRank = (lastRow?.rank || 0) + 1;

        append({ id: newRowId, new: true, rank: newRank });

        // Set editing to the new row *and* schedule a focus on the name field after a slight delay
        setEditingCell({ rowId: newRowId, fieldName, index: 0 });
        setTimeout(() => {
            if (textFieldRefs.current[`${newRowId}-${fieldName}`]) {
                textFieldRefs.current[`${newRowId}-${fieldName}`].focus();
            }
        }, 0);
    };

    const handleAddSection = () => {
        const newRowId = uuidv4();
        const fieldName = sectionFieldName;
        const lastRow = fields.at(-1);
        const newRank = (lastRow?.rank || 0) + 1;

        append({
            id: newRowId,
            new: true,
            product: {
                productType: 9
            },
            rank: newRank,
            qty: 1,
            specialCode: subtotalModuleNumber,
        });

        // Set editing to the new row *and* schedule a focus on the name field after a slight delay
        setEditingCell({ rowId: newRowId, fieldName, index: 0 });
        setTimeout(() => {
            if (textFieldRefs.current[`${newRowId}-${fieldName}`]) {
                textFieldRefs.current[`${newRowId}-${fieldName}`].focus();
            }
        }, 0);
    };

    const handleAddSubtotal = () => {
        const newRowId = uuidv4();
        const fieldName = sectionFieldName;
        const lastRow = fields.at(-1);
        const newRank = (lastRow?.rank || 0) + 1;

        append({
            id: newRowId,
            new: true,
            [sectionFieldName]: 'Sous total',
            product: {
                productType: 9
            },
            qty: 99,
            specialCode: subtotalModuleNumber,
            rank: newRank,
        });

        // Set editing to the new row *and* schedule a focus on the name field after a slight delay
        setEditingCell({ rowId: newRowId, fieldName, index: 0 });
        setTimeout(() => {
            if (textFieldRefs.current[`${newRowId}-${fieldName}`]) {
                textFieldRefs.current[`${newRowId}-${fieldName}`].focus();
            }
        }, 0);
    };

    const deleteRow = (line: FieldArrayWithId<FormValues, "rows", "trackingId">, rowIndex: number) => {
        onLineDeleted && onLineDeleted(line);
        remove(rowIndex);
    }

    return (
        <>
            <TableContainer>
                <StyledTable size={"small"}>
                    <TableHead>
                        <TableRow>
                            {
                                cols.map(
                                    col => <TableCell key={`Header-${col.field}`}><b>{col.label}</b></TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            fields.map(
                                (field, rowIndex) => {
                                    // Ligne de titre ou de Sub total
                                    if (field.product?.productType === 9) {
                                        const isEditing = (editingCell?.rowId === field.id && editingCell?.fieldName === sectionFieldName);

                                        const colCount = cols.length;

                                        const isSubtotalLine = isSubtotal(field);

                                        // Ligne de sous total
                                        if (isSubtotalLine) {
                                            return (
                                                <TableRow key={field.id} style={{
                                                    backgroundColor: !isEditing ? '#f8f9fa' : 'white',
                                                }}>
                                                    <StyledTableCell
                                                        key={`Body-${field.id}`}
                                                        colSpan={colCount  - 1} // On enlève 1 pour afficher le bouton supprimer sur la ligne
                                                        onClick={() => handleCellClick(field.id, sectionFieldName, 0)}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Controller
                                                            name={`rows.${rowIndex}.${sectionFieldName}`}
                                                            defaultValue={""}
                                                            render={
                                                                ({ field: { value, onChange, onBlur, }, fieldState: { error } }) => {
                                                                    const hasError = !!error;

                                                                    if (isEditing) {
                                                                        const editingProps: TextFieldProps = {
                                                                            value,
                                                                            onChange,
                                                                            className: isEditing ? 'editing' : '',
                                                                            inputRef: el => (textFieldRefs.current[`${field.id}-${sectionFieldName}`] = el),
                                                                            onBlur: !hasError ? handleOnInputBlur(rowIndex) : () => {},
                                                                            onKeyDown: handleKeyDown,
                                                                            error: hasError,
                                                                        }

                                                                        return (
                                                                            <StyledTextField {...editingProps} error={!!error}/>
                                                                        )
                                                                    }

                                                                    return (
                                                                        <SubTotal
                                                                            label={value}
                                                                            rank={field.rank}
                                                                            hasError={hasError}
                                                                        />
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <IconButton
                                                            aria-label="delete-row"
                                                            size={"small"}
                                                            onClick={() => deleteRow(field, rowIndex)}
                                                            sx={{
                                                                padding: 0,
                                                                "&:hover": {
                                                                    backgroundColor: 'transparent',
                                                                }
                                                            }}
                                                        >
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </StyledTableCell>
                                                </TableRow>
                                            )
                                        }

                                        // Ligne de titre de section
                                        const colSpan = isEditing ? colCount : colCount - 1; // On enlève 1 pour les boutons d'actions propres au titre de section

                                        return (
                                            <TableRow key={field.id} style={{
                                                backgroundColor: !isEditing ? '#f8f9fa' : 'white',
                                            }}>
                                                <StyledTableCell
                                                    colSpan={colSpan}
                                                    onClick={() => handleCellClick(field.id, sectionFieldName, 0)}
                                                >
                                                    <Controller
                                                        name={`rows.${rowIndex}.${sectionFieldName}`}
                                                        defaultValue={""}
                                                        render={
                                                            ({ field: { value, onChange, onBlur, }, fieldState: { error } }) => {
                                                                const hasError = !!error;

                                                                if (isEditing) {
                                                                    const editingProps: TextFieldProps = {
                                                                        value,
                                                                        onChange,
                                                                        className: isEditing ? 'editing' : '',
                                                                        inputRef: el => (textFieldRefs.current[`${field.id}-${sectionFieldName}`] = el),
                                                                        onBlur: !hasError ? handleOnInputBlur(rowIndex) : () => {},
                                                                        onKeyDown: handleKeyDown,
                                                                        error: hasError,
                                                                    }

                                                                    return (
                                                                        <StyledTextField {...editingProps} error={!!error}/>
                                                                    )
                                                                }

                                                                const html = DOMPurify.sanitize(value);

                                                                return (
                                                                    <span
                                                                        className={hasError ? "error" : ""}
                                                                        style={{
                                                                            whiteSpace: 'pre-line',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        dangerouslySetInnerHTML={{ __html: html }}
                                                                    />
                                                                )
                                                            }
                                                        }
                                                    />
                                                </StyledTableCell>

                                                {
                                                    !isEditing && (
                                                        <StyledTableCell>
                                                            <IconButton
                                                                aria-label="delete-row"
                                                                size={"small"}
                                                                onClick={() => deleteRow(field, rowIndex)}
                                                                sx={{
                                                                    padding: 0,
                                                                    "&:hover": {
                                                                        backgroundColor: 'transparent',
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    )
                                                }
                                            </TableRow>
                                        )
                                    }

                                    const RowComponent = slots?.tableRow ? slots.tableRow : DefaultTableRow;

                                    return (
                                        <RowComponent
                                            key={field.id}
                                            rowIndex={rowIndex}
                                        >
                                            {
                                                cols.map(
                                                    (col, colIndex) => {
                                                        const isEditing = (editingCell?.rowId === field.id && editingCell?.fieldName === col.field);
                                                        const CellContentWrapper = col.CellContentWrapper ?? DefaultCellContentWrapper;

                                                        return (
                                                            <StyledTableCell
                                                                key={`Body-${col.field}`}
                                                                width={col.width}
                                                                onClick={() => handleCellClick(field.id, col.field, colIndex)}
                                                            >
                                                                <CellContentWrapper rowIndex={rowIndex}>
                                                                    {
                                                                        col.disabledEdition ? (
                                                                            col.renderCell
                                                                                ? col.renderCell({
                                                                                    value: (field as any)[col.field],
                                                                                    rowIndex,
                                                                                    field,
                                                                                    remove: (index) => {
                                                                                        deleteRow(field, index as number);
                                                                                    }
                                                                                })
                                                                                : (field as any)[col.field]
                                                                        ) : (
                                                                            <Controller
                                                                                name={`rows.${rowIndex}.${col.field}`}
                                                                                defaultValue={col.defaultValue || ""}
                                                                                render={
                                                                                    ({ field: { value, onChange, onBlur, }, fieldState: { error } }) => {
                                                                                        const hasError = !!error;

                                                                                        if (isEditing) {
                                                                                            const editingProps: TextFieldProps = {
                                                                                                value,
                                                                                                onChange,
                                                                                                className: isEditing ? 'editing' : '',
                                                                                                inputRef: el => (textFieldRefs.current[`${field.id}-${col.field}`] = el),
                                                                                                onBlur: !hasError ? handleOnInputBlur(rowIndex) : () => {},
                                                                                                onKeyDown: handleKeyDown,
                                                                                                error: hasError,
                                                                                            }

                                                                                            if (col.renderInput) {
                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            col.renderInput({
                                                                                                                value,
                                                                                                                onChange,
                                                                                                                rowIndex,
                                                                                                                formContext,
                                                                                                                textFieldProps: {
                                                                                                                    className: editingProps.className,
                                                                                                                    inputRef: editingProps.inputRef,
                                                                                                                    onBlur: editingProps.onBlur,
                                                                                                                    onKeyDown: editingProps.onKeyDown,
                                                                                                                    error: editingProps.error,
                                                                                                                },
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                            if (col.inputType === "textarea") {
                                                                                                return (
                                                                                                    <StyledTextField
                                                                                                        {...editingProps}
                                                                                                        multiline
                                                                                                    />
                                                                                                )
                                                                                            }

                                                                                            return (
                                                                                                <StyledTextField {...editingProps} error={!!error}/>
                                                                                            )
                                                                                        }


                                                                                        if (col.renderCell) {
                                                                                            return (
                                                                                                <>
                                                                                                    {col.renderCell({
                                                                                                        value,
                                                                                                        rowIndex,
                                                                                                        field,
                                                                                                        remove: (index) => {
                                                                                                            deleteRow(field, index as number);
                                                                                                        },
                                                                                                        error
                                                                                                    })}
                                                                                                </>
                                                                                            )
                                                                                        }

                                                                                        return (
                                                                                            <span
                                                                                                className={hasError ? "error" : ""}
                                                                                                style={{
                                                                                                    whiteSpace: 'pre-line',
                                                                                                }}
                                                                                            >
                                                                                        {value}
                                                                                    </span>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            />
                                                                        )
                                                                    }
                                                                </CellContentWrapper>
                                                            </StyledTableCell>
                                                        )
                                                    }
                                                )
                                            }
                                        </RowComponent>
                                    )
                                }
                            )
                        }
                        <TableRow>
                            <TableCell />
                            <TableCell colSpan={cols.length - 1}>
                                <Button size="small" onClick={handleAddRow} startIcon={<AddCircle />}>
                                    Ajouter une ligne
                                </Button>

                                <Button size="small" onClick={handleAddSection} startIcon={<AddCircle />}>
                                    Ajouter un titre
                                </Button>

                                <Button size="small" onClick={handleAddSubtotal} startIcon={<AddCircle />}>
                                    Ajouter un sous-total
                                </Button>

                                {
                                    enableLinesCopy && (
                                        <CopyLinesFromTemplateButton />
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </TableContainer>

            {
                enableLinesCopy && (
                    <SearchProposalTemplatesDialog
                        onClose={
                            (lines) => {
                                append(lines as any);
                            }
                        }
                    />
                )
            }
        </>
    );
}

function CopyLinesFromTemplateButton() {
    const { showModal } = useSearchProposalTemplateDialogStore();

    return (
        <Button size="small" onClick={() => showModal()} startIcon={<ControlPointDuplicateIcon />}>
            Copier les lignes depuis un modèle
        </Button>
    )
}

function DefaultTableRow({ children }: PropsWithChildren<TableRowProps & { rowIndex: number }>) {
    return (
        <TableRow>
            {children}
        </TableRow>
    );
}

function DefaultCellContentWrapper({ children }: PropsWithChildren<{ rowIndex: number }>) {
    return (
        <>{children}</>
    )
}

const isTitle = (line: FieldArrayWithId<FormValues, "rows", "trackingId">) => {
    return line.specialCode === subtotalModuleNumber && line?.product?.productType === 9 && (line?.qty || 0) <= 9;
}

function isSubtotal(line: FieldArrayWithId<FormValues, "rows", "trackingId">) {
    return line.specialCode === subtotalModuleNumber && line?.product?.productType === 9 && (line?.qty || 0) >= 90;
}

const getParentTitleOfLine = (reversed: FieldArrayWithId<FormValues, "rows", "trackingId">[], rank: number) => {
    let skipTitle = 0;

    for (const line of reversed) {
        if (line.rank >= rank) {
            continue; // Tout ce qui ce trouve en dessous j'ignore, nous voulons uniquement ce qui ce trouve au dessus
        }

        if (isTitle(line)) {
            if (skipTitle)
            {
                skipTitle--;
                continue;
            }

            //@INFO J'ai ma ligne titre qui contient ma ligne, par contre je check pas s'il y a un sous-total
            return line;
        }
        else if (isSubtotal(line))
        {
            // Il s'agit d'un sous-total, ça veut dire que le prochain titre théoriquement doit être ignorer (je travail avec un incrément au cas ou je croise plusieurs sous-totaux)
            skipTitle++;
        }
    }
}

function SubTotal({ label, rank, hasError }: {
    label: string;
    rank: number,
    hasError?: boolean,
}) {
    const fields = useWatch({ name: 'rows' }) as FieldArrayWithId<FormValues, "rows", "trackingId">[];
    const reversed = [...fields].reverse();

    const titleBreak = getParentTitleOfLine(reversed, rank);

    let totalHT = 0;

    for (const line of reversed) {
        if (line.rank >= rank) {
            continue;
        }

        if (titleBreak && titleBreak.id === line.id) {
            break;
        }

        if (line.product?.productType !== 9) {
            const priceWithoutTax = Number(line.priceWithoutTax);
            const qty = Number(line.qty || 1);
            totalHT += (priceWithoutTax * qty);
        }
    }

    return (
        <span
            className={hasError ? "error" : ""}
            style={{
                whiteSpace: 'pre-line',
                fontWeight: 'bold',
                fontSize: '.9rem',
            }}
        >
            <span style={{
                textDecoration: 'underline'
            }}>
                {label}:
            </span>&nbsp;
            <FormattedNumber
                value={totalHT}
                minimumFractionDigits={2}
            />
        </span>
    )
}