import {Endpoint} from "../endpoint.enum";
import {http} from "../../http";
import {Proposal} from "../../models/proposal.model";

type LineDto = {
    label: string;
    desc: string;
    qty: number;
    subprice: number;
    remise_percent: number;
    tva_tx: number;
    product_type: '0' | '1';
    fk_product?: string;
}

export async function editProposalLine(proposalId: number | string, lineid: string, dto: LineDto) {
    const url = Endpoint.proposalLine
        .replace(':id', `${proposalId}`)
        .replace(':lineId', lineid)
    ;
    const { data } = await http.put<Proposal>(url, dto);

    return data;
}