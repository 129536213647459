import {http} from "../../http";
import {PaginatedResponse} from "../../models/common.model";
import {Endpoint} from "../endpoint.enum";
import {Company} from "../../models/company.model";
import axios from "axios";

export type SearchCompanyPayload = {
    name?: string;
}

type Pagination = {
    sortfield: string,
    sortorder: string,
    limit: number
}

export async function listCompanies(token: string, pagination: Pagination = {sortfield: 't.rowid', sortorder: 'ASC', limit: 100}) {
    // const  dto = omitBy(payload, v => isNil(v) || isEmpty(v));
    const url = http.defaults.baseURL + Endpoint.company;
    const { data } = await axios.get<PaginatedResponse<Company>>(url,{
        params : {
            ...pagination
        },
        headers: {
            DOLAPIKEY: token,
        }
    });

    return data;
}