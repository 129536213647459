import TableCell from "@mui/material/TableCell";
import {useEventsListContext} from "./useEventsListContext";
import {DateTime} from "luxon";
import { ColDef } from "../Common/Table";
import {ActionComm} from "../../models/action-comm.model";
import PowerTable from "../Common/Table/PowerTable";
import React from "react";

type EventTableProps = {
    eventType?: 'foire' | 'default';
}

export default function EventTable(props: EventTableProps) {
    const cols = createCols(props.eventType);

    const {query} = useEventsListContext();

    const rows = query?.data?.items || [];

    return(
        <PowerTable
            name={`event - ${props.eventType} table`}
            cols={cols}
            rows={rows}
            renderCell={renderCell}
            loading={!!query?.isLoading}
            error={query?.error}
        />
    )
}

type ColId = 'id' | 'label' | 'responsable_label' | 'rabatteur_label' | 'vttiste_label' | 'vendeur_label' | 'location' | 'datep' | 'datef' | 'status';

function createCols(eventType?: 'foire' | 'default') {
    const cols: Array<ColDef<ColId>> = [
        { id: 'id', label: 'Référence' },
        { id: 'label', label: 'Libellé' },
    ];

    if (eventType === "foire") {
        cols.push(
            { id: 'responsable_label', label: 'Responsables' },
            { id: 'rabatteur_label', label: 'Rabatteurs' },
            { id: 'vttiste_label', label: 'Vendeurs' },
            { id: 'vendeur_label', label: 'VTTistes' },
            { id: 'location', label: 'Lieu' },
        );
    }

    cols.push(
        { id: 'datep', label: 'Date de début' },
        { id: 'datef', label: 'Date de fin' },
        { id: 'status', label: 'État' }
    );

    return cols;
}

function renderCell(key: ColId, value: any, row: ActionComm) {
    if (["datep", "datef"].indexOf(key) !== -1) {
        return <DateCell value={value} />;
    }

    if (key === "status") {
        return <StatusCell value={row.status} />;
    }

    if (['responsable_label', 'rabatteur_label', 'vttiste_label', 'vendeur_label'].indexOf(key) !== -1) {
        return (
            <TableCell style={{
                maxWidth: '210px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
            }}>
                {value}
            </TableCell>
        )
    }
}

function DateCell({ value }: { value?: number }) {
    if (!value) {
        return <TableCell />;
    }

    const d = DateTime.fromSeconds(value, { zone: "Europe/Paris" }).setLocale('fr');
    return <TableCell>{d.toLocaleString(DateTime.DATE_SHORT)}</TableCell>
}

function StatusCell({ value }: {value: string}) {
    const status = Number(value);

    if (status > 0 && status < 100) {
        return <TableCell>En cours</TableCell>
    }

    if (status == 100 ) {
        return <TableCell>Terminé</TableCell>
    }

    return <TableCell>N/A</TableCell>;
}