import {IonDatetime, IonModal} from "@ionic/react";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";

type HybridDatePickerProps<T, S> = Omit<DatePickerProps<T, S>, 'renderInput'>;

export default function HybridDatePicker<T, S>(props: HybridDatePickerProps<T, S>) {
    return (
        <>
            <DatePicker
                {...props}
                renderInput={
                    (params) => (
                        <TextField {...params} />
                    )
                }
            />

            <IonModal keepContentsMounted={true}>
                <IonDatetime id="datetime"></IonDatetime>
            </IonModal>
        </>
    )
}