import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {CreateProposalFormModel, EditProposalFormModel} from "./ProposalForm/types";
import {zodResolver} from "@hookform/resolvers/zod";
import {
    EditProposalHeaderSchema,
    EditProposalLineSchema,
    EditProposalSchema,
    ProposalLineSchema
} from "./ProposalForm/validators";
import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CircularProgress, Stack} from "@mui/material";
import React from "react";
import {useParams} from "react-router";
import {IonRouterLink} from "@ionic/react";
import ProposalForm from "./ProposalForm";
import {DevTool} from "@hookform/devtools";
import {useMutation} from "@tanstack/react-query";
import {editProposalLine} from "../../calls/Proposals/editProposalLine";
import {ProposalLineDto} from "../../calls/Proposals/createProposal";
import {addProposalLine} from "../../calls/Proposals/addProposalLine";
import {deleteProposalLine} from "../../calls/Proposals/deleteProposalLine";
import {Proposal} from "../../models/proposal.model";
import {ProposalFormContext} from "./ProposalForm/ProposalFormContext";
import {DateTime} from "luxon";
import {editProposal, EditProposalHeaderDto} from "../../calls/Proposals/editProposal";

/**
 * @deprecated Utilisée comme test. Se référer à AffaireShow pour tout ce qui est édition de devis
 * @constructor
 */
export default function EditProposal() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams<{ id: string }>();

    const { isError, error, isLoading, data: proposal } = useProposalDetail(id);

    if (isLoading) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
                maxHeight="100%"
            >
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress color="inherit" size="1.5rem" />
                </div>
            </Stack>
        )
    }

    if (isError) {
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
                maxHeight="100%"
            >
                <div style={{ textAlign: 'center' }}>
                    <p>Une erreur est survenue lors du chargement du devis</p>
                    {
                        error && (
                            <pre>
                                {(error as any).message}
                            </pre>
                        )
                    }
                    <IonRouterLink routerLink={`/page/Affaires`}>
                        Revenir à la liste
                    </IonRouterLink>
                </div>
            </Stack>
        )
    }

    return (
        <Box>
            <Typography variant="h6" component={"p"}>
                Nouveau devis
            </Typography>

            <MainForm id={id} />
        </Box>
    )
}

const proposalToEditProposalFormModel = (proposal: Proposal): EditProposalFormModel => {
    return ({
        endOfValidityDate: DateTime.fromSeconds(proposal.fin_validite, { zone: "Europe/Paris" }).toJSDate(),
        paymentConditions: proposal.cond_reglement_id || '',
        extraFields: {
            coutRevientPersonnel: Number(proposal.array_options?.options_cout_revient_mo || 0),
            margePersonnel: Number(proposal.array_options?.options_coeff_marge_mo || 0),
            coutVentePersonnel: Number(proposal.array_options?.options_prix_vente_mo || 0),
            margeFourniture: Number(proposal.array_options?.options_coeff_marge_fo || 0),
            puissancePV: Number(proposal.array_options?.options_puissance_pv || 0),
            reductionCO2: Number(proposal.array_options?.options_reduction_co2 || 0),
        },
        publicNotes: proposal.note_public,
        privateNotes: proposal.note_private,
        rows: proposal.lines.map(
            line => {
                return {
                    id: line.id,
                    product: {
                        id: line.fk_product,
                        label: line.product_label,
                        unitOfMeasureId: line.fk_unit,
                        productType: Number(line.product_type || 0),
                    },
                    description: line.description || line.label,
                    qty: Number(line.qty),
                    priceWithoutTax: Number(line.subprice),
                    discount: line.remise,
                    tvaTx: Number(line.tva_tx),
                    specialCode: Number(line.special_code),
                    rank: Number(line.rang),
                    blockAutoRecalculation: false,
                    excludeFromTotal: line.array_options?.options_excluded_from_total_ht === "1",
                    extraFields: {
                        tpsPose: Number(line.array_options?.options_tps_pose || 0),
                        tauxMO: Number(line.array_options?.options_tx_mo || 0),
                        coutFournitures: Number(line.array_options?.options_cout_fournitures || 0),
                    },
                }
            }
        )
    })
}

function MainForm({ id }: { id: string }) {
    const { data } = useProposalDetail(id);

    const proposal = data!;

    const methods = useForm<EditProposalFormModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: zodResolver(EditProposalSchema),
        defaultValues: proposalToEditProposalFormModel(proposal),
    });

    const editProposal = useEditProposal(id);

    // On submit ici ne gère que le save des infos du headers, les lignes se gérant toutes seules
    const onSubmit: SubmitHandler<EditProposalFormModel> = async (values) => {
        const data = EditProposalHeaderSchema.parse(values);

        const dto: EditProposalHeaderDto = {
            fin_validite: data.endOfValidityDate ? Math.floor(data.endOfValidityDate.getTime() / 1000) : undefined,
            cond_reglement_id: Number(data.paymentConditions || '0'),
            array_options: {
                cout_revient_mo: data.extraFields?.coutRevientPersonnel || 0,
                coeff_marge_mo: data.extraFields?.margePersonnel || 0,
                prix_vente_mo: data.extraFields?.coutVentePersonnel || 0,
                coeff_marge_fo: data.extraFields?.margeFourniture || 0,
                puissance_pv: data.extraFields?.puissancePV || 0,
                reduction_co2: data.extraFields?.reductionCO2 || 0,
            },
            note_private: data.privateNotes,
            note_public: data.publicNotes
        };

        try {
            await editProposal(dto);
        } catch (e) {
            console.error(e);
        }
    }

    const updateLine =  useEditProposalLine(id);
    const addLine = useAddProposalLine(id);
    const deleteLine = useDeleteProposalLine(id);

    return (
        <>
            <FormProvider {...methods}>
                <ProposalFormContext.Provider value={{
                    submitOnBlur() {
                        methods.handleSubmit(onSubmit)();
                    }
                }}>
                    <ProposalForm
                        hiddenControls={{
                            thirdParty: true,
                            periodOfValidity: true,
                        }}
                        additionalControls={{
                            endOfValidityDate: true,
                        }}
                        onLineEdited={
                            async (line, rowIndex) => {
                                try {
                                    // On est sur une création
                                    if (line.new) {
                                        const parsed = ProposalLineSchema.parse(line);
                                        const lineId = await addLine(parsed);

                                        // Après l'ajout la ligne n'est plus considérée comme nouvelle. Donc on unset is new
                                        methods.setValue(`rows.${rowIndex}.new` as any, false);

                                        // Après l'ajout on replace l'id UUID par l'id venant de l'api
                                        methods.setValue(`rows.${rowIndex}.id`, lineId.toString());
                                    } else {
                                        const parsed = EditProposalLineSchema.parse(line);
                                        await updateLine(parsed);
                                    }
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                        }
                        onLineDeleted={
                            async (line) => {
                                // Ne pas gérer le lignes "new"
                                if (line.new) {
                                    return
                                }

                                try {
                                    // call api pour delete la ligne
                                    await deleteLine(line.id);

                                    // Gestion spécifique pour déréférencer la ligne supprimée du formulaire et
                                    // éviter des comportement indésirables lors de l'ajout d'une nouvelle ligne
                                    methods.reset(proposalToEditProposalFormModel({
                                        ...proposal,
                                        lines: proposal.lines.filter(l => l.id !== line.id),
                                    }));
                                } catch (e) {
                                    console.error(e);
                                }
                            }
                        }
                    />
                </ProposalFormContext.Provider>
            </FormProvider>

            <DevTool control={methods.control} />
        </>
    )
}

function useEditProposal(id: string) {
    const { mutateAsync } = useMutation((dto: EditProposalHeaderDto) => editProposal(id, dto));

    return (dto: EditProposalHeaderDto) => mutateAsync(dto);
}

function useEditProposalLine(proposalId: string) {
    const { mutateAsync} = useMutation(({ lineId, dto }: { lineId: string, dto: ProposalLineDto }) => editProposalLine(proposalId, lineId, dto as any));

    return async (l: EditProposalFormModel["rows"][0]) => {
        await mutateAsync({
            lineId: l.id,
            dto: {
                label: l.product?.label || '',
                desc: l.description || '',
                qty: l.qty,
                fk_product: l.product?.id || '',
                tva_tx: l.tvaTx || 0,
                subprice: l.priceWithoutTax,
                product_type: l.product?.productType || 0,
                special_code: l.specialCode,
                fk_unit: l.product?.unitOfMeasureId,
                rang: l.rank,
                array_options: {
                    tx_mo: l.extraFields?.tauxMO || 0,
                    tps_pose: l.extraFields?.tpsPose || 0,
                    cout_fournitures: l.extraFields?.coutFournitures || 0,
                    excluded_from_total_ht: l?.excludeFromTotal ? 1 : undefined,
                }
            }
        })
    }
}

function useAddProposalLine(proposalId: string) {
    const { mutateAsync} = useMutation(({ dto }: { dto: ProposalLineDto }) => addProposalLine(proposalId, dto as any));

    return async (l: CreateProposalFormModel["rows"][0]) => {
        return await mutateAsync({
            dto: {
                label: l.product?.label || '',
                desc: l.description || '',
                qty: l.qty,
                fk_product: l.product?.id || '',
                tva_tx: l.tvaTx || 0,
                subprice: l.priceWithoutTax,
                product_type: l.product?.productType || 0,
                special_code: l.specialCode,
                fk_unit: l.product?.unitOfMeasureId,
                rang: l.rank,
                array_options: {
                    tx_mo: l.extraFields?.tauxMO || 0,
                    tps_pose: l.extraFields?.tpsPose || 0,
                    cout_fournitures: l.extraFields?.coutFournitures || 0,
                    excluded_from_total_ht: l?.excludeFromTotal ? 1 : undefined,
                }
            }
        })
    }
}

function useDeleteProposalLine(proposalId: string) {
    const { mutateAsync} = useMutation(({ lineId }: { lineId: string }) => deleteProposalLine(proposalId, lineId));

    return (lineId: string) => mutateAsync({ lineId });
}