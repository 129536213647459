import {http} from "../../http";
import {Endpoint} from "../endpoint.enum";

type Payload = {
    dateStart: string; // "2023-09-01 00:00:00"
    dateEnd: string; // "2023-09-30 23:59:59"
}

type Pagination = {
    sortfield: string,
    sortorder: string,
    limit: number
}

interface Row {
    dateRef: string;
    invoiceId: string;
    invoiceRef: string;
    thirdParty: string;
    amount: string;
    operationType: string;
    accountCode: string;
    accountLabel: string;
}

interface Response {
    items: Array<Row[]>,
    toExport: string[],
}

export async function fetchExportCompta(payload: Payload, pagination: Pagination = {sortfield: 't.rowid', sortorder: 'ASC', limit: 100}) {
    const { data } = await http.post<Response>(Endpoint.reportsExportCompta, payload);

    return data;
}