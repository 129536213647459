import {useState} from "react";

export default function useToggleOpen(initialValue: boolean = false) {
    const [open, setOpen] = useState<boolean>(initialValue);

    const show = () => setOpen(true);
    const hide = () => setOpen(false);

    return {
        open,
        show,
        hide,
    }
}