import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {DateTime} from "luxon";
import InfoIcon from "@mui/icons-material/Info";

export default function EtudePlanifieeBEAlert({ cardId, status, statusUpdatedAt }: { cardId: string, status: string, statusUpdatedAt: string }) {
    if (status !== 'ETUDE_PLANIFIEE') {
        return null;
    }

    if (!statusUpdatedAt) {
        return null;
    }

    const toFormat= 'dd/MM/yyyy HH:mm:ss';
    const sentAt = DateTime.fromISO(statusUpdatedAt);
    const raw = sentAt.toFormat(toFormat);
    const relative = sentAt.toRelativeCalendar();

    return (
        <Box sx={{ mt: 1 }}>
            <Stack direction="row" alignItems="center" gap={1}>
                <InfoIcon fontSize={"small"} />
                <Typography variant={"caption"}>
                    <b>Planifiée {relative}</b><br />
                    {raw}
                </Typography>
            </Stack>
        </Box>
    )
}